import { Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from '@app/core/models/drupal8';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { StaticValuesListService } from '@app/core/services/static-values-list/static-values-list.service';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectorComponent),
      multi: true,
    },
  ],
})
export class CountrySelectorComponent implements OnDestroy {
  private static countries: Country[];

  @Input() needOnlyCountryCode = true;
  @Input() placeholder = '';
  @Input() iconPrepend: string;
  @Input() showError = false;
  focusCountry$ = new Subject<string>();
  clickCountry$ = new Subject<string>();
  currentCountry: Country;
  currentCenterModel = new FormControl(null);
  isDisabled = false;
  placementCountryAutocomplete = 'bottom-left';
  onChange;
  onTouch;
  inputCountry: NgbTypeahead;

  private needToSetValue: string | Country;
  private unsubscribeAll = new Subject<void>();

  constructor(private i18nService: I18nService, private staticValuesListService: StaticValuesListService) {
    this.fetchCountries();
    this.i18nService.changedSelectedLanguage$.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.needToSetValue = this.currentCountry?.code;
      this.fetchCountries(true);
    });
  }

  @ViewChild('input_country', { static: true }) set inputForCountry(input: NgbTypeahead) {
    this.inputCountry = input;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getFlagClass(code: string): string {
    return code.toLocaleLowerCase();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  writeValue(countryIn: string | Country): void {
    if (!countryIn) {
      delete this.currentCountry;
    }
    if (!CountrySelectorComponent.countries) {
      this.needToSetValue = countryIn;
      return;
    }

    this.setValue(countryIn);
  }

  changedCountry(event: NgbTypeaheadSelectItemEvent) {
    this.currentCountry = event.item;
    this.onChange(this.needOnlyCountryCode ? this.currentCountry.code : this.currentCountry);
  }
  countryFormmatter = (x: Country) => x.name;

  searchCountry: OperatorFunction<string, readonly Country[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickCountry$.pipe(filter(() => !this.inputCountry?.isPopupOpen()));
    const inputFocus$ = this.focusCountry$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? CountrySelectorComponent.countries ?? []
          : CountrySelectorComponent.countries?.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, 265)
      )
    );
  };

  private setValue(countryIn: string | Country) {
    const index = CountrySelectorComponent.countries.findIndex(
      (country) => country.code === (this.needOnlyCountryCode ? countryIn : (countryIn as Country).code)
    );
    this.currentCountry = index === -1 ? null : CountrySelectorComponent.countries[index];
  }
  private fetchCountries(force = false) {
    if (CountrySelectorComponent.countries && !force) {
      return;
    }
    delete CountrySelectorComponent.countries;
    return this.staticValuesListService.getCountries().subscribe({
      next: (data: Country[]) => {
        CountrySelectorComponent.countries = data;
        if (this.needToSetValue) {
          this.setValue(this.needToSetValue);
          delete this.needToSetValue;
        }
        return;
      },
    });
  }
}
