<div
  class="d-flex flex-column overflow-scroll card text-white h-100 p-1"
  *ngIf="navDataService.currentUserProfile"
  [ngClass]="cssClass"
  [style.background-image]="bgGradient"
>
  <div class="flex-grow-1">
    <div class="buttons row m-0" data-html2canvas-ignore="true">
      <div class="col-6 d-flex">
        <button
          class="btn btn-icon btn-icon-mini d-flex align-items-center justify-content-center bg-success rounded-1"
          (click)="share()"
          *ngIf="!inSharing; else shareLoader"
        >
          <i class="icon icon-share-alt"></i>
          <!-- <i *ngIf="!shareIcon" class="icon" [ngClass]="copied ? 'icon-clipboard-check' : 'icon-copy'"></i> -->
        </button>
        <!-- *ngIf="!inSharing; else shareLoader" -->
        <!-- <button
          class="btn btn-icon d-flex align-items-center justify-content-center btn-icon-mini bg-success rounded-1"
          [ngClass]="{ 'ms-1': shareIcon }"
          (click)="navDataService.navigate('https://www.ikointl.com/account/home', true)"
        >
          <i class="icon icon-arrow-circle-right-2 icon-rotate-90 icon-16"></i>
        </button> -->
        <ng-template #shareLoader>
          <circular-spinner iconClass="icon-32 mt-3"></circular-spinner>
        </ng-template>
      </div>
      <div class="col-6 text-end">
        <button
          class="btn btn-icon btn-icon-mini bg-light-transparent-1 rounded-1"
          (click)="navDataService.toggleVCard()"
        >
          <div class="d-flex align-items-center justify-content-center">
            <i class="icon icon-times icon-16"></i>
          </div>
        </button>
      </div>
    </div>
    <!-- !inSharing && customService.connected cause html2canvas not capturing img url
        without connection and even cors error and no image so load local one -->
    <div class="col-7 col-md-6 mx-auto text-center">
      <image-srcset
        routerLink="/profile"
        (click)="pictureMissing ? navDataService.toggleVCard() : null"
        imgClass="rounded-circle box-shadow w-100"
        [ngClass]="{ 'square-img': !getProfileImage() }"
        [item]="getProfileImage()"
      >
      </image-srcset>
    </div>
    <div class="card-body py-2 px-3">
      <h4 class="card-title text-center m-0">{{ navDataService.currentUserProfile.field_first_name }}</h4>
      <h4 class="card-title text-center m-0">{{ navDataService.currentUserProfile.field_surname }}</h4>

      <div class="row">
        <div class="col-6">
          <div class="small">IKO#</div>
          <div for="uid">
            {{ navDataService.currentUserProfile.uid }}
          </div>
          <!--<div for="cert_date">
            {{ navDataService.currentUserProfile?.last_certification_date }}
          </div>-->
        </div>

        <div class="col-6 text-end">
          <i
            class="icon d-flex justify-content-end"
            [ngClass]="{
              'icon-shield': navDataService.currentUserProfile.field_user_insured.toLowerCase() !== 'not insured'
            }"
            *ngIf="navDataService.currentUserProfile.field_user_insured"
          >
            <span class="ms-1" translate>{{ navDataService.currentUserProfile.field_user_insured }}</span>
          </i>
          <div for="start_date" *ngIf="navDataService.currentUserProfile.subscription_starts">
            {{ navDataService.currentUserProfile.subscription_starts.split(' - ')[0] }}
          </div>
          <div for="expiry_date" *ngIf="navDataService.currentUserProfile.subscription_expiry">
            {{ navDataService.currentUserProfile.subscription_expiry.split(' - ')[0] }}
          </div>
        </div>
      </div>

      <div class="row text-center text-white">
        <div class="col-12 px-3 pt-3">
          <h2
            *ngIf="navDataService.currentUserProfile.top_qualification"
            class="top-qual my-3 d-flex justify-content-center align-items-center"
          >
            {{ navDataService.currentUserProfile.top_qualification }}
            <i
              class="icon icon-info-circle ms-1"
              *ngIf="navDataService.currentUserProfile?.field_training_level_skills?.length > 0"
              (click)="toggleLevel()"
            ></i>
          </h2>
          <!-- <h3 *ngIf="!navDataService.currentUserProfile.top_qualification" class="mt-3">
            {{ rolesService.roleName }}
            <i
              class="icon icon-info-circle"
              *ngIf="navDataService.currentUserProfile.field_training_level_skills.length > 0"
              (click)="toggleLevel()"
            ></i>
          </h3> -->
        </div>
        <!-- <div class="col-12 px-3 align-items-center">
          <badges-skill-list
            extraClasses="justify-content-center"
            [skills]="navDataService.currentUserProfile.field_training_level_skills"
          ></badges-skill-list>         
        </div> -->
        <div *ngIf="pictureMissing" class="lr-0 py-3 bg-light-transparent-7 text-center position-absolute m-auto">
          <h2 class="m-0 fw-bold text-success" routerLink="/profile" (click)="navDataService.toggleVCard()" translate>
            Missing profile picture
          </h2>
        </div>
        <div *ngIf="expired" class="lr-0 py-3 bg-light-transparent-7 text-danger text-center position-absolute m-auto">
          <h1 *ngIf="expired" class="m-0 p-3 fw-bold" translate>Expired</h1>
        </div>
        <!-- <div *ngIf="!navDataService.currentUserProfile.field_trqualificationdate"
          class="lr-0 py-3 bg-light-transparent-7 text-danger text-center position-absolute m-auto">
          <h1 *ngIf="expired" class="m-0 p-3 fw-bold" translate>Uncertified</h1>
        </div> -->
      </div>
    </div>
  </div>
  <div class="justify-content-center row px-3" *ngIf="!levelOpen">
    <div class="col-10 col-md-8">
      <img
        id="iko-logo"
        width="300"
        height="90"
        class="d-block responsive-height m-auto mb-3"
        ngSrc="/assets/images/iko-logo.svg"
        alt="iko logo"
      />
    </div>
  </div>
  <div class="justify-content-center text-start px-3 h-100 overflow-scroll" *ngIf="levelOpen">
    <ul class="extra-small">
      <li id="skill" *ngFor="let trainingLevel of trainingLevels">
        {{ trainingLevel.code }}: {{ trainingLevel.skills.join(', ') }}
      </li>
    </ul>
  </div>
</div>
