import { Injectable } from '@angular/core';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { ToolsService } from '../tools/tools.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Center } from '@app/core/models';

export interface CenterForAutocomplete {
  id: number | string;
  label: string;
  objectValues: Center;
  picture: string;
}

@Injectable({
  providedIn: 'root',
})
export class CentersService {
  constructor(private viewService: ViewService, private tools: ToolsService) {}

  /**
   * Fetch centers from the API, filtered and paginatead by the viewOptions parameter.
   *
   * @param viewOptions
   *   View options to filter and paginate the results.
   * @returns
   *   OBSERVABLE: Array of centers.
   */
  getCenters(viewOptions?: ViewOptions): Observable<any> {
    return this.viewService.get('/api/v1.1/centers.json', viewOptions).pipe(
      map((centers) =>
        centers.map((center) => {
          center.title = this.tools.encodeHTMLEntities(center.title);
          return center;
        })
      )
    );
  }

  /**
   * Fetch centers from the API, to show in the maps.
   *
   */
  getCentersForMap(): Observable<any> {
    return this.viewService.get('/api/v1/map_centers.json');
  }

  /**
   * Fetch centers from the API for the autocomplete inputboxes, filtered and paginatead by the viewOptions parameter.
   *
   * @param viewOptions
   *   View options to filter and paginate the results.
   * @returns
   *   OBSERVABLE: Array of centers.
   */
  getCentersForAutoComplete(viewOptions?: ViewOptions): Observable<any> {
    return this.getCenters(viewOptions).pipe(
      map((centers) =>
        centers.map((center) => ({
          id: center.nid,
          // eslint-disable-next-line max-len
          label: `${center.title} (${center.field_school_address_locality} - ${center.field_school_address_country_code} )`,
          objectValues: center,
          picture: center.field_school_logo?.school_square_md,
        }))
      )
    );
  }

  /**
   * Fetch centers from the API for the autocomplete inputboxes,
   * filtered by searchedText param and paginatead by itemsPerPage param.
   *
   * @param searchedText
   *   Text to search in the centers.
   * @param itemsPerPage
   *   Number of items per page.
   * @returns
   *   OBSERVABLE: Array of centers.
   */
  getCentersForAutoCompleteByText(searchedText: string, itemsPerPage = 50): Observable<any> {
    return this.getCentersForAutoComplete({
      filters: { full_search: searchedText, country: searchedText },
      pagination: { items_per_page: itemsPerPage, page: 0 },
    });
  }

  /**
   * Fetch center from the API for the autocomplete inputboxes, defined by id.
   *
   * @param id
   *   Id of the center.
   * @returns
   *   OBSERVABLE: Array of centers.
   */
  getCentersForAutoCompleteById(id: string | number): Observable<CenterForAutocomplete | null> {
    return this.getCenters({
      filters: { full_search: id },
    }).pipe(
      map((centers) => {
        if (centers.length === 0) {
          return null;
        }
        centers = centers.map((center) => ({
          id: center.nid,
          // eslint-disable-next-line max-len
          label: `${center.title} (${center.field_school_address_locality} - ${center.field_school_address_country_code} )`,
          objectValues: center,
          picture: center.field_school_logo?.school_square_md,
        }));
        return centers[0];
      })
    );
  }
}
