import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoogleMapService } from '@app/core/services/tools';
import { BehaviorSubject } from 'rxjs';

export interface DistanceFromMapValue {
  lat: number;
  lng: number;
  distance?: number;
  formattedAddress?: string;
}

@Component({
  selector: 'distance-from-map-selector',
  templateUrl: './distance-from-map-selector.component.html',
  styleUrls: ['./distance-from-map-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DistanceFromMapSelectorComponent),
      multi: true,
    },
  ],
})
export class DistanceFromMapSelectorComponent implements ControlValueAccessor {
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() showAdressSearchBar = true;
  @Input() getMyPosition = true;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() showDistanceSelector = true;

  currentValue: DistanceFromMapValue;
  selectedPlaceHolder: string;
  mapCenter$: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject<google.maps.LatLngLiteral>(null);
  distanceValues = {
    min: 1,
    max: 200,
  };
  distanceConfig = {
    start: this.distanceValues.min,
    behaviour: 'snap',
    connect: 'lower',
    range: this.distanceValues,
    step: 1,
    tooltips: true,
  };
  distance: FormControl = new FormControl(this.distanceValues.min);
  currentZoom = 13;
  confirmed = false;
  currentAutoCompleteText = '';
  mapOptions: google.maps.MapOptions = {
    ...this.googleMapsService.standardMapOptions,
    fullscreenControl: false,
    clickableIcons: false,
  };

  private myPosition: google.maps.LatLngLiteral;

  constructor(public googleMapsService: GoogleMapService) {
    this.googleMapsService.initGoogleMaps().subscribe();
    if (this.getMyPosition) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.myPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const toSetCenterOnMap = !this.currentValue;
        if (!toSetCenterOnMap) {
          return;
        }
        this.currentValue = this.myPosition;
        this.setPinOnMap(toSetCenterOnMap);
      });
    }
    this.distance.valueChanges.subscribe(() => {
      if (!this.currentValue) {
        this.currentValue = this.myPosition;
      }
      this.setPinOnMap();
    });
  }

  @Input() set initialDistance(distance: number) {
    this.distance.setValue(distance);
    this.calculateCurrentZoom(this.currentValue?.lat ?? this.myPosition?.lat ?? 0);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get value(): DistanceFromMapValue | null {
    return this.currentValue;
  }

  set value(val: DistanceFromMapValue | null) {
    this.writeValue(val);
    this.onChange(val);
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: DistanceFromMapValue | null) => {};

  onTouched = () => {};

  writeValue(value: DistanceFromMapValue | null): void {
    this.currentValue = value;
    this.generatePlaceHolder();
    this.setPinOnMap(true, true);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * handle the click on the map
   *
   * @param {google.maps.MapMouseEvent | google.maps.IconMouseEvent} $event
   */
  mapClick($event: google.maps.MapMouseEvent | google.maps.IconMouseEvent): void {
    this.currentValue = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
    this.setPinOnMap();
  }

  /**
   * handle the address choose from the autocomplete
   *
   * @param address new address
   * @returns
   */
  handleAddressChange(address?: any) {
    if (!(address && address.geometry)) {
      return;
    }
    this.value = {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      formattedAddress: address.formatted_address,
    };
    this.setPinOnMap(true);
  }

  increaseDistance(): void {
    if (this.distance.value < this.distanceValues.max) {
      this.distance.setValue(this.distance.value + 1);
    }
  }

  decreaseDistance(): void {
    if (this.distance.value > this.distanceValues.min) {
      this.distance.setValue(this.distance.value - 1);
    }
  }

  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    this.distance.setValue(this.distanceValues.min);
    this.value = null;
  }

  /**
   * add the pin to the map from the location selected by user
   * and calculate automatically the zoom base by the distance
   * if the parameter setCenter is true center the pin on the map
   *
   * @param {boolean} setCenter specify if the pin has to be center in the map
   * @returns
   */
  private setPinOnMap(setCenter = false, notPropagate = false) {
    if (!notPropagate) {
      this.onChange(this.currentValue);
    }
    if (this.currentValue) {
      this.currentValue.distance = this.distance.value;

      /**
       * original formula to calculate meter per pixel from lat and current zoom
       *
       * (156543.03392 * Math.cos((this.currentValue.lat * Math.PI) / 180)) /
       * this.distance.value /
       * Math.pow(2, this.currentZoom)
       */

      this.calculateCurrentZoom(this.currentValue.lat);
      if (setCenter) {
        this.mapCenter$.next({ lat: this.currentValue.lat, lng: this.currentValue.lng });
      }
      this.generatePlaceHolder();
      return;
    }
    if (this.myPosition) {
      this.mapCenter$.next({ lat: this.myPosition.lat, lng: this.myPosition.lng });
      this.calculateCurrentZoom(this.myPosition.lat);
      return;
    }
  }

  private calculateCurrentZoom(latitude: number) {
    this.currentZoom =
      Math.round(
        Math.log((156543.03392 * Math.cos((latitude * Math.PI) / 180)) / this.distance.value / 15) / Math.log(2)
      ) + 1;
  }

  /**
   * generate the placeholder for the field
   *
   */
  private generatePlaceHolder(): void {
    if (!this.currentValue) {
      this.selectedPlaceHolder = null;
      this.currentAutoCompleteText = '';
      return;
    }
    if (this.currentValue.formattedAddress) {
      this.formatPlacholder();
      return;
    }

    this.googleMapsService.reverseGeocoding(this.currentValue.lat, this.currentValue.lng).subscribe((place) => {
      if (this.currentValue) {
        this.currentValue.formattedAddress = place.formatted_address;
        this.currentAutoCompleteText = place.formatted_address;
        this.formatPlacholder();
      }
    });
  }

  /**
   * format the string for the placeholder in case
   * the user select a point and distance
   *
   */
  private formatPlacholder() {
    this.selectedPlaceHolder = `${this.currentValue.formattedAddress} - ${this.currentValue.distance}Km`;
  }
}
