<ngb-accordion #acc="ngbAccordion">
  <ngb-panel [cardClass]="itemsSelected.length > 0 ? 'border border-success' : ''">
    <ng-template ngbPanelTitle>
      <div class="d-flex align-items-center">
        <div
          class="flex-fill {{ itemsSelected.length > 0 ? 'text-success fw-bold' : '' }} icon-16"
          [innerHTML]="selectedPlaceHolder ?? placeHolder"
        ></div>
        <div class="text-end p-0 pe-3">
          <i class="icon icon-32 text-success icon-angle-down"></i>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div *ngFor="let item of internalItems">
        <div class="form-check pe-3">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [checked]="item.selected" (click)="toggleCheck(item)" />
            <span class="form-check-sign"></span>
            <div class="d-inline">
              {{ item.label }} <span class="small grey">{{ item.numberOfElements }}</span>
            </div>
          </label>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
