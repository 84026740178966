import { animate, style, transition, trigger } from '@angular/animations';

export const growDown = trigger('growDown', [
  transition(
    ':enter',
    [style({ height: '0px' }), animate('300ms ease-in-out', style({ height: '{{heightContainer}}' }))],
    { params: { heightContainer: '0px' } }
  ),
  transition(':leave', [animate('300ms ease-in-out', style({ height: '0px' }))]),
]);
