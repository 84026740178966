import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { ConfirmModalComponent } from './confirm-modal.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService extends ModalServiceModel {
  protected component = ConfirmModalComponent;
  protected options: NgbModalOptions = { size: 'lg' };
}
