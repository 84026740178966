import { Injectable } from '@angular/core';
import { File as CordovaFile } from '@ionic-native/file/ngx';
import { BehaviorSubject, Observable, Subject, filter, from, map, mergeMap, of } from 'rxjs';
import { DeviceDetectService } from '../device-detect/device-detect.service';
@Injectable({
  providedIn: 'root',
})
export class StorageCacheService {
  cacheFolderName = 'downloads';
  cordovaReady: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private cacheDirectory;

  constructor(private file: CordovaFile, private deviceDetectService: DeviceDetectService) {
    if (!deviceDetectService.isCordova) {
      this.cordovaReady.next(true);
    } else {
      document.addEventListener('deviceready', () => this.cordovaReady.next(true), false);
      this.initPathCacheDirectory().subscribe();
    }
  }

  get cacheFolderNamePath$(): Observable<string> {
    if (this.cacheDirectory) {
      return of(this.cacheDirectory);
    }
    return this.initPathCacheDirectory();
  }

  /**
   * cache the file using cordova file plugin
   *
   * @param content: content object encoded
   */
  cacheHttpResponse(file: any, fileName: string): void {
    // convert the object to json
    const jsonContent: string = JSON.stringify(file);

    // save file in the cache directory
    this.cacheFolderNamePath$.subscribe((cacheFolderNamePath) => {
      this.file
        .writeFile(cacheFolderNamePath, fileName + '.json', jsonContent, { replace: true })
        ?.then()
        .catch((err: any) => console.log(err, fileName, file));
    });
  }

  /**
   * getting file from cache
   *
   * @param fileName cache file name
   */
  getCachedHttpResponse(fileName: string): Observable<any> {
    // get the promise
    if (!fileName) {
      return of(null);
    }
    return this.cacheFolderNamePath$.pipe(
      mergeMap((cacheFolderNamePath) => {
        const promise = this.file.readAsText(cacheFolderNamePath, fileName + '.json');
        // convert it to observable "rxjs forever <3!"
        const observable = from(promise);
        // map the result and return the file
        return observable.pipe(map((result) => JSON.parse(result)));
      })
    );
  }

  /**
   * Empty all device file caches
   */
  removeAllCaches(): void {
    // check if cordova is available
    if (!this.deviceDetectService.isCordova) {
      return;
    }
    // return observable of remove
    this.file.removeRecursively(this.file.cacheDirectory, this.cacheFolderName).then(() => {
      this.initCacheDirectory();
    });
  }

  /**
   * Create directory using file plugin
   */
  initCacheDirectory(): void {
    // check if directory already inited then do nothing
    this.file
      .checkDir(this.file.cacheDirectory, this.cacheFolderName)
      .then((directoryExists) => {
        if (directoryExists) {
          return;
        }
        this.file.createDir(this.file.cacheDirectory, this.cacheFolderName, false);
      })
      // WEB-1835 - the checkdir won't return false in case not exist but error, in some android device
      .catch(() => this.file.createDir(this.file.cacheDirectory, this.cacheFolderName, false));
  }

  private initPathCacheDirectory(): Observable<string> {
    return this.cordovaReady.pipe(
      filter((ready) => ready),
      map(() => {
        this.initCacheDirectory();
        return (this.cacheDirectory = this.file.cacheDirectory + this.cacheFolderName + '/');
      })
    );
  }
}
