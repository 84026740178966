import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DateValidators {
  static greaterThan(startControl: AbstractControl): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startValue: { year: number; month: number; day: number } = startControl.value;
      const endtValue: { year: number; month: number; day: number } = endControl.value;
      if (!startValue || !endtValue) {
        return null;
      }
      const startDate = new Date();
      const endDate = new Date();
      startDate.setFullYear(startValue.year);
      startDate.setMonth(startValue.month - 1);
      startDate.setDate(startValue.day);
      endDate.setFullYear(endtValue.year);
      endDate.setMonth(endtValue.month - 1);
      endDate.setDate(endtValue.day);
      if (startDate >= endDate) {
        return { greaterThan: true };
      }
      return null;
    };
  }
}
