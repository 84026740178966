<div class="d-flex vh-100 flex-column">
  <beta-bar></beta-bar>
  <offline-bar></offline-bar>

  <ng-sidebar-container class="flex-fill">
    <ng-sidebar
      *ngIf="!isFullpage"
      [(opened)]="navDataService.sideMenuOpen"
      [closeOnClickOutside]="true"
      [showBackdrop]="true"
      [closeOnClickBackdrop]="true"
      [autoFocus]="false"
    >
      <app-sidebar *ngIf="!rolesService.userHasRole()" class="d-inline d-lg-none"></app-sidebar>
      <app-annonymous-sidebar *ngIf="rolesService.userHasRole()"></app-annonymous-sidebar>
    </ng-sidebar>

    <div ng-sidebar-content class="h-100 d-flex flex-column flex-lg-row">
      <div
        id="desktop-sidemenu-container"
        class="px-0 col-3 desktop-sidemenu-container d-none d-lg-flex"
        *ngIf="!rolesService.userHasRole()"
      >
        <app-sidebar class="w-100"></app-sidebar>
      </div>
      <div class="d-flex flex-column col-12 {{ rolesService.userHasRole() ? 'col-lg-12' : 'col-lg-9' }} px-0 h-100">
        <header class="header" *ngIf="navDataService.displayHeader" [ngClass]="{ 'd-none d-lg-block': isFullpage }">
          <gap-fixer-iphone></gap-fixer-iphone>
          <app-header class="bg-primary sticky-top navbar z-index-0 p-0 m-0"></app-header>
        </header>
        <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''" class="content d-flex flex-column flex-grow-1">
          <router-outlet #o="outlet" class="content-outlet"></router-outlet>
        </div>
      </div>
    </div>
  </ng-sidebar-container>

  <app-vcard
    class="fixed-top floating-card"
    [ngClass]="{ mobile: deviceDetectService.isMobile }"
    [@slideInOut]="navDataService.vCardOpen"
  ></app-vcard>
</div>
