import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSafeAreaDirective } from './bottom-safe-area.directive';
import { CloseKeyboardOnEnterDirective } from './close-keyboard-on-enter.directive';
import { DynamicHtmlComponentDirective } from './dynamic-html-component.directive';
import { ElementHoverDirective } from './element-hover.directive';
import { FormControlStatusDirective } from './form-control-status.directive';
import { NgVarDirective } from './ng-var.directive';
import { ResponsiveBgImageDirective } from './responsive-bg-image.directive';
import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete.directive';

const directives = [
  BottomSafeAreaDirective,
  CloseKeyboardOnEnterDirective,
  DynamicHtmlComponentDirective,
  ElementHoverDirective,
  FormControlStatusDirective,
  NgVarDirective,
  ResponsiveBgImageDirective,
  GooglePlacesAutocompleteDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
