export interface IdTitle {
  id: string | number;
  title: string;
}

export interface IdTitleUidCreated extends IdTitle {
  uid: number;
  created: string;
}

/**
 * for endpoint api/v1/terms.json
 */
export interface NameTid {
  name: string;
  tid: number;
}

export interface LatLon {
  lat: number;
  lon: number;
}

export interface ResponsiveImageResource {
  header_fhd?: string;
  header_hd?: string;
  header_md?: string;
  header_sd?: string;
  header_uhd?: string;
  landscape_fhd?: string;
  landscape_hd?: string;
  landscape_md?: string;
  landscape_sd?: string;
  portrait_hd?: string;
  portrait_md?: string;
  portrait_sd?: string;
  square_hd?: string;
  square_md?: string;
  square_sd?: string;
  square_xsd?: string;
}

/**
 * for api/countries
 */
export interface Country {
  code: string;
  name: string;
}

/**
 * for api/languages
 */
export interface Language {
  id: string;
  label: string;
  direction: 'ltr' | 'rtl';
}

export interface IdDisplayName {
  id: number;
  display_name: string;
}

/**
 * @path /api/spoken-languages
 */
export interface SpokenLanguage {
  lang_code: string;
  name_en: string;
  name_lang: string;
  lang_trans: string;
  direction?: 'rtl';
}

export interface IdLabel {
  id: number;
  label: string;
}

export interface SpotForecast {
  url: string;
  title: string;
  frameborder: number;
  transparency: number;
  tokensupport: number;
  allowfullscreen: number;
  scrolling?: string;
  height?: number;
  width?: number;
  class?: string;
}

/**
 * for /api/currencies
 */
export interface CurrencyResponse {
  list: Country[];
  preferred: string;
}

/**
 * @path /admin/config/media/image-styles
 */
export const imageStyleWidths = {
  header_uhd: 3840,
  header_fhd: 1920,
  header_hd: 1280,
  header_md: 1024,
  header_sd: 640,
  landscape_fhd: 1920,
  landscape_hd: 1280,
  landscape_md: 854,
  landscape_sd: 640,
  portrait_hd: 1024,
  portrait_md: 512,
  portrait_sd: 256,
  square_hd: 512,
  square_md: 128,
  square_sd: 64,
  square_xsd: 32,
  school_square_hd: 512,
  school_square_md: 256,
  school_square_sd: 128,
  school_square_xsd: 32,
};

export const imageMinStyleWidths = {
  header_uhd: 1921,
  header_fhd: 1281,
  header_hd: 1025,
  header_md: 641,
  landscape_fhd: 1281,
  landscape_hd: 855,
  landscape_md: 641,
  portrait_hd: 513,
  portrait_md: 257,
  square_hd: 129,
  square_md: 65,
  square_sd: 33,
  school_square_hd: 257,
  school_square_md: 129,
  school_square_sd: 33,
};

export const biggetSizeWidths = {
  header_uhd: true,
  landscape_fhd: true,
  square_hd: true,
  school_square_hd: true,
};

export interface Details {
  details: string;
}
export interface DetailsIconIncluded extends Details {
  included: number;
  icon?: string;
  // dev
  title: string;
  description?: string;
}
export interface DetailsImage extends Details {
  image: ResponsiveImageResource;
}

export interface ValueEndValue {
  value: string;
  end_value: string;
}

export interface NidTitle {
  nid: number;
  title: string;
}

export interface IframeUrlTitle {
  iframe_url: string;
  iframe_title: string;
}

export interface IdTitleNameShortName extends IdTitle {
  name: string;
  short_name: string;
}

export interface NidTitleBody extends NidTitle {
  body: string;
}
