import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf,
  Type,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SplashScreenService } from '@app/core/services/splash-screen/splash-screen.service';
import { DynamicEntityAutocompleteComponent } from '@app/shared/dynamic-forms-custom-components/dynamic-entity-autocomplete/dynamic-entity-autocomplete.component';
import { DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE } from '@app/shared/dynamic-forms-custom-components/dynamic-entity-autocomplete/dynamic-entity-autocomplete.model';
import { DynamicMarkupComponent } from '@app/shared/dynamic-forms-custom-components/dynamic-markup/dynamic-markup.component';
import { DYNAMIC_FORM_CONTROL_TYPE_MARKUP } from '@app/shared/dynamic-forms-custom-components/dynamic-markup/dynamic-markup.model';
import { DynamicNowUiCheckboxGroupComponent } from '@app/shared/dynamic-forms-custom-components/dynamic-nowui-checkbox-group/dynamic-nowui-checkbox-group.component';
import { DynamicNowUiCheckboxComponent } from '@app/shared/dynamic-forms-custom-components/dynamic-nowui-checkbox/dynamic-nowui-checkbox.component';
import { ShellModule } from '@app/shell/shell.module';
import { DrupalConstants as Drupal8Constants, Drupal8RestModule } from '@makiwin/ngx-drupal8-rest';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  DYNAMIC_FORM_CONTROL_MAP_FN,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DynamicFormControl,
  DynamicFormControlModel,
} from '@ng-dynamic-forms/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { Angulartics2Module } from 'angulartics2';
import { NgCircleProgressModule } from 'ng-circle-progress';
// import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxMaskModule } from 'ngx-mask';
import { Observable, of, tap } from 'rxjs';
// import { cookieConfig } from './cookie-config';
import { SidebarModule } from 'ng-sidebar-v2';
import { drupal8Settings } from './drupal8-config';
import { UserProfile } from './models';
import { RouteReusableStrategy } from './route-reusable-strategy';
import {
  AuthGuard,
  CustomErrorHandler,
  I18nService,
  LoadingInterceptorService,
  NavDataService,
  PlatformGuard,
  RetryInterceptorService,
} from './services';

export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

export const init =
  (injector: Injector): (() => Observable<UserProfile>) =>
  () => {
    const platform = injector.get(PLATFORM_ID);
    if (isPlatformBrowser(platform)) {
      return injector
        .get(NavDataService)
        .initProfile()
        .pipe(tap(() => injector.get(SplashScreenService).hide()));
    }
    return of(null);
  };

@NgModule({
  imports: [
    CommonModule,
    ShellModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    RouterModule,
    BrowserAnimationsModule,
    Drupal8RestModule,
    SidebarModule.forRoot(),
    Angulartics2Module.forRoot(),
    SocialLoginModule,
    NgCircleProgressModule.forRoot(),
    ScrollToModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptorService,
      multi: true,
    },
    I18nService,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [Injector],
      multi: true,
    },
    // NavDataService,
    // EreaderControllerService,
    AuthGuard,
    // DeviceDetectService,
    PlatformGuard,
    // MessageService,
    // CustomService,
    // ModalManagerService,
    // RolesService,
    // BookingService,
    // DynamicWebformService,
    // ImagePickerCropperService,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null => {
        switch (model.type) {
          case DYNAMIC_FORM_CONTROL_TYPE_MARKUP:
            return DynamicMarkupComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE:
            return DynamicEntityAutocompleteComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
            return DynamicNowUiCheckboxGroupComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
            return DynamicNowUiCheckboxComponent;
        }
      },
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('454627567982311'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
    this.initDrupal8Services();
  }

  initDrupal8Services() {
    Drupal8Constants.init(drupal8Settings);
  }
}

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}
