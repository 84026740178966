import { Component, Input } from '@angular/core';

@Component({
  selector: 'circular-check-icon',
  templateUrl: './circular-check-icon.component.html',
  styleUrls: ['./circular-check-icon.component.scss'],
})
export class CircularCheckIconComponent {
  @Input() backgroundColorClass = 'bg-secondary';
  @Input() foregroundColorClass = 'text-white';
}
