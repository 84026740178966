import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { fadeAnimation, sideInOut } from '@app/core/animations';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { ModalManagerService } from '@app/core/services/modal-manager/modal-manager.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [sideInOut, fadeAnimation],
})
export class ShellComponent implements AfterViewChecked {
  isFullpage = false;
  isBeta = false;

  constructor(
    public navDataService: NavDataService,
    public deviceDetectService: DeviceDetectService,
    public rolesService: RolesService,
    private modalManagerService: ModalManagerService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd && event.snapshot.component !== undefined))
      .subscribe((event: ActivationEnd) => {
        this.isFullpage = event.snapshot.data?.fullPage;
      });
    this.checkOnBoot();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  prepareRoute(outlet: RouterOutlet) {
    /* eslint-disable @typescript-eslint/dot-notation */
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private checkOnBoot(): void {
    // check if the page is full page
    let url;
    if (this.router.config[0].path !== 'en') {
      const urls = this.router.routerState.snapshot.url.slice(1).split('/');
      urls.unshift();
      url = urls.join('/');
    } else {
      url = this.router.routerState.snapshot.url.slice(1);
    }
    for (const route of this.router.config) {
      if (route.path === url) {
        this.isFullpage = route.data?.fullPage;
        return;
      }
    }
  }
}
