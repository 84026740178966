import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { CustomService } from './drupal8/custom/custom.service';
import { environment } from '@env/environment';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private get customService() {
    return this.injector.get(CustomService);
  }

  handleError(error: any) {
    // your custom error handling logic
    if (typeof error === 'function') {
      error = error();
    }
    let stack = '';
    if (error.hasOwnProperty('stack')) {
      stack = error.stack;
    } else {
      stack = this.getStackTrace();
    }
    this.customService.sendLog(
      'error',
      (error.message ?? JSON.stringify(error)) + ' | stack trace: ' + stack + ` | url: ${window.location?.href}`
    );
    if (!environment.production) {
      throw error;
    }
  }

  private getStackTrace = () => {
    const obj: any = {};
    if (Error.captureStackTrace) {
      Error.captureStackTrace(obj, this.getStackTrace);
      return obj.stack;
    } else {
      return Error().stack;
    }
  };
}
