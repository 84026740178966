import { Pipe, PipeTransform } from '@angular/core';
import { CenterList } from '@app/core/models/drupal8/center';
// import { UrlResolverService } from '@app/core/services/url-resolver/url-resolver.service';

@Pipe({
  name: 'centerUrl',
})
export class CenterUrlPipe implements PipeTransform {
  // constructor(private urlResolver: UrlResolverService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(center: CenterList): string {
    // let url_alias = center.url_alias ?? `/centers/${center.nid}`;

    // if (url_alias.startsWith('/node')) {
    //   url_alias = url_alias.replace('/node', '/centers');
    // }
    // return this.urlResolver.cleanLanguagePrefix(url_alias);
    return '';
  }
}
