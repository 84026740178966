import { Injectable } from '@angular/core';
import { WebformService, WebformSubmission } from '@makiwin/ngx-drupal8-rest';
import { Observable } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import {
  DynamicFormModel,
  DynamicFormService,
  DynamicFormControlModel,
  DynamicRadioGroupModel,
  DynamicInputModel,
  DynamicTextAreaModel,
  DynamicFileUploadModel,
  DynamicSelectModel,
  DynamicCheckboxModel,
  DynamicCheckboxGroupModel,
  DynamicFormGroupModel,
  DynamicRatingModel,
  DynamicFormArrayModel,
} from '@ng-dynamic-forms/core';
import { map, mergeMap } from 'rxjs/operators';
import { DynamicMarkupModel } from '@app/shared/dynamic-forms-custom-components/dynamic-markup/dynamic-markup.model';
import { DynamicEntityAutocompleteModel } from '@app/shared/dynamic-forms-custom-components/dynamic-entity-autocomplete/dynamic-entity-autocomplete.model';
import { CustomService } from '../custom/custom.service';
import { I18nService } from '../../i18n/i18n.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { CountryISO } from 'ngx-intl-tel-input';
import { TranslateService } from '@ngx-translate/core';

/**
 * TODO: update interfaces in ngx drupal 8 rest
 */

/**
 * Webform dynamic forms creater and parser using Factory design pattern
 *
 * @see https://github.com/gztchan/design-patterns-in-typescript/blob/master/factory-method/factory-method.ts
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicWebformService {
  private submission: WebformSubmission;
  constructor(
    private webformService: WebformService,
    private dynamicFormService: DynamicFormService,
    private customService: CustomService,
    private i18nService: I18nService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private translateService: TranslateService
  ) {}

  getControl(group: FormGroup, model: DynamicFormModel, name: string) {
    const controlModel = this.dynamicFormService.findById(name, model);
    // TODO: add support for form array elements
    if (controlModel) {
      return this.dynamicFormService.findControlByModel(controlModel, group);
    }
  }

  submitWebForm(formGroup: FormGroup, formModel: DynamicFormModel, submissionId?: string) {
    const formValues: any = this.prepareValues(formGroup, formModel);
    this.triggerGtmWebFormsEvent(formValues);

    let observer: Observable<any> = this.webformService.submit(formValues);
    if (submissionId) {
      observer = this.webformService.updateSubmission(formGroup.value.webform_id, submissionId, formValues);
    }
    return observer;
  }

  prepareValues(formGroup: FormGroup, formModel: DynamicFormModel) {
    const finalValues = {};
    const controlNames = this.getModelControls(formModel);
    controlNames.forEach((controlName) => {
      const controlModel = this.dynamicFormService.findById(controlName, formModel);
      const control = this.dynamicFormService.findControlByModel(controlModel, formGroup);
      if (controlModel instanceof DynamicCheckboxGroupModel) {
        finalValues[controlName] = [];
        Object.keys(control.value).forEach((value) => {
          if (control.value[value]) {
            finalValues[controlName].push(value);
          }
        });
      } else if (controlModel instanceof DynamicCheckboxModel) {
        finalValues[controlName] = control.value ? '1' : '0';
      } else {
        finalValues[controlName] = control.value;
      }
    });
    return finalValues;
  }

  getModelControls(formModel: any[]): string[] {
    let controls = [];
    formModel.forEach((formGroupModel) => {
      if (!(formGroupModel instanceof DynamicCheckboxGroupModel) && formGroupModel.group) {
        controls = controls.concat(this.getModelControls(formGroupModel.group as any));
      } else {
        controls.push(formGroupModel.id);
      }
    });
    return controls;
  }

  /**
   * Fetch a dynamic webform and return structured FormGroup using ngDynamicForm module
   *
   * @param webformMachineName Drupal webform machine name "its called id in the webform settings page"
   */
  getAndCreate(
    webformMachineName: string,
    sid?: string
  ): Observable<{ formGroup: FormGroup; formModel: DynamicFormModel }> {
    if (sid) {
      return this.webformService.getSubmission(webformMachineName, sid, this.i18nService.language).pipe(
        mergeMap((submission) => {
          this.submission = submission;
          return this.webformService
            .fields(webformMachineName, this.i18nService.language)
            .pipe(map((fields) => this.create(fields, webformMachineName)));
        })
      );
    }
    return this.webformService
      .fields(webformMachineName, this.i18nService.language)
      .pipe(map((fields) => this.create(fields, webformMachineName)));
  }

  /**
   * Create dynamic form from webform fields object
   *
   * @param fields fields object to create controls of
   */
  create(
    fields: any,
    webformMachineName: string
  ): {
    formGroup: FormGroup;
    formModel: DynamicFormModel;
  } {
    const generatedFormModel = this.parseAndCreateFromFields(fields);
    const data = {
      activity_type: 'view',
      entity_type: 'webform_submission',
      id: 0,
      submission_type: webformMachineName,
    };
    this.customService.activityTracking(data).subscribe();
    // push webform_id
    generatedFormModel.push(
      new DynamicInputModel({
        id: 'webform_id',
        validators: {
          required: null,
        },
        hidden: true,
        value: webformMachineName,
      })
    );
    const generatedFormGroup = this.dynamicFormService.createFormGroup(generatedFormModel);
    if (this.submission) {
      Object.keys(this.submission.data).forEach((fieldName) => {
        const field = this.submission.data[fieldName];
        if (Array.isArray(field)) {
          const model = this.dynamicFormService.findById(fieldName, generatedFormModel);
          if (!model) {
            return;
          }
          const control = this.dynamicFormService.findControlByModel(model, generatedFormGroup);
          if (!control || !(control instanceof FormArray)) {
            return;
          }
          field.map((o) => {
            Object.keys(o).forEach((key) => {
              if (o[key] === '0') {
                o[key] = false;
              } else if (o[key] === '1') {
                o[key] = true;
              }
            });
            return o;
          });
          control.patchValue(field);
        } else {
          return;
        }
      });
      delete this.submission;
    }
    // return structured object contains the form and the model
    return {
      formGroup: generatedFormGroup,
      formModel: generatedFormModel,
    };
  }

  parseAndCreateFromFields(fields: any): DynamicFormModel {
    const models = [];
    // loop over the fields
    Object.keys(fields).forEach((controlName) => {
      // get field object
      const field = fields[controlName];
      // if the field is not of form actions
      if (controlName !== 'actions' && field && typeof field === 'object' && !(field instanceof Array)) {
        // create new control and add it to the array
        const control = this.createControlGroupArray(field);
        if (control) {
          models.push(control);
        }
      }
    });
    return models;
  }

  /**
   * Parse each form control alone and create dynamic form control model of it
   *
   * @param field the field to parse
   */
  createControlGroupArray(field: any): DynamicFormControlModel | DynamicFormGroupModel | DynamicFormArrayModel {
    const controlMethodName = `${field['#type']}ControlCreate`;
    if (this[controlMethodName]) {
      return this[controlMethodName](field);
    } else if (field['#webform_composite']) {
      return this.createFormArray(field);
    } else {
      return this.createFormGroup(field);
    }
  }

  triggerGtmWebFormsEvent(formValues: any): void {
    this.angulartics2GoogleTagManager.pushLayer({
      event: 'web_forms',
      form: JSON.stringify(formValues),
    });
  }

  private createFormGroup(field: any): DynamicFormGroupModel {
    const models = this.parseAndCreateFromFields(field);
    let labelString = '';
    if (field['#title']) {
      labelString = this.getFieldLabel(field);
    }
    return new DynamicFormGroupModel({
      id: this.getFieldKey(field),
      label: labelString,
      group: models,
      hidden: field['#access'] === false,
    });
  }

  private createFormArray(field: any): DynamicFormArrayModel {
    let length = 1;
    if (this.submission && this.submission.data[this.getFieldKey(field)]) {
      length = (this.submission.data[this.getFieldKey(field)] as any).length;
    }
    const models = this.parseAndCreateFromFields(field['#webform_composite_elements']);
    const keyId = this.getFieldKey(field);
    return new DynamicFormArrayModel({
      id: keyId,
      initialCount: length,
      hidden: field['#access'] === false,
      groupFactory: () => models,
    });
  }

  /**
   * Create control static validators
   * @param field the webform field to parse
   * @returns object of field validators
   */
  private createControlValidators(field: any) {
    const validators: any = {};
    if (field['#required']) {
      validators.required = null;
    }
    if (field['#type'] === 'email') {
      validators.email = null;
    }
    // TODO handle other validators
    return validators;
  }

  /**
   * Create radios field
   *
   * @param field the webform field to parse
   */
  private radiosControlCreate(field: any): DynamicRadioGroupModel<string> {
    const optionClasses =
      'bg-transparent border border-info col btn d-flex justify-content-center align-items-center me-2 my-2 rounded-3 text-primary custom-radio';
    let fieldValue: any;
    if (this.submission) {
      fieldValue = this.submission.data[field['#webform_key']];
    }
    return new DynamicRadioGroupModel<string>(
      {
        id: this.getFieldKey(field),
        label: field['#title'] + '' + (field['#required'] ? '<span class="text-danger"> *</span></label>' : ''),
        options: Object.keys(field['#options']).map((optionValue) =>
          // map to value, label as required in dynamic forms type radio
          ({ value: optionValue, label: field['#options'][optionValue] })
        ),
        value: fieldValue,
        validators: this.createControlValidators(field),
        // add other properties here
      },
      {
        element: {
          option: optionClasses,
          control: 'col-12',
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create select field
   *
   * @param field the webform field to parse
   */
  private selectControlCreate(field: any): DynamicSelectModel<string> {
    return new DynamicSelectModel<string>(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        options: Object.keys(field['#options']).map((optionValue) =>
          // map to value, label as required in dynamic forms type radio
          ({ value: optionValue, label: field['#options'][optionValue] })
        ),
        value: field['#default_value'],
        validators: this.createControlValidators(field),
        hidden: field['#access'] === false,
        // hidden: true,
        // add other properties here
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create date field
   *
   * @param field the webform field to parse
   */
  private dateControlCreate(field: any): DynamicInputModel {
    return new DynamicInputModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        placeholder: field['#title'],
        inputType: 'date',
        name: this.getFieldKey(field),
        // toggleLabel: 'Click',
        validators: this.createControlValidators(field),
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
        },
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create textfield field
   *
   * @param field the webform field to parse
   */
  private textfieldControlCreate(field: any): DynamicInputModel {
    let submissionValue = '';
    if (this.submission && this.submission.data[this.getFieldKey(field)]) {
      submissionValue = this.submission.data[this.getFieldKey(field)] as any;
    }
    return new DynamicInputModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        placeholder: field['#title'],
        name: this.getFieldKey(field),
        inputType: 'text',
        validators: this.createControlValidators(field),
        hidden: field['#access'] === false,
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
        },
        // additional: {
        //   states: field['#states'],
        // },
        value: submissionValue,
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create email field
   *
   * @param field the webform field to parse
   */
  private emailControlCreate(field: any): DynamicInputModel {
    return new DynamicInputModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        placeholder: field['#title'],
        validators: this.createControlValidators(field),
        name: this.getFieldKey(field),
        inputType: 'email',
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
          email: field['#title'] + ' is not valid',
        },
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create tel field
   *
   * @param field the webform field to parse
   */
  private telControlCreate(field: any): DynamicInputModel {
    return new DynamicInputModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        placeholder: field['#title'],
        validators: this.createControlValidators(field),
        inputType: 'tel',
        name: this.getFieldKey(field),
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create textarea field
   *
   * @param field the webform field to parse
   */
  private textareaControlCreate(field: any): DynamicTextAreaModel {
    let submissionValue = '';
    if (this.submission && this.submission.data[this.getFieldKey(field)]) {
      submissionValue = this.submission.data[this.getFieldKey(field)] as any;
    }
    return new DynamicTextAreaModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        rows: 5,
        validators: this.createControlValidators(field),
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
        },
        value: submissionValue,
      },
      {
        element: {
          container: 'py-2',
          control: 'rounded-textarea',
        },
      }
    );
  }

  /**
   * Create managed_file field
   *
   * @param field the webform field to parse
   */
  // temp until the resource fixed at the backend
  private managed_fileControlCreate(field: any): DynamicFileUploadModel {
    // return new DynamicInputModel({
    //   id: this.getFieldKey(field),
    //   label: this.getFieldLabel(field),
    //   inputType: 'file',
    //   validators: this.createControlValidators(field),
    //   name: this.getFieldKey(field),
    //   multiple: field['#multiple'],
    //   accept: field['#file_extensions']?.split(/,| /),
    //   errorMessages: {
    //     required: field['#required'] ? field['#title'] + ' is required.' : null,
    //   },
    //   additional: {
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     button__title: field['#button__title'],
    //   },
    // });
    return new DynamicFileUploadModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        validators: this.createControlValidators(field),
        name: this.getFieldKey(field),
        multiple: field['#multiple'],
        accept: field['#file_extensions']?.split(/,| /),
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
        },
        additional: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          button__title: field['#button__title'],
        },
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create checkbox field
   *
   * @param field the webform field to parse
   */
  private checkboxControlCreate(field: any): DynamicCheckboxModel {
    let submissionValues = '0';
    if (this.submission && this.submission.data[this.getFieldKey(field)]) {
      submissionValues = this.submission.data[this.getFieldKey(field)] as any;
    }
    const option = new DynamicCheckboxModel({
      id: this.getFieldKey(field),
      label: this.getFieldLabel(field),
      validators: this.createControlValidators(field),
      name: this.getFieldKey(field),
      errorMessages: {
        required: field['#required'] ? field['#title'] + ' is required.' : null,
      },
    });
    option.checked = submissionValues === '1';
    return option;
  }
  /**
   * Create checkboxes field
   *
   * @param field the webform field to parse
   */

  private checkboxesControlCreate(field: any): DynamicCheckboxGroupModel {
    let submissionValues = [];
    if (this.submission && this.submission.data[this.getFieldKey(field)]) {
      submissionValues = this.submission.data[this.getFieldKey(field)] as any;
    }
    return new DynamicCheckboxGroupModel({
      id: this.getFieldKey(field),
      label: this.getFieldLabel(field),
      group: Object.keys(field['#options']).map((optionValue) => {
        // map to value, label as required in dynamic forms type radio

        const option = new DynamicCheckboxModel({
          id: optionValue,
          label: field['#options'][optionValue],
        });
        option.checked = submissionValues.indexOf(optionValue) !== -1;
        return option;
      }),
    });
  }

  /**
   * Create other_radios field
   *
   * @param field the webform field to parse
   */
  private webform_radios_otherControlCreate(field: any): DynamicRadioGroupModel<string> {
    return new DynamicRadioGroupModel<string>(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        options: [
          { label: 'No', value: 'no' },
          { label: 'Yes', value: 'yes' },
        ],
        validators: this.createControlValidators(field),
      },
      {
        element: {
          option:
            'bg-transparent border border-info col btn d-flex justify-content-center align-items-center me-2 my-2 rounded-3 text-primary custom-radio',
          control: 'col-12',
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create number field
   *
   * @param field the webform field to parse
   */
  private numberControlCreate(field: any): DynamicInputModel {
    return new DynamicInputModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        placeholder: field['#title'],
        validators: this.createControlValidators(field),
        inputType: 'number',
        name: this.getFieldKey(field),
        max: field['#max'],
        min: field['#min'],
        value: field['#default_value'],
        step: field['#step'],
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  /**
   * Create rating field
   *
   * @param field the webform field to parse
   */
  private webform_ratingControlCreate(field: any): DynamicRatingModel {
    return new DynamicRatingModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        validators: this.createControlValidators(field),
        name: this.getFieldKey(field),
        max: 5,
      },
      {
        element: {
          container: 'py-2',
          control: 'icon icon-32',
        },
      }
    );
  }

  /**
   * Create rating field
   *
   * @param field the webform field to parse
   */
  private webform_markupControlCreate(field: any): DynamicMarkupModel {
    return new DynamicMarkupModel(
      {
        id: this.getFieldKey(field),
        label: '',
        markup: field['#markup'],
        title: field['#title'],
        name: this.getFieldKey(field),
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  private entity_autocompleteControlCreate(field: any): DynamicEntityAutocompleteModel | DynamicInputModel {
    return new DynamicEntityAutocompleteModel(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        selection_settings: field['#selection_settings'],
        selection_handler: field['#selection_handler'],
        target_type: field['#target_type'],
        placeholder: field['#title'],
        name: this.getFieldKey(field),
        errorMessages: {
          required: field['#required'] ? field['#title'] + ' is required.' : null,
        },
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  private addressControlCreate(field) {
    const options = field['#available_countries'].map((countryCode: string) => {
      let label = countryCode;
      const countryIndex = Object.values(CountryISO).indexOf(countryCode.toLowerCase() as any);
      // found
      if (countryIndex !== -1) {
        label = Object.keys(CountryISO)[countryIndex];
      }
      // map to value, label as required in dynamic forms type radio
      return { value: countryCode, label };
    });
    options.unshift({ value: '', label: this.translateService.instant('Select country'), disabled: true });
    return new DynamicSelectModel<string>(
      {
        id: this.getFieldKey(field),
        label: this.getFieldLabel(field),
        options,
        value: field['#default_value'] ? field['#default_value'] : '',
        validators: this.createControlValidators(field),
        hidden: field['#access'] === false,

        // hidden: true,
        // add other properties here
      },
      {
        element: {
          container: 'py-2',
        },
      }
    );
  }

  private webform_nameControlCreate(field: any) {
    const models = this.parseAndCreateFromFields(field['#webform_composite_elements']);
    let labelString = '';
    if (field['#title']) {
      labelString = this.getFieldLabel(field);
    }
    return new DynamicFormGroupModel({
      id: this.getFieldKey(field),
      label: labelString,
      group: models,
    });
  }

  private getFieldKey(field: any) {
    return field['#webform_key'] || field['#webform_composite_key']?.split('__').pop();
  }
  private getFieldLabel(field: any) {
    return field['#title'] + ' ' + (field['#required'] ? '<span class="text-danger"> *</span></label>' : '');
  }

  // redirections

  private webform_image_fileControlCreate(field: any) {
    return this.managed_fileControlCreate(field);
  }

  private webform_entity_selectControlCreate(field) {
    return this.entity_autocompleteControlCreate(field);
  }

  private webform_select_otherControlCreate(field) {
    return this.selectControlCreate(field);
  }
}
