import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UserProfile } from '@app/core/models';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { DrupalConstants, UserService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface UnitMeasure {
  label: string;
  value: string;
}

@Component({
  selector: 'profile-user-temperature-unit-measure-picker',
  templateUrl: './temperature-unit-measure-picker.component.html',
  styleUrls: ['./temperature-unit-measure-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemperatureUnitMeasurePickerComponent {
  @Output() update: EventEmitter<void> = new EventEmitter<void>();
  selectedTemperature$: BehaviorSubject<string> = new BehaviorSubject(
    this.translate.instant('Select temperature unit measure')
  );
  unitMeasures: UnitMeasure[] = [
    { label: 'K', value: 'K' },
    { label: '°C', value: 'C' },
    { label: '°F', value: 'F' },
  ];
  userProfile$: Observable<UserProfile> = this.navDataService.currentUserProfile$.pipe(
    tap((user) => {
      if (!user?.field_preferred_temperature) {
        return;
      }
      this.getMeasureUnitLabel(user.field_preferred_temperature.unit);
    })
  );
  inLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly savingText = this.translate.instant('saving...');

  constructor(
    public navDataService: NavDataService,
    private translate: TranslateService,
    private userService: UserService,
    private notificationsService: NotificationsService
  ) {}

  setUnitMeasure(unitMeasure: UnitMeasure) {
    const lastValue = this.selectedTemperature$.getValue();
    this.inLoading$.next(true);
    this.selectedTemperature$.next(this.savingText);
    const uid = DrupalConstants.Connection.current_user.uid;
    const values: any = {
      field_preferred_temperature: [
        {
          unit: unitMeasure.value,
          number: 0,
        },
      ],
    };
    this.update.emit();
    // wait for profile update
    setTimeout(() => {
      this.userService.update(uid, values).subscribe({
        next: () => {
          this.inLoading$.next(false);
          this.getMeasureUnitLabel(unitMeasure.value);

          this.navDataService.patchUserProfileValue(
            'field_preferred_temperature',
            values.field_preferred_temperature[0]
          );
          this.navDataService.initProfile();
        },
        error: () => {
          this.inLoading$.next(false);
          this.selectedTemperature$.next(lastValue);
          this.notificationsService.notifyError(this.translate.instant('Error on saving preferred temperature'));
          setTimeout(() => this.selectedTemperature$.next(lastValue), 5000);
        },
      });
    }, 500);
  }

  private getMeasureUnitLabel(unitMeasureValue: string) {
    this.unitMeasures.some((unit) => {
      if (unit.value === unitMeasureValue) {
        this.selectedTemperature$.next(unit.label);
        return true;
      }
      return false;
    });
  }
}
