export class GlobalConst {
  static url: string;
  static iwoUrl = 'https://www.iwointl.com/';
  static indexDb = {
    version: 3,
    name: 'iko-DB' as string,
    videos: 'eLearningVideos' as string,
    ebooks: 'eLearningEbooks' as string,
  };

  constructor() {
    GlobalConst.url = `${window.location.protocol}/${window.location.host}`;
  }
}
