import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NameTid } from '@app/core/models';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { UserProfileService } from '@app/profile/user/user-profile.service';
import { SharedModule } from '@app/shared/shared.module';
import { ArchwizardModule } from '@makiwin/angular-archwizard';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, forkJoin, tap } from 'rxjs';

@Component({
  selector: 'modal-segmentation-form',
  templateUrl: './segmentation-form.component.html',
  styleUrls: ['./segmentation-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ArchwizardModule, TranslateModule, SharedModule],
})
export class SegmentationFormComponent extends ModalComponent {
  isLoadingQuestions = true;
  objectives: NameTid[];
  foundList: NameTid[];
  experiences: NameTid[];
  bookings: NameTid[];

  constructor(
    public deviceDetectService: DeviceDetectService,
    public navDataService: NavDataService,
    private viewService: ViewService,
    private userProfileService: UserProfileService
  ) {
    super();
    this.loadQuestions();
  }

  protected updateProfile(field: string, value: string, lastStep = false) {
    this.userProfileService.patch({ [field]: value } as any).subscribe(() => {});
    if (lastStep) {
      this.modalRef.close();
    }
  }

  private loadQuestions() {
    this.isLoadingQuestions = true;
    forkJoin([this.getObjectiveList(), this.getIkoFoundList(), this.getExpList(), this.getBookedList()]).subscribe({
      next: () => (this.isLoadingQuestions = false),
      error: () => {
        this.isLoadingQuestions = false;
        this.modalRef.dismiss();
      },
    });
  }

  private getObjectiveList(): Observable<NameTid[]> {
    return this.viewService.get('/api/v1/terms/user_objectives.json').pipe(
      tap((data) => {
        this.objectives = data;
      })
    );
  }

  private getIkoFoundList(): Observable<NameTid[]> {
    return this.viewService.get('/api/v1/terms/found_iko.json').pipe(
      tap((data) => {
        this.foundList = data;
      })
    );
  }

  private getExpList(): Observable<NameTid[]> {
    return this.viewService.get('/api/v1/terms/user_experience.json').pipe(
      tap((data) => {
        this.experiences = data;
      })
    );
  }

  private getBookedList(): Observable<NameTid[]> {
    return this.viewService.get('/api/v1/terms/course_booked_yet.json').pipe(
      tap((data) => {
        this.bookings = data;
      })
    );
  }
}
