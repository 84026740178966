<picture>
  <source [media]="source.media" [srcset]="source.srcset" *ngFor="let source of picturesSet" />
  <img
    *ngIf="src"
    [ngSrc]="src"
    (error)="onErrorImage ? handleImgError($event, onErrorImage) : null"
    [class]="imgClass"
    [id]="imgId"
    [alt]="alt || src"
    [width]="width"
    [height]="height"
  />
</picture>
