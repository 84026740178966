import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsCustomsToolsService {
  private readonly fieldWebFormName = 'webform_id';
  constructor() {}
  getFormModelNameFromFormGroup(formGroup: any): string {
    while (formGroup != null) {
      if (formGroup.value[this.fieldWebFormName]) {
        return formGroup.value[this.fieldWebFormName];
      }
      formGroup = formGroup._parent;
    }
    return '';
  }
}
