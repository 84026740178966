import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencySelectorBarService } from './currency-selector-bar.service';

@Component({
  selector: 'currency-selector-bar',
  templateUrl: './currency-selector-bar.component.html',
  styleUrls: ['./currency-selector-bar.component.scss'],
})
export class CurrencySelectorBarComponent {
  mustShow$: Observable<boolean> = this.service.mustShow$.asObservable();

  constructor(private service: CurrencySelectorBarService) {}

  close(): void {
    this.service.closeBar();
  }
}
