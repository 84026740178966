import { CommonModule, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { NavDataService } from '@app/core/services';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { LocalStorageService } from '@app/core/services/localstorage/localstorage.service';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { SharedModule } from '@app/shared/shared.module';
import { DrupalConstants, UserService } from '@makiwin/ngx-drupal8-rest';
import { mergeMap } from 'rxjs';
import { SupportedLanguage } from 'src/translations/languages';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'language-suggest',
  templateUrl: './language-suggest.component.html',
  styleUrls: ['./language-suggest.component.scss'],
})
export class LanguageSuggestComponent extends ModalComponent implements OnInit {
  suggestedLanguage: { key: string; obj: SupportedLanguage };
  isUpdating = false;
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public i18nService: I18nService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private navDataService: NavDataService
  ) {
    super();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.dismissLocal();
      return;
    }
    const langKey = navigator.language.split('-')[0];
    if (this.i18nService.language === langKey) {
      this.dismiss();
      return;
    }
    const supportedLangObj: SupportedLanguage = this.i18nService.getSupportedLanguage(langKey);
    if (supportedLangObj) {
      this.suggestedLanguage = {
        key: langKey,
        obj: supportedLangObj,
      };
      return;
    }
    this.dismissLocal();
  }

  dismissLocal() {
    if (this.isUpdating) {
      return;
    }
    this.localStorageService.setItem('language_suggested', this.i18nService.language);
    this.close();
  }

  /**
   * Update the language of the user, if not logged only of the app
   */
  updateLanguage() {
    if (this.isUpdating) {
      return;
    }

    if (!this.navDataService.isLogged) {
      this.i18nService.language = this.suggestedLanguage.key;
      this.dismissLocal();
      return;
    }

    this.isUpdating = true;
    const uid = DrupalConstants.Connection.current_user.uid;
    const values: any = {
      preferred_langcode: this.suggestedLanguage.key,
    };
    this.userService
      .update(uid, values)
      .pipe(mergeMap(() => this.navDataService.initProfile(true)))
      .subscribe({
        next: () => {
          this.isUpdating = false;
          this.i18nService.language = this.suggestedLanguage.key;
          this.dismissLocal();
        },
        error: () => {
          this.notificationsService.notifyError('Error updating language');
          this.isUpdating = false;
        },
      });
  }
}
