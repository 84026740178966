/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
  DynamicFormControlComponent,
  DynamicFormControlLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';
import { Subject } from 'rxjs';
import { DynamicFormsCustomsToolsService } from '../dynamic-forms-customs-tools.service';
import { DynamicEntityAutocompleteModel } from './dynamic-entity-autocomplete.model';
import { ProviderDynamicAutoCompleteInstructors } from './providers/provider-dynamic-autocomplete-instructors';
import { ProviderDynamicAutoCompleteLanguagesServer } from './providers/provider-dynamic-autocomplete-languages';
import { ProviderDynamicAutoCompleteMembers } from './providers/provider-dynamic-autocomplete-members';
import { ProviderDynamicAutoCompleteSchoolsMemory } from './providers/provider-dynamic-autocomplete-schools-memory';
import { ProviderDynamicAutoCompleteSchoolServer as ProviderDynamicAutoCompleteSchoolsServer } from './providers/provider-dynamic-autocomplete-schools-server';

@Component({
  selector: 'app-dynamic-entity-autocomplete',
  templateUrl: './dynamic-entity-autocomplete.component.html',
  styleUrls: ['./dynamic-entity-autocomplete.component.scss'],
})
export class DynamicEntityAutocompleteComponent extends DynamicFormControlComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() layout?: DynamicFormControlLayout;
  @Input() model!: DynamicEntityAutocompleteModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('noImageUser', { static: true }) noImageUser: TemplateRef<any>;
  @ViewChild('noImageSchool', { static: true }) noImageSchool: TemplateRef<any>;

  click$ = new Subject<string>();
  provider: AutoCompleteInputProvider;

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService,
    private viewService: ViewService,
    private dynamicFormsTools: DynamicFormsCustomsToolsService
  ) {
    super(layoutService, validationService);
  }
  ngOnInit(): void {
    this.setProvider();
  }

  private setProvider() {
    switch (true) {
      case this.model.target_type === 'configurable_language':
        this.provider = new ProviderDynamicAutoCompleteLanguagesServer(this.viewService);
        this.model.noThumbnailsTemplate = this.noImageUser;
        break;
      case (this.model.selection_settings?.view?.view_name === 'instructors_school_owners' ||
        this.model.selection_settings?.filter?.role?.instructor_level_2_basic) &&
        this.model.target_type === 'user':
        this.provider = new ProviderDynamicAutoCompleteInstructors(
          this.viewService,
          this.dynamicFormsTools.getFormModelNameFromFormGroup(this.group)
        );
        this.model.noThumbnailsTemplate = this.noImageUser;
        break;
      case this.model.selection_settings?.view?.view_name === 'api_schools_list':
        this.provider = new ProviderDynamicAutoCompleteSchoolsServer(this.viewService);
        this.model.noThumbnailsTemplate = this.noImageSchool;
        break;
      case this.model.selection_handler === 'default:user' || this.model.target_type === 'user':
        this.provider = new ProviderDynamicAutoCompleteMembers(this.viewService);
        this.model.noThumbnailsTemplate = this.noImageUser;
        break;
      case !!this.model.selection_settings.target_bundles?.school:
        this.provider = new ProviderDynamicAutoCompleteSchoolsMemory(this.viewService);
        break;
    }
    this.model.showRoundedThumbnails = this.provider?.showRoundedThumbnails;
    this.model.showThumbnails = this.provider?.showThumbnails;
  }
}
