<ng-container *ngIf="isMultiple; else singleFile">
  <div class="my-3" *ngIf="multiFileObject.length">
    <div *ngFor="let fileObject of multiFileObject; let last = last">
      <ng-template [ngTemplateOutlet]="rowFile" [ngTemplateOutletContext]="{ file: fileObject }"></ng-template>
      <hr *ngIf="!last" />
    </div>
  </div>
  <button type="button" class="btn btn-success" (click)="openFilePicker()" translate>Upload file</button>
</ng-container>

<ng-template #singleFile>
  <button *ngIf="!fileObject" type="button" class="btn btn-success" (click)="openFilePicker()" translate>
    Upload file
  </button>
  <ng-container [ngTemplateOutlet]="rowFile" [ngTemplateOutletContext]="{ file: fileObject }" *ngIf="fileObject">
  </ng-container>
</ng-template>

<ng-template let-file="file" #rowFile>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <span class="me-2">{{ file.file.name }} ({{ file.file.size | filesize }})</span>
      <i class="text-success icon icon-spinner icon-is-spinning" *ngIf="this.commonService.uploadFileLoading"></i>
      <i class="text-danger icon icon-exclamation-triangle" *ngIf="file.hasError"></i>
    </div>
    <button
      type="button"
      *ngIf="!this.commonService.uploadFileLoading && !file.hasError"
      class="btn btn-danger"
      (click)="removeFile(file)"
    >
      Remove
    </button>
  </div>
</ng-template>
