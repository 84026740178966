import { Injectable } from '@angular/core';
import { UserProfile } from '@app/core/models';
import { environment } from '@env/environment';
import { Crisp } from 'crisp-sdk-web';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { NavDataService } from '../nav-data/nav-data.service';
import { RolesService } from '../roles/roles.service';
import { CustomService } from '../drupal8';
import { isValidPhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root',
})
export class CrispChatService {
  constructor(
    private navDataService: NavDataService,
    private roleService: RolesService,
    private deviceDetectService: DeviceDetectService,
    private customService: CustomService
  ) {
    this.navDataService.currentUserProfile$.subscribe({ next: (userProfile) => this.setSession(userProfile) });
  }

  /**
   * Init Crisp chat bot.
   */
  public initChatBot(): void {
    Crisp.configure(environment.crispChatId);
    this.initChatBotResponsive();
  }

  /**
   * Open Crisp chat bot.
   */
  public openChat(): void {
    Crisp.chat.show();
    Crisp.chat.open();
  }

  /**
   * Init Crisp chat bot for responsive behave.
   */
  protected initChatBotResponsive() {
    if (!this.deviceDetectService.isMobile) {
      Crisp.chat.show();
      Crisp.chat.onChatClosed(() => {});
    } else {
      Crisp.chat.hide();
      Crisp.chat.onChatClosed(() => Crisp.chat.hide());
    }
  }

  /**
   * Set Crisp chat bot session.
   * @param userProfile
   *  User profile of the current user
   */
  protected setSession(userProfile: UserProfile): void {
    if (!userProfile?.uid) {
      Crisp.session.reset();
      return;
    }
    try {
      Crisp.user.setEmail(userProfile.mail);
      Crisp.user.setNickname(`${userProfile.field_first_name} ${userProfile.field_surname}`);

      if (
        userProfile.field_phone !== null &&
        userProfile.field_phone !== '' &&
        isValidPhoneNumber(userProfile.field_phone)
      ) {
        try {
          Crisp.user.setPhone(userProfile.field_phone);
        } catch (error) {
          console.error('Error setting phone number on crisp', error);
          this.customService.sendLog('error', `Error setting phone number on crisp ${error.toString()}`);
        }
      }

      Crisp.session.setData({
        user_id: userProfile.uid,
        email: userProfile.mail,
        username: userProfile.name,
        first_name: userProfile.field_first_name,
        last_name: userProfile.field_surname,
        user_role: userProfile.current_role.display_name,
        membership: userProfile.field_membership_plan,
        top_qualification: userProfile.field_top_qualification,
        country: this.roleService.isSchool
          ? this.navDataService.currentSchool.field_school_address_country_code
          : userProfile.field_address_administrative_area,
        address: userProfile.field_address,
        nationality: userProfile.field_nationality,
        phone: userProfile.field_phone,
        gender: userProfile.field_gender,
        teaching_hours: userProfile.field_teaching_hours,
        training_hours: userProfile.field_training_hours,
        students: userProfile.field_certified_students,
        subscription_state: userProfile.subscription_state,
        subscription_starts: userProfile.subscription_starts,
        next_renewal: userProfile.next_renewal,
        profile_completeness: userProfile.profile_completeness,
        preferred_language: userProfile.preferred_langcode.name,
        preferred_currency: userProfile.field_preferred_currency,
        insurance: userProfile.field_user_insured,
      });
    } catch (error) {
      this.customService.sendLog(
        'frontend',
        `Error setting phone number on crisp ${error.message ?? error.toString()}`
      );
    }
  }
}
