<div ngbDropdown [placement]="placement" class="dropdown">
  <div
    class="btn btn-default dropdown-toggle position-relative d-flex justify-content-center align-items-center align-self-center"
    ngbDropdownToggle
    [ngClass]="{
      'btn-sm': small,
      'bg-color-light-blue': deviceDetectService.isDesktopOrTablet && !gutenberg,
      'bg-info': deviceDetectService.isMobile || gutenberg
    }"
  >
    <ng-container *ngIf="loadingNewLanguage$ | async; else languageSelected">
      <circular-spinner class="px-4"></circular-spinner>
    </ng-container>
    <ng-template #languageSelected>
      <ng-container *appNgVar="languageToDisplay$ | async as languageToDisplay">
        <ng-container *ngIf="languageToDisplay">
          <div class="iti-flag iti-flag-select" [ngClass]="languagesToShow[languageToDisplay]?.flag"></div>

          <div class="ps-2 d-inline-block {{ isHeader ? 'd-lg-none d-xxl-inline-block' : '' }}">
            {{ languagesToShow[languageToDisplay]?.name }}
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
  <ul class="dropdown-menu dropdown-menu-end dropdown-language bg-white" ngbDropdownMenu>
    <li *ngFor="let language of keysLanguageToShow">
      <div
        class="dropdown-item cursor-pointer d-flex justify-content-start align-items-center position-relative"
        (click)="setLanguage(language)"
      >
        <div class="iti-flag iti-flag-select" [ngClass]="getLanguage(language)?.flag"></div>
        <div class="ps-2">{{ languagesToShow[language]?.name }}</div>
      </div>
    </li>
  </ul>
</div>
