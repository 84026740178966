import { Injectable, inject, signal } from '@angular/core';
import { NotificationMessage } from '@app/core/models';
import { Observable, from } from 'rxjs';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { ModalManagerService, ModalTypes } from '../modal-manager';

const DefaultNotificationDuration = 7000;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly currentNotify = signal<NotificationMessage>(null);
  readonly currentCounter = signal<number>(null);
  private queueNotification: NotificationMessage[] = [];
  private modalManager: ModalManagerService = inject(ModalManagerService);
  private currentTimerClose: NodeJS.Timeout;
  private curentTimerTick: NodeJS.Timeout;

  /* #region modal section */
  /**
   * Display a warning modal with a message.
   *
   * @param message
   *  The message to display.
   * @returns
   * An observable that will emit when the modal is closed.
   */
  warningModal(message: string): Observable<any> {
    return this.notifyModal(message, null, 'icon-exclamation-triangle', 'text-warning');
  }

  /**
   * Display an error modal with a message.
   *
   * @param message
   *  The message to display.
   * @returns
   * An observable that will emit when the modal is closed.
   */
  errorModal(message: string): Observable<any> {
    return this.notifyModal(message, null, 'icon-circle-exclamation', 'text-danger');
  }

  /**
   * Display an info modal with a message.
   *
   * @param message
   *  The message to display.
   * @returns
   * An observable that will emit when the modal is closed.
   */
  infoModal(message: string): Observable<any> {
    return this.notifyModal(message, null, 'icon-info-circle', 'text-info');
  }

  /**
   * Open a modal with message, title and icon.
   *
   * @param message
   * Message to show. It can be html.
   * @param title
   * Title of the modal.
   * @param icon
   * Icon class of the icon.
   * @param iconColor
   * Color of the icon. It could be a boostrap class color or a custom class.
   * @param iconSize
   * Size of the icon. It could be a boostrap class size or a custom class.
   * @returns
   * Observable that will emit when the modal is closed.
   */
  notifyModal(
    message: string,
    title?: string,
    icon?: string,
    iconColor?: string,
    iconSize = 'icon-64'
  ): Observable<any> {
    return this.modalManager.open(ModalTypes.standard, {
      message,
      icon,
      title,
      iconColor,
      iconSize,
    });
  }

  /* #endregion */

  /* #regione sweet alert section */
  /**
   * Show a sweet alert modal.
   *
   * @param {SweetAlertOptions} options
   * Options to display the sweet alert.
   * Please refer to Sweet Alert 2 documentation [https://sweetalert2.github.io/#configuration]
   * @returns {Observable<SweetAlertResult<any>>}
   * An observable that will emit a SweetAlertResult when the modal is closed.
   */
  notifySwal(options: SweetAlertOptions): Observable<SweetAlertResult<any>> {
    return from(Swal.fire(options));
  }
  /* #endregion */

  /* #region notification section */
  /**
   * Show a notification.
   * If a notification is already shown, the new notification will be queued.
   *
   * @param notification
   * The notification to show.
   */
  notify(notification: NotificationMessage) {
    this.openNotify(notification);
  }

  /**
   * Show an info notification with a message.
   * If a notification is already shown, the new notification will be queued.
   *
   * @param messageToShow
   * The message to show.
   * @param icon
   * The icon to show.
   * @param duration
   * The duration of the notification.
   */
  notifyInfo(messageToShow: string, icon = 'icon-info-circle', duration: number = DefaultNotificationDuration) {
    this.openNotify({
      type: 'info',
      message: messageToShow,
      autoHide: true,
      close: true,
      icon: `icon ${icon}`,
      duration,
    });
  }

  /**
   * Show a Success notification with a message.
   * If a notification is already shown, the new notification will be queued.
   *
   * @param messageToShow
   * The message to show.
   * @param icon
   * The icon to show.
   * @param duration
   * The duration of the notification.
   */
  notifySuccess(
    messageToShow: string,
    icon = 'icon-check-circle',
    duration: number = DefaultNotificationDuration
  ): void {
    this.openNotify({
      type: 'secondary',
      message: messageToShow,
      autoHide: true,
      close: true,
      icon: `icon ${icon}`,
      duration,
    });
  }

  /**
   * Show a warning notification with a message.
   * If a notification is already shown, the new notification will be queued.
   *
   * @param messageToShow
   * The message to show.
   * @param icon
   * The icon to show.
   * @param duration
   * The duration of the notification.
   */
  notifyWarning(warningMessage: string, autoHide = true, close = true): void {
    this.openNotify({
      type: 'warning',
      message: warningMessage,
      autoHide,
      close,
      icon: 'icon icon-circle-exclamation',
    });
  }

  /**
   * Show an error notification with a message.
   * If a notification is already shown, the new notification will be queued.
   *
   * @param messageToShow
   * The message to show.
   * @param icon
   * The icon to show.
   * @param duration
   * The duration of the notification.
   */
  notifyError(
    errorMessage: string,
    autoHide = true,
    close = true,
    duration: number = DefaultNotificationDuration
  ): void {
    this.openNotify({
      type: 'danger',
      message: errorMessage,
      autoHide,
      close,
      icon: 'icon icon-times-circle',
      duration,
    });
  }

  /**
   * Close the current opened notification.
   *
   */
  close() {
    this.currentNotify.set(null);
    if (this.curentTimerTick) {
      clearTimeout(this.curentTimerTick);
    }
    if (this.currentTimerClose) {
      clearTimeout(this.currentTimerClose);
    }
    if (this.queueNotification.length) {
      setTimeout(() => this.openNotify(this.queueNotification.shift()), 600);
    }
  }
  /* #endregion */

  /* #endregion */

  /* #region private section for notify */
  /**
   * Internal method to open a notification.
   * @param notify
   * The notification to open.
   */
  private openNotify(notify: NotificationMessage) {
    if (!this.currentNotify()) {
      this.currentNotify.set(notify);
      if (notify.autoHide) {
        this.launchTimer(notify.duration ?? DefaultNotificationDuration);
      }
      return;
    }
    this.queueNotification.push(notify);
  }

  /**
   * Launch a timer to close the notification.
   * @param duration
   */
  private launchTimer(duration = 1000) {
    this.currentCounter.set(0);
    this.curentTimerTick = setTimeout(() => this.tickNotify(), 1000);
    this.currentTimerClose = setTimeout(() => this.close(), duration);
  }

  /**
   * Tick the notification timer.
   */
  private tickNotify() {
    this.currentCounter.update((value) => value + 1);
    this.curentTimerTick = setTimeout(() => this.tickNotify(), 1000);
  }
  /* #endregion */
}
