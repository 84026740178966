/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { CustomService } from '@app/core/services/drupal8/custom/custom.service';
import { ModalTypes } from '@app/core/services/modal-manager';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { environment } from '@env/environment';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import {
  CommerceOrder,
  CommerceOrderEntity,
  CommercePayment,
  CommercePaymentEntity,
  CommerceService,
  DrupalConstants,
  FlagService,
} from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import {
  PaymentRequestOptions,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementLocale,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { IClientAuthorizeCallbackData, IPayPalConfig, NgxPayPalModule } from 'ngx-paypal';
import { NgxStripeModule, StripeCardComponent, StripeService } from 'ngx-stripe';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedModule } from '../../shared.module';

@Component({
  standalone: true,
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  imports: [CommonModule, NgxPayPalModule, NgxStripeModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class PaymentComponent extends ModalComponent implements OnInit {
  @ViewChild('stripeCard') stripeCard: StripeCardComponent;
  paymentForm: FormGroup;
  processing = false;
  cardValid = false;
  totalPrice = 0;
  currentOrderId: number;
  payPalConfig: IPayPalConfig = {
    currency: 'USD',
    clientId: environment.paypalClientId,
    advanced: {
      commit: 'false',
    },
    style: {
      label: 'paypal',
      layout: 'horizontal',
      tagline: false,
    },
    onApprove: () => {},
    onClientAuthorization: (data) => {
      this.paypalClientAuth(data);
    },
  };
  // successMessage: NotificationMessage = {
  //   type: 'secondary',
  //   message: 'Thank you for your purchase',
  //   close: true,
  //   icon: 'icon icon-check-circle',
  // };
  // errorMessage: NotificationMessage = {
  //   type: 'danger',
  //   message: 'Sorry we are not able to process your request. Please try again later.',
  //   close: true,
  //   icon: 'icon icon-times',
  // };
  cardError: Error;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#32325d',
        lineHeight: '40px',
        fontWeight: '300',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
    },
    hidePostalCode: true,
  };

  elementsOptions: StripeElementsOptions = {
    locale: this.translate.currentLang as StripeElementLocale,
  };

  requestOptions: PaymentRequestOptions = {
    country: 'US',
    currency: 'usd',
    total: {
      amount: 0,
      label: 'Pay Now',
    },
  };

  displayIap = false;
  stripeAvailable = false;

  stripeService: StripeService;

  constructor(
    public navDataService: NavDataService,
    private formBuilder: FormBuilder,
    private commerceService: CommerceService,
    private customService: CustomService,
    private inAppPurchase2: InAppPurchase2,
    private translate: TranslateService,
    private flagService: FlagService,
    private deviceDetectService: DeviceDetectService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private notificationsService: NotificationsService,
    @Inject(Injector) private readonly injector: Injector
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.extraData?.products?.[0].purchased_entity.type === 'subscription' &&
      !this.deviceDetectService.iosDisplayed
    ) {
      this.inAppPurchase2.register({
        id: this.extraData?.products?.[0].purchased_entity.sku,
        type: this.inAppPurchase2.PAID_SUBSCRIPTION,
      });
      this.displayIap = true;
      this.payWithInAppPurchase();
    }
    this.totalPrice = this.extraData.total;
    this.requestOptions.total.amount = Math.round(this.customService.replaceCommaWithDot(this.totalPrice) * 100);
    this.payPalConfig.currency = this.extraData?.products?.[0].purchased_entity.currency_code;
    this.requestOptions.currency = this.payPalConfig.currency.toLowerCase();
    this.buildForm();
    this.initPayPal();

    this.commerceService.getCart().subscribe((o) => {
      const entity_type = 'commerce_order';
      const flag_id = 'started_payment';

      if (o && o.length > 0) {
        const entity_id = o[0].order_id;
        this.currentOrderId = entity_id;
        const entity_id_str = o[0].order_id.toString();
        this.customService.isEntityFlagged(entity_type, entity_id_str, flag_id).subscribe((flagged) => {
          if (!flagged) {
            this.flagService
              .post({
                flag_id,
                entity_type,
                entity_id,
                uid: DrupalConstants.Connection.current_user.uid,
              })
              .subscribe();
          }
        });
      }
    });
  }

  openBankTransferInfo(): void {
    this.modalManager.open(ModalTypes.bankTransferInfo);
    // this.modalBankTransferInfo.open().subscribe();
  }

  payWithInAppPurchase() {
    this.processing = true;
    const sku = ''; //this.extraData?.products?.[0].purchased_entity.sku;

    this.inAppPurchase2
      .when(sku)
      .approved((product: IAPProduct) => {
        this.createOrder().subscribe((order) => {
          this.payment();

          this.customService.verifyInAppBrowser(order.order_id[0].value, product).subscribe((data) => {
            if (data) {
              product.verify();
              this.processing = false;
            }
          });
        });
      })
      .verified((product: IAPProduct) => {
        product.finish();
        this.processing = false;
      })
      .finished(() => {
        this.displayMessageAndClose();
      });
    this.inAppPurchase2
      .order(sku)
      .then(() => {})
      .error((err) => {
        console.log(err);
      });
  }

  buildForm() {
    this.paymentForm = this.formBuilder.group({
      termsCheck: [false, [Validators.required, CustomValidators.equal(true)]],
    });
  }

  cardUpdated(result: StripeCardElementChangeEvent) {
    this.cardValid = result.complete;
  }

  createOrder(): Observable<CommerceOrderEntity> {
    // WEB-3858
    let entityType = this.extraData?.products?.[0].purchased_entity.type;
    // entityType = 'porco dio';
    if (entityType === 'shop' && this.totalPrice !== 0) {
      return this.commerceService.getCart().pipe(
        map(
          (c) =>
            ({
              order_id: [
                {
                  value: c[0].order_id,
                },
              ],
            } as any)
        )
      );
    }
    if (!entityType) {
      entityType = 'default';
    } else {
      entityType = this.totalPrice === 0 ? 'default' : entityType;
    }
    const typeKey = 'type';
    const typeQuantity = 'quantity';
    const typeUnitPrice = 'unit_price';
    const order: CommerceOrder = {
      user: {
        mail: this.navDataService.currentUserProfile.mail,
      },
      order: {
        order_items: this.extraData?.products?.map((purchasedEntity) => {
          purchasedEntity[typeKey] = entityType;
          purchasedEntity[typeQuantity] = purchasedEntity.purchased_entity.quantity;
          purchasedEntity[typeUnitPrice] = {
            number: parseFloat(purchasedEntity.purchased_entity.price),
            currency_code: purchasedEntity.purchased_entity.currency_code,
          };
          return purchasedEntity;
        }),
        type: entityType,
      },
    };
    if (this.totalPrice === 0) {
      order.order.state = 'completed';
    }
    return this.commerceService.createOrder(order);
  }

  requestUpdated(result: any) {
    if (result.paymentMethod) {
      this.processing = true;
      this.createOrder().subscribe((orderData) => {
        this.createPayment(orderData.order_id[0].value).subscribe((paymentData) => {
          const promise = this.stripeService.confirmCardPayment(
            paymentData.remote_id[0].value,
            { payment_method: result.paymentMethod.id },
            { handleActions: false }
          );
          from(promise).subscribe((confirmResult: any) => {
            if (confirmResult.error) {
              this.logError(confirmResult.error, 'credit-card');
              this.displayMessageAndClose(true);
              result.complete('fail');
            } else {
              result.complete('success');
              // eslint-disable-next-line @typescript-eslint/dot-notation
              const promiseCardPayment = this.stripeService.confirmCardPayment(paymentData.remote_id[0].value);
              from(promiseCardPayment).subscribe((confirmCardResult: any) => {
                if (confirmCardResult.error) {
                  this.displayMessageAndClose(true);
                  return;
                }
                this.commerceService
                  .capturePayment(orderData.order_id[0].value, paymentData.payment_id[0].value)
                  .subscribe(() => {
                    this.displayMessageAndClose();
                  });
              });
            }
          });
        });
      });
    }
  }

  displayMessageAndClose(error = false) {
    // this.modalManagerService.dismiss();
    if (error) {
      this.notificationsService.notifyError(
        this.translate.instant('Sorry we are not able to process your request. Please try again later.')
      );
    } else {
      this.notificationsService.notifySuccess(
        this.translate.instant('Thank you for your purchase'),
        'icon icon-check-circle'
      );
    }
    this.navDataService.navigate('/shop/orders');
    this.processing = false;
  }

  paypalClientAuth(authorization: IClientAuthorizeCallbackData) {
    if (authorization.status === 'COMPLETED') {
      this.processing = true;
      this.createOrder().subscribe({
        next: (data) => {
          this.payment();
          this.customService.verifyPaypal(data.order_id[0].value, authorization.id).subscribe((confirmData) => {
            this.displayMessageAndClose(!confirmData);
          });
        },
        error: (err) => {
          this.logError(err, 'paypal');
          this.displayMessageAndClose(true);
        },
      });
    }
  }

  payWithCard() {
    if (this.processing || (this.totalPrice !== 0 && (!this.paymentForm.valid || !this.cardValid))) {
      return;
    }
    this.processing = true;
    if (this.totalPrice === 0) {
      this.createOrder().subscribe({
        next: () => {
          this.payment();
          this.commerceService.deleteCartOrderItems(this.currentOrderId).subscribe();
          this.displayMessageAndClose();
        },
        error: () => this.displayMessageAndClose(true),
      });
      return;
    }

    this.createOrder().subscribe((data) => {
      if (data && data.order_id && data.order_id.length > 0) {
        this.createPayment(data.order_id[0].value).subscribe({
          next: (paymentData) => {
            this.stripeService
              .confirmCardPayment(paymentData.remote_id[0].value, {
                payment_method: {
                  card: this.stripeCard.element,
                },
              })
              .subscribe({
                next: (result) => {
                  if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                    this.commerceService
                      .capturePayment(data.order_id[0].value, paymentData.payment_id[0].value)
                      .subscribe(() => {
                        //this.modalManagerService.isPaymentSuccess = true;
                        this.displayMessageAndClose();
                      });
                  } else {
                    this.logError(result.error?.message ?? result.error, 'credit-card');
                    if (result.error?.message) {
                      this.notificationsService.notifyError(result.error.message);
                      this.processing = false;
                    } else {
                      this.displayMessageAndClose(true);
                    }
                  }
                },
                error: (err) => {
                  this.processing = false;
                  this.logError(err.message ?? err, 'credit-card');
                  this.notificationsService.notifyError(
                    err.message ?? this.translate.instant('Error during the payment')
                  );
                },
              });
          },
          error: (err) => {
            this.processing = false;
            this.logError(err.message ?? err, 'credit-card');
            this.notificationsService.notifyError(err.message ?? this.translate.instant('Error during the payment'));
          },
        });
      }
    });
  }

  createPayment(orderId: number): Observable<CommercePaymentEntity> {
    const payment: CommercePayment = {
      // decoupled_stripe_recurring
      gateway: 'decoupled_stripe',
      type: 'credit_card',
    };

    return this.commerceService.createPayment(orderId, payment);
  }

  initStripe() {
    const canMakePayment = this.stripeService.paymentRequest(this.requestOptions).canMakePayment();
    if (canMakePayment) {
      this.stripeAvailable = true;
    }
  }

  initPayPal() {
    this.payPalConfig.createOrderOnClient = () => ({
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: this.payPalConfig.currency,
            value: `${this.customService.replaceCommaWithDot(this.totalPrice)}`,
            breakdown: {
              item_total: {
                currency_code: this.payPalConfig.currency,
                value: `${this.customService.replaceCommaWithDot(this.totalPrice)}`,
              },
            },
          },
          items: this.extraData?.products?.map((product) => {
            const item = {
              name: `${product.purchased_entity.title} - ${product.purchased_entity.sku}`,
              quantity: product.purchased_entity.quantity ?? '1',
              unit_amount: {
                currency_code: this.payPalConfig.currency,
                value: product.purchased_entity.price,
              },
            };
            return item;
          }),
        },
      ],
    });
  }

  payment(): void {
    this.angulartics2GoogleTagManager.pushLayer({
      event: 'payment',
      dl_name: 'payment_title',
      dl_value: `Payment ${this.requestOptions.currency}${this.totalPrice}`,
    });
  }

  onCookieStatusChange(status: boolean): void {
    if (status === true) {
      this.stripeService = this.injector.get(StripeService);
      // fix the lazy load of the stripe service
      this.stripeService.loader.asStream().subscribe((loader) => {
        if (loader.loaded) {
          this.initStripe();
        }
      });
    }
  }

  private logError(error: any, type: string): void {
    this.customService.sendLog('error', error.toString() + ` | payment: ${type} | url: ${window.location?.href}`);
  }
}
