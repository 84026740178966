import { Destination, Spot } from '@app/core/models';
import { CenterForAutocomplete } from '@app/core/services/centers/centers.service';
import { SpotForAutocomplete } from '@app/core/services/destinations-spots/destinations-spots.service';
import { AutoCompletePlaceQueryItem } from '@app/core/services/tools/google-map.service';

export enum MultiLocationValueType {
  myPosition,
  location,
  destination,
  spot,
  center,
  googlePlace,
  none,
}

export interface LocationValue {
  lat: number;
  lng: number;
  distance?: number;
  formattedAddress?: string;
  viewport?: any;
}

export interface MultiLocationValue {
  type: MultiLocationValueType;
  value:
    | LocationValue
    | CenterForAutocomplete
    | Destination
    | Spot
    | SpotForAutocomplete
    | string
    | AutoCompletePlaceQueryItem;
}

export interface ApiResults {
  centers: CenterForAutocomplete[];
  spots: Spot[];
  destinations: Destination[];
  googlePlaces: AutoCompletePlaceQueryItem[];
}

export enum DropdownViews {
  main,
  spot,
  center,
  destination,
  map,
  myPosition,
}
