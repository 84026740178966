<div class="overflow-scroll flex-grow-1 d-flex h-100 flex-column bg-primary-gradient text-white segment-body-form">
  <div class="modal-header pt-1 mb-1">
    <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
      <span class="d-inline-block align-middle ms-1" translate>&nbsp;</span>
    </h3>
    <button class="btn-close" (click)="dismiss()"></button>
  </div>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <aw-wizard class="mt-3 pb-3 position-relative" *ngIf="!isLoadingQuestions; else loader">
      <div class="mb-3" awWizardStep>
        <div class="modal-header pt-0 text-center">
          <h4 class="mx-auto my-0" translate>What is your objective?</h4>
        </div>
        <div class="modal-body mt-2 p-3">
          <div class="row px-3">
            <div
              awNextStep
              class="col-12 bg-light-transparent-3 px-4 rounded mb-3 pointer"
              *ngFor="let item of objectives"
              (click)="updateProfile('field_user_objective', item.tid)"
            >
              <div class="row align-items-center">
                <div class="col">{{ item.name }}</div>
                <div class="col-2 text-end">
                  <i class="icon icon-64 icon-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" awWizardStep>
        <div class="modal-header pt-0 text-center">
          <h4 class="mx-auto my-0" translate>Where did you hear about us?</h4>
        </div>
        <div class="modal-body mt-2 p-3">
          <div class="row px-3">
            <div
              awNextStep
              class="col-12 bg-light-transparent-3 px-4 rounded mb-3 pointer"
              *ngFor="let item of foundList"
              (click)="updateProfile('field_user_found_iko', item.tid)"
            >
              <div class="row align-items-center">
                <div class="col">{{ item.name }}</div>
                <div class="col-2 text-end">
                  <i class="icon icon-64 icon-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-6" awWizardStep>
        <div class="modal-header pt-0 text-center">
          <h4 class="mx-auto my-0" translate>How good of a Kiter are you?</h4>
        </div>
        <div class="modal-body mt-2 p-3">
          <div class="row px-3">
            <div
              awNextStep
              class="col-12 bg-light-transparent-3 px-4 rounded mb-3 pointer"
              *ngFor="let item of experiences"
              (click)="updateProfile('field_user_experience', item.tid)"
            >
              <div class="row align-items-center">
                <div class="col">{{ item.name }}</div>
                <div class="col-2 text-end">
                  <i class="icon icon-64 icon-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-6" awWizardStep>
        <div class="modal-header pt-0 text-center">
          <h4 class="mx-auto my-0" translate>Have you booked a kite course yet?</h4>
        </div>
        <div class="modal-body mt-2 p-3">
          <div class="row px-3">
            <div
              class="col-12 bg-light-transparent-3 px-4 rounded mb-3 pointer"
              *ngFor="let item of bookings"
              (click)="updateProfile('field_user_booked_yet', item.tid, true)"
            >
              <div class="row align-items-center">
                <div class="col">{{ item.name }}</div>
                <div class="col-2 text-end">
                  <i class="icon icon-64 icon-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aw-wizard>
  </div>
</div>
<ng-template #loader>
  <div class="m-6 text-center">
    <circular-spinner iconClass="text-white icon-100"></circular-spinner>
  </div>
</ng-template>
