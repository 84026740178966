import {
  DynamicFormControlLayout,
  DynamicFormControlModel,
  DynamicFormValueControlModelConfig,
  serializable,
} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_MARKUP = 'WEBFORM_MARKUP';

export interface DynamicMarkcupModelConfig extends DynamicFormValueControlModelConfig<null> {
  markup: string;
  title?: string;
}

export class DynamicMarkupModel extends DynamicFormControlModel {
  @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_MARKUP;
  markup: string;
  title?: string;

  constructor(config: DynamicMarkcupModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.markup = config.markup;
    this.title = config.title;
  }
}
