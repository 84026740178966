import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavDataService } from '@app/core/services';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule],
  selector: 'renew-membership-modal',
  templateUrl: './renew-membership-modal.component.html',
  styleUrls: ['./renew-membership-modal.component.scss'],
})
export class RenewMembershipModalComponent extends ModalComponent {
  feesManagementUrl = '/roles-fees-management';
  constructor(private navDataService: NavDataService) {
    super();
  }

  openFeesManagement() {
    this.close();
    this.navDataService.navigate(`https://${environment.server7Url + this.feesManagementUrl}`, true);
  }
}
