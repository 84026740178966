import { Directive, ElementRef } from '@angular/core';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';

@Directive({
  selector: '[appBottomSafeArea]',
})
export class BottomSafeAreaDirective {
  constructor(private elementRef: ElementRef, private deviceDetect: DeviceDetectService) {
    if (this.deviceDetect.isIphoneX) {
      this.elementRef.nativeElement.classList.add('safe-area-bottom');
    }
  }
}
