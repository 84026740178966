import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavDataService } from '@app/core/services';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'request-home-address',
  templateUrl: './request-home-address.component.html',
  styleUrls: ['./request-home-address.component.scss'],
})
export class RequestHomeAddressComponent extends ModalComponent {
  constructor(private navDataService: NavDataService) {
    super();
  }

  openSettingsLocation(): void {
    const type = this.navDataService.currentUserProfile.school ? 'school' : 'user';
    this.navDataService.navigate(`/profile/${type}`, false, {
      fragment: 'location',
    });
    this.close();
  }
}
