import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() message = this.translate.instant('Error loading the resource');
  @Input() goBackButtonText = this.translate.instant('Go back');
  @Input() backgroudClass = 'bg-primary-gradient';
  @Input() iconColor = '';
  @Input() textColorClass = 'text-white';
  @Input() redirectTo = '';
  @Input() icon = 'icon-exclamation-triangle';
  @Output() goBackButtonAction: EventEmitter<void> = new EventEmitter<void>();

  constructor(public navDataService: NavDataService, private translate: TranslateService, private router: Router) {}

  redirectToPage() {
    if (this.goBackButtonAction.observed) {
      return this.goBackButtonAction.emit();
    }

    if (this.redirectTo) {
      this.router.navigateByUrl(this.redirectTo);
    } else {
      this.navDataService.navigateBack();
    }
  }
}
