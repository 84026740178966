<div *ngIf="forceDisplay || display" class="px-3 d-flex text-info align-items-center d-lg-none light-gray-background">
  <div class="flex-grow-1 align-items-center d-flex">
    <div class="d-flex align-items-center flex-grow-1 position-relative langauge-chooser-labels">
      <div [@crossfade]="valueState1" class="position-absolute lr-0">{{ preferredLanguageText1 }}</div>
      <div [@crossfade]="valueState2" class="position-absolute lr-0">{{ preferredLanguageText2 }}</div>
    </div>
    <div class="ms-auto me-3 d-flex align-items-center text-white">
      <app-language-select
        class="in-bar"
        [small]="true"
        [updateProfile]="updateProfile"
        [updateUi]="updateUi"
        [isContent]="isContent"
        (languageChanged)="languageChanged.emit($event)"
      ></app-language-select>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="!forceDisplay" (click)="close()">
    <i class="icon icon-times icon-61"></i>
  </div>
</div>
