import { Injectable } from '@angular/core';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { I18nService } from '../i18n/i18n.service';
import { CountrySpot, Destination, Spot } from '@app/core/models';
import { Observable, map, throwError } from 'rxjs';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { UrlResolverService } from '../url-resolver/url-resolver.service';
import { TranslateService } from '@ngx-translate/core';

export interface SpotForAutocomplete {
  id: number | string;
  label: string;
  objectValues: Spot;
  field_spot_gps;
  picture: string;
}
@Injectable({
  providedIn: 'root',
})
export class DestinationsSpotsService {
  private isCordova: boolean;

  constructor(
    private viewService: ViewService,
    private i18nService: I18nService,
    private deviceDetectService: DeviceDetectService,
    private urlResolverService: UrlResolverService,
    private translateService: TranslateService
  ) {
    this.isCordova = this.deviceDetectService.isCordova;
  }

  /**
   * Get destinations from the API, filtered by searchText, and with optional langCode.
   *
   * @param searchText
   *  Search text to filter the results.
   * @param langCode
   *  Language code of the results.
   * @returns
   *  Observable of the destinations.
   */
  getDestinations(searchText?: string, langCode?: string): Observable<Destination[]> {
    const options: ViewOptions = {
      filters: {
        langcode: langCode ?? this.i18nService.language,
      },
    };
    if (searchText) {
      options.filters.search = searchText;
    }

    return this.viewService.get('/api/v1.2/destinations.json', options);
  }

  /**
   * Get destination from the API, filtered by bud , and with optional langCode.
   *
   * @param nid
   *  Node id of the destination.
   * @param langCode
   *  Language code of the results.
   * @returns
   *  Observable of the destination.
   */
  getDestinationByNid(nid: number, langCode?: string): Observable<Destination> {
    const viewOptions: ViewOptions = {
      args: [nid],
      filters: {
        langcode: langCode ?? this.i18nService.language,
      },
    };
    return this.viewService
      .get('/api/v1/destination-details.json', viewOptions)
      .pipe(
        map((destinations) =>
          destinations.length
            ? destinations[0]
            : throwError(() => this.translateService.instant('Destination not found'))
        )
      );
  }

  /**
   * Get spots from the API, per country and destination.
   *
   * @param countryCode
   *   ISO code of the country.
   * @param destinationNid
   *  Node id of the destination.
   * @returns
   *  Observable of the CountrySpot.
   */
  getSpotsByCountry(
    countryCode: string,
    destinationNid: number | string,
    langCode?: string
  ): Observable<CountrySpot[]> {
    const viewOptions: ViewOptions = {
      args: [countryCode, destinationNid.toString()],
      filters: {
        langcode: langCode ?? this.i18nService.language,
      },
    };
    return this.viewService.get('/api/v1/country_spots.json', viewOptions).pipe(
      map((spots) => {
        spots.map((spot) => {
          spot.url_alias = this.isCordova
            ? `/destinations/spot/${spot.nid}`
            : this.urlResolverService.cleanLanguagePrefix(spot.url_alias) ?? `/destinations/spot/${spot.nid}`;
        });
        return spots;
      })
    );
  }

  /**
   * Get spots from the API, filtered by searchText, and with optional langCode.
   * @param searchText
   *  Search text to filter the results.
   * @param langCode
   *  Language code of the results.
   * @returns
   *  Observable of the spots.
   */
  getSpots(searchText?: string, langCode?: string): Observable<Spot[]> {
    const options: ViewOptions = {
      filters: {
        langcode: langCode ?? this.i18nService.language,
      },
    };
    if (searchText) {
      options.filters.search_api_fulltext = searchText;
    }
    return this.viewService.get('/api/v1/spots.json', options);
  }

  /**
   * Get spot from the API, filtered by spotNid.
   * @param spotNid
   *  Node id of the spot.
   * @returns
   *  Observable of the spot.
   */
  getSpotByNid(spotNid: number): Observable<Spot> {
    const viewOptions: ViewOptions = {
      filters: {
        nid: spotNid.toString(),
      },
    };
    return this.viewService
      .get('/api/v1/spots.json', viewOptions)
      .pipe(
        map((spots) => (spots.length ? spots[0] : throwError(() => this.translateService.instant('Spot not found'))))
      );
  }

  getSpotsForAutoCompleteById(spotNid: number): Observable<SpotForAutocomplete | null> {
    const viewOptions: ViewOptions = {
      filters: {
        nid: spotNid.toString(),
      },
    };

    return this.viewService.get('/api/v1/spots.json', viewOptions).pipe(
      map((spots) => {
        if (spots.length === 0) {
          return null;
        }
        spots = spots.map((spot) => ({
          id: spot.nid,
          // eslint-disable-next-line max-len
          label: `${spot.title}`,
          objectValues: spot,
          field_spot_gps: spot.field_spot_gps,
          picture: null,
        }));
        console.log(spots[0]);
        return spots[0];
      })
    );
  }
}
