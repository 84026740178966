import { NgModule } from '@angular/core';
import { DaysLeftPipe } from './days-left.pipe';
import { SafePipe } from './safe/safe.pipe';
import { GroupByPipe } from './group-by.pipe';
import { FilterSpokenLanguagesPipe } from './filter-spoken-languages.pipe';
import { CenterUrlPipe } from './center-url.pipe';
import { StripeCountryCodePipe } from './stripe-country-code.pipe';
import { ShowSpokenLanguagesPipe } from './show-spoken-languages.pipe';
import { I18nDatePipe } from './i18n-date.pipe';
import { EntitiesHtmlPipe } from './entities-html.pipe';
import { StripHtmlTagPipe } from './strip-html-tag.pipe';
import { ArrayToCommaSepratedPipe } from './array-to-comma-seprated.pipe copy';

const pipes = [
  DaysLeftPipe,
  SafePipe,
  GroupByPipe,
  FilterSpokenLanguagesPipe,
  CenterUrlPipe,
  StripeCountryCodePipe,
  ShowSpokenLanguagesPipe,
  I18nDatePipe,
  EntitiesHtmlPipe,
  StripHtmlTagPipe,
  ArrayToCommaSepratedPipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
