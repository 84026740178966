import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalBankTransferInfoComponent } from './modal-bank-transfer-info.component';

@Injectable({
  providedIn: 'root',
})
export class ModalBankTransferInfoService extends ModalServiceModel {
  protected component = ModalBankTransferInfoComponent;
  protected options: NgbModalOptions = {
    size: 'md',
    centered: true,
    windowClass: 'modal-dialog-centered',
  };
}
