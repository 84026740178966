import { Observable } from 'rxjs';
import { ItemListForFilters } from '../models/item-list-for-filters.model';

export class ResponsiveSearchFiltersConfiguration {
  private mappedFields: { [key: string]: ResponsiveSearchFiltersFieldConfiguration };
  constructor(
    /**
     * Define the fields of the filters.
     */
    public fields: ResponsiveSearchFiltersFieldConfiguration[],
    /**
     * Define if has to show the clear filters button.
     */
    public showClearButton = true,
    /**
     * Define the number of multi rows in mobile if 0 is not activated
     */
    public multiRowsMobile = 0,
    /*
     * Define if show more button has to handled by automatic or not, false it will be handled by automatically.
     */
    public handleShowMoreButton = false
  ) {
    this.mappedFields = {};
    fields.forEach((field) => (this.mappedFields[field.name] = field));
  }

  getByFieldsName(fieldName: string): ResponsiveSearchFiltersFieldConfiguration | undefined | null {
    return this.mappedFields[fieldName];
  }
}

export interface ResponsiveSearchFiltersFieldConfiguration {
  /**
   * define the type of filter
   */
  type: ResponsiveSearchFiltersFieldType;
  /**
   * name of the field, it will be returned in the event of change
   */
  name: string;
  /**
   * define the placholder of the field of the field for empty value
   */
  placeholder?: string;
  /**
   * define if the field must be shown or not in desktop bar ot the component
   */
  mustShow?: boolean;
  /**
   * items for the select type fields
   */
  itemsForSelect?: ItemListForFilters[] | Observable<ItemListForFilters[]>;
  /**
   * initial value has to have the field
   */
  initialValue?: any;
  /**
   * define if the standard button in mobile must be replaced with this field
   */
  replaceStandardButtonMobile?: boolean;
  /**
   * extra data for the field
   */
  extraData?: any;
  /**
   * reset the field when the standard button in mobile change
   */
  resetOnStandardButtonMobileChange?: boolean;
  /**
   * define if the field is disabled or not
   */
  disabled?: any;
  /**
   * when mustoShow is true, define the width of the field in percentual
   */
  mustShowPercentualWidth?: string;
  /**
   * define is the field show appear as quick filter in mobile and in which row
   */
  multiRowsMobilePosition?: number;
  /**
   * define if the field must be shown in the overflow menu in desktop
   */
  mustShowOverflowDesktop?: boolean;
}

export enum ResponsiveSearchFiltersFieldType {
  multiSelect,
  singleSelect,
  slider,
  freeTextValue,
  distanceFromMap,
  courseType,
  courseTemplate,
  maxPrice,
  centerSelector,
  coachSelector,
  multiLocation,
  chipFilters,
  freeSearch,
}
