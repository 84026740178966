import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { DeviceDetectService, EreaderControllerService, NavDataService, RolesService } from '@app/core/services';
import { ModalManagerService } from '@app/core/services/modal-manager';
import { GlobalConst } from '@app/shared/constants/global.const';
import { environment } from '@env/environment';
import { UserService } from '@makiwin/ngx-drupal8-rest';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('logoDropdown') logoDropdown: ElementRef;
  currencies: Array<string> = ['USD', 'EUR'];
  selectedCurrency = 'USD';
  view = null;
  urlIsContent = false;
  isTablet = this.deviceDetectService.isTablet;
  isLogged = this.navDataService.isLogged;
  iwoUrl = GlobalConst.iwoUrl;
  logoDropdownOpened = false;

  constructor(
    public navDataService: NavDataService,
    public deviceDetectService: DeviceDetectService,
    public ereaderControllerService: EreaderControllerService,
    public rolesService: RolesService,
    public userService: UserService,
    protected modalManagerService: ModalManagerService
  ) {}

  @HostListener('document:click', ['$event'])
  logoClickout(event) {
    if (!this.logoDropdown?.nativeElement.contains(event.target)) {
      this.logoDropdownOpened = false;
    }
  }
  @HostListener('document:mouseover', ['$event'])
  logoHoverOut(event) {
    if (!this.logoDropdown?.nativeElement.contains(event.target)) {
      this.logoDropdownOpened = false;
    }
  }

  ngOnInit() {
    this.detectCurrentPage(this.navDataService.router.url);
    this.navDataService.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.detectCurrentPage(event.url);
      }
    });
  }

  /**
   * detect the home page on page change and set the required variable to handle back button
   */
  detectCurrentPage(currentURL: string) {
    this.isLogged = this.navDataService.isLogged;
    if (currentURL.indexOf('/account') !== -1 || currentURL === '/') {
      this.navDataService.resetHeaderLeftButton(true);
    } else {
      this.navDataService.ikoLogo = false;
      this.navDataService.resetHeaderLeftButton(false);
    }
    if (this.rolesService.userHasRole()) {
      setTimeout(() => {
        this.navDataService.resetHeaderRightButton();
        this.navDataService.resetHeaderLeftButton(true);
      });
    }

    this.urlIsContent = !(
      currentURL.indexOf('/elearning/gallery') === -1 || currentURL.indexOf('/gallery/skills') !== -1
    );
  }

  openCourses() {
    this.navDataService.navigate('/gutenberg/296');
  }

  openSpecialAdvisories() {
    this.navDataService.navigate(
      `https://${environment.serverUrl}/special-advisories?return_url=https://${environment.betaUrl}/sanction`,
      true
    );
  }

  handleDropDownMenu(menu: NgbDropdown) {
    if (!this.isTablet) {
      return;
    }
    menu.toggle();
  }

  handleMouseEnter(menu: NgbDropdown) {
    if (this.isTablet) {
      return;
    }
    menu.open();
  }

  handleMouseLeave(menu: NgbDropdown) {
    if (this.isTablet) {
      return;
    }
    menu.close();
  }

  handleMobileTouch() {
    this.logoDropdownOpened = !this.logoDropdownOpened;
    if (!this.logoDropdownOpened) {
      this.openHome();
    }
  }

  handleDesktopClick() {
    if (this.deviceDetectService.isTablet || this.deviceDetectService.isMobile) {
      return;
    }
    if (this.logoDropdownOpened) {
      this.openHome();
    }
  }

  openIWO($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.navDataService.navigate(this.iwoUrl, true);
    this.logoDropdownOpened = false;
  }

  private openHome() {
    this.navDataService.navigate(this.navDataService.isLogged ? '/account' : '/');
    this.logoDropdownOpened = false;
  }
}
