import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { CountryCode } from './country-code';

@Component({
  selector: 'iko-ngx-intl-tel-input',
  templateUrl: './ngx-intl-tel-input.component.html',
  styleUrls: ['./ngx-intl-tel-input.component.scss'],
  providers: [
    CountryCode,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxIntlTelInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NgxIntlTelInputComponent),
      multi: true,
    },
    // NgbDropdownConfig,
  ],
})
export class NgxIntlTelInputComponent implements ControlValueAccessor, Validator {
  @ViewChild('input', { static: false })
  input: { nativeElement: HTMLInputElement };
  @Input()
  autocomplete: string;
  @Input()
  cssClass = '';
  @Output() savePhone = new EventEmitter<string>();

  phoneControl = new FormControl(undefined);
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  phoneNumberFormat = PhoneNumberFormat;
  isDisabled = false;
  onChange;
  onTouch;
  validator;
  successStatus;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.valid && this.phoneControl.invalid) {
      return { phoneValidation: true };
    }
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.validator = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string) {
    this.phoneControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  changedCountry(country: Country) {
    if (!this.phoneControl.value) {
      this.phoneControl.setValue('+' + country.dialCode);
    }
  }

  changedValue() {
    if (!this.phoneControl.value) {
      // this.successStatus = 'border-success';
      this.onChange(null);
      return;
    }
    if (this.phoneControl.value) {
      delete this.successStatus;
      this.onChange(this.phoneControl.value.e164Number);
      return;
    }
  }
}
