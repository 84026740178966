<ng-container *ngIf="(isCookieHasConsented$ | async) === true">
  <ng-content></ng-content>
</ng-container>

<ng-container *ngIf="(isCookieHasConsented$ | async) === false">
  <div class="d-flex flex-column justify-content-center align-items-center w-100 h-100">
    <div class="d-flex justify-content-center align-items-center gap-1">
      <span> {{ 'content.message' | translate }} </span
      ><a [href]="'content.href' | translate">{{ 'content.link' | translate }}</a>
    </div>
    <button class="btn btn-success" (click)="openCookieBar()">{{ 'content.allow' | translate }}</button>
  </div>
</ng-container>
