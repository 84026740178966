<div ngbDropdown class="dropdown custom-width" *ngIf="userProfile$ | async">
  <a
    class="btn btn-default dropdown-toggle position-relative d-flex justify-content-center align-items-center align-self-center text-primary w-100 btn btn-simple border bg-white btn-round dropdown-toggle m-0 text-start border-light-gray"
    ngbDropdownToggle
  >
    <span>{{ selectedTemperature$ | async }}</span>
  </a>
  <ul
    *ngIf="(inLoading$ | async) === false"
    class="dropdown-menu dropdown-menu-right bg-white currency-div dropdown-height"
    ngbDropdownMenu
  >
    <li *ngFor="let unit of unitMeasures">
      <a
        class="dropdown-item position-relative justify-content-center align-items-center align-self-center"
        (click)="setUnitMeasure(unit)"
      >
        <span>{{ unit.label }}</span>
      </a>
    </li>
  </ul>
</div>
