import { Injectable } from '@angular/core';
import { UserSearch } from '@app/core/models';
import { CourseFormDetails } from '@app/courses/course-form/editable-course.models';
import { BaseService, HttpOptions } from '@makiwin/ngx-drupal8-rest';
import { mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends BaseService {
  bookCourse(content: any) {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/booking', content);
  }

  courseAddStudent(course: CourseFormDetails, user: UserSearch) {
    const bookingBody: any = {
      entity_type_id: 'node',
      entity_id: course.nid,
      status: 'pending',
      user_id: user.uid,
    };
    if (course.type === 'recreational_course') {
      const jsDate = new Date();
      bookingBody.from = jsDate.getTime() / 1000;
    } else if (course.type === 'pro_course') {
      const courseDate = course.field_course_date.split(' - ');
      const fromDate = new Date(courseDate[0]);
      const toDate = new Date(courseDate[1]);
      bookingBody.from = fromDate.getTime() / 1000;
      bookingBody.to = toDate.getTime() / 1000;
    }
    let source = this.bookCourse(bookingBody);
    if (!bookingBody.user_id) {
      delete user.uid;
      // WEB-3137
      (user as any).name = (user as any).student;
      delete (user as any).student;
      source = this.createUser(user).pipe(
        mergeMap((createdUser) => {
          bookingBody.user_id = createdUser.uid[0].value;
          return this.bookCourse(bookingBody);
        })
      );
    }
    return source;
  }

  createUser(user: UserSearch) {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/user/modal-create', user);
  }

  cancelBooking(id: number) {
    const httpOptions: HttpOptions = {
      method: 'post',
    };

    return this.request(httpOptions, '/api/booking', { status: 'canceled', id });
  }
}
