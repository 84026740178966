import { Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-image-magnifier',
  templateUrl: './image-magnifier.component.html',
  styleUrls: ['./image-magnifier.component.scss'],
})
export class ImageMagnifierComponent implements OnChanges {
  @Input() image: any;
  @Input() actualImage: any;
  @Input() toMagnifiedImage: any;
  @Input() alt: any;
  isZoomed = false;
  zoomedImageStyle: any = {};
  magnifierCanvas: HTMLCanvasElement;
  imageElement: HTMLImageElement;
  img: any;
  glass: any;
  w: number;
  h: number;
  bw: number;
  zoomLevel = 5;
  randomId = Math.round(Math.random() * 10000);

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    setTimeout(() => {
      this.magnify('image');
    }, 300);
  }

  getCursorPos(e) {
    /*get the x and y positions of the image:*/
    const a = this.img.getBoundingClientRect();
    let x = 0;
    let y = 0;
    e = e || window.event;
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x, y };
  }

  moveMagnifier = (e) => {
    /*get the cursor's x and y positions:*/
    const pos = this.getCursorPos(e);
    let x;
    let y;
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    x = pos.x;
    y = pos.y;
    /*prevent the magnifier glass from being positioned outside the image:*/
    if (x > this.img.width - this.w / this.zoomLevel) {
      x = this.img.width - this.w / this.zoomLevel;
    }
    if (x < this.w / this.zoomLevel) {
      x = this.w / this.zoomLevel;
    }
    if (y > this.img.height - this.h / this.zoomLevel) {
      y = this.img.height - this.h / this.zoomLevel;
    }
    if (y < this.h / this.zoomLevel) {
      y = this.h / this.zoomLevel;
    }
    /*set the position of the magnifier glass:*/
    this.glass.style.display = 'block';
    this.glass.style.left = '120%';
    this.glass.style.top = '0';
    /*display what the magnifier glass "sees":*/
    this.glass.style.backgroundPosition =
      '-' +
      ((x - 10) * this.zoomLevel - this.w + this.bw) +
      'px -' +
      ((y - 10) * this.zoomLevel - this.h + this.bw) +
      'px';
  };

  hideMagnifier = () => {
    this.glass.style.display = 'none';
  };

  magnify(imgID) {
    this.img = document.getElementById(imgID + this.randomId);
    this.glass = document.createElement('DIV');
    /*create magnifier glass:*/
    this.glass.setAttribute('id', 'img-magnifier-glass' + this.randomId);
    this.glass.setAttribute('class', 'img-magnifier-glass');
    /*insert magnifier glass:*/
    this.img.parentElement.insertBefore(this.glass, this.img);
    /*set background properties for the magnifier glass:*/
    this.glass.style.display = 'none';
    this.glass.style.backgroundImage = `url(${
      this.image ? this.image.square_hd : this.toMagnifiedImage ?? this.actualImage
    })`;
    this.glass.style.backgroundRepeat = 'no-repeat';
    this.glass.style.backgroundSize = this.img.width * this.zoomLevel + 'px ' + this.img.height * this.zoomLevel + 'px';
    this.bw = 3;
    this.w = this.glass.offsetWidth / 2;
    this.h = this.glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    this.glass.addEventListener('mousemove', this.moveMagnifier);
    this.img.addEventListener('mousemove', this.moveMagnifier);
    this.img.addEventListener('mouseout', this.hideMagnifier);
    this.glass.addEventListener('mouseout', this.hideMagnifier);
    /*and also for touch screens:*/
    this.glass.addEventListener('touchmove', this.moveMagnifier, { passive: true });
    this.img.addEventListener('touchmove', this.moveMagnifier, { passive: true });
    this.glass.addEventListener('pointermove', this.moveMagnifier, { passive: true });
    this.img.addEventListener('pointermove', this.moveMagnifier, { passive: true });
  }
}
