import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreferredLanguageService {
  langs$: Observable<string[]>;
  private pLangs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private i18nService: I18nService) {
    this.langs$ = this.pLangs$.asObservable();
    this.initLanguages();
  }

  private initLanguages() {
    this.pLangs$.next(
      Object.keys(this.i18nService.translateService.store.translations).map(
        (key) => this.i18nService.translateService.store.translations[key]
      )
    );
  }
}
