import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';

@Component({
  selector: 'responsive-filter-freesearch',
  templateUrl: './responsive-filter-freesearch.component.html',
  styleUrls: ['./responsive-filter-freesearch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResponsiveFilterFreesearchComponent),
      multi: true,
    },
  ],
})
export class ResponsiveFilterFreesearchComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<string>, OnInit
{
  @Input() placeHolder;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @Input() extraData?: any;

  protected currentValue: string;
  protected formControl: FormControl = new FormControl();
  private isInit = false;

  @Input() set initialValue(value: string) {
    this.isInit = true;
    this.formControl.setValue(value);
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.currentValue = value;
      if (this.isInit) {
        this.isInit = false;
        return;
      }
      this.onChange(value);
    });
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string | null) => {};

  /**
   * Handle the touch of the element.
   */
  onTouched = () => {};

  writeValue(center: string): void {
    this.isInit = true;
    this.formControl.setValue(center);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
