<div ngbDropdown class="dropdown dropdown-icon-right" #dropdown *ngIf="!isInModal; else templateForModal">
  <button
    class="dropdown-toggle form-control d-flex text-start bg-white p-3 {{
      selectedPlaceHolder ? 'border border-1 border-success text-success fw-bold' : ''
    }}"
    ngbDropdownToggle
    style
  >
    <div class="w-100 flex-fill d-flex align-items-center">
      <i class="text-info icon me-2 icon-16 icon-graduation-cap"></i>
      <span class="text-truncate d-inline-block flex-fill">
        {{ selectedPlaceHolder ?? placeHolder }}
      </span>
      <i class="icon icon-times icon-20 me-2" *ngIf="selectedPlaceHolder" (click)="clear($event)"></i>
    </div>
  </button>
  <div ngbDropdownMenu class="rounded-2 mt-2 shadow" style="min-width: 250px">
    <div class="m-3 items-container">
      <ng-container [ngTemplateOutlet]="slider"></ng-container>
    </div>
    <hr class="m-0" />
    <div class="p-2 pb-0 d-flex justify-content-between drodpdown-buttons-filters">
      <button class="btn rounded-2 m-0 d-flex align-items-center" (click)="clear($event)">
        <i class="icon icon-times me-1"></i> <span tanslate>Clear</span>
      </button>
      <button class="btn btn-success rounded-2 m-0 d-flex align-items-center" (click)="confirm()">
        <i class="icon icon-check me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</div>

<ng-template #templateForModal>
  <div class="{{ priceControl.value > extraData.max ? 'text-success' : 'text-dark' }} font-size- fw-bold mb-2     ">
    {{ placeHolder | translate }}
  </div>
  <div class="row">
    <ng-container [ngTemplateOutlet]="slider"></ng-container>
  </div>
  <hr />
</ng-template>

<ng-template #slider>
  <div class="col-12 mb-3">
    <div class="form-group custom-slider">
      <div class="d-flex flex-row align-items-center">
        <a
          class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
          (click)="decreasePrice()"
        >
          <div>&minus;</div></a
        >
        <div class="px-4">{{ extraData?.min }}{{ currencySymbol }}</div>
        <div class="flex-fill">
          <nouislider
            *ngIf="extraData"
            [config]="sliderConfig"
            class="slider slider-success"
            [formControl]="priceControl"
          ></nouislider>
        </div>
        <div class="px-4">{{ extraData?.max }}{{ currencySymbol }}</div>
        <a
          class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
          (click)="increasePrice()"
        >
          <div>&plus;</div>
        </a>
      </div>
    </div>
  </div>
</ng-template>
