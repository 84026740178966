<ng-container [formGroup]="group">
  <div
    class="btn-group btn-group-toggle form-group"
    data-toggle="buttons"
    [formGroupName]="model.id"
    [id]="id"
    [ngClass]="getClass('element', 'control')"
  >
    <div class="form-check">
      <label
        *ngFor="let checkboxModel of model.group"
        ngbButtonLabel
        [hidden]="checkboxModel.hidden"
        [ngClass]="getClass('element', 'control', checkboxModel)"
        class="form-check-label mb-2 d-block"
      >
        <input
          class="form-check-input"
          type="checkbox"
          ngbButton
          [checked]="checkboxModel.checked"
          [formControlName]="checkboxModel.id"
          [id]="getCheckboxId(checkboxModel)"
          [name]="checkboxModel.name"
          [required]="checkboxModel.required"
          [tabindex]="checkboxModel.tabIndex"
          [value]="checkboxModel.value"
          (blur)="onBlur($event)"
          (change)="onCheckboxChange($event, checkboxModel)"
          (focus)="onFocus($event)"
        />
        <span class="form-check-sign" [ngClass]="{ checked: checkboxModel.value }"></span>
        <span [ngClass]="getClass('element', 'label', checkboxModel)" [innerHTML]="checkboxModel.label"></span>
      </label>
    </div>
  </div>
</ng-container>
