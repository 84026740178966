<!-- <div class="container h-100" *ngIf="images && images.length > 0">
  <div class="row align-items-center h-100">
    <div class="col">
      <swiper class="swiper" [config]="galleryTop">
        <ng-template swiperSlide *ngFor="let image of images">
          <div class="bg-no-repeate bg-cover">
            <image-srcset [item]="image"></image-srcset>
          </div>
        </ng-template>
        <span slot="container-start" class="button-prev start-0 position-absolute button-navigation-swiper l-0 pointer">
          <i class="icon icon-64 icon-angle-left"></i>
        </span>
        <span slot="container-end" class="button-next position-absolute button-navigation-swiper r-0 pointer">
          <i class="icon icon-64 icon-angle-right"></i>
        </span>
      </swiper>
      <swiper class="swiper gallery-thumbs" [config]="galleryThumbs">
        <ng-template swiperSlide *ngFor="let image of images">
          <div class="swiper-slide bg-no-repeate bg-cover">
            <image-srcset [item]="image"></image-srcset>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</div> -->
<!-- WEB-2580 -->
<ngb-carousel>
  <ng-template ngbSlide *ngFor="let image of images">
    <image-srcset imgClass="w-100" [item]="image"></image-srcset>
  </ng-template>
</ngb-carousel>
