import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CkeditorComponent implements OnInit {
  static ckEditorLoaded = false;
  @Input() controlName: string;
  editor: any;

  constructor() {}

  get editorIsLoaded(): boolean {
    return CkeditorComponent.ckEditorLoaded;
  }

  ngOnInit() {
    if (!CkeditorComponent.ckEditorLoaded) {
      this.loadCkEditor();
    } else {
      this.editor = (window as any).ClassicEditor;
    }
  }

  loadCkEditor() {
    const jsElmCK = document.createElement('script');
    jsElmCK.type = 'application/javascript';
    jsElmCK.src = 'https://cdn.ckeditor.com/ckeditor5/34.0.0/classic/ckeditor.js';
    document.body.appendChild(jsElmCK);

    jsElmCK.onload = () => {
      this.editor = (window as any).ClassicEditor;
      this.editor.defaultConfig = {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'undo',
            'redo',
          ],
        },
        // This value must be kept in sync with the language defined in webpack.config.js.
        language: 'en',
      };
      CkeditorComponent.ckEditorLoaded = true;
    };
  }
}
