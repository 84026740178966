import { CommonModule } from '@angular/common';
import { Component, NgZone, ViewEncapsulation, inject } from '@angular/core';

import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { CustomService } from '@app/core/services/drupal8/custom/custom.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@ionic-native/camera-preview/ngx';
import { Camera, CameraOptions, Direction } from '@ionic-native/camera/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CircularSpinnerComponent } from '../circular-spinner/circular-spinner.component';
import { ImagePickerCropperOptions } from './image-picker-cropper-options.interface';
export enum PickerSteps {
  chooseSource,
  takeFromWebCam,
  cropImage,
}

enum SourceFrom {
  camera,
  gallery,
}

@Component({
  standalone: true,
  selector: 'image-picker-cropper',
  imports: [CommonModule, TranslateModule, WebcamModule, ImageCropperModule, CircularSpinnerComponent],
  templateUrl: './image-picker-cropper.component.html',
  styleUrls: ['./image-picker-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImagePickerCropperComponent {
  options: ImagePickerCropperOptions;
  sources = SourceFrom;
  steps = PickerSteps;
  deviceId: string;
  previewYPosision: number;
  previewXSize: number;
  cameraOpened = false;
  aspectRatio: number;
  videoOptions: MediaTrackConstraints = {};
  errors: WebcamInitError[] = [];
  imageCropped: string;
  imageToCropper: string;
  cropperInLoading = false;
  title = 'Select picture from';

  // latest snapshot
  public webcamImage: WebcamImage = null;

  private pCurrentStep: PickerSteps = PickerSteps.chooseSource;
  private pTriggerWebcamPic: Subject<void> = new Subject<void>();
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public activeModal: NgbActiveModal,
    private deviceDetectService: DeviceDetectService,
    private camera: Camera,
    private dialogs: Dialogs,
    private diagnostic: Diagnostic,
    private cameraPreview: CameraPreview,
    private navDataService: NavDataService,
    private translateService: TranslateService,
    private zone: NgZone,
    private customService: CustomService
  ) {}

  get triggerWebcamPicObservable(): Observable<void> {
    return this.pTriggerWebcamPic.asObservable();
  }

  get currentStep(): PickerSteps {
    return this.pCurrentStep;
  }

  set currentStep(step: PickerSteps) {
    this.pCurrentStep = step;
    switch (this.currentStep) {
      case PickerSteps.chooseSource:
        if (this.options.excludeCamera) {
          this.importPicture(this.sources.gallery);
          break;
        }
        this.title = 'Select picture from';
        break;
      case PickerSteps.takeFromWebCam:
        this.title = this.options.takePictureTitle ?? 'Say cheese';
        break;
      case PickerSteps.cropImage:
        this.title = '';
        break;
    }
  }
  resetSteps(): void {
    this.currentStep = PickerSteps.chooseSource;
  }

  importPicture(source: SourceFrom): void {
    if (this.deviceDetectService.isCordova) {
      this.importWithCordova(source);
    } else {
      this.importFallbackWeb(source);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  imageLoaded(image: LoadedImage): void {
    this.cropperInLoading = false;
  }

  //region webcam functions

  public triggerWebcamPic(): void {
    this.pTriggerWebcamPic.next();
  }

  handleImageWebcam(webcamImage: WebcamImage): void {
    this.openCropper(webcamImage.imageAsDataUrl);
  }

  handleInitErrorWebCam(error: WebcamInitError): void {
    this.notificationsService.notifyError(this.translateService.instant('Webcam error') + ' ' + error.message);
  }

  //endregion

  imageCroppedReceive(event: ImageCroppedEvent) {
    this.zone.run(() => {
      if (!event.base64 && event.blob) {
        this.customService.convertFileToBase64(event.blob).subscribe((data) => {
          event.base64 = data as any;
          this.imageCropped = event.base64;
        });
        return;
      }
      this.imageCropped = event.base64;
    });
  }

  readFile(file: File): void {
    if (!file) {
      return;
    }
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (loadEvent: ProgressEvent<FileReader>) => {
      this.openCropper(loadEvent.target.result.toString());
    };
    myReader.readAsDataURL(file);
  }

  complete(): void {
    if (this.imageCropped) {
      this.activeModal.close(this.imageCropped);
    }
  }

  takePreviewPicture() {
    // picture options
    const pictureOpts: CameraPreviewPictureOptions = {
      quality: 100,
    };
    this.cameraPreview.takeSnapshot(pictureOpts).then((imageData) => {
      this.openCropper('data:image/png;base64,' + imageData);
      this.disableCamera();
    });
  }

  disableCamera() {
    if (!this.cameraOpened) {
      return;
    }
    this.cameraOpened = false;
    document.body.classList.remove('bg-transparent');
    this.navDataService.displayHeader = true;
    this.cameraPreview.stopCamera();
  }

  private importWithCordova(source: SourceFrom): void {
    if (source === SourceFrom.camera) {
      return this.openCameraPreviewMobile();
    }
    const camOptions: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      allowEdit: false,
      targetHeight: this.options.maxHeight,
      targetWidth: this.options.maxWidth,
      cameraDirection: this.options.backCamera ? Direction.BACK : Direction.FRONT,
      correctOrientation: true,
    };
    camOptions.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;

    this.camera
      .getPicture(camOptions)
      .then((imageData) => {
        this.openCropper('data:image/png;base64,' + imageData);
      })
      .catch((err) => {
        if (err === 'Illegal access' || err === 20) {
          this.dialogs
            .confirm(
              this.translateService.instant('check_photos_permission_setting'),
              this.translateService.instant('Authorization')
            )
            .then((data) => {
              if (data === 1) {
                this.diagnostic.switchToSettings();
              }
            });
        } else if (err !== 'No Image Selected') {
          this.notificationsService.notifyError(this.translateService.instant(err));
          this.logError(err);
        } else {
        }
      });
  }

  private openCameraPreviewMobile(): void {
    this.previewYPosision = (window.screen?.height - window.screen?.width) / 2;
    this.previewXSize = window.screen.width;
    const cameraPreviewOpts: CameraPreviewOptions = {
      x: 0,
      y: this.previewYPosision,
      width: window.screen?.width,
      height: window.screen?.width,
      camera: this.options.backCamera ? 'back' : 'front',
      tapPhoto: true,
      tapFocus: true,
      previewDrag: true,
      toBack: true,
      alpha: 1,
    };

    // start camera
    this.cameraPreview.startCamera(cameraPreviewOpts).then(
      () => {
        this.cameraOpened = true;
        document.body.classList.add('bg-transparent');
        this.navDataService.displayHeader = false;
      },
      (err) => {
        if (err === 'Illegal access') {
          this.dialogs
            .confirm(
              this.translateService.instant('check_camera_permission_setting'),
              this.translateService.instant('Authorization')
            )
            .then((data) => {
              if (data === 1) {
                this.diagnostic.switchToSettings();
              }
            });
        }
      }
    );
  }

  private importFallbackWeb(source: SourceFrom): void {
    if (source === SourceFrom.camera) {
      this.currentStep = PickerSteps.takeFromWebCam;
    } else {
      document.getElementById('image-picker-cross-file-input-picker').click();
    }
  }

  private openCropper(url: string) {
    if (this.options.noCropping) {
      this.imageCropped = url;
      this.complete();
      return;
    }
    this.cropperInLoading = true;
    this.aspectRatio = this.options.maxWidth / this.options.maxHeight;
    this.imageToCropper = url;
    this.currentStep = PickerSteps.cropImage;
  }

  private logError(error: any): void {
    this.customService.sendLog(
      'error',
      error.toString() + ` | image-picker-cropper.component.ts | url: ${window.location?.href}`
    );
  }
}
