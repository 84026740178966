import { Component, Input } from '@angular/core';
import { CustomService } from '@app/core/services/drupal8/custom/custom.service';
import { WebformService } from '@makiwin/ngx-drupal8-rest';
import { ImagePickerCropperService } from '../image-picker-cropper/image-picker-cropper.service';
import { CommonService } from '@app/core/services/common/common.service';

interface FileForForm {
  file: File;
  isLoading: boolean;
  hasError: boolean;
  uploadId?: number;
}

@Component({
  selector: 'file-upload-picker-cropper',
  templateUrl: './file-upload-picker-cropper.component.html',
  styleUrls: ['./file-upload-picker-cropper.component.scss'],
})
export class FileUploadPickerCropperComponent {
  fileObject: FileForForm;
  multiFileObject: FileForForm[] = [];
  isMultiple: boolean;
  private localConfig: any;

  constructor(
    private imagePickerCropperService: ImagePickerCropperService,
    private customService: CustomService,
    public commonService: CommonService,
    private webformService: WebformService
  ) {}

  // TODO: interface config
  @Input() set config(config: any) {
    this.localConfig = config;
    this.isMultiple = config.multiple;
  }

  /**
   * Open the file picker and handle the optionally upload.
   * WEB-3676: enable until refactoring is completed
   * TODO: switch to new code hehe
   */
  openFilePicker() {
    this.imagePickerCropperService.open(this.localConfig?.picker).subscribe((imageData) => {
      const imageContent = imageData.split(',');
      const fileExt = imageContent[0].split('/').pop().split(';')[0];
      const fileName = `${Math.floor(Math.random() * (1000 - 0 + 1) + 0)}.${fileExt}`;
      const tempFile: FileForForm = {
        file: this.customService.getFileObjectFromImgData(imageContent[1], fileName),
        isLoading: false,
        hasError: false,
      };
      if (!this.isMultiple) {
        this.fileObject = tempFile;
      } else {
        this.multiFileObject.push(tempFile);
      }
      if (this.localConfig.type === 'webform') {
        this.commonService.changeUploadFileLoading(true);
        // tempFile.isLoading = true;
        this.webformService.upload(this.localConfig.id, this.localConfig.field, tempFile.file).subscribe({
          next: (data) => {
            console.log(data);
            this.commonService.changeUploadFileLoading(false);
            // tempFile.isLoading = false;
            tempFile.uploadId = data.fid[0].value;
            this.updateValue();
          },
          error: () => {
            this.commonService.changeUploadFileLoading(false);
            // tempFile.isLoading = false;
            tempFile.hasError = true;
            setTimeout(() => this.removeFile(tempFile), 5000);
          },
        });
      }
    });
  }

  /**
   * Remove file from the list in case of the multiple upload, or the file selected.
   *
   * @param fileObject
   */
  removeFile(fileObject: FileForForm) {
    if (!this.isMultiple) {
      delete this.fileObject;
      return;
    } else {
      const index = this.multiFileObject.indexOf(fileObject);
      if (index !== -1) {
        this.multiFileObject.splice(index, 1);
      }
    }
    this.updateValue();
  }

  /**
   * Update the value of the form controller associated.
   *
   */
  updateValue() {
    if (!this.localConfig.control) {
      return;
    }
    this.localConfig.control.patchValue(
      this.isMultiple
        ? this.multiFileObject.filter((singleFile) => singleFile.uploadId).map((singleFile) => singleFile.uploadId)
        : this.fileObject.uploadId
    );
  }
}
