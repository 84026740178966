/**
 * notification message and options
 *
 * @for notification component in shared module
 */
export interface NotificationMessage {
  // type of the message will change the bg color
  type: 'success' | 'info' | 'warning' | 'danger' | 'secondary';
  // message to be displayed
  message: string;
  // left icon
  icon?: string;
  // display close button
  close?: boolean;
  // auto hide after 3 seconds or seconds set in duration
  autoHide?: boolean;
  // flag to check if is connection warning or not
  connectionWarning?: boolean;
  // time in seconds to before the notification will be auto closed
  duration?: number;
}
