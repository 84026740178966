import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { crossfade } from '@app/core/animations';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { PreferredLanguageService } from './preferred-language.service';
import { LocalStorageService } from '@app/core/services/localstorage/localstorage.service';

@Component({
  selector: 'app-preferred-language',
  templateUrl: './preferred-language.component.html',
  styleUrls: ['./preferred-language.component.scss'],
  animations: [crossfade],
})
export class PreferredLanguageComponent implements OnInit, OnDestroy {
  @Input() updateProfile = true;
  @Input() updateUi = true;
  @Input() forceDisplay = false;
  @Input() isContent = false;
  @Output() languageChanged = new EventEmitter<string>();

  preferredLanguageText1: string;
  preferredLanguageText2: string;

  display = true;
  interval: NodeJS.Timer;
  valueState1 = false;
  valueState2 = false;
  languageIndex = -1;
  langs = [];

  private pUnsubscribeAll: Subject<any> = new Subject<any>();
  private readonly keyLabel = 'Your preferred language is';

  constructor(
    public deviceDetectService: DeviceDetectService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private prefLanguageService: PreferredLanguageService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    // force adding it to translation template
    this.translateService.get(this.keyLabel).subscribe();

    if (this.localStorageService.getItem('preferredLanguage')) {
      this.display = false;
      // return;
    }
    this.initLanguages();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.pUnsubscribeAll.next(0);
    this.pUnsubscribeAll.complete();
    clearInterval(this.interval);
  }

  initLanguages() {
    // save current language

    this.prefLanguageService.langs$.subscribe((data) => {
      // remove last result from the temp fix for current language
      this.langs = data.filter((lang) => lang[this.keyLabel]);
      // start animation
      this.animateValue();
      this.interval = setInterval(() => {
        this.animateValue();
      }, 4000);
    });
  }

  animateValue(): void {
    if (!this.i18nService.supportedLanguages) {
      return;
    }
    this.languageIndex++;
    if (this.languageIndex === Object.keys(this.i18nService.supportedLanguages).length) {
      this.languageIndex = 0;
    }

    const text =
      this.langs.length > 0
        ? this.langs[this.languageIndex]
          ? this.langs[this.languageIndex][this.keyLabel]
          : this.keyLabel
        : this.keyLabel;
    if (this.valueState1) {
      this.preferredLanguageText2 = text;
      this.valueState1 = false;
      this.valueState2 = true;
    } else {
      this.preferredLanguageText1 = text;
      this.valueState1 = true;
      this.valueState2 = false;
    }
  }

  close() {
    this.localStorageService.setItem('preferredLanguage', 'true');
    this.display = false;
  }
}
