import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'daysleft' })
export class DaysLeftPipe implements PipeTransform {
  transform(date: string, location: string): any {
    // get the date string
    let requiredDate = date;
    // if the date contain multiple parts
    if (location) {
      // split the date parts
      const dateFrags = date.split(' - ');
      // if the required is the end date get the second item, else get the first one
      if (location === 'end') {
        requiredDate = dateFrags[1];
      } else {
        requiredDate = dateFrags[0];
      }
    }
    // single day in ms
    const oneDay = 24 * 60 * 60 * 1000;
    // get current date
    const now = new Date();
    const currentDate = now.getTime();
    const endDate = new Date(requiredDate).getTime();
    let leftDays: number;
    if (endDate > currentDate) {
      // calculate days left
      leftDays = Math.round(Math.abs(currentDate - endDate) / oneDay);
    } else {
      leftDays = 0;
    }
    return leftDays;
  }
}
