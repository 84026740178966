import { Component, EventEmitter, Input, Output, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Observable,
  OperatorFunction,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AutocompleteForFiltersItem } from './autocomplete-for-filters-item.interface';

@Component({
  selector: 'autocomplete-for-filters',
  templateUrl: './autocomplete-for-filters.component.html',
  styleUrls: ['./autocomplete-for-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteForFiltersComponent),
      multi: true,
    },
  ],
})
export class AutocompleteForFiltersComponent implements ControlValueAccessor {
  /**
   * Set extra input class of the form control.
   */
  @Input() inputClass: string;
  /**
   * Set if should be return only the id or the full object.
   * If true will return only the id, by default will return the full object.
   */
  @Input() needOnlyId = false;
  /**
   * Set if the image should be displayed.
   */
  @Input() showImageItem = false;
  /**
   * Set if the image should be rounded.
   */
  @Input() roundedImage = false;
  @Input() highlightOnValue = false;
  /**
   * Set if the control is inside the modal.
   * If true, the form control won't be highlighted with value.
   */
  @Input() isInModal = false;
  /**
   * Set the placement of the dropdown.
   */
  @Input() placement: string[] = ['bottom-start'];
  /**
   * Set the placeholder of the form control.
   */
  @Input() placeHolder = 'Search for a value';
  /**
   * Set the function to call when the user search for a value.
   */
  @Input() searchValues: (searchedText: string) => Observable<AutocompleteForFiltersItem[]>;
  /**
   * Set the template to display when there's no picture defined.
   */
  @Input() imagePlaceholder: TemplateRef<any>;
  /**
   * Set the graphicals status of validity of the form control.
   */
  @Input() isInvalid: boolean;
  /**
   * Emits when the form control is focused.
   */
  @Output() focused: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Emits when the form control is blurred.
   */
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  searching = false;
  currentItem: AutocompleteForFiltersItem;
  currentItemModel = new FormControl(null);
  isDisabled = false;
  hasError = false;
  onChange;
  onTouch;
  protected isInit = false;

  constructor() {
    this.currentItemModel.valueChanges.subscribe((value) => this.onChangeValue(value));
  }

  formatter = (x: AutocompleteForFiltersItem) => (!x ? '' : `${x.label}${x.subLabel ? ` - ${x.subLabel}` : ''}`);

  search: OperatorFunction<string, readonly AutocompleteForFiltersItem[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        this.searching = true;
        this.hasError = false;
        return this.searchValues(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.hasError = true;
            return of([]);
          }),
          finalize(() => (this.searching = false))
        );
      })
    );

  writeValue(obj: any): void {
    this.currentItem = obj;
    this.isInit = true;
    this.currentItemModel.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onChangeValue(value: AutocompleteForFiltersItem | string) {
    if (!this.onChange) {
      return;
    }
    if (this.isInit) {
      this.isInit = false;
      return;
    }
    if (typeof value === 'string') {
      this.currentItem = null;
      this.onChange(null);
      return;
    }

    this.currentItem = value;
    this.onChange(value);
  }
}
