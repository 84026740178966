import { AutocompleteInputItem } from '@app/shared/forms/autocomplete-input/autocomplete-input-item';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { Observable, of } from 'rxjs';

export class ProviderDynamicAutoCompleteSchoolsMemory implements AutoCompleteInputProvider {
  private viewService: ViewService;
  private schools: AutocompleteInputItem[];

  constructor(viewService: ViewService) {
    this.viewService = viewService;
    this.fetchSchools();
  }

  filter(term: string): Observable<AutocompleteInputItem[]> {
    term = term.toLowerCase();
    return of(this.schools.filter((item) => item.label.toLowerCase().indexOf(term) > -1));
  }

  private fetchSchools(): void {
    this.viewService.get('/api/v1/centers_autocomplete.json').subscribe({
      next: (result) => {
        this.schools = result
          .map((item) => ({ value: item.nid, label: item.title }))
          .sort((a, b) => a.label.toLowerCase().localeCompare(b.label));
      },
    });
  }
}
