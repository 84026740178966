<div class="w-100 mt-2 text-center">
  <div class="flex-row justify-content-center align-items-center d-flex h3 px-3">
    <i class="icon icon-exclamation-triangle me-2 text-warning"></i>
    <span translate>Error loading content</span>
  </div>
  <button class="btn btn-success rounded-1" *ngIf="showReloadButton" (click)="reloadClick.emit()">
    <i class="icon icon-sync me-2"></i>
    <span translate>Reload</span>
  </button>
</div>
