import { BehaviorSubject } from 'rxjs';
import { ApiResults, MultiLocationValue, MultiLocationValueType } from './responsive-filter-multilocation.interfaces';

export class ResponsiveFilterMultilocationBestResult {
  currentBest$: BehaviorSubject<MultiLocationValue | null> = new BehaviorSubject<MultiLocationValue | null>(null);
  currentBest: MultiLocationValue;

  check(results: ApiResults, searchValue: string) {
    if (!searchValue || searchValue?.length < 3) {
      this.reset();
      return;
    }
    searchValue = searchValue.toLowerCase();
    for (const destination of results.destinations) {
      if (destination.field_destination_country_country_name.toLowerCase() === searchValue) {
        this.currentBest$.next({ value: destination, type: MultiLocationValueType.destination });
        return;
      }
    }

    for (const spot of results.spots) {
      if (spot.title.toLowerCase() === searchValue) {
        this.currentBest$.next({ value: spot, type: MultiLocationValueType.spot });
        return;
      }
    }

    const searchValues = searchValue.split(' ');
    let count = 0;
    for (const center of results.centers) {
      count = 0;
      for (const centerWord of center.label.toLowerCase().split(' ')) {
        for (const searchWord of searchValues) {
          if (centerWord === searchWord) {
            count++;
          }
        }
        if (count === 0) {
          continue;
        } else if (count === searchValues.length) {
          this.currentBest$.next({ value: center, type: MultiLocationValueType.center });
          return;
        }
      }
    }
    if (results.centers.length > 0) {
      this.currentBest$.next({ value: results.centers[0], type: MultiLocationValueType.center });
      return;
    }

    if (results.googlePlaces.length > 0) {
      this.currentBest$.next({ value: results.googlePlaces[0], type: MultiLocationValueType.googlePlace });
      return;
    }

    this.reset();
  }

  reset() {
    this.currentBest$.next(null);
  }
}
