import { TemplateRef } from '@angular/core';
import {
  DynamicFormControlLayout,
  DynamicFormControlModel,
  DynamicFormValueControlModelConfig,
  serializable,
} from '@ng-dynamic-forms/core';
// import { stringify } from 'querystring';

interface ViewInterface {
  arguments: string[];
  display_name: string;
  view_name: string;
}

interface SelectionSettings {
  view: ViewInterface;
  target_bundles?: { [key: string]: string };
  filter?: { [key: string]: any };
}

export const DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE = 'ENTITY_AUTOCOMPLETE';

export interface DynamicEntityAutocompleConfig extends DynamicFormValueControlModelConfig<string | number> {
  selection_settings: SelectionSettings;
  selection_handler?: string;
  placeholder?: string;
  showThumbnails?: boolean;
  showRoundedThumbnails?: boolean;
  noThumbnailsTemplate?: TemplateRef<any>;
  target_type?: string;
}

export class DynamicEntityAutocompleteModel extends DynamicFormControlModel {
  @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE;
  selection_settings: SelectionSettings;
  placeholder?: string;
  showThumbnails?: boolean;
  showRoundedThumbnails?: boolean;
  noThumbnailsTemplate?: TemplateRef<any>;
  selection_handler: string;
  target_type?: string;

  constructor(config: DynamicEntityAutocompleConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.selection_settings = config.selection_settings;
    this.placeholder = config.placeholder;
    this.selection_handler = config.selection_handler;
    this.target_type = config.target_type;
    this.showThumbnails = config.showThumbnails;
    this.showRoundedThumbnails = config.showRoundedThumbnails;
    this.noThumbnailsTemplate = config.noThumbnailsTemplate;
  }
}
