import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { trigger, state, style, transition, animate } from '@angular/animations';

export enum CourseTypeValues {
  recreational = 'recreational_course',
  pro = 'pro_course',
  none = 'none',
}

@Component({
  selector: 'course-type-selector',
  templateUrl: './course-type-selector.component.html',
  styleUrls: ['./course-type-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CourseTypeSelectorComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('backgroundAnimationRecreational', [
      state(
        CourseTypeValues.recreational,
        style({
          opacity: 1,
        })
      ),
      state(
        CourseTypeValues.pro,
        style({
          opacity: 0,
        })
      ),
      transition('* => *', [animate('0.75s ease-in-out')]),
    ]),
    trigger('backgroundAnimationPro', [
      state(
        CourseTypeValues.recreational,
        style({
          opacity: 0,
        })
      ),
      state(
        CourseTypeValues.pro,
        style({
          opacity: 1,
        })
      ),
      transition('* => *', [animate('0.75s ease-in-out')]),
    ]),
  ],
})
export class CourseTypeSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<CourseTypeValues>, OnInit
{
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: CourseTypeValues;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;

  currentValue: CourseTypeValues;
  currentBooleanValue;
  selectedPlaceHolder: string;
  courseTypes = CourseTypeValues;

  get value(): CourseTypeValues | null {
    return this.currentValue;
  }

  set value(val: CourseTypeValues | null) {
    this.writeValue(val ?? CourseTypeValues.recreational);
    this.onTouched();
  }

  ngOnInit(): void {
    this.value = this.initialValue ?? CourseTypeValues.recreational;
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: CourseTypeValues | null) => {};

  onTouched = () => {};

  writeValue(value: CourseTypeValues | null): void {
    this.currentValue = value ?? CourseTypeValues.recreational;
    this.currentBooleanValue = value === CourseTypeValues.pro;
    this.onChange(this.currentValue);
    this.generatePlaceHolder();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  switchType($event: any): void {
    this.value = $event.currentValue ? CourseTypeValues.pro : CourseTypeValues.recreational;
  }

  /**
   * generate the placeholder for the field
   *
   */
  private generatePlaceHolder(): void {}
}
