import { AutocompleteInputItem } from '@app/shared/forms/autocomplete-input/autocomplete-input-item';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { map, Observable } from 'rxjs';

export class ProviderDynamicAutoCompleteSchoolServer implements AutoCompleteInputProvider {
  showThumbnails = true;
  noThumbnailsTemplate?: boolean;
  private viewService: ViewService;
  private endpoint: string;

  constructor(viewService: ViewService) {
    this.viewService = viewService;
    this.endpoint = '/api/v1/schools_list.json';
  }

  filter(term: string): Observable<AutocompleteInputItem[]> {
    return this.viewService
      .get(this.endpoint, {
        filters: { title: term },
        pagination: { items_per_page: 50 },
      })
      .pipe(
        map((result) =>
          result.map((school) => ({
            value: school.nid,
            label: school.title,
            picture: school.field_school_logo_multiple_json?.school_square_md,
          }))
        )
      );
  }
}
