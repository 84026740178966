<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex align-items-center">
    <div class="image-preview d-flex align-items-center me-2" *ngIf="showThumbnails">
      <img
        *ngIf="r.picture"
        class="image-preview"
        [src]="r.picture"
        [ngClass]="{ 'rounded-circle': showRoundedThumbnails }"
        [alt]="r.label"
      />
      <ng-container [ngTemplateOutlet]="noThumbnailsTemplate" *ngIf="!r.picture"></ng-container>
    </div>
    <ngb-highlight [result]="formatter(r)" class="text-autocomplete-result" [term]="t"></ngb-highlight>
  </div>
</ng-template>
<div class="input-group position-relative {{ containerClass }}">
  <span class="input-group-text">
    <i class="icon icon-search" *ngIf="!currentItem || !showThumbnails"></i>
    <div *ngIf="showThumbnails && currentItem" class="me-1 d-flex align-items-center">
      <img
        [src]="currentItem?.picture ?? ''"
        [ngClass]="{ 'rounded-circle': showRoundedThumbnails }"
        *ngIf="currentItemModel.value?.picture"
        class="image-preview-small"
        [alt]="currentItem.label"
      />
      <ng-container [ngTemplateOutlet]="noThumbnailsTemplate" *ngIf="!currentItem?.picture"></ng-container>
    </div>
  </span>
  <input
    class="form-control"
    type="text"
    [placeholder]="placeholder"
    [ngClass]="inputClass"
    [disabled]="isDisabled"
    [formControl]="currentItemModel"
    [ngbTypeahead]="search"
    (click)="click$.next($any($event).target.value)"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    #instance="ngbTypeahead"
  />
  <span class="input-group-text suffix-icon pointer">
    <i *ngIf="currentItem && !isLoading" (click)="currentItemModel.reset()" class="icon icon-times"></i>
    <circular-spinner iconClass="icon-16 text-success" *ngIf="isLoading" class="small-circular"></circular-spinner>
  </span>
  <!-- <div ngbDropdown #myDrop="ngbDropdown">
    <div ngbDropdownMenu aria-labelledby="dropdownManual">
      <button type="button" class="d-none" ngbDropdownToggle></button>
      <div class="d-flex w-100 justify-content-center py-3">
        <circular-spinner iconClass="icon-48 success"></circular-spinner>
      </div>
    </div>
  </div> -->
</div>
