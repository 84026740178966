import { Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@app/core/services/currency/currency.service';
import { NavDataService } from '@app/core/services';

export interface MaxPriceConfiguration {
  min: number;
  max: number;
  step: number;
}

@Component({
  selector: 'max-price-selector',
  templateUrl: './max-price-selector.component.html',
  styleUrls: ['./max-price-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaxPriceSelectorComponent),
      multi: true,
    },
  ],
})
export class MaxPriceSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<number>, OnInit
{
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: number;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @Input() extraData?: MaxPriceConfiguration;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;

  sliderConfig: any = {};
  priceControl: FormControl = new FormControl();
  selectedPlaceHolder: string;
  currencySymbol: string;

  private notPropagateValue = false;

  constructor(private currencyService: CurrencyService, private navDataService: NavDataService) {
    this.currencySymbol = this.currencyService.getCurrentCurrencySymbol(
      this.navDataService.currentUserProfile?.field_preferred_currency ?? this.currencyService.getCurrencyFromStorage()
    );
  }

  ngOnInit(): void {
    this.sliderConfig = {
      start: this.extraData.min,
      behaviour: 'snap',
      connect: 'lower',
      range: {
        min: this.extraData.min,
        max: this.extraData.max,
      },
      step: 1,
      tooltips: {
        from: (value) => value,
        to: (value) => Math.round(value) + ' ' + this.currencySymbol,
      },
    };

    this.priceControl.valueChanges.subscribe(() => {
      if (this.notPropagateValue) {
        this.notPropagateValue = false;
        return;
      }
      if (this.isInModal) {
        this.confirm();
      }
    });
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: number | null) => {};

  onTouched = () => {};

  writeValue(val: any): void {
    this.notPropagateValue = true;
    this.priceControl.setValue(val ?? this.extraData?.max);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  decreasePrice(): void {
    if (this.priceControl.value > this.extraData?.min + this.extraData?.step) {
      this.priceControl.setValue(this.priceControl.value - this.extraData.step);
    } else if (this.extraData.min !== this.priceControl.value) {
      this.priceControl.setValue(this.extraData.min);
    }
  }

  increasePrice(): void {
    if (this.priceControl.value < this.extraData?.max - this.extraData?.step) {
      this.priceControl.setValue(this.priceControl.value + this.extraData.step);
    } else if (this.extraData.max !== this.priceControl.value) {
      this.priceControl.setValue(this.extraData.max);
    }
  }

  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    this.priceControl.setValue(this.extraData?.max);
    this.dropDown?.close();
  }

  confirm() {
    this.onChange(this.priceControl.value !== this.extraData?.max ? this.priceControl.value : null);
    this.onTouched();
    this.dropDown?.close();
  }
}
