<div class="p-3 d-flex flex-column text-center">
  <div class="d-flex flex-grow-1 flex-column justify-content-center text-white bg-primary-gradient rounded-3">
    <div class="position-relative py-4">
      <div class="modal-header flex-column row">
        <h1 class="col-12 fw-bold mb-2 ls-2" translate>RENEW YOUR MEMBERSHIP NOW</h1>
        <div class="col-11 col-lg-5 ls-2 fw-semibold" translate>TO CONTINUE WITH YOUR PRO-MEMBER BENEFITS</div>
      </div>
      <div class="modal-body">
        <h5 class="m-0 mb-4 ls-8 fw-semibold" translate>BENEFITS :</h5>
        <div class="row px-3 text-start">
          <div class="col-12 col-md-6">
            <ul>
              <li class="mb-2" translate>Certify Your Students</li>
              <li class="mb-2" translate>Find Jobs Worldwide</li>
              <li class="mb-2" translate>Manage Your Professional Portfolio</li>
            </ul>
          </div>
          <div class="col-12 col-md-6">
            <ul>
              <li class="mb-2" translate>Be IKO Recognized</li>
              <li class="mb-2" translate>Worldwide Insurance Coverage</li>
              <li class="mb-2" translate>Obtain a Teaching License</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-block btn-success py-2 px-4 rounded-1" (click)="openFeesManagement()">
          <span translate>Renew My Membership</span>
        </button>
      </div>

      <div
        class="position-absolute close-modal text-black bg-white rounded-circle pointer d-flex justify-content-center align-items-center"
        (click)="close()"
      >
        <i class="icon icon-times"></i>
      </div>
    </div>
  </div>
</div>
