<!--main body -->
<div
  class="form-control text-light border position-relative border-1 p-2 overflow-hidden"
  [ngStyle]="{ height: !replaceMobileButton ? '53px' : '' }"
  *ngIf="!isInModal; else templateForModal"
>
  <div
    [@backgroundAnimationRecreational]="currentValue"
    class="position-absolute background-recreational left-0 top-0 right-0 bottom-0"
  ></div>
  <div
    [@backgroundAnimationPro]="currentValue"
    class="position-absolute is-modal background-pro left-0 top-0 right-0 bottom-0"
  ></div>
  <ng-container [ngTemplateOutlet]="noMobile" [ngTemplateOutletContext]="{ replaceMobileButton }"> </ng-container>
</div>

<!-- template for modal -->
<ng-template #templateForModal>
  <div class="w-100 is-modal position-relative">
    <div class="label-type fw-bold is-modal" *ngIf="!replaceMobileButton" translate>Type of course</div>
    <ng-container [ngTemplateOutlet]="switcher" [ngTemplateOutletContext]="{ isInModal: true }"></ng-container>
  </div>
  <hr />
</ng-template>

<!-- template for desktop and for replaced button in mobile -->
<ng-template #noMobile let-replaceMobileButton="replaceMobileButton">
  <div
    class="d-flex w-100 h-100 justify-content-between position-relative align-items-center"
    [ngClass]="{ 'px-3': !replaceMobileButton }"
    style="z-index: 4"
  >
    <div class="label-type fw-bold" *ngIf="!replaceMobileButton" translate>Type:</div>
    <ng-container
      [ngTemplateOutlet]="switcher"
      [ngTemplateOutletContext]="{isInModal, replaceMobileButton}"
    ></ng-container>
    <div class="position-absolute right-0">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>

<!-- template of the swither -->
<ng-template #switcher let-isInModal="isInModal" let-replaceMobileButton="replaceMobileButton">
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'flex-fill': replaceMobileButton,
      'justify-content-center': replaceMobileButton || isInModal,
      'w-100': isInModal
    }"
  >
    <div
      translate
      class="label-type cursor-pointer recreational"
      [ngClass]="{
        'type-course-selected': !currentBooleanValue,
        'is-modal': isInModal,
        unselected: currentBooleanValue
      }"
      (click)="value = courseTypes.recreational"
    >
      Recreational
    </div>
    <bSwitch
      class="mx-2 course-type-switcher"
      [ngClass]="{ 'is-modal': isInModal }"
      [switch-on-text]="' '"
      [switch-off-text]="' '"
      [(ngModel)]="currentBooleanValue"
      (changeState)="switchType($event)"
      [switch-size]="replaceMobileButton ? 'min' : 'normal'"
      [switch-readonly]="disabled"
    ></bSwitch>
    <div
      translate
      (click)="value = courseTypes.pro"
      class="label-type cursor-pointer pro"
      [ngClass]="{
        'type-course-selected': currentBooleanValue,
        'is-modal': isInModal,
        unselected: !currentBooleanValue
      }"
    >
      Pro
    </div>
  </div>
</ng-template>
