<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="formatter(r)" [term]="t"></ngb-highlight>
</ng-template>

<input
  type="text"
  class="form-control"
  [ngClass]="inputClass"
  [formControl]="currentCoachModel"
  [ngbTypeahead]="search"
  [resultTemplate]="rt"
  [inputFormatter]="formatter"
  [disabled]="isDisabled"
  placeholder="{{ 'Select a trainer' | translate }}"
/>
