<ng-container *ngIf="!isInModal; else templateForModal">
  <ng-container [ngTemplateOutlet]="control"></ng-container>
</ng-container>

<ng-template #templateForModal>
  <div class="{{ formControl.value ? 'text-success' : 'text-dark' }} font-size- fw-bold ">
    {{ placeHolder | translate }}
  </div>
  <div class="mb-4">
    <ng-container [ngTemplateOutlet]="control"></ng-container>
  </div>
  <hr />
</ng-template>

<ng-template #control>
  <autocomplete-for-filters
    [formControl]="formControl"
    [searchValues]="search"
    [showImageItem]="true"
    [isInModal]="isInModal"
    placeHolder="Search for a center..."
  ></autocomplete-for-filters>
</ng-template>
