import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

const textLengthBreakPointModal = 150;

@Component({
  selector: 'info-point',
  templateUrl: './info-point.component.html',
  styleUrls: ['./info-point.component.scss'],
})
export class InfoPointComponent {
  @ViewChild('modalInfo', { static: false }) modalRef: TemplateRef<ElementRef>;
  @ViewChild('infopoint', { static: false, read: NgbTooltip }) tooltipTrigger: NgbTooltip;
  @Input() textInfo: string;
  @Input() template: TemplateRef<any>;

  @ViewChild('container', { static: false }) container: ElementRef<any>;

  private currentModal: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  toggleInfo($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.shouldOPenModal()) {
      this.currentModal = this.modalService.open(this.modalRef, {
        centered: true,
        scrollable: true,
      });
      return;
    }
    this.tooltipTrigger.toggle();
  }

  dismissModal() {
    this.currentModal.dismiss();
    this.currentModal = null;
  }

  private shouldOPenModal(): boolean {
    return this.container.nativeElement.outerText.length > textLengthBreakPointModal;
  }
}
