import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Country } from '@app/core/models';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { UserService, ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from '../../../core/services/currency/currency.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
})
export class CurrencySelectComponent implements OnInit {
  @Input() btnClass = 'bg-color-light-blue';
  @Output() update: EventEmitter<void> = new EventEmitter<void>();
  selectedCurrency = this.translate.instant('Select currency');
  currencies: Country[] = [];
  saving = false;
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public navDataService: NavDataService,
    public deviceDetectService: DeviceDetectService,
    public route: ActivatedRoute,
    private userService: UserService,
    private viewService: ViewService,
    private translate: TranslateService,
    private currencyService: CurrencyService
  ) {}

  ngOnInit() {
    this.getCurrencies();
    this.setCurrency(
      this.navDataService.currentUserProfile?.field_preferred_currency ?? this.currencyService.getCurrencyFromStorage(),
      false
    );
    if (this.navDataService.currentPage.indexOf('gutenberg') === 1) {
      this.btnClass = 'bg-info';
    }
  }

  getCurrencies() {
    this.viewService.get('/api/currencies').subscribe((data) => {
      this.currencies = (data as any).list;
    });
  }

  /**
   * set currency
   *
   * @param currency currency key to set
   */
  setCurrency(currency: string, update = true) {
    if (!currency) {
      this.selectedCurrency = 'USD';
      return;
    }
    if (!update) {
      this.selectedCurrency = currency;
      return true;
    }
    this.saving = true;
    this.currencyService.setCurrency(currency).subscribe({
      next: () => {
        this.selectedCurrency = currency;
        this.saving = false;
        // this.currencyService.setCurrencyInStorage(currency);
        this.update.emit();
      },
      //TODO: saving in localStorage for anonymous User
      error: () => {
        this.saving = false;
        this.notificationsService.notifyError(this.translate.instant('An error occured switching the currency'));
      },
    });
  }
}
