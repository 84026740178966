<div class="{{ value ? 'text-success' : 'text-dark' }} font-size- fw-bold " *ngIf="isInModal">
  {{ placeHolder | translate }}
</div>
<div ngbDropdown class="py-2 dropdown multi-location dropdown-icon-right" #dropdown [autoClose]="true">
  <div
    class="input-group mb-0 w-100 d-flex flex-nowrap border-1 form-control border-0 p-0 m-0
    {{ value ? '  border-success text-success fw-bold' : '' }}
    "
  >
    <ng-container *ngIf="!value; else valueTemplate">
      <div class="input-group-prepend input-group-text border-0">
        <i
          *ngIf="iconPrepend && !value"
          class="me-2 icon icon-16 {{ iconPrepend }} {{ value ? 'text-success' : 'text-info' }}"
        ></i>
      </div>
      <input
        [formControl]="formControl"
        (click)="handleFocus()"
        (keydown.enter)="handleEnter($event)"
        class="flex-fill form-control border-0 bg-white m-0 p-0"
        *ngIf="!value"
        [placeholder]="!isInModal ? (placeHolder | translate) : ''"
      />
    </ng-container>
    <div class="input-group-append input-group-text border-0 ps-0 pe-3 d-flex align-items-center">
      <i class="icon icon-times" *ngIf="value" (click)="clear($event)"></i>
      <i class="icon icon-search" *ngIf="!value && !searching && !hasError"></i>
      <circular-spinner *ngIf="searching" class="small-circular"></circular-spinner>
      <i
        *ngIf="!searching && hasError"
        class="icon text-danger icon-exclamation-triangle"
        [ngbTooltip]="'Error. Please try again later.' | translate"
      ></i>
    </div>
  </div>
  <div ngbDropdownMenu class="rounded-2 d-flex flex-column w-100 mt-2 shadow p-4 limit-height-dropdown">
    <ng-container [ngSwitch]="true">
      <ng-container
        [ngTemplateOutlet]="mainDropdownView"
        *ngSwitchCase="true"
        [ngTemplateOutletContext]="{ $implicit: { results: apiResults$ | async, catchWord: formControl.value } }"
      ></ng-container>
    </ng-container>
  </div>
</div>
<hr *ngIf="isInModal" />
<ng-template #mainDropdownView let-values>
  <ng-container [ngTemplateOutlet]="bestResult"></ng-container>
  <ng-container
    *ngIf="myPosition"
    [ngTemplateOutlet]="itemList"
    [ngTemplateOutletContext]="{
      $implicit: generateModelValue({ type: types.myPosition, value: null })
    }"
  ></ng-container>
  <ng-container
    *ngIf="!extraData?.hideMap"
    [ngTemplateOutlet]="itemList"
    [ngTemplateOutletContext]="{
      $implicit: generateModelValue({ type: types.location, value: null })
    }"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="destinationsList"
    [ngTemplateOutletContext]="{
      $implicit: values.results?.destinations ?? []
    }"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="spostsList"
    [ngTemplateOutletContext]="{
      $implicit: values.results?.spots ?? []
    }"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="centerList"
    [ngTemplateOutletContext]="{
      $implicit: values.results?.centers ?? []
    }"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="placesList"
    [ngTemplateOutletContext]="{
      $implicit: values.results?.googlePlaces ?? []
    }"
  ></ng-container>

  <!-- part for multilevel results -->
  <!-- <ng-container
    *ngIf="values.results?.destinations?.length"
    [ngTemplateOutlet]="itemList"
    [ngTemplateOutletContext]="{
      $implicit: {
        icon: 'icon-world_map',
        primaryText: values.catchWord,
        secondaryText: 'in destinations',
        showArrowNext: true,
        subhead: 'found out ' + values.results.destinations.length + ' results'
      }
    }"
  ></ng-container>
  <ng-container
    *ngIf="values.results?.spots?.length"
    [ngTemplateOutlet]="itemList"
    [ngTemplateOutletContext]="{
      $implicit: {
        icon: 'icon-wind',
        primaryText: values.catchWord,
        secondaryText: 'in kite spots',
        showArrowNext: true,
        subhead: 'found out ' + values.results.spots.length + ' results'
      }
    }"
  ></ng-container> 
  <ng-container
    *ngIf="values.results?.centers?.length"
    [ngTemplateOutlet]="itemList"
    [ngTemplateOutletContext]="{
      $implicit: {
        icon: 'icon-home',
        primaryText: values.catchWord,
        secondaryText: 'in centers',
        showArrowNext: true,
        subhead: 'found out ' + values.results.centers.length + ' results'
      }
    }"
  ></ng-container>

-->
</ng-template>

<ng-template #itemList let-item>
  <div class="item" (click)="item.click?.()">
    <div class="d-flex align-items-center cursor-pointer w-100">
      <ng-container [ngTemplateOutlet]="leftSideRow" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <div class="d-flex flex-column justify-content-center flex-fill">
        <div>
          <b class="me-1" *ngIf="item.primaryText">{{ item.primaryText }}</b>
          <span> {{ item.secondaryText | translate }}</span>
        </div>
        <div class="small" *ngIf="item.subhead">{{ item.subhead }}</div>
      </div>
      <i class="icon icon-angle-right icon-24 ms-2" *ngIf="item.showArrowNext"></i>
    </div>
  </div>
</ng-template>

<ng-template #centerList let-centers>
  <ng-container *ngFor="let center of centers">
    <ng-container
      [ngTemplateOutlet]="itemList"
      [ngTemplateOutletContext]="{
        $implicit: generateModelValue({ type: types.center, value: center })
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #destinationsList let-destinations>
  <ng-container *ngFor="let destination of destinations">
    <ng-container
      ,
      [ngTemplateOutlet]="itemList"
      [ngTemplateOutletContext]="{
        $implicit: generateModelValue({ type: types.destination, value: destination })
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #spostsList let-spots>
  <ng-container *ngFor="let spot of spots">
    <ng-container
      ,
      [ngTemplateOutlet]="itemList"
      [ngTemplateOutletContext]="{
        $implicit: generateModelValue({ type: types.spot, value: spot })
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #placesList let-places>
  <ng-container *ngFor="let place of places">
    <ng-container
      ,
      [ngTemplateOutlet]="itemList"
      [ngTemplateOutletContext]="{
        $implicit: generateModelValue({ type: types.googlePlace, value: place })
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #valueTemplate>
  <ng-container [ngTemplateOutlet]="modelValue" [ngTemplateOutletContext]="{ $implicit: generateModelValue(value) }">
  </ng-container>
</ng-template>

<ng-template #modelValue let-value>
  <div class="border-0 input-group-prepend pe-1 value-box">
    <ng-container [ngTemplateOutlet]="leftSideRow" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>
  </div>
  <div
    class="flex-fill bg-white align-self-stretch d-flex align-items-center border-0 m-0 p-0 max-text-width text-truncated"
    [innerHTML]="value.primaryText"
  ></div>
</ng-template>

<ng-template #leftSideRow let-item>
  <i class="icon me-2" [ngClass]="item.icon" *ngIf="item.icon"></i>
  <div
    class="me-2 logo-school"
    *ngIf="item.logoSchool"
    [ngStyle]="{ 'background-image': 'url(' + item.logoSchool + ')' }"
    [title]="item.primaryText"
  ></div>
  <div class="flag-container d-flex justify-content-center align-items-center me-2" *ngIf="item.countryFlag">
    <i class="iti-flag" [ngClass]="item.countryFlag"></i>
  </div>
</ng-template>

<ng-template #bestResult>
  <ng-container *appNgVar="currentBest.currentBest$ | async as bestResultValue">
    <ng-container *ngIf="bestResultValue">
      <div class="fw-bold text-muted" translate>Best match</div>
      <ng-container
        [ngTemplateOutlet]="itemList"
        [ngTemplateOutletContext]="{
          $implicit: generateModelValue(bestResultValue)
        }"
      ></ng-container>
      <div class="fw-bold mt-2 text-muted" translate>Other results</div>
    </ng-container>
  </ng-container>
</ng-template>
