import { Injectable } from '@angular/core';
import { MembershipProduct } from '@app/core/models';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of } from 'rxjs';
import { NavDataService } from '../nav-data/nav-data.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlansService {
  protected membershipPlans: MembershipProduct[];

  constructor(
    private viewService: ViewService,
    private translate: TranslateService,
    private navDataService: NavDataService
  ) {
    this.translate.onLangChange.subscribe(() => {
      if (this.navDataService.isLogged) {
        this.delete();
        this.get().subscribe();
      }
    });
  }

  /**
   * Get membership plans.
   *
   * @returns
   *  Obsavable of membership plans.
   */
  get(): Observable<MembershipProduct[]> {
    if (this.membershipPlans) {
      return of(this.membershipPlans);
    }
    const path = `/${this.translate.currentLang.replace('en', '')}/api/v1/membership_products.json`.replace('//', '/');
    return this.viewService.get(path).pipe(
      map((data: MembershipProduct[]) => {
        // data = data.splice(3,3);
        data.forEach((p) => (p.field_features_export = p.field_features_export.filter((f) => f.details)));
        // if feature details is not equal for all plans - not the title
        data = data.map((plan) => {
          plan.field_features_export = plan.field_features_export.map((feature, i) => {
            feature.title = feature.details;
            const remProds = data.filter((p) => p.variation_id !== plan.variation_id);
            if (
              feature.details &&
              remProds
                .map(
                  (p) =>
                    p.field_features_export[i] &&
                    p.field_features_export[i].details?.split(/\r\n</)[1] !== feature.details.split(/\r\n</)[1]
                )
                .every(Boolean)
            ) {
              feature.description = '<' + feature.details.split(/\r\n</)[1];
            }
            if (feature.title) {
              if (feature.title.indexOf('Credits</h3>') !== -1) {
                feature.title = feature.title.split(/\r\n/)[0];
              }
              if (feature.title.indexOf('Free Certification Credits</strong></p>') !== -1) {
                const titleFrags = feature.title.split(/\r\n/);
                feature.title = `${titleFrags[0]} \r\n ${titleFrags[4]}`;
              }
            }
            return feature;
          });
          return plan;
        });
        this.membershipPlans = data;
        return this.membershipPlans;
      })
    );
  }

  /**
   * Delete the cached membership plans.
   */
  delete() {
    delete this.membershipPlans;
  }
}
