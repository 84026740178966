import { HttpClientJsonpModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, BrowserAnimationsModuleConfig } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { NgbDateIKOParserFormatter, SharedModule } from '@app/shared';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { environment } from '@env/environment';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { SpeedTestModule } from 'ng-speed-test';
import { I18nService } from './core/services';
import { NgbDatepickerI18nIko } from './shared/forms/ngb-datepicker-i18n-iko';
import { ShellModule } from './shell/shell.module';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './core/cookie-config';
import { NgxStripeModule } from 'ngx-stripe';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { GlobalConst } from './shared/constants/global.const';
import { NgOptimizedImage } from '@angular/common';
// import { Loader } from '@googlemaps/js-api-loader';
// import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

const dbConfig: DBConfig = {
  name: GlobalConst.indexDb.name,
  version: GlobalConst.indexDb.version,
  objectStoresMeta: [
    {
      store: GlobalConst.indexDb.videos,
      storeConfig: { keyPath: 'nid', autoIncrement: false },
      storeSchema: [
        { name: 'blob', keypath: 'blob', options: { unique: false } },
        { name: 'videoId', keypath: 'videoID', options: { unique: false } },
      ],
    },
    {
      store: GlobalConst.indexDb.ebooks,
      storeConfig: { keyPath: 'nid', autoIncrement: false },
      storeSchema: [{ name: 'blob', keypath: 'blob', options: { unique: false } }],
    },
  ],
};

export const browserAnimationsModuleConfig: BrowserAnimationsModuleConfig = {};
@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    HttpClientJsonpModule,
    ShellModule,
    SpeedTestModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxIndexedDBModule.forRoot(dbConfig),
    BrowserAnimationsModule.withConfig(browserAnimationsModuleConfig),
    // NgxGpAutocompleteModule,
    NgOptimizedImage,
  ],
  declarations: [AppComponent],
  providers: [
    Keyboard,
    StatusBar,
    SplashScreen,
    Facebook,
    FirebaseX,
    Device,
    InAppBrowser,
    File,
    Toast,
    SocialSharing,
    FileTransfer,
    Camera,
    Dialogs,
    Network,
    MobileAccessibility,
    ScreenOrientation,
    AppVersion,
    SpinnerDialog,
    AppRate,
    Diagnostic,
    CallNumber,
    EmailComposer,
    LaunchNavigator,
    CameraPreview,
    Geolocation,
    IOSFilePicker,
    FileChooser,
    FilePath,
    SignInWithApple,
    CodePush,
    InAppPurchase2,
    LocalNotifications,
    Insomnia,
    {
      provide: LOCALE_ID,
      useFactory: (i18n: I18nService) => i18n.language,
      deps: [I18nService],
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateIKOParserFormatter, deps: [I18nService] },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nIko, deps: [I18nService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
