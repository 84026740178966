import { inject, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ButtonWithAction, Center, Download, HttpRequestDetails, UserProfile } from '@app/core/models';
import { environment } from '@env/environment';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { DrupalConstants, UserService, ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { I18nService } from '../i18n/i18n.service';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { StorageDocumentsService } from '../storage-documents/storage-documents.service';
declare const navigator: any;

const NOT_PUBLIC_START_URLS = [
  '/students',
  '/messages',
  // '/quality-check',
  '/bookings',
  '/account',
  '/profile',
  '/sanction',
  '/quality',
];

@Injectable({ providedIn: 'root' })
export class NavDataService {
  displayHeader = true;
  // current page
  currentPage = '/';
  // current opened browser page from cordova plugin iab
  currentBrowser: InAppBrowserObject;
  // application ready to render
  appReady = true;
  // side menu open close variable
  sideMenuOpen = false;
  // navigation router history
  navigationHistory = ['/account'];
  // current page title
  currentPageTitle = '';
  // store the data here
  storage: any;
  // vcard shared component variable, closed by default
  vCardOpen = false;
  // go premium component variable, closed by default
  goPremiumOpen = false;
  // user profile info

  // http requests Details
  httpRequestDetails: HttpRequestDetails = {
    number: 0,
  };
  cartCount: any;
  displayCurrency = false;

  displayLanguage = true;
  showFooter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // notification message data
  // observable of current User profile
  currentUserProfile$: Observable<UserProfile>;
  localProfilePic: string;
  currentSchool: Center;
  ikoLogoCenterMobileVar = false;

  // display iko logo
  private ikoLogoVar = false;

  private ikoLogoCenterVar = false;
  // header left button
  private leftButtonVar: ButtonWithAction;
  // header right button
  private rightHeaderActionsVar: ButtonWithAction[];
  private pCurrentUserProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  private pCurrentUserProfile: UserProfile;
  private userService: UserService = inject(UserService);

  constructor(
    public router: Router,
    private deviceDetectService: DeviceDetectService,
    private inAppBrowser: InAppBrowser,
    private spinnerDialog: SpinnerDialog,
    private viewService: ViewService,
    private i18nService: I18nService,
    @Inject(PLATFORM_ID) private platform: string,
    private keyboard: Keyboard,
    private localize: LocalizeRouterService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private injector: Injector
  ) {
    this.currentUserProfile$ = this.pCurrentUserProfile$.asObservable();
    this.resetHeaderRightButton();
    this.resetHeaderLeftButton();
    this.checkShowFooter();
  }

  get isLogged(): boolean {
    return DrupalConstants.Connection && DrupalConstants.Connection.current_user.uid !== 0;
  }

  get rightHeaderActions(): ButtonWithAction[] {
    return this.rightHeaderActionsVar;
  }

  set rightHeaderActions(actions: ButtonWithAction[]) {
    setTimeout(() => {
      this.rightHeaderActionsVar = actions;
    });
  }

  // temp fix for conflict with order-memering caused by get > set and all get should be before set

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get ikoLogo(): boolean {
    return this.ikoLogoVar;
  }

  set ikoLogo(display: boolean) {
    setTimeout(() => {
      this.ikoLogoVar = display;
      if (this.deviceDetectService.isDesktop && display) {
        this.leftButton = null;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get ikoLogoCenter(): boolean {
    return this.ikoLogoCenterVar;
  }
  set ikoLogoCenter(display: boolean) {
    setTimeout(() => {
      this.ikoLogoCenterVar = display;
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get leftButton(): ButtonWithAction {
    return this.leftButtonVar;
  }

  set leftButton(action: ButtonWithAction) {
    setTimeout(() => {
      this.leftButtonVar = action;
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get currentUserProfile(): UserProfile {
    return this.pCurrentUserProfile;
  }

  set currentUserProfile(userProfile: UserProfile) {
    if (userProfile?.field_gps_position_export && typeof userProfile?.field_gps_position_export === 'string') {
      try {
        const jsonLatLon = JSON.parse(userProfile.field_gps_position_export.replace(/&quot;/g, '"'));
        if (jsonLatLon.coordinates) {
          userProfile.field_gps_position = { lat: jsonLatLon.coordinates[1], lon: jsonLatLon.coordinates[0] };
        } else {
          userProfile.field_gps_position = null;
        }
      } catch (err) {
        console.log('Error parsing user coordinaates', err);
        userProfile.field_gps_position = null;
      }
    }
    this.pCurrentUserProfile = userProfile;

    this.notifyUserProfileChanged();
  }

  /**
   * Set the centering of the iko logo on the mobile header.
   *
   * @param value
   *  true to center the logo, false to align it to the left.
   */
  ikoLogoCenterMobile(value: boolean): void {
    this.ikoLogoCenterMobileVar = value;
  }

  logoutUser(): void {
    this.pCurrentUserProfile$.next(null);
    this.currentUserProfile = undefined;
    this.currentSchool = undefined;
  }

  patchUserProfileValue(field: string, value: any) {
    this.pCurrentUserProfile[field] = value;
    this.notifyUserProfileChanged();
  }

  /**
   * Set page title after translating it to current language
   *
   * @param key The string key in English
   */
  setPageTitle(key?: string) {
    setTimeout(() => {
      // if no key sent then remove current title
      if (!key) {
        this.currentPageTitle = '';
        return;
      }
      // translate the key and set the title
      this.currentPageTitle = this.i18nService.translateService.instant(key);
    });
  }

  resetHeaderLeftButton(headerLogo = false) {
    setTimeout(() => {
      if (this.ikoLogo || headerLogo) {
        this.ikoLogo = headerLogo;
        this.leftButton = null;
        return;
      }
      this.leftButton = {
        action: () => {
          this.navigateBack();
        },
        iconClass: 'icon-angle-left',
        type: 'button',
        display: true,
      };
    });
  }

  closeKeyboard() {
    if (this.deviceDetectService.isCordova) {
      this.keyboard.hide();
    }
  }

  resetHeaderRightButton() {
    // setTimeout(() => {
    this.rightHeaderActions = [
      {
        action: () => {
          this.toggleSideMenu();
        },
        iconClass: 'icon-bars',
        btnClass: 'd-block d-lg-none',
        type: 'button',
        display: true,
        // this.deviceDetectService.isMobile ||
        // (this.deviceDetectService.isTablet && !this.deviceDetectService.isTabletOrientationLandscape),
      },
    ];
    // });
  }

  /**
   * Toggle the side menu
   */
  toggleSideMenu() {
    if (document.body.clientWidth < 1024) {
      this.sideMenuOpen = !this.sideMenuOpen;
    }
  }

  /**
   * Toggle the vCard
   */
  toggleVCard() {
    this.vCardOpen = !this.vCardOpen;
    // force close the side menu
    this.sideMenuOpen = false;
  }

  reloadProfile(): void {
    this.initProfile().subscribe();
  }

  initProfile(notSetLanguage?: boolean): Observable<UserProfile> {
    return this.viewService.get('/system/connect').pipe(
      mergeMap((connection: any) => {
        if (!connection || connection.current_user.uid === 0) {
          // empty current session
          return this.userService.attemptToLoginWithSavedCredentials().pipe(
            mergeMap(() => this.initProfile(notSetLanguage)),
            catchError(() => this.deleteCurrentSession())
          );
        }
        // let redirect = false;
        // if (!DrupalConstants.Connection) {
        //   redirect = true;
        // }
        // set the current session
        DrupalConstants.Connection = connection;
        DrupalConstants.Token = connection.csrf_token;
        // save the connection in localstorage
        this.localStorageService.setItem('connection', JSON.stringify(connection));
        // get current time in ms
        const now = new Date().getTime();
        // get the future expiration time in ms
        const expiration = now + DrupalConstants.Settings.cookieLifetime * 1000;
        // set the expiration time
        this.localStorageService.setItem('expiration', expiration.toString());
        this.localStorageService.setItem('token', connection.csrf_token);
        return this.viewService.get('/api/v1/user_profile.json').pipe(
          mergeMap((profileData: UserProfile[]) => {
            if (!profileData[0]) {
              // this.navigate('/');
              return;
            }
            if (!profileData[0].user_picture_multiple_json) {
              profileData[0].user_picture_multiple_json = '/assets/images/blank-profile.png' as any;
            }
            const currentUserProfile = profileData[0];
            if (currentUserProfile.field_phone && currentUserProfile.field_phone.indexOf('+') === -1) {
              currentUserProfile.field_phone = `+${currentUserProfile.field_phone}`;
            }
            currentUserProfile.top_qualification = currentUserProfile.field_top_qualification;
            if (
              currentUserProfile.field_training_level_skills &&
              currentUserProfile.field_training_level_skills.length > 0
            ) {
              const qualification = currentUserProfile.field_training_level_skills.pop();
              const qualificationLabel = qualification.label;
              if (!currentUserProfile.top_qualification) {
                currentUserProfile.top_qualification = qualificationLabel;
              }
              currentUserProfile.field_training_level_skills.push(qualification);
            }
            if (profileData[0].preferred_langcode && !notSetLanguage) {
              setTimeout(() => {
                const language = profileData[0].preferred_langcode.code.toLowerCase();
                if (this.i18nService.language !== language) {
                  this.i18nService.language = language;
                }
              }, 3000);
            }
            if (currentUserProfile.school) {
              const options: ViewOptions = {
                args: [+currentUserProfile.school.id],
              };
              return this.viewService.get('/api/v1/school_details.json', options).pipe(
                map((data: any) => {
                  if (data.length > 0) {
                    this.currentSchool = data[0];
                    currentUserProfile.user_picture_multiple_json = data[0].field_school_logo;
                    currentUserProfile.schoolName = data[0].title;
                  }
                  this.currentUserProfile = currentUserProfile;
                  return currentUserProfile;
                })
              );
            }
            this.currentUserProfile = currentUserProfile;
            // cause throwing circular dependencies error
            const storageDocumentsServiceIns = this.injector.get(StorageDocumentsService);
            // converting imgUrl to base64 and store in service var so user loose the connection
            // after upload pic but he/she download vCard with image
            if (
              this.currentUserProfile.user_picture_multiple_json &&
              this.currentUserProfile.user_picture_multiple_json.square_md
            ) {
              const userPictureMultipleJson = this.currentUserProfile.user_picture_multiple_json.square_md;
              storageDocumentsServiceIns.getLocalUrl(userPictureMultipleJson).subscribe((data) => {
                this.localProfilePic = data;
              });
            } else {
              this.localProfilePic = null;
            }
            return of(profileData[0]);
          })
        );
      }),
      // prevent to give error and block the boostrap of application
      // so initcordova can be executed and hide the splashscreen
      catchError((err) => {
        console.log('Error init profile', err);
        return of(null);
      })
    );
  }

  /**
   * navigate back when user click back button
   */
  navigateBack() {
    // remove current page from navigation history
    this.navigationHistory.pop();
    // get previous page or home page if not
    const previousPage = this.navigationHistory.pop() || '/';
    // navigate
    this.router.navigate([previousPage]);
  }

  navigate(path: string, iab = false, navExtras?: NavigationExtras) {
    if (!iab) {
      if (!path.startsWith('/user/')) {
        path = this.localize.translateRoute(path).toString();
      }
      this.router.navigate([path], navExtras);
      this.sideMenuOpen = false;
      return;
    }
    let url: string;
    if (path.indexOf('http') === -1 && path.indexOf('mailto:') === -1 && path.indexOf('tel') === -1) {
      if (path[0] !== '/') {
        path = '/' + path;
      }
      url = DrupalConstants.backEndUrl + path;
    } else {
      url = path;
    }
    const options: InAppBrowserOptions = {
      location: this.deviceDetectService.isIOS ? 'no' : 'yes',
      hardwareback: 'yes',
      hideurlbar: 'yes',
      zoom: 'no',
      closebuttoncaption: this.translateService.instant('Close'),
      closebuttoncolor: '#ff6600',
      toolbarcolor: '#005eb8',
      toolbarposition: 'top',
      toolbartranslucent: 'no',
    };
    this.openExternal(url, options);
  }

  notifyUserProfileChanged(): void {
    this.pCurrentUserProfile$.next(this.pCurrentUserProfile);
    this.checkShowFooter();
  }

  navigateToCourse(course: any, schoolId: number | string, force = false) {
    /* eslint-disable @typescript-eslint/dot-notation */
    if (!this.deviceDetectService.isCordova && !force) {
      return;
    }
    // only redirect pro // allow rec: WEB-3671
    if (environment.inAppBrowsing && environment.inAppBrowsing.course && course.type === 'pro_course') {
      this.navigate(`https://www.ikointl.com/iko-kite-School-detail?nid=${schoolId}&trainingid=${course.nid}`, true);
      return;
    }
    // const data = { activity_type: 'view', entity_type: 'node', id: course.nid };
    // this.customService.activityTracking(data).subscribe();

    this.navigate(`/courses/${course.type?.replace('_course', '')}/${schoolId}/${course.nid}`);
  }

  generateLinkCourse(course: any, schoolId: number | string, courseType?: string) {
    /* eslint-disable @typescript-eslint/dot-notation */
    if (
      environment.inAppBrowsing &&
      environment.inAppBrowsing.course &&
      (!courseType || courseType === 'recreational_course')
    ) {
      return `https://www.ikointl.com/iko-kite-School-detail?nid=${schoolId}&trainingid=${course.nid}`;
    }
    // const data = { activity_type: 'view', entity_type: 'node', id: course.nid };
    // this.customService.activityTracking(data).subscribe();

    return this.localize
      .translateRoute(`/courses/${course.type?.replace('_course', '')}/${schoolId}/${course.nid}`)
      .toString();
  }

  isPublicUrl(url: string): boolean {
    url = url.toLowerCase();
    const path = url.substring(1).split('/');
    url = '/' + path[0];
    return NOT_PUBLIC_START_URLS.indexOf(url) === -1;
  }

  showModalNotification(title: string, body: string): void {
    if (!navigator.notification) {
      alert(title + '\n' + body);
      return;
    }
    navigator.notification.alert(body, null, title);
  }

  openDownload(documentId: number): Observable<void> {
    if (!documentId) {
      return of(null);
    }
    const options: ViewOptions = {
      filters: {
        lang: this.i18nService.language.toLowerCase(),
        mid: documentId,
      },
    };

    return this.viewService.get('/api/v1.1/downloads.json', options).pipe(
      map((data: Download[]) => {
        if (!data.length) {
          throw new Error(this.translateService.instant('Download not found or temporarily unavailable'));
        }
        this.storage = data[0];
        this.navigate('/documents/document');
        return;
      })
    );
  }

  /**
   * open a url in the external browser.
   *
   * @param link link to open in external
   * @param target target window to open the page
   */
  private openExternal(link: string, options: InAppBrowserOptions) {
    // if cordova available use inappbrowser plugin
    if (!this.deviceDetectService.isCordova) {
      window.open(link, '_blank');
      return;
    }
    // if there is already page
    if (this.currentBrowser) {
      // close it before
      this.currentBrowser.close();
      delete this.currentBrowser;
    }
    options.beforeload = 'get';
    // get the initialized browser.
    this.currentBrowser = this.inAppBrowser.create(link, '_blank', options);
    this.spinnerDialog.show(null, null, true);
    // handle before load event.
    this.currentBrowser.on('beforeload').subscribe({
      next: (eve) => {
        if (!eve.url.toLowerCase().startsWith(environment.currentWebUrl)) {
          return this.currentBrowser._loadAfterBeforeload(eve.url);
        }
        this.navigate(eve.url.replace(environment.currentWebUrl, ''), false);
        this.currentBrowser.close();
        return;
      },
    });
    // handle loading spinner
    this.currentBrowser.on('loadstart').subscribe({
      next: () => {
        this.spinnerDialog.show(null, null, true);
      },
      error: () => {
        this.spinnerDialog.hide();
      },
    });
    this.currentBrowser.on('loadstop').subscribe({
      next: () => {
        this.spinnerDialog.hide();
      },
      error: () => {
        this.spinnerDialog.hide();
      },
    });
    this.currentBrowser.on('loaderror').subscribe({
      next: () => {
        this.spinnerDialog.hide();
      },
      error: () => {
        this.spinnerDialog.hide();
      },
    });
    this.currentBrowser.on('exit').subscribe({
      next: () => {
        this.spinnerDialog.hide();
        delete this.currentBrowser;
      },
      error: () => {
        this.spinnerDialog.hide();
      },
    });
  }

  private checkShowFooter() {
    this.showFooter$.next(!this.pCurrentUserProfile && !this.deviceDetectService.isCordova);
  }

  private deleteCurrentSession(): Observable<any> {
    DrupalConstants.Connection = undefined;
    DrupalConstants.Token = undefined;
    // removed saved data
    document.cookie = 'CHOCOLATECHIPSSL=;path=/;domain=.ikointl.com;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    this.localStorageService.removeItem('connection');
    this.localStorageService.removeItem('expiration');
    this.localStorageService.removeItem('token');
    this.userService.deleteStoredCredentials();
    return of(null);
  }
}
