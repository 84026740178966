<ng-container *ngIf="hasInitialized">
  <form [formGroup]="formToShow" class="align-items-center d-md-flex d-none light-gray-background" *ngIf="formToShow">
    <ng-container *ngFor="let field of fieldsHasToShow; let first = first; let last = last">
      <div
        *appNgVar="
          field.mustShowPercentualWidth ? field.mustShowPercentualWidth : 100 / fieldsHasToShow.length + '%' as width
        "
        class="field-item-desktop px-2 align-items-center {{ field.mustShowOverflowDesktop ? 'overflow-scroll' : '' }}"
        [ngStyle]="{
          'max-width': width,
          'min-width': width,
          'flex-basis': width
        }"
      >
        <ng-container
          [ngTemplateOutlet]="fieldsFactory"
          [ngTemplateOutletContext]="{ field, isInModal: false, formControl: formToShow.get(field.name) }"
        ></ng-container>
      </div>
    </ng-container>

    <div *ngIf="hasToShowMoreFields">
      <ng-container [ngTemplateOutlet]="desktopMoreFilterButton"></ng-container>
    </div>
    <button
      *ngIf="configuration.showClearButton"
      (click)="clearDesktop()"
      class="btn-icon btn btn-link d-flex justify-content-center align-items-center rounded-circle"
    >
      <i class="icon icon-trash-alt icon-16 text-success"></i>
    </button>
    <div class="flex-fill"></div>
  </form>
  <div class="d-flex d-md-none light-gray-background">
    <ng-container *ngIf="replacedMobileButton" [ngTemplateOutlet]="replacedModbileButtonTemplate"></ng-container>
    <ng-container *ngIf="multiRowsMobile" [ngTemplateOutlet]="multiRowsMobileTemplate"></ng-container>
    <ng-container
      *ngIf="!multiRowsMobile && !replacedMobileButton"
      [ngTemplateOutlet]="standardMobileButton"
    ></ng-container>
  </div>
</ng-container>

<ng-template #multiRowsMobileTemplate>
  <form [formGroup]="multiRowsMobileForm" class="d-flex w-100 flex-column" *ngIf="multiRowsMobileForm">
    <div class="d-flex" *ngFor="let field of multiRowsMobileFields; let first = first">
      <div class="{{ first ? 'flex-fill' : 'w-100' }}">
        <ng-container
          [ngTemplateOutlet]="fieldsFactory"
          [ngTemplateOutletContext]="{ field , isInModal: false, formControl: multiRowsMobileForm.get(field.name) }"
        ></ng-container>
      </div>
      <div *ngIf="first && hasToShowMoreFields" class="ms-1">
        <ng-container [ngTemplateOutlet]="desktopMoreFilterButton"></ng-container>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #standardMobileButton>
  <button
    class="border border-3 {{
      mobileFilters > 0 ? ' border-success fw-bold text-success' : 'bg-light border-white text-dark'
    }} flex-fill d-flex align-items-center icon-16 justify-content-center rounded-4 shadow-sm p-1 "
    (click)="openModalMobileFilters()"
  >
    <span translate>Filters</span>
    <span class="ps-1" *ngIf="mobileFilters > 0">· {{ mobileFilters }}</span>
    <i class="icon ms-2 icon-sliders-h"></i>
  </button>
</ng-template>

<ng-template #modalFilterMobile let-modal>
  <div class="d-flex flex-column" style="height: 100vh">
    <div class="py-2 mb-1 d-flex align-items-center justify-content-between">
      <div class="col-4"></div>
      <h3 class="text-center mb-0 col-4" id="modal-basic-title">
        <span class="d-inline-block align-middle" translate>Filters</span>
      </h3>
      <div class="col-4 d-flex align-items-center justify-content-end" (click)="modal.dismiss('cross clik')">
        <i class="icon text-success icon-24 icon-times me-2"></i>
      </div>
    </div>
    <div class="modal-body filters overflow-scroll flex-fill">
      <form [formGroup]="formModal" class="w-100">
        <ng-container
          *ngFor="let field of fieldsForModal; let first = fisrt; let last = last"
          [ngTemplateOutlet]="fieldsFactory"
          [ngTemplateOutletContext]="{ field, isInModal: true, formControl: formModal.get(field.name) }"
        ></ng-container>
      </form>
    </div>
    <div class="modal-footer pt-3 d-flex justify-content-between align-items-center">
      <button class="btn rounded-2 m-0 d-flex justify-content-center align-items-center" (click)="eraseMobileFilters()">
        <i class="icon icon-16 icon-times me-1"></i> <span translate>Clear</span>
      </button>
      <button
        class="btn btn-success rounded-2 m-0 d-flex justify-content-center align-items-center"
        (click)="confirmModal()"
      >
        <i class="icon icon-check icon-16 me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modalFilter let-modal>
  <div class="d-flex flex-column modal-filter-height">
    <div class="py-2 mb-1 d-flex align-items-center justify-content-between">
      <div class="col-4"></div>
      <h3 class="text-center mb-0 col-4">
        <span class="d-inline-block align-middle" translate>More Filters</span>
      </h3>
      <div class="col-4 d-flex align-items-center justify-content-end" (click)="modal.dismiss('')">
        <i class="icon text-success icon-24 icon-times me-2"></i>
      </div>
    </div>
    <div class="modal-body filters overflow-scroll flex-fill">
      <form [formGroup]="formModal" class="w-100">
        <ng-container
          *ngFor="let field of fieldsForModal; let first = fisrt; let last = last"
          [ngTemplateOutlet]="fieldsFactory"
          [ngTemplateOutletContext]="{ field, isInModal: true, formControl: formModal.get(field.name) }"
        ></ng-container>
      </form>
    </div>
    <div class="modal-footer pt-3 d-flex justify-content-between align-items-center">
      <button class="btn rounded-2 m-0 d-flex justify-content-center align-items-center" (click)="eraseMobileFilters()">
        <i class="icon icon-16 icon-times me-1"></i> <span translate>Clear</span>
      </button>
      <button
        class="btn btn-success rounded-2 m-0 d-flex justify-content-center align-items-center"
        (click)="confirmModal()"
      >
        <i class="icon icon-check icon-16 me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #replacedModbileButtonTemplate>
  <ng-container
    [ngTemplateOutlet]="fieldsFactory"
    [ngTemplateOutletContext]="{
      field: replacedMobileButton,
      isInModal: false,
      formControl: replacedMobileButtonControl,
      replacedMobileButton: true
    }"
  ></ng-container>
</ng-template>

<ng-template
  #fieldsFactory
  let-isInModal="isInModal"
  let-field="field"
  let-formControl="formControl"
  let-replaceMobileButton="replaceMobileButton"
>
  <ng-container *ngIf="!field?.disabled">
    <ng-container [ngSwitch]="true">
      <rounded-multiselect-for-filters
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.multiSelect"
        [extraData]="field.extraData"
        [formControl]="formControl"
        [items]="field.itemsForSelect"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
      ></rounded-multiselect-for-filters>
      <responsive-distance-from-map-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.distanceFromMap"
        [formControl]="formControl"
        iconPrepend="icon-world_map"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
      ></responsive-distance-from-map-selector>
      <course-type-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.courseType"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
      >
        <ng-container [ngTemplateOutlet]="miniFilterButton" *ngIf="replacedMobileButton" />
      </course-type-selector>
      <chip-filters
        class="w-100"
        [placeHolder]="field.placeholder | translate"
        [extraData]="field.extraData"
        [items]="field.itemsForSelect"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [replaceMobileButton]="replacedMobileButton"
        *ngSwitchCase="field.type === fieldTypes.chipFilters"
      >
      </chip-filters>
      <course-template-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.courseTemplate"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      >
        <ng-container [ngTemplateOutlet]="miniFilterButton" *ngIf="replacedMobileButton" />
      </course-template-selector>
      <max-price-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.maxPrice"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      />
      <responsive-filter-center-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.centerSelector"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      />
      <responsive-filter-coach-selector
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.coachSelector"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      />
      <!-- iconPrepend="icon-world_map" -->
      <responsive-filter-multilocation
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.multiLocation"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      />
      <responsive-filter-freesearch
        [placeHolder]="field.placeholder | translate"
        class="w-100"
        *ngSwitchCase="field.type === fieldTypes.freeSearch"
        [formControl]="formControl"
        [initialValue]="field.initialValue"
        [isInModal]="isInModal"
        [replaceMobileButton]="replacedMobileButton"
        [extraData]="field.extraData"
      />
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #miniFilterButton>
  <div class="text-end">
    <button
      class="border border-1 bg-white mini-filter-button {{
        mobileFilters > 0 ? ' border-success fw-bold text-success ' : 'border-info text-info'
      }} d-flex align-items-center icon-16 justify-content-center rounded-4 shadow p-1 "
      (click)="openModalMobileFilters()"
    >
      <i class="icon icon-sliders-h"></i>
      <span class="ps-1" style="line-height: 16px" *ngIf="mobileFilters > 0">· {{ mobileFilters }}</span>
    </button>
  </div>
</ng-template>

<ng-template #desktopMoreFilterButton>
  <div class="h-100 d-flex justify-content-center position-relative align-items-center">
    <button
      class="btn-more-filter {{
        mobileFilters > 0 ? 'has-filter' : ''
      }} d-flex align-items-center icon-20 justify-content-center position-relative "
      (click)="openModalFilters()"
    >
      <i class="icon icon-filter-list"></i>
      <div class="badge-filter d-flex align-items-center justify-content-center" *ngIf="mobileFilters">
        <span class="inline-block">{{ mobileFilters }}</span>
      </div>
    </button>
  </div>
</ng-template>
