import { Directive, ElementRef, Host, Input, OnChanges, OnInit } from '@angular/core';
import { ResponsiveImageResource, imageStyleWidths } from '@app/core/models';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';

@Directive({
  selector: '[appResponsiveBgImage]',
})
export class ResponsiveBgImageDirective implements OnInit, OnChanges {
  @Input('appResponsiveBgImage')
  item: ResponsiveImageResource;
  @Input()
  propertyName: string;
  @Input()
  forceOnlyOneSize: string;
  gridItems: number;
  fullPath = false;
  host: ElementRef;

  constructor(@Host() hostElement: ElementRef) {
    hostElement.nativeElement.style.backgroundSize = 'cover';
    hostElement.nativeElement.style.backgroundPosition = 'center center';
    hostElement.nativeElement.style.backgroundRepeat = 'no-repeat';
    this.host = hostElement;
  }

  get imageSetNotSupported(): boolean {
    const isFirefox = navigator.userAgent.indexOf(`Firefox`) !== -1 || navigator.userAgent.indexOf(`Mozilla`) !== -1;
    const isIE = navigator.userAgent.indexOf(`MSIE`) !== -1 || !!(document as any).documentMode;
    const isEdge = !isIE && !!(window as any).StyleMedia;
    return isFirefox || isIE || isEdge;
  }

  ngOnInit() {
    this.initGrid();
    if (!this.item) {
      const tempUrl = '-webkit-image-set(url(/assets/images/default-image.jpeg) 1x)';
      this.setNormalImage(tempUrl);
      return;
    } else if (typeof this.item === 'string') {
      this.setNormalImage(`-webkit-image-set(url(${this.item}) 1x)`);
      return;
    }

    if (!this.propertyName) {
      this.propertyName = Object.keys(this.item)[0].split('_')[0];
    }
    const item = this.forceOnlyOneSize
      ? this.item[`${this.propertyName}_${this.forceOnlyOneSize}`]
      : this.item[`${this.propertyName}_hd`] ?? this.item[`${this.propertyName}_md`];
    this.fullPath = item && item.indexOf('http') !== -1;
    if (this.imageSetNotSupported) {
      if (this.fullPath) {
        this.setNormalImage(`url(${item})`);
      } else {
        this.setNormalImage(`url(${DrupalConstants.backEndUrl}${item})`);
      }
      return;
    }
    this.initSet();
  }

  initGrid() {
    if (this.gridItems) {
      return;
    }
    const width = window.innerWidth;
    let gridItems = Math.round(width / this.host.nativeElement.offsetWidth);
    if (!isFinite(gridItems)) {
      gridItems = 1;
    }
    this.gridItems = gridItems;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  setNormalImage(bgImage: string) {
    this.host.nativeElement.style.backgroundImage = bgImage;
  }

  initSet() {
    let style = `-webkit-image-set(`;
    let counter = 1;
    Object.keys(imageStyleWidths).forEach((styleWidthName) => {
      counter++;
      if (styleWidthName.indexOf(this.propertyName) !== -1) {
        // const styleValue = imageStyleWidths[styleWidthName];
        if (this.item[styleWidthName]) {
          style += 'url(';
          if (!this.fullPath) {
            style += DrupalConstants.backEndUrl;
          }
          const ratioCalc = (counter * this.gridItems) / 1.4;
          style += `${this.item[styleWidthName]}) ${ratioCalc}x,`;
        }
      }
      return;
    });
    style = `${style.slice(0, -1)})`;
    this.setNormalImage(style);
  }
}
