import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ResponsiveImageResource, UserProfile } from '@app/core/models';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { environment } from '@env/environment';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-progress',
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileProgressComponent {
  schoolDetail: any;
  userPicture: ResponsiveImageResource;
  isSchool = false;
  userProfile$: Observable<UserProfile> = this.navDataService.currentUserProfile$.pipe(
    tap((userProfile) => {
      if (!userProfile) {
        this._resetData();
        return;
      }
      this.isSchool = this.rolesService.userHasRole('school', true);
      this.userPicture = userProfile?.user_picture_multiple_json;
    })
  );

  constructor(public navDataService: NavDataService, public rolesService: RolesService) {}

  navigateAndCloseMenu() {
    this.navDataService.toggleSideMenu();
    if (this.navDataService.currentUserProfile.profile_completeness === '100%') {
      if (environment.needD7) {
        this.navDataService.navigate(`/member-public-detail?uid=${this.navDataService.currentUserProfile.uid}`, true);
      } else {
        this.navDataService.navigate('/members/' + DrupalConstants.Connection.current_user.name);
      }
      return;
    }
    // compelete my profile
    let path = '/profile';
    if (this.rolesService.userHasRole('school', true)) {
      path = path + '/school';
    } else {
      path = path + '/user';
    }
    const profile = this.navDataService.currentUserProfile;
    const extras: NavigationExtras = {};
    // WEB-2950 active missing info tab
    if (
      profile.user_picture_multiple_json &&
      profile.field_first_name &&
      profile.field_surname &&
      profile.field_birth_date &&
      profile.field_gender
    ) {
      extras.fragment = 'location';
    }
    this.navDataService.navigate('/');
    setTimeout(() => {
      this.navDataService.navigate(path, false, extras);
    }, 100);
  }

  private _resetData(): void {
    this.isSchool = false;
    this.userPicture = null;
    this.schoolDetail = null;
  }
}
