import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from '@app/core/services/auth-guard/auth-guard.service';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'elearning',
    loadChildren: () => import('./elearning/elearning.module').then((m) => m.ElearningModule),
  },
  {
    path: 'ebooks',
    loadChildren: () => import('./elearning/ebooks/ebooks.module').then((m) => m.EbooksModule),
    canActivate: [AuthGuard],
    data: {
      redirect: true,
    },
  },
  {
    path: 'deals',
    loadChildren: () => import('./deals/deals.module').then((m) => m.DealsModule),
  },
  {
    path: 'blogs',
    loadChildren: () => import('./blogs/blogs.module').then((m) => m.BlogsModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blogs/blogs.module').then((m) => m.BlogsModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
  },
  {
    path: 'ecourses',
    loadChildren: () => import('./ecourses/ecourses.module').then((m) => m.EcoursesModule),
    canActivate: [AuthGuard],
    data: {
      redirect: true,
    },
  },
  {
    path: 'documents',
    loadChildren: () => import('./downloads/downloads.module').then((m) => m.DownloadsModule),
    canActivate: [AuthGuard],
    data: {
      redirect: true,
    },
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'members',
    loadChildren: () => import('./members/members.module').then((m) => m.MembersModule),
  },
  {
    path: 'centers',
    loadChildren: () => import('./centers/centers.module').then((m) => m.CentersModule),
  },
  {
    path: 'school',
    loadChildren: () => import('./centers/centers.module').then((m) => m.CentersModule),
  },
  {
    path: 'students',
    loadChildren: () => import('./students/students.module').then((m) => m.StudentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'destinations',
    loadChildren: () => import('./destinations/destinations.module').then((m) => m.DestinationsModule),
  },
  {
    path: 'credits',
    loadChildren: () => import('./credits/credits.module').then((m) => m.CreditsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/courses.module').then((m) => m.CoursesModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'purchase-certification-credits',
    loadChildren: () => import('./purchase/purchase.module').then((m) => m.PurchaseModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'training-calendar',
    loadChildren: () =>
      import('./bookings/training-calendar/training-calendar.module').then((m) => m.TrainingCalendarModule),
  },
  {
    path: 'referral',
    redirectTo: '/referral/instructor',
    pathMatch: 'full',
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then((m) => m.ReferralModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quality-check',
    loadChildren: () => import('./quality/quality.module').then((m) => m.QualityModule),
  },
  {
    path: 'sanction',
    loadChildren: () => import('./sanction/sanction.module').then((m) => m.SanctionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'certifications',
    loadChildren: () => import('./certifications/certifications.module').then((m) => m.CertificationsModule),
  },
  {
    path: 'request-certification',
    loadChildren: () =>
      import('./request-certification/request-certification.module').then((m) => m.RequestCertificationModule),
  },
  {
    path: 'quality',
    loadChildren: () => import('./webform/webform.module').then((m) => m.WebformModule),
    canActivate: [AuthGuard],
  },
  // redirect homepage
  {
    path: 'gutenberg',
    loadChildren: () => import('./gutenberg/gutenberg.module').then((m) => m.GutenbergModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'kiter-membership',
    loadChildren: () => import('./kiter-membership/kiter-membership.module').then((m) => m.KiterMembershipModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [AuthGuard],
    data: {
      fullPage: true,
      onlyPublic: true,
    },
  },
  {
    path: 'user/login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuard],
    data: {
      fullPage: true,
      onlyPublic: true,
    },
  },
  {
    path: 'user/password',
    loadChildren: () => import('./password-reset/password-reset.module').then((m) => m.ForgetPasswordModule),
    canActivate: [AuthGuard],
    data: {
      fullPage: true,
      onlyPublic: true,
    },
  },
  {
    path: 'user/reset',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    canActivate: [AuthGuard],
    data: {
      fullPage: true,
      onlyPublic: true,
    },
  },
  {
    path: 'user/register',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
    canActivate: [AuthGuard],
    data: {
      fullPage: true,
      onlyPublic: true,
    },
  },
  {
    path: 'profile/billing',
    loadChildren: () => import('./billing-methods/billing-methods.module').then((m) => m.BillingMethodsModule),
  },
  // Fallback when no prior route is matched
  {
    matcher: (url) => {
      if (url.length > 0) {
        return {
          consumed: url,
          posParams: {
            alias: new UrlSegment(url.join('/'), { alias: url.map((segment) => segment.path).join('/') }),
          },
        };
      }

      return null;
    },
    loadChildren: () =>
      import('./dynamic-url-page-content/dynamic-url-page-content.module').then((m) => m.DynamicUrlPageContentModule),
  },
  // route to home
  {
    path: '',
    loadChildren: () => import('./homepage/homepage.module').then((m) => m.HomepageModule),
    canActivate: [AuthGuard],
    data: {
      onlyPublic: true,
    },
  },
];

@NgModule({
  imports: [
    TranslateModule.forRoot(),
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      anchorScrolling: 'enabled',
    }),
    // { initialNavigation: 'enabled', relativeLinkResolution: 'legacy' }),
    LocalizeRouterModule.forRoot(routes, {
      // parser: {
      //     provide: LocalizeParser,
      //     useFactory: ManualLoaderFactory,
      //     deps: [TranslateService, Location, LocalizeRouterSettings]
      // }
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(
            translate,
            location,
            settings,
            ['it', 'de', 'it', 'es', 'fr', 'pt-br'],
            'ROUTES.',
            '!'
          ),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      // parser: {
      //   provide: LocalizeParser,
      //   useFactory: HttpLoaderFactory,
      //   deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      // },
      // cacheMechanism: CacheMechanism.Cookie,
      // cookieFormat: '{{value}};{{expires:20}};path=/',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      defaultLangFunction: (languages: string[], cachedLang?: string, browserLang?: string) => 'en',
      alwaysSetPrefix: false,
      cacheMechanism: CacheMechanism.Cookie,
      cookieFormat: '{{value}};{{expires:20}};path=/',
      initialNavigation: true,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
