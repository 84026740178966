import { Component, Input } from '@angular/core';
import { ProfileTrainingSkill } from '@app/core/models';

@Component({
  selector: 'badges-skill-list',
  templateUrl: './badges-skill-list.component.html',
  styleUrls: ['./badges-skill-list.component.scss'],
})
export class BadgesSkillListComponent {
  @Input() badgeSize = 48;
  @Input() extraClasses = '';
  @Input() instructorOfTheWeek = false;
  badges: { svg: string; label: string }[] = [];
  constructor() {}
  @Input() set skills(skills: ProfileTrainingSkill[]) {
    const groups: { [key: string]: ProfileTrainingSkill[] } = {};
    const indexes: number[] = [];
    skills.forEach((skill) => {
      if (!groups[skill.category_id]) {
        groups[skill.category_id] = [];
        indexes.push(skill.category_id);
      }
      groups[skill.category_id].push(skill);
    });
    for (const index of indexes) {
      groups[index].sort((a, b) => b.weight - a.weight);
      this.badges.push({
        svg: groups[index][0].svg,
        label: groups[index][0].label,
      });
    }
    this.badges = this.badges.filter((badge) => badge.svg !== '');
  }
}
