<div class="w-100 d-flex flex-row align-items-center {{ extraClasses }} text-primary">
  <div *ngIf="instructorOfTheWeek">
    <img
      src="/assets/images/badge-instructor-of-the-week.svg"
      class="badge-skill"
      placement="bottom"
      [ngbTooltip]="'Instructor of the week' | translate"
      width="48"
      alt="badge-instructor-of-the-week"
    />
  </div>
  <div *ngFor="let badge of badges">
    <img
      [src]="badge.svg"
      class="badge-skill hiding-stage"
      placement="bottom"
      ngbTooltip="{{ badge.label }}"
      [style.width]="badgeSize + 'px'"
      [alt]="'badge-icon-' + badge.svg"
    />
  </div>
</div>
