<div ngbDropdown class="dropdown dropdown-icon-right" #dropdown *ngIf="!isInModal; else templateModal">
  <button
    class="dropdown-toggle form-control d-flex text-start bg-white p-3 {{
      itemsSelected.length > 0 ? 'border border-1 border-success text-success fw-bold' : ''
    }}"
    ngbDropdownToggle
    style
  >
    <div class="w-100 flex-fill d-flex align-items-center">
      <!-- <span class="me-2" *ngIf="!selectedPlaceHolder && iconPrepend"
          ><i class="text-info icon icon-16 {{ iconPrepend }}"></i
        ></span> -->
      <span class="text-truncate d-inline-block flex-fill">
        {{ selectedPlaceHolder ?? placeHolder }}
      </span>
      <i class="icon icon-times icon-20 me-2" *ngIf="selectedPlaceHolder" (click)="clear($event)"></i>
    </div>
  </button>
  <div ngbDropdownMenu class="rounded-2 mt-2 shadow" style="min-width: 250px">
    <div class="p-3 items-container">
      <div *ngFor="let item of internalItems">
        <ng-container
          [ngTemplateOutlet]="extraData?.useChips ? chipItems : checkboxItems"
          [ngTemplateOutletContext]="{item}"
        ></ng-container>
      </div>
    </div>
    <hr class="m-0" />
    <div class="p-2 pb-0 d-flex justify-content-between">
      <button class="btn rounded-2 m-0" (click)="clear($event)">
        <i class="icon icon-times me-1"></i> <span tanslate>Clear</span>
      </button>
      <button class="btn btn-success rounded-2 m-0" (click)="dropDown.close()">
        <i class="icon icon-check me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</div>
<ng-template #templateModal>
  <div class="{{ value ? 'text-success' : 'text-dark' }} font-size- fw-bold ">
    {{ placeHolder | translate }}
  </div>
  <div class="items-container d-flex w-100 flex-wrap">
    <div
      *ngFor="let item of internalItems | slice : 0 : currentLimit"
      class="{{ extraData?.useChips ? 'd-flex flex-wrap' : 'col-6 col-xl-4' }}"
    >
      <ng-container
        [ngTemplateOutlet]="extraData?.useChips ? chipItems : checkboxItems"
        [ngTemplateOutletContext]="{item}"
      ></ng-container>
    </div>
  </div>
  <a *ngIf="needShowMoreItems" class="text-success text-decoration-underline" (click)="switchLimit()">
    <span *ngIf="currentLimit === defaultLimit" translate>More</span>
    <span *ngIf="currentLimit !== defaultLimit" translate>Less</span>
  </a>
  <hr />
</ng-template>

<ng-template #checkboxItems let-item="item">
  <div class="form-check mt-1 pe-3">
    <label class="form-check-label">
      <input class="form-check-input" type="checkbox" [checked]="item.selected" (click)="toggleCheck(item)" />
      <span class="form-check-sign"></span>
      <div class="d-inline">
        {{ item.label }} <span class="small grey">{{ item.numberOfElements }}</span>
      </div>
    </label>
  </div>
</ng-template>

<ng-template #chipItems let-item="item">
  <a
    class="rounded-3 px-4 py-1 me-2 border {{
      item.selected ? 'border-success text-white bg-success' : 'border-dark text-dark'
    }} mb-2 d-inline-block"
    (click)="toggleCheck(item)"
  >
    {{ item.label }} <span class="small grey">{{ item.numberOfElements }}</span>
  </a>
</ng-template>
