import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '@app/core/services/drupal8/custom/custom.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  gutenbergFooter = false;
  @Input()
  needSeparator = false;
  @Input()
  outOfMargin = false;
  userEmailForm: FormGroup;
  isUserEmailSubmitting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentYear: number = new Date().getFullYear();
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public navDataService: NavDataService,
    public rolesService: RolesService,
    private formBuilder: FormBuilder,
    private customService: CustomService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.userEmailForm = this.formBuilder.group({
      mail: ['', [Validators.required, CustomValidators.email]],
      condition: [true, Validators.requiredTrue],
    });
  }

  registerUser() {
    if (this.userEmailForm.valid) {
      this.isUserEmailSubmitting$.next(true);

      this.customService.registerEmail(this.userEmailForm.value).subscribe({
        next: (data) => {
          this.isUserEmailSubmitting$.next(false);

          if (data) {
            this.notificationsService.notifySuccess(this.translateService.instant('Subscription Successful'));
            this.navigateToProfile();
          }
        },
        error: () => {
          this.isUserEmailSubmitting$.next(false);
          this.notificationsService.notifyError(this.translateService.instant('Unable to subscribe now'));
        },
      });
    }
  }

  navigateToProfile() {
    this.navDataService.resetHeaderRightButton();
    this.rolesService.init();
    // init push notification
    this.customService.initPush();
    // this.customService.trackLocation();
    this.navDataService.initProfile().subscribe(() => {
      // update the user timezone
      // this.customService.updateUserTimeZone();
      // redirect to home page
      this.navDataService.navigate('/profile/user');
    });
  }
}
