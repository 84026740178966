<div class="modal-header pt-1 mb-1 position-relative">
  <h3 class="mt-0 modal-title text-center mx-auto" id="modal-confirm-title">
    <span class="d-inline-block ms-1" translate [innerHTML]="extraData?.title"></span>
  </h3>
  <button class="btn-close btn-red" (click)="closedByUser()"></button>
</div>
<div class="modal-body text-center">
  <div class="d-flex justify-content-center align-items-center" translate [innerHTML]="extraData.message"></div>
  <div class="d-flex justify-content-center align-items-center" [innerHTML]="extraData?.extraMessage"></div>
  <div class="d-flex justify-content-center mt-4">
    <button class="btn btn-info rounded-1" (click)="reject()" translate>
      {{ extraData?.buttonNoLabel ?? ('No' | translate) }}
    </button>
    <button class="btn btn-success rounded-1" (click)="confirm()">
      {{ extraData?.buttonYesLabel ?? ('Yes' | translate) }}
    </button>
  </div>
</div>
