import { HttpEvent, HttpResponse, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Observable, scan, distinctUntilChanged } from 'rxjs';
const isHttpResponse = (event: HttpEvent<any>): event is HttpResponse<any> => event.type === HttpEventType.Response;
const isHttpProgressEvent = (event: HttpEvent<unknown>): event is HttpProgressEvent =>
  event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
export interface UploadDownloadProgress {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  progress: number;
  content: Blob | null;
}
export const uploadDownloadProgress =
  (): ((source: Observable<HttpEvent<Blob>>) => Observable<UploadDownloadProgress>) =>
  (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (download: UploadDownloadProgress, event): UploadDownloadProgress => {
          if (isHttpProgressEvent(event)) {
            return {
              state: 'IN_PROGRESS',
              progress: event.total ? Math.round((100 * event.loaded) / event.total) : download.progress,
              content: null,
            };
          } else if (isHttpResponse(event)) {
            return {
              state: 'DONE',
              progress: 100,
              content: event.body,
            };
          }
          return download;
        },
        { state: 'PENDING', progress: 0, content: null }
      ),
      distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress && a.content === b.content)
    );
