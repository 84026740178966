import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TrainingCalendarSearchComponent } from '../components';
@Directive({
  selector: '[appDynamicHtmlComponent]',
})
export class DynamicHtmlComponentDirective implements OnInit {
  @Input('appDynamicHtmlComponent') templateString: string;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    if (this.templateString) {
      this.createComponent();
    }
  }

  createComponent() {
    // fetch this
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // create runtime dynamic component for the node body
    @Component({
      selector: 'app-dynamic-html-component',
      template: '',
      // template: this.templateString,
    })
    class DynamicHtmlComponent implements AfterViewInit {
      // reference the div where to inject the search component
      @ViewChild('trainingCalendarContainer', { read: ViewContainerRef }) trainingCalendarContainer: ViewContainerRef;
      ngAfterViewInit() {
        if (!this.trainingCalendarContainer) {
          return;
        }
        // inject our component inside the reference
        const searchFactory = self.componentFactoryResolver.resolveComponentFactory(TrainingCalendarSearchComponent);
        this.trainingCalendarContainer.createComponent(searchFactory);
      }
    }

    // display node body dynamic component
    const factory = this.componentFactoryResolver.resolveComponentFactory(DynamicHtmlComponent);
    this.viewContainerRef.createComponent(factory);
  }
}
