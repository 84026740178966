<ng-container *ngIf="isBrowser">
  <form [formGroup]="captchaGroup">
    <ngx-invisible-recaptcha
      #invisibleCaptchaElem
      formControlName="recaptcha"
      [siteKey]="siteKey"
      [hl]="lang"
      [type]="'image'"
      [badge]="'inline'"
      (load)="handleLoad()"
      hidden
    >
    </ngx-invisible-recaptcha>
  </form>
</ng-container>
