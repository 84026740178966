import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'error-content',
  templateUrl: './error-content.component.html',
  styleUrls: ['./error-content.component.scss'],
})
export class ErrorContentComponent {
  @Input() showReloadButton = true;
  @Output() reloadClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
