<div
  ngbDropdown
  class="dropdown dropdown-icon-right"
  #dropdown
  *ngIf="!isInModal; else templateForModal"
  [autoClose]="false"
>
  <button
    class="dropdown-toggle form-control d-flex text-start bg-white p-3 {{
      value ? 'border border-1 border-success text-success fw-bold' : ''
    }}"
    ngbDropdownToggle
  >
    <div class="w-100 flex-fill d-flex align-items-center">
      <i *ngIf="iconPrepend" class="me-2 icon icon-16 {{ iconPrepend }} {{ value ? 'text-success' : 'text-info' }}"></i>
      <span class="text-truncate d-inline-block flex-fill">
        {{ selectedPlaceHolder ?? placeHolder }}
      </span>
      <i class="icon icon-times icon-20 me-2" *ngIf="selectedPlaceHolder" (click)="clear($event)"></i>
    </div>
  </button>
  <div
    ngbDropdownMenu
    class="rounded-2 d-flex flex-column w-100 mt-2 shadow pt-0"
    style="min-width: 250px; max-width: 700px"
  >
    <ng-container [ngTemplateOutlet]="map"></ng-container>
    <hr class="m-0" />
    <div class="p-2 pb-0 d-flex justify-content-between drodpdown-buttons-filters">
      <button class="btn rounded-2 m-0 d-flex align-items-center" (click)="clear($event)">
        <i class="icon icon-times me-1"></i> <span tanslate>Clear</span>
      </button>
      <button class="btn btn-success rounded-2 m-0 d-flex align-items-center" (click)="confirm()">
        <i class="icon icon-check me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</div>

<ng-template #templateForModal>
  <div class="{{ value ? 'text-success' : 'text-dark' }} font-size- fw-bold ">
    {{ placeHolder | translate }}
  </div>
  <div class="text-dark small my-2">{{ selectedPlaceHolder }}</div>
  <div class="row">
    <ng-container [ngTemplateOutlet]="map"></ng-container>
  </div>
  <hr />
</ng-template>

<ng-template #map>
  <div
    class="h-100 d-flex flex-column justify-content-center align-items-center"
    *ngIf="(googleMapsService.mapsLoaded$ | async) === false"
  >
    <circular-spinner iconClass="success icon-64"></circular-spinner>
  </div>
  <div class="flex-will h-100 w-100 position-relative" *ngIf="googleMapsService.mapsLoaded$ | async">
    <google-map
      width="100%"
      height="35vh"
      class="h-100 w-100 flex-fill"
      (mapClick)="mapClick($event)"
      [(zoom)]="currentZoom"
      [options]="mapOptions"
      [center]="mapCenter$ | async"
      *ngIf="googleMapsService.mapsLoaded$ | async"
    >
      <map-marker #markerElem="mapMarker" *ngIf="currentValue" [position]="currentValue"> </map-marker>
      <map-circle
        [options]="{
          fillColor: '#005EB8',
          strokeColor: '#005EB8'
        }"
        *ngIf="currentValue"
        [center]="currentValue"
        [radius]="currentValue.distance * 1000"
      ></map-circle>
    </google-map>
    <div class="input-group address-searcher position-absolute">
      <div class="input-group-text"><i class="text-info icon icon-search"></i></div>
      <input
        appGooglePlacesAutocomplete
        class="form-control bg-white"
        (blur)="handleAddressChange()"
        (addressChange)="handleAddressChange($event)"
        [(ngModel)]="currentAutoCompleteText"
        [placeholder]="'Insert an address or location...' | translate"
        maxlength="128"
        [ngClass]="{ fullWidth: isInModal }"
      />
    </div>
  </div>
  <div class="col-12">
    <div class="form-group mb-5 custom-slider">
      <label class="text-primary d-flex align-items-center">
        <span translate>Distance (Km)</span>
      </label>
      <div class="d-flex flex-row align-items-center">
        <a
          class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
          (click)="decreaseDistance()"
        >
          <div>&minus;</div></a
        >
        <div class="px-2">{{ distanceValues.min }}</div>
        <div class="flex-fill">
          <nouislider [config]="distanceConfig" class="slider slider-success" [formControl]="distance"></nouislider>
        </div>
        <div class="px-2">{{ distanceValues.max }}</div>
        <a
          class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
          (click)="increaseDistance()"
        >
          <div>&plus;</div>
        </a>
      </div>
    </div>
  </div>
</ng-template>
