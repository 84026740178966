<div [ngClass]="{ 'need-separator': needSeparator, 'out-of-margin': outOfMargin }">
  <div class="container">
    <div class="row px-lg-0 px-3">
      <div class="footer-left col-xs-12 col-sm-4 col-md-4 col-lg-3">
        <div class="mt-4">
          <app-language-select
            [gutenberg]="gutenbergFooter"
            class="col-12 p-0"
            placement="top-left"
          ></app-language-select>
        </div>
        <div>
          <app-currency-select btnClass="bg-info"></app-currency-select>
        </div>
        <div class="d-none d-md-block">
          <p translate>Follow us on social media</p>
          <div>
            <span class="social facebook">
              <a
                class="sbtnf icon-30 me-3 icon icon-facebook-square"
                href="https://www.facebook.com/ikointl"
                target="_blank"
              ></a>
            </span>
            <span class="social instagram">
              <a
                class="sbtnf icon-30 me-3 icon icon-instagram"
                href="https://www.instagram.com/ikointl/"
                target="_blank"
              ></a>
            </span>
            <span class="social youtube">
              <a class="sbtnf icon-30 icon icon-youtube" href="https://www.youtube.com/@IKOINTL" target="_blank"></a>
            </span>
          </div>
          <ng-container *ngTemplateOutlet="newsletterForm"></ng-container>
        </div>
      </div>
      <div class="footer-right col-xs-12 col-sm-8 col-md-8 col-lg-9 mb-1">
        <div class="widget block block-block">
          <div class="block-content clearfix">
            <div class="block-footer-links">
              <div class="block-footer-links-full">
                <ul>
                  <li>
                    <strong><span translate>About Us</span></strong>
                    <ul>
                      <li><a href="https://www.ikointl.com/about">IKO</a></li>
                      <li><a [routerLink]="['/quality-assurance'] | localize" translate>Quality Check</a></li>
                      <li><a href="https://ifkitesports.org/">IFKO</a></li>
                      <li><a href="https://www.ikointl.com/merchants" translate>Merchants</a></li>
                      <li><a [routerLink]="['/jobs'] | localize" translate>Jobs</a></li>
                      <li>
                        <a [routerLink]="['/giving-back-program'] | localize"
                          ><img
                            src="/assets/images/IKO-giving back logo.png"
                            style="height: 65px"
                            alt="giving back program"
                        /></a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong><span translate>Discover</span></strong>
                    <ul>
                      <li>
                        <a href="https://www.ikointl.com/courses" translate>Training and Certification</a>
                      </li>
                      <li>
                        <a href="https://www.ikointl.com/Training-Kite-Course-Calendar" translate>Training Calendar</a>
                      </li>
                      <li>
                        <a [routerLink]="['/centers'] | localize" translate>Find a Center</a>
                      </li>
                      <li>
                        <a href="https://www.ikointl.com/iko-kite-members-community" translate>Find a Member</a>
                      </li>
                      <li>
                        <a href="https://www.ikointl.com/request-certification" translate>Request Certification</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong><span translate>Resources</span></strong>
                    <ul>
                      <li><a href="https://www.ikointl.com/memberships" translate>Memberships</a></li>
                      <li><a [routerLink]="['/blog'] | localize" translate>Blog</a></li>
                      <li><a [routerLink]="['/help'] | localize" translate>Contact Us</a></li>
                      <li><a href="/documents/53" translate>Standards</a></li>
                      <li>
                        <a href="/documents/53" translate>Chart of Sanctions</a>
                      </li>
                    </ul>
                  </li>
                  <li class="d-inline d-md-none">
                    <div>
                      <p class="social" translate>Follow us on social media</p>
                      <div>
                        <span>
                          <a
                            class="icon-30 me-3 icon icon-facebook-square"
                            href="https://www.facebook.com/ikointl"
                            target="_blank"
                          ></a>
                        </span>
                        <span>
                          <a
                            class="icon-30 icon icon-instagram"
                            href="https://www.instagram.com/ikointl/"
                            target="_blank"
                          ></a>
                        </span>
                      </div>
                    </div>
                    <ng-container *ngTemplateOutlet="newsletterForm"></ng-container>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dmca-block">
              <a
                target="_blank"
                href="https://www.dmca.com/Protection/Status.aspx?ID=07632c28-59de-4a46-99db-ac63ffec2e86&refurl=https://www.ikointl.com{{
                  navDataService.router.url
                }}"
              >
                <img src="/assets/images/dmca-badge-w100-5x1-02.png" alt="dmca-badge-w100-5x1-02" />
              </a>
            </div>
          </div>
        </div>
        <div class="widget block block-block iko-app-icon-block d-none d-lg-block">
          <a
            href="https://play.google.com/store/apps/details?id=com.ikointl.app"
            class="btn btn-download-app mb-xl-0 me-2 me-md-3"
            target="_blank"
          >
            <span class="d-flex align-items-center justify-content-center">
              <span class="icon-brand me-1 me-md-2 icon icon-google-play"> </span>
              <span class="d-inline-block text-start">
                <small class="fw-normal d-none d-md-block" translate>Get it on</small> Google Play
              </span>
            </span>
          </a>
          <a
            href="https://itunes.apple.com/us/app/ikointl/id1314907796"
            class="btn btn-download-app mb-xl-0 me-2 me-md-3"
            target="_blank"
          >
            <span class="d-flex align-items-center justify-content-center">
              <span class="icon-brand me-1 me-md-2 icon icon-apple"> </span>
              <span class="d-inline-block text-start">
                <small class="fw-normal d-none d-md-block" translate>Download on the</small> App Store
              </span>
            </span>
          </a>

          <!-- <a
            href="https://www.microsoft.com/store/apps/9N0ZZB315PW3"
            class="btn btn-download-app mb-xl-0 me-2 me-md-3"
            target="_blank"
          >
            <span class="d-flex align-items-center justify-content-center">
              <span class="icon-brand me-1 me-md-2 icon icon-windows"> </span>
              <span class="d-inline-block text-start">
                <small class="fw-normal d-none d-md-block" translate>Get it on</small> Windows Store
              </span>
            </span>
          </a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row footer-bottom-area p-3 d-flex justify-content-center copyright">
    <span class="me-1">{{ 'COPYRIGHT_CONTENT_RESERVED' | translate : { currentYear: currentYear } }}</span>
    <span>
      <a [routerLink]="'/gutenberg/367099' | localize" translate> Terms of Use </a>|
      <a [routerLink]="'/gutenberg/305' | localize" translate> Privacy Policy</a></span
    >
  </div>
</div>
<ng-template #newsletterForm>
  <div class="mt-3 hiding-stage" *ngIf="rolesService.userHasRole()">
    <form [formGroup]="userEmailForm">
      <div class="form-group">
        <p for="field_email social" translate>Join our Newsletter</p>
        <div class="input-group">
          <input
            appFormControlStatus
            id="email"
            type="email"
            class="form-control border-info"
            [placeholder]="'Your email' | translate"
            autocomplete="email"
            maxlength="40"
            formControlName="mail"
          />
          <div class="input-group-text border-info ps-1">
            <ng-container *ngIf="(isUserEmailSubmitting$ | async) === true; else newsletterEmailSubmitting">
              <circular-spinner
                *ngIf="isUserEmailSubmitting$"
                class="d-flex justify-content-center align-items-center"
              ></circular-spinner>
            </ng-container>

            <ng-template #newsletterEmailSubmitting>
              <i class="text-info icon icon-arrow-circle-right-2 pointer" (click)="registerUser()"></i>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="form-check">
        <label class="form-check-label success-checkbox-border">
          <input
            class="form-check-input"
            type="checkbox"
            name="isTermsAndConditionsAgreed"
            formControlName="condition"
          />
          <span class="form-check-sign"></span>
          <div class="small">
            <span translate>By proceeding, you agree to the</span>
            <a class="text-primary" [routerLink]="'/gutenberg/367099' | localize" translate> Terms of Use </a>
            <span translate> and </span>
            <a class="text-primary" [routerLink]="'/gutenberg/305' | localize" translate> Privacy Policy </a>
          </div>
        </label>
      </div>
    </form>
  </div>
</ng-template>
