import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'multiselect-checkbox',
  templateUrl: './multiselect-checkbox.component.html',
  styleUrls: ['./multiselect-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectCheckboxComponent),
      multi: true,
    },
  ],
})
export class MultiselectCheckboxComponent implements ControlValueAccessor {
  @Input() valueField: string;
  @Input() labelField: string;
  @Input() placeHolder: string;
  @Input() showCleaner = false;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() hasError = false;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;

  disabled = false;
  itemSelected: { [key: string]: boolean } = {};
  selectedPlaceHolder: string;
  internalItems: any[];

  constructor() {}

  @Input() set items(items: any[]) {
    this.internalItems = items;
    this.generatePlaceHolder();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get value(): any[] {
    return Object.keys(this.itemSelected);
  }

  set value(val: any[]) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.convertValues(val);
    this.onChange(this.value);
    this.onTouched();
    this.generatePlaceHolder();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (selected: any[]) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  writeValue(values: any[]): void {
    this.convertValues(values);
    this.onChange(values);
    this.generatePlaceHolder();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleCheck(item: any) {
    const value = item[this.valueField];
    if (this.itemSelected[value]) {
      delete this.itemSelected[value];
    } else {
      this.itemSelected[value] = true;
    }
    this.onChange(this.value);
    this.generatePlaceHolder();
  }

  clear($event: Event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.itemSelected = {};
    this.value = null;
    this.generatePlaceHolder();
    this.dropDown.close();
  }

  private generatePlaceHolder() {
    const value = this.value;
    if (value.length === 0) {
      this.selectedPlaceHolder = null;
      return;
    }
    if (!this.internalItems) {
      this.selectedPlaceHolder = null;
      return;
    }
    this.selectedPlaceHolder = this.internalItems
      .filter((item) => value.includes(item[this.valueField]))
      .map((item) => item[this.labelField])
      .join(',');
  }

  private convertValues(val: any[]) {
    this.itemSelected = {};
    if (val && Array.isArray(val)) {
      val.forEach((value) => (this.itemSelected[value] = true));
    }
  }
}
