<div ngbDropdown class="dropdown multi-select dropdown-icon-right" #dropdown>
  <button
    class="dropdown-toggle form-control d-flex text-start {{ placeHolderClass }}"
    [ngClass]="{ 'is-invalid': hasError }"
    ngbDropdownToggle
    style
  >
    <div class="w-100 flex-fill d-flex align-items-center">
      <span class="me-2" *ngIf="!selectedPlaceHolder && iconPrepend"
        ><i class="text-info icon icon-16 {{ iconPrepend }}"></i
      ></span>
      <span class="text-truncate d-inline-block flex-fill">
        {{ selectedPlaceHolder ?? placeHolder }}
      </span>
      <i class="icon icon-times icon-24 me-2" *ngIf="selectedPlaceHolder" (click)="clear($event)"></i>
    </div>
  </button>
  <div ngbDropdownMenu>
    <div *ngFor="let item of internalItems" class="ps-2">
      <div class="form-check pe-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="itemSelected[item[valueField]]"
            (click)="toggleCheck(item)"
          />
          {{ item[labelField] }}
          <span class="form-check-sign"></span>
        </label>
      </div>
    </div>
  </div>
</div>
