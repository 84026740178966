import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { LocalizeParser } from '@gilsdav/ngx-translate-router';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';
import { NavDataService } from '../nav-data/nav-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private navDataService: NavDataService, private router: Router, private localize: LocalizeParser) {}

  /**
   * implement can activate interface
   *
   * @param route the current active route
   * @param state the current router state
   * @see https://angular.io/api/router/CanActivate
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // let mainPage = environment.mainPage.ssr;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    // if (window['cordova']) {
    //   mainPage = environment.mainPage.cordova;
    // }
    // WEB-2658 redirect to destenation if provided in the url
    const dest = route.queryParamMap.get('destination');
    const onlyPublic = route.queryParamMap.get('onlyPublic');

    if (state.url.indexOf('/user/reset') !== -1) {
      return true;
    } //else if (this.anonymousPages.indexOf(state.url) !== -1) {
    else if (route.data?.onlyPublic && !onlyPublic) {
      if (DrupalConstants.Connection && DrupalConstants.Connection.current_user.uid !== 0) {
        // Navigate to home page of each platform
        this.navDataService.navigate(dest || '/account');
        return false;
      }
    } else {
      // if user id is 0 this means he is not logged in yet
      if (!DrupalConstants.Connection || DrupalConstants.Connection.current_user.uid === 0) {
        // Navigate to the login page with extras
        //return this.router.createUrlTree([mainPage]);
        // WEB-4179: allow anonymous redirect
        const params: NavigationExtras = {
          queryParams: {},
        };
        if (route.data?.redirect) {
          params.queryParams.destination = state.url;
        }
        this.navDataService.navigate(dest || '/user/login', false, params);
        return false;
      }
    }
    return true;
  }
}
