<button
  class="btn btn-primary w-100 {{ buttonClasses }} {{
    buttonSize
  }} rounded-1 d-flex align-items-center justify-content-center"
  (click)="clicked.emit()"
>
  <ng-container *ngIf="!showLoader; else loaderLike">
    <i class="fw-bold icon {{ !hasLike ? 'icon-heart' : 'icon-favorite text-danger' }}"></i>
    <span class="ms-1">{{ likesNumber }}</span>
  </ng-container>
  <ng-template #loaderLike><circular-spinner></circular-spinner></ng-template>
</button>
