<ng-container *ngIf="navDataService.appReady">
  <!-- <app-loader [hidden]="navDataService.httpRequestDetails.number === 0" class="w-100 h-100"></app-loader> -->
  <app-notification-bar class="fixed-top"></app-notification-bar>
  <div class="h-100 hide-in-trasparent">
    <!-- <router-outlet></router-outlet> -->
    <app-shell></app-shell>
  </div>
  <loader-with-words></loader-with-words>
</ng-container>
<canvas id="canvas-to-resize" class="d-none"></canvas>
