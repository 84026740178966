import { IdLabel } from '@app/core/models';
import { AutocompleteInputItem } from '@app/shared/forms/autocomplete-input/autocomplete-input-item';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { map, Observable, of } from 'rxjs';

export class ProviderDynamicAutoCompleteLanguagesServer implements AutoCompleteInputProvider {
  languages: { value: string; label: string; label_low: string }[];
  private viewService: ViewService;

  constructor(viewService: ViewService) {
    this.viewService = viewService;
  }

  filter(term: string): Observable<AutocompleteInputItem[]> {
    if (this.languages && term) {
      return of(this.languages.filter((l) => l.label_low.indexOf(term) !== -1));
    }
    this.languages = [];
    return this.viewService.get('/api/languages').pipe(
      map((result) =>
        result.map((language: IdLabel) => {
          const l = {
            value: `${language.id}`,
            label: language.label,
            label_low: language.label.toLowerCase(),
          };
          this.languages.push(l);
          return l;
        })
      )
    );
  }
}
