import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IpApi, LatLon } from '@app/core/models';
import { DeviceDetectService, NavDataService, ToolsService } from '@app/core/services';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BaseService, DrupalConstants, HttpOptions, UserService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, catchError, finalize, from, map, mergeMap, of, tap } from 'rxjs';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { ConfirmModalResult, ModalManagerService } from '../modal-manager';
import { GoogleMapService } from '../tools/google-map.service';

const maxDistanceKmToChangeLocation = 30;
@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {
  currentLocation: LatLon;
  currentLocation$: BehaviorSubject<LatLon> = new BehaviorSubject(null);
  forceUpdateLocation = false;
  httpNativeClient: HttpClient;
  isTrackingLocation$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly debounceLocationStorageKey = 'debounce_location_key';
  private modalsChangeLocaitonAlreadyOpen = false;
  private updatingLocation = false;
  // private declinedChangePosition = true;
  private locationBlackListKey = 'location_blacklist';
  private latLonBlackListKey = 'lat_lon_blacklist';
  private locationBlackList: string[] = [];
  private latLonBlackList: LatLon[] = [];
  private currentTrackingLocation: Observable<any>;

  constructor(
    httpClient: HttpClient,
    @Inject(PLATFORM_ID) platform: string,
    private navDataService: NavDataService,
    private geolocation: Geolocation,
    private gmConverter: GoogleMapService,
    private tools: ToolsService,
    private diagnostic: Diagnostic,
    private modalManagerService: ModalManagerService,
    private deviceDetectService: DeviceDetectService,
    private userService: UserService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    super(httpClient, platform);
    this.httpNativeClient = httpClient;
    this.loadBlackListLocation();
  }

  /**
   * Get the permission to access the location of the user.
   *
   * @returns
   *  Observable of the permission to access the location of the user.
   */
  hasPermission(): Observable<boolean> {
    return !this.deviceDetectService.isCordova
      ? from(navigator.permissions.query({ name: 'geolocation' }).then((result) => result.state === 'granted'))
      : from(this.diagnostic.getLocationAuthorizationStatus().then((status) => status.indexOf('authorized') !== -1));
  }

  /**
   * Get the current location of the user.
   *
   * @returns
   *   Observabel of the current location of the user.
   */
  getMyLocation(): Observable<LatLon> {
    if (this.currentLocation) {
      return of(this.currentLocation);
    }
    this.fetchMyLocation();
    return this.currentLocation$;
  }

  /**
   * Fetch the current location of the user and store it in memory.
   *
   */
  fetchMyLocation(): void {
    // have already location
    if (this.currentLocation) {
      return;
    }

    if (this.deviceDetectService.isCordova) {
      // is cordova
      this.geolocation
        .getCurrentPosition()
        .then((resp) => {
          const location = {
            lat: resp.coords.latitude,
            lon: resp.coords.longitude,
          };
          this.setCurrentLocation(location);
        })
        .catch(() => {
          this.setLocationByIp();
        });
      return;
    }
    // is browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setCurrentLocation({ lat: position.coords.latitude, lon: position.coords.longitude });
      });
      return;
    }
    this.setLocationByIp();
  }

  /**
   * Track and update current location of the user.
   *
   */
  trackLocation(): Observable<any> {
    if (this.isTrackingLocation$.value && this.currentTrackingLocation) {
      return this.currentTrackingLocation;
    }
    this.isTrackingLocation$.next(true);
    if (!this.deviceDetectService.isCordova) {
      return this.getGpsAndUpdate();
    }
    return (this.currentTrackingLocation = from(
      this.diagnostic.getLocationAuthorizationStatus().then((status) => {
        if (
          status === this.diagnostic.permissionStatus.GRANTED ||
          status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
        ) {
          return from(
            this.diagnostic
              .isLocationEnabled()
              .then((gpsEnabled) => (gpsEnabled ? this.getGpsAndUpdate() : of(null)))
              .catch((err) => {
                this.isTrackingLocation$.next(false);
                throw new Error(err);
              })
          ).pipe(mergeMap((result) => result));
        }
      })
    ).pipe(
      mergeMap((result) => result),
      finalize(() => {
        delete this.currentTrackingLocation;
        this.isTrackingLocation$.next(false);
      })
    ));
  }

  /**
   * Clear the session of this service for the current user.
   */
  clearSession(): void {
    this.modalsChangeLocaitonAlreadyOpen = false;
  }

  private getGpsAndUpdate(): Observable<any> {
    if (this.localStorageService.getItem('locationTrackingDenied')) {
      return this.trackMyLocation();
    }
    if (!this.deviceDetectService.isCordova) {
      return this.trackMyLocation();
    }
    return from(
      this.geolocation
        .getCurrentPosition()
        .then((resp) => {
          const location = {
            lat: resp.coords.latitude,
            lon: resp.coords.longitude,
          };
          // submit to backend location tracking
          return this.trackMyLocation(location);
        })
        .catch((error: GeolocationPositionError) => {
          if (error.code === error.PERMISSION_DENIED) {
            this.localStorageService.setItem('locationTrackingDenied', 'true');
          }
          return this.trackMyLocation();
        })
    ).pipe(mergeMap((result) => result));
  }

  private trackMyLocation(location?: LatLon): Observable<null> {
    return of('').pipe(
      mergeMap(() => {
        if (location) {
          // get reverse code
          return this.gmConverter.reverseGeocoding(location.lat, location.lon).pipe(
            mergeMap((place) => {
              const currentAddress = `${place.country}, ${place.locality}`;
              return this.updateLocation(location, currentAddress);
            })
          );
        }
        return this.getLocationFromIp().pipe(
          mergeMap((response) => {
            if (!location) {
              location = response.location;
              const currentAddress = `${response.responseApi.country_name}, ${response.responseApi.city}`;
              return this.updateLocation(location, currentAddress);
            }
          })
        );
      })
    );
  }

  /**
   * Get the user location by ip.
   */
  private setLocationByIp() {
    this.getLocationFromIp().subscribe((response) => {
      this.setCurrentLocation(response.location);
    });
  }

  /**
   * Fetch the location from the ip.
   *
   * @returns
   *  An observable of the location of the user.
   */
  private getLocationFromIp(): Observable<{
    location: LatLon;
    responseApi: IpApi;
  }> {
    return this.httpNativeClient.get('https://ipapi.co/json').pipe(
      map((ipApiData: IpApi) => {
        if (!ipApiData) {
          throw new Error('Not valid data from ip');
        }
        return {
          location: {
            lat: ipApiData.latitude,
            lon: ipApiData.longitude,
          },
          responseApi: ipApiData,
        };
      })
    );
  }

  /**
   * Try to update the location of the user.
   *
   * @param location
   *  The location to update.C<LatLon>
   * @param currentAddress
   *  The current address of the user.
   * @returns
   *   An observable when the location is updated.
   */
  private updateLocation(location: LatLon, currentAddress: string): Observable<null> {
    if (!this.navDataService.currentUserProfile) {
      return of(null);
    }
    if (this.forceUpdateLocation) {
      this.forceUpdateLocation = false;
      return this._updateLocation(location, currentAddress, true);
    }
    if (this.navDataService.currentUserProfile.field_current_city === '' && !this.forceUpdateLocation) {
      return of(null);
    }
    this.forceUpdateLocation = false;
    if (this.navDataService.currentUserProfile.field_gps_position) {
      if (
        maxDistanceKmToChangeLocation >
        this.getDistanceFromLatLonInKm(location, this.navDataService.currentUserProfile.field_gps_position as LatLon)
      ) {
        return of(null);
      }

      if (this.isLatLonBlackList(location)) {
        return of(null);
      }
    } else {
      if (this.navDataService.currentUserProfile && this.navDataService.currentUserProfile.field_current_city) {
        // currentAddress = this.navDataService.currentUserProfile.field_current_city;
        if (this.navDataService.currentUserProfile.field_current_city.toLowerCase() === currentAddress.toLowerCase()) {
          return of(null);
        }
      }

      if (this.isLocationInBlackList(currentAddress)) {
        return of(null);
      }
    }

    // ask to change permission
    if (this.modalsChangeLocaitonAlreadyOpen) {
      return of(null);
    }
    this.modalsChangeLocaitonAlreadyOpen = true;
    return this.modalManagerService
      .openConfirmModal(
        this.translateService.instant('Confirm change position'),
        this.translateService.instant('Your current position has changed, do you want set it as default?'),
        {
          extraMessage: `<div class="mt-2 display-block">
        ${this.translateService.instant('from_to_location', {
          oldLocation: this.navDataService.currentUserProfile.field_current_city,
          newLocation: currentAddress,
        })}</div>`,
        }
      )
      .pipe(
        mergeMap((result: ConfirmModalResult) => {
          if (result === ConfirmModalResult.yes) {
            return this._updateLocation(location, currentAddress, true);
          }

          this.addLatLonToBlackList(location);
          this.addLocationToBlackList(currentAddress);
          return of(null);
        }),
        catchError(() => {
          return of(null);
        })
      );

    // return this._updateLocation(location, currentAddress);
    // check permission to change if ANDROID/IOS
  }

  /**
   * Update the location of the user in the backend.
   *
   * @param location
   *  The location to update.
   * @param currentAddress
   *  The current address of the user.
   * @returns
   *  An observable.
   */
  private _updateLocation(location: LatLon, currentAddress: string, forceUpdate = false): Observable<any> {
    // TODO make a debounce time to send location to backend example one send every 15 minutes
    if (!forceUpdate && (!this._canUpdateLocation() || !location)) {
      return of(null);
    }
    this.updatingLocation = false;

    const user: any = {
      field_current_city: currentAddress,
      field_gps_position: `POINT(${location.lon} ${location.lat})`,
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.userService.update(DrupalConstants.Connection.current_user.uid, user).pipe(
      mergeMap(() => {
        this.localStorageService.setItem(this.debounceLocationStorageKey, new Date().getTime().toString());
        if (!this.navDataService.currentUserProfile) {
          return of(null);
        }
        this.navDataService.currentUserProfile.field_current_city = currentAddress;
        this.navDataService.notifyUserProfileChanged();
        return this.request(httpOptions, '/location_tracking', location).pipe(
          tap(() => {
            this.updatingLocation = false;
            this.navDataService.initProfile().subscribe();
          }),
          map(() => ({ address: currentAddress, location })),
          catchError((err) => {
            this.updatingLocation = false;
            return err;
          })
        );
      })
    );
  }

  /**
   *  Check if the location can be updated, base by the time of the last update.
   *
   * @returns
   *   True if the location can be updated, false otherwise.
   */
  private _canUpdateLocation(): boolean {
    if (this.updatingLocation) {
      return false;
    }
    const lastTime = this.localStorageService.getItem(this.debounceLocationStorageKey);
    if (!lastTime) {
      return true;
    }
    const lastTimeNumeric = Number(lastTime);
    const now = new Date().getTime();
    const diff = this.tools.timeDifference(now, lastTimeNumeric);
    return diff.days > 0 || diff.hours > 1 || diff.minutes >= 15;
  }

  /**
   * Check if location is in the black list.
   *
   * @param location
   *  The location to check.
   * @returns
   *  True if the location is in the black list, false otherwise.
   */
  private isLocationInBlackList(location: string): boolean {
    return this.locationBlackList.indexOf(location.toLowerCase()) !== -1;
  }

  /**
   *  Check if the coordinates are in the black list.
   * @param latLon
   *  The coordinates to check.
   * @returns
   *   True if the coordinates are in the black list, false otherwise.
   */
  private isLatLonBlackList(latLon: LatLon): boolean {
    for (const ll of this.latLonBlackList) {
      if (this.getDistanceFromLatLonInKm(ll, latLon) <= maxDistanceKmToChangeLocation) {
        return true;
      }
    }
    return false;
  }

  /**
   * Add the location in the black list of locations.
   *
   * @param location
   *  The location to add to the black list.
   */
  private addLocationToBlackList(location: string): void {
    location = location.toLowerCase();
    if (this.locationBlackList.indexOf(location) !== -1) {
      return;
    }
    this.locationBlackList.push(location);
    this.saveBlackListLocation();
  }

  /**
   *  Add the coordinates to the black list.
   * @param location
   *  The coordinates to add to the black list.
   */
  private addLatLonToBlackList(location: LatLon): void {
    if (this.latLonBlackList.indexOf(location) !== -1) {
      return;
    }
    this.latLonBlackList.push(location);
    this.saveBlackListLocation();
  }

  /**
   * Load the black list location from the local storage.
   */
  private loadBlackListLocation(): void {
    let jsonBlacklist = this.localStorageService.getItem(this.locationBlackListKey);
    this.locationBlackList = JSON.parse(jsonBlacklist || '[]') ?? [];
    jsonBlacklist = this.localStorageService.getItem(this.latLonBlackListKey);
    this.latLonBlackList = JSON.parse(jsonBlacklist || '[]') ?? [];
  }

  /**
   * Save the black list location in the local storage.
   */
  private saveBlackListLocation(): void {
    this.localStorageService.setItem(this.locationBlackListKey, JSON.stringify(this.locationBlackList));
    this.localStorageService.setItem(this.latLonBlackListKey, JSON.stringify(this.latLonBlackList));
  }

  /**
   * Set the current location.
   *
   * @param location
   *  The location to set as current location.
   */
  private setCurrentLocation(location: LatLon): void {
    this.currentLocation = location;
    this.currentLocation$.next(location);
  }

  /**
   * Calculate the distance in Km between two locations.
   *
   * @param location1
   *  Latitude and longitude of the first location.
   * @param location2
   *  Latitude and longitude of the second location.
   * @returns
   *  Distance in Km.
   */
  private getDistanceFromLatLonInKm(location1: LatLon, location2: LatLon) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(location2.lat - location1.lat); // deg2rad below
    const dLon = this.deg2rad(location2.lon - location1.lon);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(location1.lat)) *
        Math.cos(this.deg2rad(location2.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  /**
   *  Coonvert degrees to radians.
   *
   * @param deg
   *  Degrees to convert
   * @returns
   *  Radians value.
   */
  private deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }
}
