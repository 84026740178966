import { Directive, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormControlStatus]',
})
export class FormControlStatusDirective implements OnInit, OnDestroy {
  valueSub: Subscription;

  constructor(
    private el: ElementRef,
    private formControlName: FormControlName // Inject FormControlName
  ) {}

  ngOnInit() {
    if (!this.formControlName.value) {
      this.setClassStatus('add', 'border-success');
    }
    // Listen value changes
    this.valueSub = this.formControlName.valueChanges.subscribe((value) => {
      if (value) {
        this.setClassStatus('remove', 'border-success');
      } else {
        this.setClassStatus('add', 'border-success');
      }
    });
  }

  ngOnDestroy() {
    // Unlisten value changes
    this.valueSub.unsubscribe();
  }

  setClassStatus(action: 'add' | 'remove', className: string) {
    const inputGroupText = this.el.nativeElement.parentElement.getElementsByClassName('input-group-text')[0];
    this.el.nativeElement.classList[action](className);
    if (inputGroupText) {
      inputGroupText.classList[action](className);
    }
  }
}
