import { Injectable } from '@angular/core';
import { UserProfile } from '@app/core/models';
import { CustomService, NavDataService } from '@app/core/services';
import { DrupalConstants, FileEntity, UserEntity, UserService } from '@makiwin/ngx-drupal8-rest';
import { BehaviorSubject, Observable, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /**
   * User profile information
   */
  profile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(this.navDataService.currentUserProfile);

  constructor(
    private userService: UserService,
    private customService: CustomService,
    private navDataService: NavDataService
  ) {}

  /**
   *  Update one more fields of the user profile.
   *
   * @param data
   *   Fields to update for the current user.
   * @returns
   */
  patch(data: any): Observable<UserEntity> {
    return this.userService.update(DrupalConstants.Connection.current_user.uid, data);
  }

  logError(error: any): void {
    this.customService.sendLog('error', error.toString() + ` | user.component.ts | url: ${window.location?.href}`);
  }

  uploadFile(file: File, fieldName: string): Observable<number> {
    return this.customService
      .uploadFile('user', 'user', `${DrupalConstants.Connection.current_user.uid}/${fieldName}`, file, 512)
      .pipe(
        mergeMap((data: FileEntity) => {
          const value = data.fid[0].value;
          return this.patch({
            [fieldName]: value,
            field_nationality: this.navDataService.currentUserProfile?.field_nationality,
          }).pipe(
            map(() => {
              this.getProfile();
              return value;
            })
          );
        })
      );
  }

  getProfile() {
    this.navDataService.initProfile().subscribe({
      next: (profile) => {
        this.profile$.next(profile);
      },
      error: (error) => {
        console.log('error updating profile', error);
      },
    });
  }

  setTempProfilePicture(tempImage: string) {
    const profile = this.profile$.getValue();

    for (const size of ['hd', 'md', 'sd', 'xsd']) {
      profile.user_picture_multiple_json['square_' + size] = tempImage;
    }
    this.profile$.next(profile);
  }

  resyncProfile(): void {
    this.profile$.next(this.navDataService.currentUserProfile);
  }
}
