import { inject, Injectable, NgZone } from '@angular/core';
import { SpeedTestService } from 'ng-speed-test';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternetConnectionService {
  public readonly threshold = {
    videos: {
      hd: 6,
    },
  };
  public isOnline = true;
  public currentMbs$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public isOnline$: Observable<boolean> = this.speedTestService
    .isOnline()
    .pipe(tap((isOnline) => (this.isOnline = isOnline)));
  private zone: NgZone = inject(NgZone);
  private currentInterval: NodeJS.Timeout;

  constructor(private speedTestService: SpeedTestService) {
    // prevent speed test on first app open
    // WEB-3142
    // this.testSpeed();
  }

  startIntervalledCheck(timeoutMs = 60000) {
    if (this.currentInterval) {
      clearInterval(this.currentInterval);
    }
    // start when requested then set interval to repeat
    // WEB-3142
    this.testSpeed();
    this.currentInterval = setInterval(() => {
      this.zone.run(() => this.testSpeed());
    }, timeoutMs);
  }

  stopIntervalledCheck() {
    clearInterval(this.currentInterval);
  }

  private testSpeed(): void {
    this.speedTestService
      .getMbps({
        iterations: 5,
        file: {
          path: 'https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/500kb.jpg',
          size: 408949,
          shouldBustCache: true,
        },
        retryDelay: 1500,
      })
      .subscribe((mbps) => this.currentMbs$.next(mbps));
  }
}
