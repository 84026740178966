import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { DrupalConstants, UserService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SupportedLanguages } from 'src/translations/languages';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { CommonModule } from '@angular/common';
import { CircularSpinnerComponent } from '../circular-spinner/circular-spinner.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DirectivesModule, CircularSpinnerComponent, NgbDropdownModule],
})
export class LanguageSelectComponent implements OnDestroy {
  @Input()
  small = false;
  @Input()
  gutenberg = false;
  @Input() updateProfile = true;
  @Input() updateUi = true;
  @Input() isContent = false;
  @Input() placement: string[] = ['bottom-left', 'bottom-right', 'right-bottom', 'top-left', 'top-right'];
  @Input() isHeader = false;
  @Output() languageChanged = new EventEmitter<string>();

  loadingNewLanguage$: Observable<boolean>;
  tempLanguage: string;
  languagesToShow: SupportedLanguages;
  keysLanguageToShow: string[];
  languageToDisplay$: Observable<string> = this.i18nService.changedSelectedLanguage$.asObservable();

  private pLoadingNewLanguage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private languageSubscriber: Subscription;
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public i18nService: I18nService,
    public deviceDetectService: DeviceDetectService,
    public navDataService: NavDataService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.loadingNewLanguage$ = this.pLoadingNewLanguage$.asObservable();
    this.languageSubscriber = this.i18nService.languagesToShow$.subscribe((languages) => {
      this.languagesToShow = languages;
      this.keysLanguageToShow = languages ? Object.keys(languages) : [];
    });
  }

  /**
   * set language
   *
   * @param language language key to set
   */
  setLanguage(language: string) {
    // set the language
    const supportedLanguage = this.i18nService.getSupportedLanguage(language);
    if (!supportedLanguage?.mainLanguage) {
      this.i18nService.language = language;
      this.emitNewlang(language);
      return;
    }

    if (this.userService.isLoggedIn && this.updateProfile) {
      this.pLoadingNewLanguage$.next(true);
      const uid = DrupalConstants.Connection.current_user.uid;
      const values: any = {
        preferred_langcode: language.toLowerCase(),
      };
      this.userService.update(uid, values).subscribe({
        next: () => {
          this.navDataService.initProfile(true).subscribe({
            next: () => {
              this.i18nService.language = language;
              this.emitNewlang(language);
              this.pLoadingNewLanguage$.next(false);
            },
            error: () => {
              this.handleErrorSwitching();
            },
          });
        },
        error: () => {
          this.handleErrorSwitching();
        },
      });
    } else {
      this.i18nService.language = language;
      this.tempLanguage = language;
      this.emitNewlang(language);
    }
  }

  // switch back to app language
  ngOnDestroy() {
    /**
     * @Wassem i removed this part, because WEB-1394.
     *
     * */
    // if (!this.userService.isLoggedIn) {
    //   return;
    // }
    // const uid = DrupalConstants.Connection.current_user.uid;
    // const language = this.i18nService.language;
    // const values: any = {
    //   preferred_langcode: language.toLowerCase(),
    // };
    // this.userService.update(uid, values).subscribe(() => this.navDataService.initProfile().subscribe());
    this.languageSubscriber.unsubscribe();
  }

  emitNewlang(language: string) {
    this.languageChanged.emit(language.toLowerCase());
  }

  getSupportedLanguageKeys() {
    if (!this.languagesToShow) {
      return [];
    }
    return Object.keys(this.languagesToShow);
  }

  getLanguage(language: string) {
    return this.languagesToShow[language.toLowerCase()];
  }

  private handleErrorSwitching() {
    this.pLoadingNewLanguage$.next(false);
    this.notificationsService.notifyError(this.translateService.instant('An error occured switching your language'));
  }
}
