<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex align-items-center">
    <div class="image-preview d-flex align-items-center me-2" *ngIf="showImageItem">
      <img
        [src]="r.picture"
        *ngIf="r.picture"
        [ngClass]="{ 'rounded-circle': roundedImage }"
        [alt]="'profile-' + r.picture"
      />
      <ng-container [ngTemplateOutlet]="imagePlaceholder" *ngIf="!r.picture"></ng-container>
    </div>
    <div class="d-flex align-items-center flex-column">
      <ngb-highlight [result]="r.label" class="text-autocomplete-result" [term]="t"></ngb-highlight>
      <div class="text-small" *ngIf="r.subLabel" [innerHtml]="r.subLabel"></div>
    </div>
  </div>
</ng-template>
<div
  class="input-group position-relative"
  [ngClass]="{ 'border-success': currentItem && !isInModal, 'not-valid-field': isInvalid }"
>
  <span class="input-group-text" [ngClass]="{ 'text-success': currentItem && !isInModal }">
    <i class="icon icon-search" *ngIf="!currentItem || !showImageItem"></i>
    <div *ngIf="showImageItem && currentItem" class="image-preview-small me-1 d-flex align-items-center">
      <img
        [src]="currentItem.picture"
        [ngClass]="{ 'rounded-circle': roundedImage }"
        *ngIf="currentItem.picture"
        [alt]="'profile-' + currentItem.picture"
      />
      <ng-container [ngTemplateOutlet]="imagePlaceholder" *ngIf="!currentItem.picture"></ng-container>
    </div>
  </span>

  <input
    type="text"
    class="form-control 
      {{ currentItem && !isInModal ? 'text-success' : '' }}
      {{ inputClass }}
      "
    [ngClass]="{
      'is-invalid': isInvalid
    }"
    [formControl]="currentItemModel"
    [ngbTypeahead]="search"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    [disabled]="isDisabled"
    [placement]="placement"
    (focus)="focused.emit()"
    (blur)="blurred.emit()"
    popupClass="items-selector"
    placeholder="{{ placeHolder | translate }}"
  />
  <span class="input-group-text suffix-icon pointer">
    <i
      *ngIf="currentItemModel.value && !searching && !hasError"
      (click)="isInit = false; currentItemModel.reset()"
      class="{{ highlightOnValue ? 'text-success' : 'text-danger' }} icon icon-times"
    ></i>
    <circular-spinner iconClass="icon-16" *ngIf="searching" class="small-circular"></circular-spinner>
    <i
      *ngIf="!searching && hasError"
      class="icon text-danger icon-exclamation-triangle"
      [ngbTooltip]="'Error. Please try again later.' | translate"
    ></i>
  </span>
</div>
