<ng-container *ngIf="!isInModal; else templateForModal">
  <ng-container [ngTemplateOutlet]="control"></ng-container>
</ng-container>

<ng-template #templateForModal>
  <div class="{{ formControl.value ? 'text-success' : 'text-dark' }} font-size- fw-bold ">
    {{ placeHolder | translate }}
  </div>
  <div class="mb-4">
    <ng-container [ngTemplateOutlet]="control"></ng-container>
  </div>
  <hr />
</ng-template>

<ng-template #control>
  <autocomplete-for-filters
    [formControl]="formControl"
    [searchValues]="search"
    [showImageItem]="true"
    [isInModal]="isInModal"
    [roundedImage]="true"
    [placement]="placement"
    [imagePlaceholder]="imagePlaceholder"
    placeHolder="Search for a trainer..."
  >
  </autocomplete-for-filters>
</ng-template>

<ng-template #imagePlaceholder>
  <i class="icon icon-user-circle" image-placeholder></i>
</ng-template>
