import { Component } from '@angular/core';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { ModalManagerService } from '@app/core/services/modal-manager/modal-manager.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
})
export class BottomNavbarComponent {
  constructor(
    public navDataService: NavDataService,
    public deviceDetectService: DeviceDetectService,
    private rolesService: RolesService,
    private modalManagerService: ModalManagerService
  ) {}

  navigateToBookings() {
    if (this.rolesService.userHasRole('school', true)) {
      if (
        this.rolesService.userHasRole(['school_free', 'school_pending', 'school_expired', 'school_suspended'], true)
      ) {
        this.modalManagerService.toggle();
      } else {
        this.navDataService.navigate('/account/training');
      }
    } else if (this.rolesService.userHasRole(['kiter', 'instructor', 'assistant_trainer'], true)) {
      this.navDataService.navigate('/bookings/userBookings');
    } else {
      this.navDataService.navigate('/account');
    }
  }
}
