<div class="input-group m-0 rounded-pill">
  <input
    appCloseKeyboardOnEnter
    type="text"
    [placeholder]="placeHolder ?? ('Search' | translate)"
    [formControl]="formControl"
    class="form-control bg-white border-0"
  />
  <span class="input-group-text border-0">
    <i *ngIf="formControl.value" (click)="formControl.reset()" class="text-danger icon icon-times"></i>
    <i class="icon icon-search" *ngIf="!formControl.value"></i>
  </span>
</div>
