import { Injectable, NgZone, inject } from '@angular/core';
import { FileTransferObject, FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Observable, of, map, mergeMap, from, catchError, throwError, Observer, Subscriber } from 'rxjs';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { File as CordovaFile, FileEntry } from '@ionic-native/file/ngx';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageCacheService } from '../storage-cache/storage-cache.service';
import { NavDataService } from '../nav-data/nav-data.service';
import { TranslateService } from '@ngx-translate/core';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';
import { DirectoryEntry } from '@ionic-native/file';
import { NotificationsService } from '../notifications/notifications.service';
declare const cordova: any;
declare const window: any;
declare const FileError: any;
declare const navigator: any;
@Injectable({
  providedIn: 'root',
})
export class StorageDocumentsService {
  cacheFolderName = 'downloads';
  storageLocation: string;
  private downloadEntry: DirectoryEntry;
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    private deviceDetectService: DeviceDetectService,
    private file: CordovaFile,
    private transfer: FileTransfer,
    private httpNativeClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private storageCacheService: StorageCacheService,
    private navDataService: NavDataService,
    private translateService: TranslateService,
    private zone: NgZone
  ) {}

  /**
   * download and get file from device local storage
   *
   * @param fileURL the url of the file
   * @param responseType type of response
   */
  getLocalUrl(
    fileURL: string,
    responseType = 'readAsDataURL',
    forceHttpClient = false,
    rawData = false,
    sendCredentials = true,
    downloadInBackground = false
  ): Observable<any> {
    if (!this.deviceDetectService.isCordova || forceHttpClient) {
      if (responseType === 'readAsDataURL') {
        return of(fileURL);
      }
      return this.httpNativeClient
        .get(fileURL, { responseType: responseType as any, withCredentials: sendCredentials, reportProgress: true })
        .pipe(
          map((blob: any) => {
            // fix to fetch pdf with cookie, the ngx-extended-pdf-viewer not send cookie
            if (rawData) {
              return blob;
            }
            return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
          })
        );
    }
    // get the url frags
    const fileFrags = fileURL.split('/');
    // get the file name from latest prag
    const fileName = decodeURIComponent(fileFrags[fileFrags.length - 1].split('?')[0]);

    // checking if file exists and return the result
    return this.fileExists(fileName).pipe(
      mergeMap((fileExists) => {
        // if file already downloaded before
        if (fileExists) {
          // return it from cache
          if (responseType && this.file[responseType]) {
            return this.storageCacheService.cacheFolderNamePath$.pipe(
              mergeMap((cacheFolderNamePath) =>
                responseType !== 'resolveLocalFilesystemUrl'
                  ? from(this.file[responseType](cacheFolderNamePath, fileName))
                  : from(this.file[responseType](cacheFolderNamePath + '/' + fileName)).pipe(
                      map((file) => file.nativeURL)
                    )
              )
            );
          } else {
            return of(fileURL);
          }
        }

        // else: return it from http request and create a cache for it
        // cordova file transfere

        const downloadObservable = this.storageCacheService.cacheFolderNamePath$.pipe(
          mergeMap((cacheFolderNamePath) => {
            const fileTransfer: FileTransferObject = this.transfer.create();
            // get observable of the download file request
            const observable = from(
              fileTransfer.download(fileURL, cacheFolderNamePath + fileName, true, {
                withCredentials: true,
              })
            );
            this.navDataService.httpRequestDetails.number++;
            fileTransfer.onProgress((event) => {
              const downloadPercentDone = Math.round((100 * event.loaded) / event.total);
              // display download progress message
              this.navDataService.httpRequestDetails.progress = downloadPercentDone;
            });
            // get the dataurl type from the file and return it to sender
            return observable.pipe(
              mergeMap((file: FileEntry) => {
                delete this.navDataService.httpRequestDetails.progress;
                this.navDataService.httpRequestDetails.number--;
                // if data url needed
                return responseType && this.file[responseType] && responseType !== 'resolveLocalFilesystemUrl'
                  ? from(this.file[responseType](this.file.cacheDirectory + this.cacheFolderName, fileName))
                  : of(file.nativeURL);
              }),
              catchError((err) => {
                console.error(err);
                this.navDataService.httpRequestDetails.number--;
                // if something fail
                this.file.removeFile(cacheFolderNamePath, fileName).then();
                return throwError(() => err);
              })
            );
          })
        );
        if (downloadInBackground) {
          downloadObservable.subscribe();
          return of(fileURL);
        }

        return downloadObservable;
      })
    );
  }

  /**
   * check if file exists in device cache
   *
   * @param fileName file name to check for
   * @param directory directory name to check inside it
   */
  fileExists(fileName: string): Observable<boolean> {
    // if cordova exists
    if (this.deviceDetectService.isCordova) {
      // get the primise
      return this.storageCacheService.cacheFolderNamePath$.pipe(
        mergeMap((cacheFolderNamePath) => {
          const promise = this.file.checkFile(cacheFolderNamePath, fileName);
          // convert to observable and return
          return new Observable<boolean>((observer: Observer<boolean>) => {
            promise.then(
              (exists) => {
                observer.next(exists);
                observer.complete();
              },
              () => {
                observer.next(false);
                observer.complete();
              }
            );
          });
        })
      );
    }
    // if cordova not exists return empty observable with false value
    return of(false);
  }
  // downloadAndroid(fileName: string) {
  //   this.file
  //     .checkDir(this.file.externalRootDirectory, 'Download')
  //     .then((exists) => {
  //       if (exists) {
  //         this.copyFile(fileName);
  //       } else {
  //         this.file
  //           .createDir(this.file.externalRootDirectory, 'Download', false)
  //           .then(() => {
  //             this.copyFile(fileName);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             this.downloadFails(err);
  //           });
  //       }
  //     })
  //     .catch(() => {
  //       this.file
  //         .createDir(this.file.externalRootDirectory, 'Download', false)
  //         .then(() => {
  //           this.copyFile(fileName);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           this.downloadFails(err);
  //         });
  //     });
  // }

  downloadSuccess(message: string) {
    this.notificationsService.notifySuccess(message, 'icon-check-circle');
  }

  downloadFails(reason: any) {
    this.notificationsService.notifyError(
      this.translateService.instant(`Error while downloading the file. (${reason.message})`)
    );
  }

  copyFile(fileName: string) {
    this.storageCacheService.cacheFolderNamePath$.subscribe((cacheFolderNamePath) => {
      this.file
        .copyFile(cacheFolderNamePath, fileName, this.file.externalRootDirectory + '/Download', fileName)
        .then(() => {
          this.translateService.get(`File ${fileName} is added to Download folder.`).subscribe((translated) => {
            this.downloadSuccess(translated);
          });
        })
        .catch((reason) => {
          this.downloadFails(reason);
        });
    });
  }

  /**
   * Download a file from drupal, and if it's in cordova download it to the device
   * in the download folder of the device.
   *
   * @param download
   * @returns
   */
  downloadFile(download: any): Observable<any> {
    return new Observable((observer) => {
      let remoteFileUrl = download.field_media_download_file || download.field_media_document_file_1;
      // allow direct link path downloads
      if (!remoteFileUrl && typeof download === 'string') {
        remoteFileUrl = download;
      }
      if (remoteFileUrl.indexOf('http') === -1) {
        remoteFileUrl = DrupalConstants.backEndUrl + remoteFileUrl;
      }
      const frags = remoteFileUrl.split('/');
      const fileName = frags[frags.length - 1].split('?')[0];

      if (this.deviceDetectService.isCordova) {
        this.getLocalUrl(remoteFileUrl, 'resolveLocalFilesystemUrl').subscribe({
          next: async (fileUrl) => {
            window.resolveLocalFileSystemURL(
              fileUrl,
              async (fileEntry) => {
                if (this.deviceDetectService.isIOS) {
                  this.downloadEntry.getFile(
                    fileEntry.name,
                    { create: false, exclusive: false },
                    (fileEntryExist) => {
                      this.openFileDownloaded(fileEntryExist, observer);
                    },
                    (error) => {
                      if (error.code === FileError.NOT_FOUND_ERR) {
                        this.copyFileEntry(fileEntry, this.downloadEntry, observer, true);
                      } else {
                        observer.error(error);
                      }
                    }
                  );
                } else {
                  try {
                    const uri = await cordova.plugins.safMediastore.existsFile(fileEntry.name);
                    if (uri !== -1) {
                      await cordova.plugins.safMediastore.touchFile({ uri });
                      this.openFileDownloaded(uri, observer);
                    } else {
                      this.copyFileEntry(fileEntry, this.downloadEntry, observer, true);
                    }
                  } catch (error) {
                    console.error(error);
                    observer.error(error);
                  }
                }
              },
              (err) => observer.error(err)
            );
          },
          error: (err) => observer.error(err),
        });
      } else {
        // browser

        // use native client in pdf and images only to reduce the waiting time
        // WEB-3861
        const fileExt = fileName.split('.').pop();
        const clientFormats = ['pdf', 'jpg', 'jpeg', 'png', 'gif'];
        if (clientFormats.indexOf(fileExt) === -1) {
          this.downloadBrowserFromLink(remoteFileUrl, fileName);
          this.zone.run(() => {
            observer.next();
            observer.complete();
          });
          return;
        }
        this.httpNativeClient.get(remoteFileUrl, { responseType: 'blob', withCredentials: true }).subscribe({
          next: (data) => {
            this.downloadBrowserFromLink(window.URL?.createObjectURL(data), fileName);
            this.zone.run(() => {
              observer.next();
              observer.complete();
            });
          },
          error: (err) => observer.error(err),
        });
      }
    });
  }
  public setDownloadEntry() {
    window.resolveLocalFileSystemURL(
      this.storageLocation,
      (dir) => {
        this.downloadEntry = dir;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private downloadBrowserFromLink(link: string, name: string) {
    const element = document.createElement('a');
    element.setAttribute('download', name);
    element.setAttribute('target', '_blank');
    // set the element as hidden
    element.style.display = 'none';
    // append the body
    document.body.appendChild(element);
    // set href attr
    element.href = link;
    // click on it to start downloading
    element.click();
    // remove the link from the dom
    document.body.removeChild(element);
  }

  private openFileDownloaded(fileEntry: FileEntry | string, observer: Subscriber<any>) {
    navigator.notification.confirm(
      this.translateService.instant('Do you want open the file now?'),
      async (buttonNumber) => {
        if (buttonNumber !== 2) {
          this.zone.run(() => {
            observer.complete();
          });
          return;
        }
        if (typeof fileEntry !== 'string') {
          return this.openFileEntry(fileEntry, observer);
        }
        // android opener
        try {
          await cordova.plugins.safMediastore.openFile(fileEntry);
          observer.next();
          observer.complete();
        } catch (err) {
          console.error(err);
          observer.error(err);
        }
      },
      this.translateService.instant('Open file'),
      [this.translateService.instant('No'), this.translateService.instant('Yes')]
    );
  }

  private openFileEntry(fileEntry: FileEntry, observer: Subscriber<any>) {
    (fileEntry as FileEntry).file((res) => {
      cordova.plugins.fileOpener2.showOpenWithDialog(this.storageLocation + '/' + fileEntry.name, res.type, {
        error: (e) => {
          this.zone.run(() => {
            observer.error();
          });
          console.error('Error status: ' + e.status + ' - Error message: ' + e.message);
        },
        success: () => {
          this.zone.run(() => {
            observer.complete();
          });
          console.error('file opened successfully');
        },
      });
    });
  }

  private copyFileEntry(
    fileEntry: FileEntry,
    parentEntry: DirectoryEntry,
    observer: Subscriber<any>,
    openFile = false
  ) {
    fileEntry.file(
      (file) => {
        const reader = new FileReader();
        reader.onerror = () => {
          console.error('error reading source file', reader.error);
          observer.error(reader.error);
        };
        if (this.deviceDetectService.isIOS) {
          reader.onloadend = () => {
            parentEntry.getFile(
              fileEntry.name,
              { create: true, exclusive: false },
              (newFileEntry) => {
                newFileEntry.createWriter((fileWriter) => {
                  fileWriter.onerror = () => {
                    console.error('error writing destination file', fileWriter.error);
                    observer.error(reader.error);
                  };
                  fileWriter.onwriteend = () => {
                    if (openFile) {
                      this.openFileDownloaded(newFileEntry, observer);
                    } else {
                      observer.complete();
                    }
                  };
                  fileWriter.write(reader.result);
                });
              },
              (error) => {
                console.error('error create file', error, parentEntry, fileEntry);
              }
            );
          };

          reader.readAsArrayBuffer(file);
        } else {
          reader.onloadend = () => {
            cordova.plugins.safMediastore
              .writeFile({ data: (reader.result as string).split(',')[1], filename: fileEntry.name })
              .then(
                (result) => this.openFileDownloaded(result, observer),
                (err) => console.error('error', err)
              );
          };
          reader.readAsDataURL(file);
        }
      },
      (err) => {
        console.error('error opening source file', err);
      }
    );
  }
}
