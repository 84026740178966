import { inject } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { ModalManagerInterface } from './modal-manager-interface';

export class ModalServiceModel {
  protected modalRef: NgbModalRef;
  protected modalService: NgbModal = inject(NgbModal);
  protected deviceDetectService: DeviceDetectService = inject(DeviceDetectService);
  protected component: any;
  protected options: NgbModalOptions = { size: 'lg' };

  open(modalManager: ModalManagerInterface, extraData?: any): Observable<any> {
    if (this.deviceDetectService.isMobile && !this.options.windowClass) {
      this.options.windowClass = 'modal-fullscreen';
    }
    this.modalRef = this.modalService.open(this.component, this.options);
    this.modalRef.componentInstance.extraData = extraData;
    this.modalRef.componentInstance.modalRef = this.modalRef;
    this.modalRef.componentInstance.modalManager = modalManager;
    this.modalRef.componentInstance.beforeShow();
    return new Observable((observer) => {
      this.modalRef.result.then(
        (result) => {
          observer.next(result);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }
}
