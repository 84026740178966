import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appNgVar]',
})
export class NgVarDirective {
  context: any = {};

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}
  @Input()
  set appNgVar(context: unknown) {
    this.context.$implicit = this.context.appNgVar = context;
    this.updateView();
  }

  updateView(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
