import { Component, OnInit } from '@angular/core';
import { RolesService } from '@app/core/services/roles/roles.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { ModalManagerService } from '@app/core/services/modal-manager/modal-manager.service';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { ToolsService } from '@app/core/services/tools';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  sidebarActionBtn: { title: string; action: () => void; icon?: string };

  constructor(
    private rolesService: RolesService,
    private modalManagerService: ModalManagerService,
    private navDataService: NavDataService,
    private translateService: TranslateService,
    private deviceDetectService: DeviceDetectService,
    private tools: ToolsService
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    switch (true) {
      case this.rolesService.userHasRole(['school_pending', 'school_suspended']):
        this.setTitleAction('Complete Affiliation', () => {
          if (environment.production) {
            this.navDataService.navigate('https://www.ikointl.com/account/safety-form', true);
            return;
          }
          this.navDataService.navigate('profile/school');
        });
        break;
      case this.rolesService.userHasRole('expired', true):
        this.setTitleAction('Renew', () => {
          if (environment.inAppBrowsing.renew) {
            this.navDataService.navigate('/roles-fees-management', true);
          } else {
            this.modalManagerService.toggle();
          }
        });
        break;
      case this.rolesService.isInstructor:
      case this.rolesService.userHasRole([
        'kiter_free',
        'kiter_basic',
        'assistant_instructor_candidate',
        'assistant_instructor_basic',
        'assistant_instructor_plus',
        'assistant_instructor_premium',
        'school_free',
      ]):
        if (this.deviceDetectService.iosDisplayed) {
          this.setTitleAction(
            'Upgrade',
            () => {
              this.modalManagerService.toggle();
            },
            'icon-chevron-double-up'
          );
        }
        break;
      case this.rolesService.userHasRole(['kiter_plus', 'kiter_premium']):
        this.setTitleAction(
          'Refer a Friend',
          () => {
            this.tools.share(location.href, 'IKO App', '');
          },
          'icon-user-plus'
        );
        break;
      // } else if (this.rolesService.userHasRole(['instructor', 'examiner', 'coach', 'assistant'], true)) {
      case this.rolesService.userHasRole(['school'], true):
        this.setTitleAction(
          'Register a Student',
          () => {
            if (environment.needD7) {
              this.navDataService.navigate('https://www.ikointl.com/account/student-registration-form', true);
            } else {
              this.navDataService.navigate('/students/register');
            }
          },
          'icon-user-plus'
        );
    }
  }

  setTitleAction(titleString: string, actionFunction: () => void, iconString?: string) {
    this.sidebarActionBtn = {
      title: titleString,
      action: actionFunction,
      icon: iconString,
    };
  }
}
