import { Pipe, PipeTransform } from '@angular/core';
import { UrlResolverService } from '@app/core/services/url-resolver/url-resolver.service';

@Pipe({
  name: 'stripeCountryCode',
})
export class StripeCountryCodePipe implements PipeTransform {
  constructor(private urlResolver: UrlResolverService) {}
  transform(url: string): string {
    return this.urlResolver.cleanLanguagePrefix(url);
  }
}
