import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { PaymentComponent } from './payment.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends ModalServiceModel {
  protected component = PaymentComponent;
  protected options = { size: 'md' };
}
