import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { NavDataService } from '../nav-data/nav-data.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuard {
  constructor(private navDataService: NavDataService, private deviceDetectService: DeviceDetectService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // getting the platforms from the route data
    const platforms: string[] = route.data.platforms;
    // init allow with false
    let allow = false;
    // looping over the platforms
    platforms.forEach((platform: string) => {
      // capetalizing first char to match the function name
      const platformCamelCase = `${platform.charAt(0).toUpperCase()}${platform.slice(1)}`;
      // structure the function name
      const detectFunction = `is${platformCamelCase}`;
      // check if the right platform detected
      if (this.deviceDetectService[detectFunction]) {
        allow = true;
      }
    });
    // check if cordova is required
    if (platforms.indexOf('cordova')) {
      allow = allow && this.deviceDetectService.isCordova;
    }
    // if platform is wrong
    if (!allow) {
      // navigate to the home page
      this.navDataService.navigate('/account');
    }
    return allow;
  }
}
