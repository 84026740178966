import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Destination, LatLon, Spot } from '@app/core/models';
import { CenterForAutocomplete, CentersService } from '@app/core/services/centers/centers.service';
import { DestinationsSpotsService } from '@app/core/services/destinations-spots/destinations-spots.service';
import { LocationService } from '@app/core/services/location/location.service';
import { AutoCompletePlaceQueryItem, GoogleMapService } from '@app/core/services/tools/google-map.service';
import { ModalMapFormControlService } from '@app/shared/components/modal-map-form-control/modal-map-form-control.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { ResponsiveFilterMultilocationBestResult } from './responsive-filter-multilocation.best-result';
import {
  ApiResults,
  DropdownViews,
  LocationValue,
  MultiLocationValue,
  MultiLocationValueType,
} from './responsive-filter-multilocation.interfaces';

@Component({
  selector: 'responsive-filter-multilocation',
  templateUrl: './responsive-filter-multilocation.component.html',
  styleUrls: ['./responsive-filter-multilocation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResponsiveFilterMultilocationComponent),
      multi: true,
    },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveFilterMultilocationComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<MultiLocationValue>
{
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: MultiLocationValue;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @Input() extraData?: any;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;

  currentLevelDropdown: DropdownViews = DropdownViews.main;
  dropdownViews = DropdownViews;
  formControl = new FormControl(null);
  apiResults$: BehaviorSubject<ApiResults> = new BehaviorSubject<ApiResults>(null);
  hasError = false;
  searching = false;
  myPosition: LatLon;
  types = MultiLocationValueType;
  protected currentBest = new ResponsiveFilterMultilocationBestResult();
  private currentValue: MultiLocationValue;

  constructor(
    private centersService: CentersService,
    private destinationsSpotsService: DestinationsSpotsService,
    private locationService: LocationService,
    private translateService: TranslateService,
    private modalMapFormService: ModalMapFormControlService,
    private googleMapService: GoogleMapService
  ) {
    this.googleMapService.initGoogleMapsAutoComplete().subscribe();
    this.locationService.getMyLocation().subscribe((position) => {
      this.myPosition = position;
    });
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap((term) => this.searchLocations(term)),
        tap(() => (this.searching = false))
      )
      .subscribe({
        next: (value) => {
          this.handleResult(value);
        },
        error: () => (this.hasError = true),
      });
  }

  get value(): MultiLocationValue | null {
    return this.currentValue;
  }

  set value(val: MultiLocationValue | null) {
    this.currentValue = val;
    this.onChange(val);
    this.onTouched();
    if (val !== null) {
      this.dropDown?.close();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: MultiLocationValue | null) => {};

  onTouched = () => {};

  writeValue(value: MultiLocationValue | null): void {
    this.currentValue = value;
    if (value === null) {
      this.currentBest.reset();
      this.formControl.setValue(null, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    this.formControl.reset();
    this.value = null;
    this.dropDown.close();
    this.currentBest.reset();
  }

  handleFocus() {
    this.dropDown.open();
  }

  selectMyPosition() {
    return () =>
      (this.value = {
        type: MultiLocationValueType.myPosition,
        value: {
          lat: this.myPosition.lat,
          lng: this.myPosition.lon,
          distance: 10,
        },
      });
  }

  selectCenter(center: CenterForAutocomplete): () => void {
    return () =>
      (this.value = {
        type: MultiLocationValueType.center,
        value: center,
      });
  }

  selectDestination(destination: Destination): () => void {
    return () => (this.value = { type: MultiLocationValueType.destination, value: destination });
  }

  selectSpot(spot: Spot): () => void {
    return () => (this.value = { type: MultiLocationValueType.spot, value: spot });
  }

  selectNone(): void {
    this.value = { type: MultiLocationValueType.none, value: this.formControl.value };
  }

  selectMap(): () => void {
    return () => {
      this.modalMapFormService.open(50).subscribe({
        next: (location) => {
          this.value = { type: MultiLocationValueType.location, value: location };
        },
      });
    };
  }
  selectGooglePlace(place: AutoCompletePlaceQueryItem): () => void {
    return () => {
      this.searching = true;
      this.dropDown.close();
      this.googleMapService.getPlaceDetails(place.place_id).subscribe({
        next: (placeDetails) => {
          this.searching = false;
          this.value = {
            type: MultiLocationValueType.googlePlace,
            value: {
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng(),
              formattedAddress: placeDetails.formatted_address,
              description: placeDetails.formatted_address,
              distance: this.googleMapService.calculateRadiusOfVieport(placeDetails.geometry.viewport),
              viewport: placeDetails.geometry.viewport,
            },
          };
        },
        error: () => {
          this.searching = false;
        },
      });
    };
  }

  generateModelValue(item: MultiLocationValue): any {
    switch (true) {
      case item.type === MultiLocationValueType.center:
        const center = item.value as CenterForAutocomplete;
        return {
          primaryText: center.objectValues?.title,
          subhead: this.translateService.instant('multilocation_search_kiteschool', {
            country: center.objectValues?.field_school_address_country_code,
          }),
          logoSchool: center.picture,
          click: this.selectCenter(center),
        };
      case item.type === MultiLocationValueType.googlePlace:
        const place = item.value as AutoCompletePlaceQueryItem;
        return {
          primaryText: place.description,
          subhead: this.translateService.instant('multilocation_search_googleplaces'),
          click: this.selectGooglePlace(place),
          icon: 'icon-map-marker-alt',
        };
      case item.type === MultiLocationValueType.destination:
        const destination = item.value as Destination;
        return {
          primaryText: destination.field_destination_country_country_name,
          subhead: this.translateService.instant('multilocation_search_destination', {
            count: destination.centers_count,
          }),
          click: this.selectDestination(destination),
          countryFlag: destination.field_destination_country_country_code?.toLowerCase(),
        };
      case item.type === MultiLocationValueType.spot:
        const spot = item.value as Spot;
        return {
          primaryText: spot.title,
          subhead: this.translateService.instant('multilocation_search_spot', {
            country: spot.field_destination_country?.country_name,
          }),
          click: this.selectSpot(spot),
          icon: 'icon-wind',
        };
      case item.type === MultiLocationValueType.myPosition:
        return {
          icon: 'icon-location-crosshairs',
          primaryText: this.translateService.instant('Around me'),
          click: this.selectMyPosition(),
        };
      case item.type === MultiLocationValueType.location:
        return {
          icon: 'icon-map',
          primaryText:
            (this.value?.value as LocationValue)?.formattedAddress ??
            this.translateService.instant('Search in the map'),
          click: this.selectMap(),
        };
      case item.type === MultiLocationValueType.none:
        return {
          icon: 'icon-world_map',
          primaryText: '&nbsp;' + item.value,
        };
    }
  }

  protected handleEnter($event: KeyboardEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.currentBest.currentBest$.value === null) {
      this.selectNone();
      this.dropDown.close();
      return;
    }
    switch (this.currentBest.currentBest$.value.type) {
      case MultiLocationValueType.center:
        this.selectCenter(this.currentBest.currentBest$.value.value as CenterForAutocomplete)();
        break;
      case MultiLocationValueType.destination:
        this.selectDestination(this.currentBest.currentBest$.value.value as Destination)();
        break;
      case MultiLocationValueType.spot:
        this.selectSpot(this.currentBest.currentBest$.value.value as Spot)();
        break;
      case MultiLocationValueType.googlePlace:
        this.selectGooglePlace(this.currentBest.currentBest$.value.value as AutoCompletePlaceQueryItem)();
        break;
    }
  }

  private searchLocations(value: string) {
    if (!value) {
      this.dropDown.close();
      return of([]);
    }
    return forkJoin([
      this.centersService.getCentersForAutoCompleteByText(value),
      this.destinationsSpotsService.getDestinations(value),
      this.destinationsSpotsService.getSpots(value).pipe(map((spots) => spots.filter((spot) => spot.field_spot_gps))),
      this.googleMapService.suggestionPlaces(value),
    ]);
  }

  private handleResult(values: any[]) {
    this.dropDown.open();
    this.apiResults$.next({
      centers: values[0] ?? [],
      destinations: values[1] ?? [],
      spots: values[2] ?? [],
      googlePlaces: values[3] ?? [],
    });
    this.currentBest.check(this.apiResults$.value, this.formControl.value);
  }
}
