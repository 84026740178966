import { Injectable } from '@angular/core';
import { RequestHomeAddressComponent } from './request-home-address.component';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';

@Injectable({
  providedIn: 'root',
})
export class RequestHomeAddressService extends ModalServiceModel {
  protected component = RequestHomeAddressComponent;
}
