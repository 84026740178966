<div class="modal-header p-3 position-relative">
  <h3 class="mt-0 modal-title text-center mx-auto" id="modal-confirm-title" *ngIf="extraData?.title">
    <span class="d-inline-block ms-1" translate [innerHTML]="extraData?.title"></span>
  </h3>
  <button class="btn-close btn-red" (click)="close()"></button>
</div>
<div class="modal-body pt-1 text-center">
  <i
    class="icon {{ extraData.icon }} {{ extraData.iconSize ?? 'icon-32' }} {{ extraData.iconColor }} mb-2"
    *ngIf="extraData.icon"
  ></i>
  <div class="{{ extraData.classMessage }}" translate [innerHTML]="extraData.message"></div>
  <div class="d-flex justify-content-center mt-4">
    <button class="btn btn-success rounded-1" (click)="close()" translate>Ok</button>
  </div>
</div>
