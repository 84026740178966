import { AutocompleteInputItem } from '@app/shared/forms/autocomplete-input/autocomplete-input-item';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { map, Observable } from 'rxjs';

export class ProviderDynamicAutoCompleteInstructors implements AutoCompleteInputProvider {
  showRoundedThumbnails?: boolean;
  showThumbnails?: boolean;
  noThumbnailsTemplate?: boolean;
  private viewService: ViewService;
  private endpoint: string;

  constructor(viewService: ViewService, formName: string) {
    this.viewService = viewService;
    this.endpoint = this.getEndpoint(formName);
  }

  filter(term: string): Observable<AutocompleteInputItem[]> {
    return this.viewService
      .get(this.endpoint, {
        filters: { search: term },
        pagination: { items_per_page: 100 },
      })
      .pipe(
        map((result) =>
          result
            .map((member) => ({
              value: member.uid,
              label: member.field_first_name + ' ' + member.field_surname,
              picture: member.user_picture_multiple_json?.portrait_md,
            }))
            .sort((instructorA, instructorB) => instructorA.label.localeCompare(instructorB.label))
        )
      );
  }

  private getEndpoint(formName: string): string {
    switch (true) {
      case formName.indexOf('certification') !== -1:
        this.showThumbnails = true;
        this.showRoundedThumbnails = true;
        return '/api/v1/certification-request/instructors';
      case formName.indexOf('school_affiliation_form') !== -1:
        return '/api/v1/school-affiliation/instructors';
      default:
        return '/api/v1/users_can_certify.json';
    }
  }
}
