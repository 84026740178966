import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ConfirmModalResult } from '@app/core/services/modal-manager';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends ModalComponent {
  /**
   * Handle the event 'closed by user';
   */
  closedByUser(): void {
    this.close(ConfirmModalResult.closedByUser);
  }

  /**
   * Handle the 'no' choice of the user;
   */
  reject(): void {
    this.close(ConfirmModalResult.no);
  }

  /**
   * Handle the 'Yes' choice of the user;
   */
  confirm(): void {
    this.close(ConfirmModalResult.yes);
  }
}
