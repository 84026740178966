<div class="d-flex flex-column flex-grow-1 overflow-scroll text-center">
  <div class="d-flex flex-grow-1 flex-column justify-content-center">
    <div class="modal-header pt-1 mb-1">
      <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
        <span class="d-inline-block align-middle ms-1" translate></span>
      </h3>
      <button class="btn-close btn-red" [disabled]="isUpdating" (click)="dismissLocal()"></button>
    </div>
    <div class="modal-body text-center" translate>
      <div class="d-flex justify-content-center align-items-center">
        <div class="iti-flag" [ngClass]="i18nService.getSupportedLanguage(i18nService.language).flag"></div>
        <div class="mx-2">&rarr;</div>
        <div *ngIf="suggestedLanguage" class="iti-flag" [ngClass]="suggestedLanguage.obj.flag"></div>
      </div>
      <div translate>Do you want to change the language?</div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-info rounded-1" [disabled]="isUpdating" (click)="dismissLocal()" translate>No</button>
        <button class="btn btn-success rounded-1" [disabled]="isUpdating" (click)="updateLanguage()">
          <span translate *ngIf="!isUpdating; else loader">Yes</span>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loader>
  <circular-spinner></circular-spinner>
</ng-template>
