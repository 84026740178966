import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import {
  NgbAccordionModule,
  NgbActiveModal,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { WebcamModule } from 'ngx-webcam';
import { SwiperModule } from 'swiper/angular';
import {
  BadgesSkillListComponent,
  BottomNavbarComponent,
  CaptchaComponent,
  CkeditorComponent,
  CurrencySelectComponent,
  CurrencySelectorBarComponent,
  ErrorContentComponent,
  ErrorMessageComponent,
  FooterComponent,
  GapFixerIphoneComponent,
  InfoPointComponent,
  LabelInfoPointComponent,
  CircularCheckIconComponent,
  ModalMapFormControlComponent,
  LanguageSelectComponent,
  LikeButtonComponent,
  LoaderWithWordsComponent,
  MultiselectCheckboxComponent,
  NotificationBarComponent,
  PreferredLanguageComponent,
  SliderPreviewComponent,
  TemperatureUnitMeasurePickerComponent,
  TrainingCalendarSearchComponent,
  CookieConsentWarningComponent,
  StudentDetailsComponent,
  ImageMagnifierComponent,
  MapIconLoaderComponent,
} from './components';
import { FileUploadPickerCropperModule } from './components/file-upload-picker-cropper';
import { LoaderModule } from './components/loader/loader.module';

import {
  AccordionMultiselectForFiltersComponent,
  AutocompleteInputComponent,
  CenterSelectorComponent,
  CoachSelectorComponent,
  CountrySelectorComponent,
  NgxIntlTelInputComponent,
  ResponsiveSearchFiltersComponent,
  DistanceFromMapSelectorComponent,
  CourseTypeSelectorComponent,
  MaxPriceSelectorComponent,
  ResponsiveFilterCenterSelectorComponent,
  AutocompleteForFiltersComponent,
  ResponsiveFilterCoachSelectorComponent,
  ResponsiveFilterMultilocationComponent,
  ResponsiveDistanceFromMapSelectorComponent,
  ChipFiltersComponent,
  ResponsiveFilterFreesearchComponent,
} from './forms';
import { JwBootstrapSwitchNg2Module } from '@servoy/jw-bootstrap-switch-ng2';
import { CourseTemplateSelectorComponent } from './forms/course-template-selector/course-template-selector.component';
import { RoundedMultiselectForFiltersComponent } from './forms';

import { CircularSpinnerComponent } from './components/circular-spinner/circular-spinner.component';
import { ImageSrcsetComponent } from './components/image-srcset/image-srcset.component';
import { DirectivesModule } from './directives/directives.module';

const components = [
  NotificationBarComponent,
  NgxIntlTelInputComponent,
  PreferredLanguageComponent,
  FooterComponent,
  SliderPreviewComponent,
  BottomNavbarComponent,
  CaptchaComponent,
  CurrencySelectComponent,
  CkeditorComponent,
  TrainingCalendarSearchComponent,
  LoaderWithWordsComponent,
  ErrorContentComponent,
  BadgesSkillListComponent,
  ErrorMessageComponent,
  TemperatureUnitMeasurePickerComponent,
  MultiselectCheckboxComponent,
  CoachSelectorComponent,
  CenterSelectorComponent,
  GapFixerIphoneComponent,
  AutocompleteInputComponent,
  LikeButtonComponent,
  CountrySelectorComponent,
  RoundedMultiselectForFiltersComponent,
  ResponsiveSearchFiltersComponent,
  AccordionMultiselectForFiltersComponent,
  InfoPointComponent,
  LabelInfoPointComponent,
  CurrencySelectorBarComponent,
  DistanceFromMapSelectorComponent,
  CourseTypeSelectorComponent,
  CourseTemplateSelectorComponent,
  MaxPriceSelectorComponent,
  ResponsiveFilterCenterSelectorComponent,
  AutocompleteForFiltersComponent,
  ResponsiveFilterCoachSelectorComponent,
  CircularCheckIconComponent,
  ResponsiveFilterMultilocationComponent,
  ResponsiveDistanceFromMapSelectorComponent,
  ModalMapFormControlComponent,
  InfoPointComponent,
  CookieConsentWarningComponent,
  ChipFiltersComponent,
  ResponsiveFilterFreesearchComponent,
  StudentDetailsComponent,
  ImageMagnifierComponent,
  MapIconLoaderComponent,
];

const componentsStandalone = [CircularSpinnerComponent, ImageSrcsetComponent, LanguageSelectComponent];

@NgModule({
  imports: [
    CommonModule,
    LoaderModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbModalModule,
    SwiperModule,
    NgbDropdownModule,
    NgbModule,
    NgxCaptchaModule,
    CKEditorModule,
    TranslateModule,
    ScrollToModule,
    ImageCropperModule,
    WebcamModule,
    NgbTooltipModule,
    RouterModule,
    LocalizeRouterModule,
    NgxIntlTelInputModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbAccordionModule,
    NgbModalModule,
    GoogleMapsModule,
    NouisliderModule,
    JwBootstrapSwitchNg2Module,
    DirectivesModule,
    NgOptimizedImage,
    ...componentsStandalone,
  ],
  declarations: components,
  exports: [
    NgbDropdownModule,
    ScrollToModule,
    TranslateModule,
    ...components,
    ...componentsStandalone,
    FileUploadPickerCropperModule,
    LoaderModule,
  ],
  providers: [NgbActiveModal],
})
export class SharedModule {}
