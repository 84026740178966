import { Component, inject } from '@angular/core';
import { slideTopBottom } from '@app/core/animations';

import { NotificationsService } from '@app/core/services/notifications/notifications.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  animations: [slideTopBottom],
})
export class NotificationBarComponent {
  protected notificationsService: NotificationsService = inject(NotificationsService);
  protected notification = this.notificationsService.currentNotify;

  /**
   * Handle the close button click event.
   */
  close() {
    this.notificationsService.close();
  }
}
