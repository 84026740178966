<div class="row">
  <div class="col-6">
    <div class="form-group">
      <ng-template #whereTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
      </ng-template>
      <!-- <input type="text" class="form-control" [ngbTypeahead]="whereSearch" placeholder="{{ 'Where' | translate }}"
        [resultTemplate]="whereTemplate" [inputFormatter]="nodeFormatter" (selectItem)="selectWhere($event.item)" /> -->
    </div>
  </div>

  <div class="col-6">
    <div class="form-group">
      <div ngbDropdown class="dropdown dropdown-icon-right">
        <button
          class="w-100 btn btn-simple border bg-white btn-round dropdown-toggle m-0 text-start border-light-gray"
          ngbDropdownToggle
        >
          <span translate *ngIf="!selectedCourse">- Which course -</span>
          <span translate *ngIf="selectedCourse">{{ selectedCourse.title }}</span>
        </button>
        <div ngbDropdownMenu>
          <a class="dropdown-item" (click)="selectedCourse = course" *ngFor="let course of courses">
            <span class="me-2"><i class="text-info icon icon-16 icon-mars"></i></span>
            <span>{{ course.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
