import { Injectable, inject } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { SegmentationFormComponent } from './segmentation-form.component';
import { DeviceDetectService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class SegmentationFormService extends ModalServiceModel {
  protected deviceDetectService: DeviceDetectService = inject(DeviceDetectService);
  protected component = SegmentationFormComponent;
  protected options = {
    size: 'lg',
    // windowClass: this.deviceDetectService.isMobile ? 'modal-fullscreen' : 'responsive-height',
  };
}
