import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NavDataService } from '../nav-data/nav-data.service';
import { ModalManagerComponentResolverService } from './modal-manager-component-resolver.service';
import { ConfirmModalResult, ExtraOptionsConfirmModal, ModalManagerInterface } from './modal-manager-interface';
import { ModalTypes } from './modal-types.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalManagerService implements ModalManagerInterface {
  constructor(
    private modalService: NgbModal,
    private navDataService: NavDataService,
    private resolver: ModalManagerComponentResolverService
  ) {}

  /**
   * @inheritdoc
   */
  dismissAll() {
    this.navDataService.sideMenuOpen = false;
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

  /**
   * @inheritdoc
   */
  openConfirmModal(title: string, message: string, options?: ExtraOptionsConfirmModal): Observable<ConfirmModalResult> {
    return this.open(ModalTypes.confirm, {
      ...{
        title,
        message,
      },
      ...options,
    });
  }

  /**
   * @inheritdoc
   */
  open(type: ModalTypes = ModalTypes.subscriptions, extraData?: any): Observable<any> {
    return this.resolver.resolve(type).open(this, extraData);
  }

  /**
   * @inheritdoc
   */
  toggle(type?: ModalTypes, extraData?: any): Observable<any> {
    if (this.modalService.hasOpenModals()) {
      this.dismissAll();
      return;
    }
    return this.open(type, extraData);
  }
}
