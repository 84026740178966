<div *ngIf="userProfile$ | async as userProfile" class="bg-light-transparent-2 rounded px-2 py-1">
  <div class="row text-white justify-content-center px-3">
    <div class="col-4 p-0 align-self-center">
      <image-srcset
        [imgClass]="isSchool ? 'rounded' : 'rounded-circle'"
        [item]="userPicture"
        (click)="navDataService.navigate('/profile')"
        role="button"
        [alt]="userProfile.field_first_name + 'profile image'"
        [onErrorImage]="'/assets/images/blank-profile.png'"
      >
      </image-srcset>
    </div>
    <div class="col-8 pe-0 small ps-2 d-flex flex-column">
      <div
        class="big"
        *ngIf="!isSchool; else displayTitle"
        [innerHtml]="userProfile.field_first_name + ' ' + userProfile.field_surname"
      ></div>
      <ng-template #displayTitle>
        <div class="big" *ngIf="userProfile.schoolName" [innerHtml]="userProfile.schoolName"></div>
      </ng-template>
      <div>#{{ userProfile.uid }}</div>

      <div class="row">
        <div class="col-9 pe-0">
          <div class="progress-container">
            <div class="progress mt-2">
              <div
                class="green-bg-gradient"
                role="progressbar"
                [ngStyle]="{ width: userProfile.profile_completeness }"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div class="col-3 ps-1">
          {{ userProfile.profile_completeness }}
        </div>
      </div>

      <div class="h-100 d-flex text-center justify-content-center align-items-end">
        <button class="btn btn-success btn-sm rounded-2 m-1" (click)="navigateAndCloseMenu()">
          <small *ngIf="userProfile.profile_completeness !== '100%'" translate> Complete profile </small>
          <small *ngIf="userProfile.profile_completeness === '100%'" translate> View Public Page </small>
        </button>
      </div>
    </div>
  </div>
</div>
