<div class="px-3 py-2 bg-primary hide-desktop" appBottomSafeArea>
  <div class="row">
    <div class="col" (click)="navDataService.navigate('/centers')">
      <i class="icon icon-16 icon-store-alt"></i>
      <div translate>Centers</div>
    </div>
    <div class="col" (click)="navDataService.navigate('/members')">
      <i class="icon icon-16 icon-users"></i>
      <div translate>Members</div>
    </div>
    <div class="col" (click)="navDataService.navigate('/destinations')">
      <i class="icon icon-16 icon-globe-americas"></i>
      <div translate>Spots</div>
    </div>
    <div class="col" (click)="navDataService.navigate('/training-calendar')">
      <i class="icon icon-16 icon-calendar-alt"></i>
      <div translate>Courses</div>
    </div>
  </div>
</div>
