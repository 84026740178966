// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  needD7: false,
  betaUrl: 'beta.ikointl.com',
  serverUrl: 'd8.ikointl.com',
  server7Url: 'www.ikointl.com',
  currentWebUrl: 'https://beta.ikointl.com',
  defaultLanguage: 'en',
  stripeKey: 'pk_test_CzFZzgZ4A9hmhKESQ5HBCF41',
  googleMapsKey: 'AIzaSyCdGIkSOHfMd8tSdU6ZZRw42XjKoJB2PiM',
  googleMapId: 'a4822d073556f291',
  googleTagManagerId: 'GTM-KB4CDQ3',
  googleAnalyticId: 'G-F30LK1VP1R',
  paypalClientId: 'AeUy35vgwdH7tDCUFU_doAYbEI0o1HSi5BghxFpOWkw2Hurx_2HyZOKo3BzYYvQul9i1OR0wuHoxLfVT',
  crispChatId: '17e72c28-5457-4172-8dff-841699c961e3',
  captchaKey: '6Lf02NEUAAAAAHsHk5l-74g9S2t7QqSHI38wLfJO',
  hostname: 'localhost',
  linkGFormAddSpot:
    'https://docs.google.com/forms/d/e/1FAIpQLSdiFubnOx8DtQ8zz_E3FfX3M1z-0l2U-yozzLZCvsZuFK2Gig/viewform',
  inAppBrowsing: {
    course: false,
    quality: true,
    renew: false,
    messages: false,
    bookings: false,
    userBookings: false,
    students: {
      kiter: false,
      assistant: false,
      instructor: false,
      coach: false,
    },
    certifications: {
      request: false,
    },
  },
  mainPage: {
    cordova: '/welcome',
    ssr: '/gutenberg/306',
    login: '/user/login',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
