import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { HelpModalComponent } from './help-modal.component';

@Injectable({
  providedIn: 'root',
})
export class HelpModalService extends ModalServiceModel {
  protected component = HelpModalComponent;
  protected options = { size: 'sm', windowClass: 'd-flex modal-dialog-centered' };
}
