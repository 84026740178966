<div class="modal-header pt-1 mb-1 position-relative">
  <h3 class="mt-0 modal-title text-center mx-auto" id="modal-confirm-title">
    <span
      class="d-inline-block ms-1"
      translate
      [innerHTML]="extraData?.title ?? 'Select a place in the map' | translate"
    ></span>
  </h3>
  <button class="btn-close btn-red" (click)="reject()"></button>
</div>
<div class="modal-body text-center">
  <distance-from-map-selector
    class="w-100"
    [formControl]="currentPosition"
    [showDistanceSelector]="!extraData?.hideDistanceSelector"
    [getMyPosition]="!extraData?.notGetMyPosition"
  ></distance-from-map-selector>
  <div class="d-flex justify-content-center mt-4">
    <button class="btn btn-info rounded-1" (click)="reject()" translate>Cancel</button>
    <button class="btn btn-success rounded-1" (click)="confirm()" translate>Confirm</button>
  </div>
</div>
