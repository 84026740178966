import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'label-info-point',
  templateUrl: './label-info-point.component.html',
  styleUrls: ['./label-info-point.component.scss'],
})
export class LabelInfoPointComponent {
  @Input() label: string;
  @Input() textInfo: string;
  @Input() template: TemplateRef<any>;
}
