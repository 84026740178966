import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WebcamModule } from 'ngx-webcam';
import { FileUploadPickerCropperComponent } from './file-upload-picker-cropper.component';
import { NgxFilesizeModule } from 'ngx-filesize';
@NgModule({
  declarations: [FileUploadPickerCropperComponent],
  imports: [CommonModule, TranslateModule, WebcamModule, NgxFilesizeModule],
  exports: [FileUploadPickerCropperComponent],
})
export class FileUploadPickerCropperModule {}
