import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const flyInOutLR = trigger('flyInOutLR', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0, transform: 'translateX(-40px)' })], { optional: true }),
    query(':enter', stagger('2ms', [animate('800ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))]), {
      optional: true,
    }),
  ]),
  transition(':leave', [animate('.5s ease-in-out', style({ right: '0' }))]),
]);
