import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CommonService } from '@app/core/services/common/common.service';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';

import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { ModalManagerService } from '@app/core/services/modal-manager/modal-manager.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { GoogleMapService } from '@app/core/services/tools';
import { environment } from '@env/environment';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-help-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent extends ModalComponent {
  firstShiftStart: string = this.commonService.convertDateToCurrentTimeZone('2023-08-11 13:30:00');
  firstShiftEnd: string = this.commonService.convertDateToCurrentTimeZone('2023-08-11 18:30:00');
  secondShiftStart: string = this.commonService.convertDateToCurrentTimeZone('2023-08-11 19:30:00');
  secondShiftEnd: string = this.commonService.convertDateToCurrentTimeZone('2023-08-11 23:30:00');

  constructor(
    private commonService: CommonService,
    private ngbModal: NgbModal,
    private googleMap: GoogleMapService,
    public deviceDetectService: DeviceDetectService,
    public callNumber: CallNumber,
    private navDataService: NavDataService,
    public modalManagerService: ModalManagerService
  ) {
    super();
    this.initTimeZone();
  }

  initTimeZone() {}

  openLauncher() {
    // this.googleMap.openExternalMap(19.74991244616918, -70.41586999920565, 'IKO International');
    this.googleMap.openExternalMap(
      'IKO - International Kiteboarding Organization Cabarete',
      10,
      19.7501516,
      -70.4139484
    );
  }

  openCall() {
    const phoneNumber = '+18095719530';
    if (this.deviceDetectService.isCordova) {
      this.callNumber.callNumber(phoneNumber, true);
      return;
    }
    this.navDataService.navigate(`tel:${phoneNumber}`, true);
  }

  navigateToMessages() {
    this.navDataService.navigate('/messages', environment.needD7);
    this.ngbModal.dismissAll();
  }
}
