import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { ResponsiveImageResource } from '@app/core/models';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { ToolsService } from '@app/core/services/tools';
import { AutocompleteForFiltersItem } from '@app/shared/forms/autocomplete-for-filters/autocomplete-for-filters-item.interface';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { StudentDetails } from './student-details.interface';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';

interface StudentItem {
  uid: string;
  name: string;
  field_first_name: string;
  field_surname: string;
  user_picture_multiple_json: ResponsiveImageResource;
  field_address_country_code: string;
  mail: string;
}

@Component({
  selector: 'certification-form-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss'],
})
export class StudentDetailsComponent implements OnInit {
  @Input() userDetails: StudentDetails = {};
  @Input() displayCountry = true;
  @Input() title = 'Student details';
  @Input() showSearch = true;
  @Output() userDetailsChange: EventEmitter<StudentDetails> = new EventEmitter<StudentDetails>();

  resultStudents$: BehaviorSubject<StudentItem[]> = new BehaviorSubject<StudentItem[]>([]);
  userDetailForm: FormGroup;
  creatingNew = true;
  loadingStudent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchTerm$ = new Subject<string>();
  curentStudentItem: StudentItem;
  isCheckingEmail = false;
  protected focusOnFullname;
  private studentSearcher: NgbDropdown;
  private checkingEmailSubscribe;
  private tools: ToolsService = inject(ToolsService);
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public i18nService: I18nService,
    public toolsService: ToolsService,
    private viewService: ViewService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.initSearchUser();
  }

  @ViewChild('studentSearcher', { read: NgbDropdown }) set studentSearcherBox(searcher: NgbDropdown) {
    this.studentSearcher = searcher;
  }

  getEntitiesHtml(value: any) {
    return this.toolsService.encodeHTMLEntities(value);
  }

  ngOnInit() {
    this.buildForm();
    const uid = this.route.snapshot.queryParamMap.get('uid');
    if (uid) {
      this.searchStudentForTerm(uid).subscribe((data) => {
        const student = data.find((u) => u.uid === uid);
        this.setCreatingNew(false);
        this.setStudent(student);
      });
    }
  }

  buildForm() {
    this.userDetailForm = this.formBuilder.group({
      student_id: [],
      fullName: [],
      student_firstname: [''],
      student_surname: [''],
      student_email: [''],
      student_country: [],
      preferred_language: [],
    });
    this.setCreatingNew(true);
    this.userDetailForm.valueChanges.subscribe(() => {
      if (this.creatingNew) {
        this.emitNewValue();
      }
    });
    this.userDetailForm.get('student_email').valueChanges.subscribe((value) => {
      if (!this.creatingNew) {
        return;
      }
      if (this.checkingEmailSubscribe) {
        this.checkingEmailSubscribe.unsubscribe();
        this.isCheckingEmail = false;
      }
      if (!this.userDetailForm.get('student_email').valid) {
        return;
      }
      this.isCheckingEmail = true;
      this.checkingEmailSubscribe = this.checkAlreadyCertifiedStudent(value)
        .pipe(
          mergeMap(() =>
            this.viewService.get('/api/v1/student-registeration/users-by-email', { filters: { mail: value } })
          )
        )
        .subscribe({
          next: (data) => {
            if (data.length) {
              this.userDetailForm.get('fullName').setValue(this.converStudentToItemFilter(data[0]));
              this.setCreatingNew(false);
            }
            this.isCheckingEmail = false;
          },
          error: () => {
            this.isCheckingEmail = false;
            this.userDetailForm.get('student_email').reset();
          },
        });
    });
    this.userDetailForm.get('fullName').valueChanges.subscribe((value) => {
      this.setStudent(value?.objectValues, false);
      if (value?.objectValues) {
        this.checkAlreadyCertifiedStudent(value.objectValues.mail).subscribe({
          error: () => {
            this.userDetailForm.get('fullName').reset();
          },
        });
      }
    });
  }

  setCreatingNew(creatingNew: boolean): void {
    this.creatingNew = creatingNew;
    if (this.creatingNew) {
      this.userDetailForm.get('fullName').clearValidators();
      this.userDetailForm.get('student_firstname').addValidators(Validators.required);
      this.userDetailForm.get('student_surname').addValidators(Validators.required);
      this.userDetailForm.get('student_email').addValidators([Validators.required, CustomValidators.email]);
      this.userDetailForm.get('student_country').addValidators(Validators.required);
      this.userDetailForm.get('preferred_language').addValidators(Validators.required);
    } else {
      this.userDetailForm.get('fullName').addValidators(Validators.required);
      this.userDetailForm.get('student_firstname').clearValidators();
      this.userDetailForm.get('student_surname').clearValidators();
      this.userDetailForm.get('student_email').clearValidators();
      this.userDetailForm.get('student_country').clearValidators();
      this.userDetailForm.get('preferred_language').clearValidators();
    }
  }

  validate(): boolean {
    this.toolsService.markFormFieldsDirty(this.userDetailForm);
    this.emitNewValue();
    return this.userDetailForm.valid;
  }

  setLanguage(language: string) {
    const control = this.userDetailForm.get('preferred_language');
    control.setValue(language);
  }

  protected searchStudents = (searchedText: string): Observable<AutocompleteForFiltersItem[]> =>
    this.searchStudentForTerm(searchedText).pipe(
      map((students) => students.map((student) => this.converStudentToItemFilter(student)))
    );
  /**
   * Set the current student from a existing one.
   *
   * @param student
   *   Student to set.
   * @param checkCertified
   */
  protected setStudent(student?: StudentItem, validate = true) {
    if (!student && !this.creatingNew) {
      this.clearStudent();
    } else {
      this.curentStudentItem = student;
      this.userDetailForm.get('student_id').setValue(student.uid);
      this.userDetailForm.get('student_firstname').setValue(student.field_first_name);
      this.userDetailForm.get('student_surname').setValue(student.field_surname);
      this.userDetailForm.get('student_email').setValue(student.mail);
      this.userDetailForm.get('student_country').setValue(student.field_address_country_code);
    }
    if (validate) {
      this.validate();
    }
  }

  /**
   * Check if the student is already certified with that email.
   *
   * @param email
   *  Email to check if is already certified.
   * @returns
   */
  private checkAlreadyCertifiedStudent(email: string) {
    return this.viewService
      .get('/api/v1/instructor-certifications.json?_format=json', {
        filters: { student: email },
      })
      .pipe(
        tap((certifications) => {
          if (certifications.length) {
            this.notificationsService.warningModal(
              this.i18nService.translateService.instant('student_already_certified', { email })
            );
            throw new Error('Already certified');
          }
        })
      );
  }

  private searchStudentForTerm(term: string): Observable<any[]> {
    return this.viewService.get('/api/v1/student-registeration/users', {
      filters: { search: term },
      pagination: { items_per_page: 50, page: 0 },
    });
  }

  /**
   * Emit new values of the form.
   */
  private emitNewValue(): void {
    const values = { ...this.userDetailForm.value };
    if (this.creatingNew) {
      delete values.student_id;
      delete values.fullName;
      return this.userDetailsChange.emit(values);
    }
    if (values.fullName) {
      values.fullName =
        values.fullName?.originalObject?.field_first_name + ' ' + values.fullName?.originalObject?.field_surname;
    }
    return this.userDetailsChange.emit(values);
  }

  /**
   * Clear the student values and set the current student to null.
   */
  private clearStudent() {
    this.curentStudentItem = null;
    this.userDetailForm.get('student_id').reset();
    this.userDetailForm.get('student_firstname').reset();
    this.userDetailForm.get('student_surname').reset();
    this.userDetailForm.get('student_email').reset();
    this.userDetailForm.get('student_country').reset();
  }

  /**
   * Init search user.
   */
  private initSearchUser() {
    this.searchTerm$
      .asObservable()
      .pipe(
        tap((value) => {
          this.curentStudentItem = null;
          this.loadingStudent$.next(true);
          if (value !== '') {
            this.studentSearcher.open();
          } else {
            this.studentSearcher.close();
          }
        }),
        debounceTime(400),
        switchMap((term) => this.searchStudentForTerm(term)),
        tap(() => this.loadingStudent$.next(false))
      )
      .subscribe((list) => {
        this.resultStudents$.next(list);
      });
  }

  private converStudentToItemFilter(student: StudentItem): AutocompleteForFiltersItem {
    return {
      id: student.uid,
      label: student.field_first_name
        ? `${this.tools.encodeHTMLEntities(
            this.tools.encodeHTMLEntities(student.field_first_name)
          )} ${this.tools.encodeHTMLEntities(this.tools.encodeHTMLEntities(student.field_surname))}`
        : `✉️ ${student.mail}`,
      objectValues: student,
      picture: student.user_picture_multiple_json.square_sd,
    };
  }
}
