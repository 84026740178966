<h1 class="mt-0 d-none d-lg-block" translate>{{ title }}</h1>
<form class="row" [formGroup]="userDetailForm">
  <div class="col-12" *ngIf="!creatingNew">
    <div class="form-group mb-0">
      <label-info-point [textInfo]="'Full name  or email of the student' | translate" translate
        >Full Name or Email</label-info-point
      >
      <autocomplete-for-filters
        (focused)="focusOnFullname = true"
        (blurred)="focusOnFullname = false; validate()"
        [showImageItem]="true"
        [roundedImage]="true"
        [isInModal]="true"
        formControlName="fullName"
        [searchValues]="searchStudents"
        [imagePlaceholder]="imagePlaceholder"
        [placeHolder]="'Search for a student name or email...' | translate"
        [isInvalid]="toolsService.fieldIsInvalid(userDetailForm, 'fullName') && !focusOnFullname"
      >
      </autocomplete-for-filters>
      <div
        class="small text-danger ms-4"
        *ngIf="toolsService.fieldHasError(userDetailForm, 'fullName', 'required') && !focusOnFullname"
        translate
      >
        the field is required
      </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="!creatingNew">
      <a (click)="setCreatingNew(true)" class="text-success d-flex justify-content-end align-items-center">
        <i class="icon icon-user-plus me-1"></i>
        <span translate>Create new student</span>
      </a>
    </div>
  </div>
  <div *ngIf="creatingNew && showSearch" class="d-flex justify-content-end">
    <a (click)="setCreatingNew(false)" class="text-success d-flex align-items-center">
      <i class="icon icon-search-2 me-1"></i>
      <span translate>Select from a registered student</span>
    </a>
  </div>
  <ng-container *ngIf="creatingNew">
    <div class="col-12 mb-2">
      <div class="form-group mb-0">
        <label-info-point
          [textInfo]="
            'Email of the student will be used for IKO registration and as a username for the student to access the platform'
              | translate
          "
          translate
          >Email</label-info-point
        >
        <div
          class="input-group"
          [ngClass]="{
            'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_email')
          }"
        >
          <div class="input-group-text"><i class="icon icon-envelope"></i></div>
          <input
            type="email"
            class="form-control border-start-0"
            placeholder="johnsmith@example.com"
            formControlName="student_email"
            [ngClass]="{
              'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_email'),
              'border-end-0 rounded-0': isCheckingEmail
            }"
          />
          <span class="input-group-text suffix-icon" *ngIf="isCheckingEmail">
            <circular-spinner iconClass="icon-16 text-success" class="small-circular"></circular-spinner>
          </span>
        </div>
        <div class="small ms-4" *ngIf="isCheckingEmail" translate>checking if user exists...</div>
        <div
          class="small text-danger ms-4"
          *ngIf="toolsService.fieldHasError(userDetailForm, 'student_email', 'required')"
          translate
        >
          the field is required
        </div>
        <div
          class="small text-danger ms-4"
          *ngIf="toolsService.fieldHasError(userDetailForm, 'student_email', 'email')"
          translate
        >
          email not valid
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-2">
      <div class="form-group mb-0">
        <label-info-point [textInfo]="'First names of the student' | translate" translate>First Name</label-info-point>
        <div
          class="input-group"
          [ngClass]="{
            'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_firstname')
          }"
        >
          <div class="input-group-text"><i class="icon icon-user"></i></div>
          <input
            type="text"
            class="form-control"
            placeholder="John"
            [ngClass]="{
              'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_firstname')
            }"
            formControlName="student_firstname"
          />
        </div>
      </div>
      <div
        class="small text-danger ms-4"
        *ngIf="toolsService.fieldHasError(userDetailForm, 'student_firstname', 'required')"
        translate
      >
        the field is required
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-2">
      <div class="form-group mb-0">
        <label-info-point [textInfo]="'Last name of the student' | translate" translate>Last Name</label-info-point>
        <div
          class="input-group"
          [ngClass]="{
            'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_surname')
          }"
        >
          <div class="input-group-text"><i class="icon icon-user"></i></div>
          <input
            type="email"
            class="form-control"
            placeholder="Smith"
            formControlName="student_surname"
            [ngClass]="{
              'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_surname')
            }"
          />
        </div>
      </div>
      <div
        class="small text-danger ms-4"
        *ngIf="toolsService.fieldHasError(userDetailForm, 'student_surname', 'required')"
        translate
      >
        the field is required
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-2" *ngIf="displayCountry">
      <div
        class="form-group"
        [ngClass]="{
          'is-invalid': toolsService.fieldIsInvalid(userDetailForm, 'student_country')
        }"
      >
        <label-info-point [textInfo]="'Nationality of the student' | translate" translate>Nationality</label-info-point>
        <country-selector
          formControlName="student_country"
          iconPrepend="icon-globe-americas"
          [placeholder]="'Nationality' | translate"
          [needOnlyCountryCode]="true"
          [showError]="toolsService.fieldIsInvalid(userDetailForm, 'student_country')"
        ></country-selector>
      </div>
      <div
        class="small text-danger ms-4"
        *ngIf="toolsService.fieldHasError(userDetailForm, 'student_country', 'required')"
        translate
      >
        the field is required
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-2">
      <div class="form-group">
        <label-info-point [textInfo]="'Preferred language from the student' | translate"
          >Preferred language</label-info-point
        >
        <div ngbDropdown class="dropdown dropdown-icon-right" id="student_language">
          <button
            class="w-100 btn btn-simple border bg-white btn-round dropdown-toggle m-0 text-start {{
              toolsService.fieldIsInvalid(userDetailForm, 'preferred_language') ? 'border-danger' : 'border-light-gray'
            }} "
            ngbDropdownToggle
          >
            <span *ngIf="!userDetailForm.value.preferred_language">
              <i class="icon icon-language-2 me-3"></i>
              <span translate="">Select a language </span>
            </span>
            <span *ngIf="userDetailForm.value.preferred_language as lang">
              <span class="iti-flag iti-flag-select" [ngClass]="i18nService.supportedLanguages[lang].flag"></span>
              <span class="ps-4">{{ i18nService.supportedLanguages[lang].name }}</span>
            </span>
          </button>
          <div ngbDropdownMenu class="standard-dropdown mx-0 p-0 col-12 dropdown-students overflow-scroll">
            <ng-container *ngFor="let language of i18nService.supportedLanguages | keyvalue">
              <a
                class="dropdown-item position-relative"
                (click)="setLanguage(language.key)"
                *ngIf="language.value.mainLanguage"
              >
                <span class="iti-flag iti-flag-select" [ngClass]="language.value.flag"></span>
                <span class="ps-4">{{ language.value.name }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="small text-danger ms-4"
        *ngIf="toolsService.fieldHasError(userDetailForm, 'preferred_language', 'required')"
        translate
      >
        the field is required
      </div>
    </div>
  </ng-container>
</form>
<ng-template #studentListTemplate let-term="term">
  <button
    ngbDropdownItem
    class="d-flex px-1 student-item align-items-center"
    *ngFor="let student of resultStudents$ | async"
    (click)="setStudent(student)"
  >
    <div class="student-picture me-1">
      <div
        class="w-100 h-100 rounded-circle"
        *ngIf="student.user_picture_multiple_json"
        [appResponsiveBgImage]="student.user_picture_multiple_json"
      ></div>
      <i class="w-100 h-100 icon icon-user-circle icon-48" *ngIf="!student.user_picture_multiple_json"></i>
    </div>
    <ngb-highlight
      [result]="getEntitiesHtml(student.field_first_name) + ' ' + getEntitiesHtml(student.field_surname)"
      [term]="term"
    ></ngb-highlight>
  </button>
</ng-template>
<ng-template #imagePlaceholder>
  <i class="icon icon-user-circle" image-placeholder></i>
</ng-template>
