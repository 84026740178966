import { Injectable, inject } from '@angular/core';
import { ConfirmModalService } from '@app/shared/modals/confirm-modal/confirm-modal.service';
import { HelpModalService } from '@app/shared/modals/help-modal/help-modal.service';
import { LanguageSuggestService } from '@app/shared/modals/language-suggest/language-suggest.service';
import { MapSelectorService } from '@app/shared/modals/map-selector/map-selector.service';
import { MembershipSubscriptionsService } from '@app/shared/modals/membership-subscriptions/membership-subscriptions.service';
import { ModalBankTransferInfoService } from '@app/shared/modals/modal-bank-transfer-info/modal-bank-transfer-info.service';
import { PaymentService } from '@app/shared/modals/payment/payment.service';
import { RenewMembershipModalService } from '@app/shared/modals/renew-membership-modal/renew-membership-modal.service';
import { RequestHomeAddressService } from '@app/shared/modals/request-home-address/request-home-address.service';
import { SegmentationFormService } from '@app/shared/modals/segmentation-form/segmentation-form.service';
import { StandardModalService } from '@app/shared/modals/standard/standard.service';
import { StudentCertInfoService } from '@app/shared/modals/student-cert-info/student-cert-info.service';
import { ModalServiceModel } from './modal-service.model';
import { ModalTypes } from './modal-types.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalManagerComponentResolverService {
  /**
   * Configuration for resolving services for each modal type.
   */
  private resolveServices: {
    [key: string]: ModalServiceModel;
  } = {
    [ModalTypes.help]: inject(HelpModalService),
    [ModalTypes.subscriptions]: inject(MembershipSubscriptionsService),
    [ModalTypes.renewMembership]: inject(RenewMembershipModalService),
    [ModalTypes.languageSuggest]: inject(LanguageSuggestService),
    [ModalTypes.requestHomeAddress]: inject(RequestHomeAddressService),
    [ModalTypes.studentCertInfo]: inject(StudentCertInfoService),
    [ModalTypes.payment]: inject(PaymentService),
    [ModalTypes.confirm]: inject(ConfirmModalService),
    [ModalTypes.bankTransferInfo]: inject(ModalBankTransferInfoService),
    [ModalTypes.standard]: inject(StandardModalService),
    [ModalTypes.mapSelector]: inject(MapSelectorService),
    [ModalTypes.segmentationForm]: inject(SegmentationFormService),
  };

  /**
   * Resolve and return service for the modal type.
   *
   * @param type
   *  Modal type.
   * @returns
   *  Service of the modal type;
   */
  resolve(type: ModalTypes): ModalServiceModel {
    return this.resolveServices[type];
  }
}
