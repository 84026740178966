import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { NavDataService } from '../nav-data/nav-data.service';

@Injectable({
  providedIn: 'root',
})
export class RetryInterceptorService implements HttpInterceptor {
  timeoutObs: Observable<any>;
  constructor(private navDataService: NavDataService, private translateService: TranslateService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/api/log?_format=json') !== -1) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      retry({
        count: 2,
        delay: (error: HttpErrorResponse) => {
          const timeoutStatuses = [524, 504, 408, 502];
          if (timeoutStatuses.indexOf(+error.status) === -1) {
            return throwError(() => error);
          }
          const time = 10000; // 10 seconds
          // this.displayRetryTimer(time);
          if (!this.timeoutObs) {
            this.timeoutObs = new Observable((observer) => {
              // this.navDataService.timerObservable = observer;
              timer(time).subscribe(() => {
                observer.next();
                observer.complete();
              });
            });
          }
          return this.timeoutObs;
        },
      })
    );
  }

  private displayRetryTimer(time: number) {
    // TODO: display retry timer
    console.log(time);
    // const interval = setInterval(() => {
    //   if (!this.navDataService.timerObservable || time <= 1000) {
    //     clearInterval(interval);
    //     delete this.navDataService.timerObservable;
    //     delete this.navDataService.notificationMessage;
    //     delete this.timeoutObs;
    //     return;
    //   }
    //   time -= 1000;
    //   // this.navDataService.notificationMessage = {
    //   //   message: `${this.translateService.instant('An error occurred, Retrying in:')} ${time / 1000}`,
    //   //   type: 'danger',
    //   // };
    // }, 1000);
  }
}
