import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToolsService } from '@app/core/services';
import { CentersService } from '@app/core/services/centers/centers.service';
import { Observable } from 'rxjs';
import { AutocompleteForFiltersItem } from '../autocomplete-for-filters/autocomplete-for-filters-item.interface';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';

@Component({
  selector: 'responsive-filter-center-selector',
  templateUrl: './responsive-filter-center-selector.component.html',
  styleUrls: ['./responsive-filter-center-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResponsiveFilterCenterSelectorComponent),
      multi: true,
    },
  ],
})
export class ResponsiveFilterCenterSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<AutocompleteForFiltersItem>, OnInit
{
  @Input() placeHolder;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @Input() extraData?: any;

  currentValue: AutocompleteForFiltersItem;
  formControl: FormControl = new FormControl();

  private isInit = false;

  constructor(private centersService: CentersService, private tools: ToolsService) {}

  @Input() set initialValue(center: AutocompleteForFiltersItem) {
    this.isInit = true;
    this.formControl.setValue(center);
  }

  search = (searchedText: string): Observable<AutocompleteForFiltersItem[]> =>
    this.centersService.getCentersForAutoComplete({
      filters: { full_search: searchedText, country: searchedText },
      pagination: { items_per_page: 50, page: 0 },
    });

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.currentValue = value;
      if (this.isInit) {
        this.isInit = false;
        return;
      }
      this.onChange(value);
    });
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: AutocompleteForFiltersItem | null) => {};

  /**
   * Handle the touch of the element.
   */
  onTouched = () => {};

  writeValue(center: AutocompleteForFiltersItem): void {
    this.isInit = true;
    this.formControl.setValue(center);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
