import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
  constructor() {}
  transform(collection: any, property: any): any {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property[0]][property[1]]]) {
        previous[current[property[0]][property[1]]] = [current];
      } else {
        previous[current[property[0]][property[1]]].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map((key) => ({ key, value: groupedCollection[key] }));
  }
}
