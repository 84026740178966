/* eslint-disable @typescript-eslint/naming-convention */

import { ResponsiveImageResource } from './universal';

/**
 * @path /api/v1/related_products.json/printful_item?_format=json
 */
export interface RelatedProductVariation {
  product_id: number;
  title: string;
  product_image: ResponsiveImageResource;
}

/**
 * @path /api/v1.1/store_products.json?_format=json&langcode=en
 */
export interface ProductVariation {
  variation_id: number;
  type: string;
  type_1: string; // product
  title: string;
  sku: string;
  attribute_color: string;
  attribute_size: string;
  price__number: string;
  price__number_1: string; // discount
  body: string;
  product_id?: number;
  field_image?: string;
  field_card_image_export?: ResponsiveImageResource[];
  field_clothing_image_export?: ResponsiveImageResource[];
  field_equipment_image_export?: ResponsiveImageResource[];
  field_branding_image_export?: ResponsiveImageResource[];
}

export interface Product {
  product_id: number;
  title: string;
  type: string;
  view: ProductVariation[];
}

export interface ProductColorVariation {
  name: string;
  picture?: ResponsiveImageResource;
  sizes: {
    fontSize: string;
    id: number;
    name: string;
    variation: ProductVariation;
  }[];
}
