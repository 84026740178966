import { Component } from '@angular/core';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor(public navDataService: NavDataService) {}
}
