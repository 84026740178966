<div ngbDropdown class="dropdown dropdown-icon-right" #dropdown *ngIf="!isInModal; else templateForModal">
  <button
    class="dropdown-toggle form-control d-flex text-start bg-white p-3 {{
      selectedPlaceHolder ? 'border border-1 border-success text-success fw-bold' : ''
    }}"
    ngbDropdownToggle
    style
  >
    <div class="w-100 flex-fill d-flex align-items-center">
      <i class="text-info icon me-2 icon-16 icon-graduation-cap"></i>
      <span class="text-truncate d-inline-block flex-fill">
        {{ selectedPlaceHolder ?? placeHolder }}
      </span>
      <i class="icon icon-times icon-20 me-2" *ngIf="selectedPlaceHolder" (click)="clear($event)"></i>
    </div>
  </button>
  <div ngbDropdownMenu class="rounded-2 mt-2 shadow" style="min-width: 250px">
    <div class="m-3 items-container">
      <ng-container
        [ngTemplateOutlet]="templatesChooser"
        [ngTemplateOutletContext]="{ templates$: (extraData | async) !== 'pro_course' ? recreationalType$ : proType$ }"
      ></ng-container>
    </div>
    <!-- missing information from the backend -->
    <!-- <ng-container *ngIf="isEvolution">
      <div class="mx-3 mb-3">
        <hr />
        <div class="d-flex align-items-center">
          <label translate class="me-1">Evo Type</label>
          <select style="width: auto" class="form-control flex-fill" [formControl]="evolutionCourseTypeControl">
            <option value="" disabled selected>choose the type desired...</option>
            <option *ngFor="let evolutionType of evolutionTypes$ | async" [value]="evolutionType.tid">
              {{ evolutionType.name }}
            </option>
          </select>
        </div>
      </div> 
    </ng-container>-->
    <hr class="m-0" />
    <div class="p-2 pb-0 d-flex justify-content-between drodpdown-buttons-filters">
      <button class="btn rounded-2 m-0 d-flex align-items-center" (click)="clear($event)">
        <i class="icon icon-times me-1"></i> <span tanslate>Clear</span>
      </button>
      <button class="btn btn-success rounded-2 m-0 d-flex align-items-center" (click)="confirm()">
        <i class="icon icon-check me-1"></i>
        <span translate>Confirm</span>
      </button>
    </div>
  </div>
</div>

<ng-template #templatesChooser let-templates$="templates$">
  <div class="d-flex justify-content-between align-items-center">
    <ng-container *ngFor="let template of templates$ | async">
      <div class="pointer text-center" (click)="setType(template)">
        <img
          src="/assets/images/levels/{{ template.image }}{{
            currentValue?.courseTemplate === template.id ? '-selected' : ''
          }}.svg"
          width="80%"
          alt="level-image"
        />
        <div class="small">{{ template.title }}</div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #templateForModal>
  <div class="{{ value ? 'text-success' : 'text-dark' }} font-size- fw-bold ">
    {{ placeHolder | translate }}
  </div>
  <ng-container
    [ngTemplateOutlet]="templatesChooser"
    [ngTemplateOutletContext]="{ templates$: (extraData | async) !== 'pro_course' ? recreationalType$ : proType$ }"
  >
  </ng-container>
  <hr />
</ng-template>
