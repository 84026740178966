import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { ToolsService } from '@app/core/services/tools/tools.service';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'modal-bank-transfer-info',
  templateUrl: './modal-bank-transfer-info.component.html',
  styleUrls: ['./modal-bank-transfer-info.component.scss'],
})
export class ModalBankTransferInfoComponent extends ModalComponent {
  constructor(private toolsService: ToolsService) {
    super();
  }

  copy(text: string): void {
    this.toolsService.copyToClipboard(text);
  }
}
