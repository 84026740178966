import { Component, OnInit } from '@angular/core';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';

import { FormBuilder, FormControl } from '@angular/forms';
import { EreaderControllerService } from '@app/core/services/ereader-controller/ereader-controller.service';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-table-of-content',
  templateUrl: './table-of-content.component.html',
  styleUrls: ['./table-of-content.component.scss'],
})
export class TableOfContentComponent implements OnInit {
  searchField: FormControl;
  constructor(
    private formBuilder: FormBuilder,
    public ereaderControllerService: EreaderControllerService,
    public navDataService: NavDataService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.searchField = this.formBuilder.control('');
    this.searchField.valueChanges.pipe(debounceTime(400)).subscribe((enteredValue) => {
      this.ereaderControllerService.search(enteredValue);
    });
  }
}
