/* eslint-disable @angular-eslint/no-output-native */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DynamicCheckboxModel,
  DynamicFormControlComponent,
  DynamicFormControlLayout,
  DynamicFormLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';

@Component({
  selector: 'dynamic-nowui-checkbox.component',
  templateUrl: './dynamic-nowui-checkbox.component.html',
  styleUrls: ['./dynamic-nowui-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DynamicNowUiCheckboxComponent extends DynamicFormControlComponent {
  @Input() formLayout?: DynamicFormLayout;
  @Input() group!: FormGroup;
  @Input() layout?: DynamicFormControlLayout;
  @Input() model!: DynamicCheckboxModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }
}
