/**
 * ngx-device-detector
 *
 * @see https://www.npmjs.com/package/ngx-device-detector
 */

import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';

import * as Constants from './device-detector.constants';
import { ReTree } from './retree.service';
import { Device } from '@ionic-native/device/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { isPlatformBrowser } from '@angular/common';
import { RolesService } from '../roles/roles.service';
import { NavDataService } from '../nav-data/nav-data.service';

declare const window: any;
export interface DeviceInfo {
  userAgent: string;
  os: string;
  browser: string;
  device: string;
  os_version: string;
  browser_version: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectService {
  ua = '';
  userAgent = '';
  os = '';
  browser = '';
  currentDevice = '';
  os_version = '';
  browser_version = '';

  mobileWidth = 767;
  tabletWidth = 991;
  tabletPortraitWidth = 991;
  private readonly iphoneXMinHeight = 750;

  constructor(
    public device: Device,
    @Inject(PLATFORM_ID) private platform: string,
    private screenOrientation: ScreenOrientation,
    private rolesService: RolesService,
    private injector: Injector
  ) {
    this._setDeviceInfo();
  }

  /**
   * @desc Returns the device information
   * @returns the device information object.
   */
  get getDeviceInfo(): DeviceInfo {
    const deviceInfo: DeviceInfo = {
      userAgent: this.userAgent,
      os: this.os,
      browser: this.browser,
      device: this.currentDevice,
      os_version: this.os_version,
      browser_version: this.browser_version,
    };
    return deviceInfo;
  }

  /**
   * @desc Compares the current device info with the mobile devices to check
   * if the current device is a mobile.
   * @returns whether the current device is a mobile
   */
  get isIphoneX(): boolean {
    return (
      isPlatformBrowser(this.platform) &&
      window.device?.model?.toLowerCase().startsWith(Constants.DEVICES.IPHONE) &&
      window.innerHeight >= this.iphoneXMinHeight
    );
  }

  get isIOS() {
    return this.isCordova && this.device.platform === 'iOS';
  }

  /**
   * @desc Compares the current device info with the mobile devices to check
   * if the current device is a mobile.
   * @returns whether the current device is a mobile
   */
  get isMobile(): boolean {
    // DEVELOPMENT
    // return true;
    if (isPlatformBrowser(this.platform)) {
      if (window.innerWidth <= this.mobileWidth) {
        return true;
      }
      return [
        Constants.DEVICES.ANDROID,
        Constants.DEVICES.IPHONE,
        Constants.DEVICES.I_POD,
        Constants.DEVICES.BLACKBERRY,
        Constants.DEVICES.FIREFOX_OS,
        Constants.DEVICES.WINDOWS_PHONE,
        Constants.DEVICES.VITA,
      ].some((item) => this.currentDevice === item);
    }
  }

  /**
   * @desc Compares the current device info with the tablet devices to check
   * if the current device is a tablet.
   * @returns whether the current device is a tablet
   */
  get isTablet() {
    // DEVELOPEMENT
    // return false;
    const ua = navigator.userAgent;
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
    // if (isPlatformBrowser(this.platform)) {
    //   if (window.innerWidth <= this.tabletWidth && window.innerWidth > this.mobileWidth) {
    //     return true;
    //   }
    // }
    // return [Constants.DEVICES.I_PAD, Constants.DEVICES.FIREFOX_OS].some((item) => this.currentDevice === item);
  }

  /**
   * @desc Compares the current device info with the desktop devices to check
   * if the current device is a desktop device.
   * @returns whether the current device is a desktop device
   */
  get isDesktop() {
    // DEVELOPMENT
    // return false;
    // console.log(isPlatformBrowser(this.platform) && window.innerWidth > this.tabletWidth);
    return isPlatformBrowser(this.platform) && window.innerWidth > this.tabletWidth;
    //     return true;
    //   }
    // }
    // return false;
    // return [
    //   Constants.DEVICES.PS4,
    //   Constants.DEVICES.CHROME_BOOK,
    //   Constants.DEVICES.UNKNOWN
    // ].some((item) => {
    //   return this.currentDevice === item;
    // });
  }

  get isMobileOrTablet() {
    return this.isTablet || this.isMobile;
  }

  get isDesktopOrTablet() {
    return this.isTablet || this.isDesktop;
  }

  get isDesktopOrTabletLandscape() {
    if (isPlatformBrowser(this.platform)) {
      return this.isDesktop || (this.isTablet && window.innerWidth > this.tabletPortraitWidth);
    }
  }

  get isCordova() {
    // return true;
    const key = 'cordova';
    if (window?.[key]) {
      return true;
    }
    return false;
  }

  get isTabletOrientationLandscape() {
    if (isPlatformBrowser(this.platform)) {
      return this.isTablet && window.innerWidth > this.tabletPortraitWidth;
    }
  }

  /**
   * check for apple, hehehe
   * @returns false if on ios and ( user is anonymous or ( kiterfree and not certified ) )
   */
  get iosDisplayed() {
    const notCertifiedFree =
      this.rolesService.userHasRole('kiter_free') &&
      !this.injector.get(NavDataService).currentUserProfile?.field_top_qualification;
    return !(this.isCordova && this.device.platform === 'iOS' && (this.rolesService.userHasRole() || notCertifiedFree));
  }

  enableOrientation() {
    if (this.isCordova && this.isTablet) {
      try {
        this.screenOrientation.unlock();
      } catch (err) {
        console.log('Screen orientation error: ', err);
      }
    }
  }

  private _setDeviceInfo() {
    const reTree = new ReTree();

    const ua = this.ua;
    this.userAgent = ua;
    const mappings = [
      { const: 'OS', prop: 'os' },
      { const: 'BROWSERS', prop: 'browser' },
      { const: 'DEVICES', prop: 'currentDevice' },
      { const: 'OS_VERSIONS', prop: 'os_version' },
    ];

    mappings.forEach((mapping) => {
      this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce((obj: any, item: any) => {
        obj[Constants[mapping.const][item]] = reTree.test(ua, Constants[`${mapping.const}_RE`][item]);
        return obj;
      }, {});
    });

    mappings.forEach((mapping) => {
      this[mapping.prop] = Object.keys(Constants[mapping.const])
        .map((key) => Constants[mapping.const][key])
        .reduce(
          (previousValue, currentValue) =>
            previousValue === Constants[mapping.const].UNKNOWN && this[mapping.prop][currentValue]
              ? currentValue
              : previousValue,
          Constants[mapping.const].UNKNOWN
        );
    });

    this.browser_version = '0';
    if (this.browser !== Constants.BROWSERS.UNKNOWN) {
      const re = Constants.BROWSER_VERSIONS_RE[this.browser];
      const res = reTree.exec(ua, re);
      if (!!res) {
        this.browser_version = res[1];
      }
    }
  }
}
