<div class="px-0 class w-100 {{ backgroudClass }} container-fluid h-100 text-center d-flex flex-column flex-fill">
  <div class="mt-5 rounded-1 {{ textColorClass }} text-center big mx-3 card-bg">
    <label class="mt-5">
      <i class="icon icon-64 {{ icon }} {{ iconColor }}"></i><br />
      <span [innerHTML]="message | translate"></span>
    </label>
  </div>
  <div class="text-center mt-3">
    <button class="btn btn-success rounded-1" (click)="redirectToPage()" translate>
      {{ goBackButtonText }}
    </button>
  </div>
</div>
