<div class="h-100 d-flex align-items-center">
  <i
    class="icon icon-info-circle cursor-pointer"
    [ngbTooltip]="tooltipInfo"
    #infopoint="ngbTooltip"
    (click)="toggleInfo($event)"
    triggers="manual"
    tooltipClass="text-dark font-size-12"
  ></i>
</div>

<ng-template #modalInfo>
  <div class="modal-header pt-1 mb-1">
    <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
      <span class="d-inline-block align-middle ms-1"></span>
    </h3>
    <button class="btn-close btn-red" style="position: absolute !important" (click)="dismissModal()"></button>
  </div>
  <div class="modal-body text-center d-flex flex-column" translate>
    <div class="flex-fill overflow-scroll">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #tooltipInfo>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</ng-template>
<ng-template #content>
  <ng-container [ngTemplateOutlet]="template" *ngIf="template"></ng-container>
  <div *ngIf="!template" [innerHtml]="textInfo"></div>
</ng-template>
<div class="d-none" #container>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>
