import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { LanguageSuggestComponent } from './language-suggest.component';

@Injectable({
  providedIn: 'root',
})
export class LanguageSuggestService extends ModalServiceModel {
  protected component = LanguageSuggestComponent;
}
