import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { RenewMembershipModalComponent } from './renew-membership-modal.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class RenewMembershipModalService extends ModalServiceModel {
  protected component = RenewMembershipModalComponent;
  protected options: NgbModalOptions = { size: 'lg', modalDialogClass: 'modal-dialog-centered bg-transparent' };
}
