import { Injectable } from '@angular/core';
import { MapSelectorComponent } from './map-selector.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';

@Injectable({
  providedIn: 'root',
})
export class MapSelectorService extends ModalServiceModel {
  protected component = MapSelectorComponent;
  protected options: NgbModalOptions = { size: 'lg' };
}
