import { Injectable } from '@angular/core';
import { QualificationCategory } from '@app/core/models';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { Observable, of, Subject } from 'rxjs';
import { I18nService } from '../i18n/i18n.service';

export enum TaxonomyTerms {
  qualifications = 'qualifications',
}

const endpoints = {
  [TaxonomyTerms.qualifications]: '/api/v1/terms/qualifications.json',
};

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  private cacheTerms: Map<string, Map<string, any[]>> = new Map<string, Map<string, any>>();
  private apiCallCache: Map<string, Map<string, Observable<any[]>>> = new Map<string, Map<string, any>>();

  constructor(private viewService: ViewService, private i18nService: I18nService) {}

  /**
   * get the qualification termsList
   *
   * @returns {Observable<QualificationCategory[]>}
   */
  getQualifications(): Observable<QualificationCategory[]> {
    return this.getTerms(TaxonomyTerms.qualifications);
  }

  /**
   * fetch from api or from memory cache if exists the terms defined in the parameters
   * based by current language
   *
   * @param {TaxonomyTerms} terms
   * @returns {Observable<any[]>} list of terms
   */
  getTerms(terms: TaxonomyTerms): Observable<any[]> {
    const currentLanguage = this.i18nService.language;
    if (this.cacheTerms.has(currentLanguage)) {
      if (this.cacheTerms.get(currentLanguage).has(terms)) {
        return of(this.cacheTerms.get(currentLanguage).get(terms));
      }
    }
    return this.fetchTerms(terms, currentLanguage);
  }

  /**
   * fetch the terms specifid in terms with the specified language from D8
   * with a system to avoid to deduplication of the calls
   *
   * @param {TaxonomyTerms} terms
   * @param {string} language
   * @returns {Observabel<any[]>} an observable that will return the terms
   */
  fetchTerms(terms: TaxonomyTerms, language: string): Observable<any[]> {
    if (this.apiCallCache.has(language)) {
      if (this.apiCallCache.get(language).has(terms)) {
        return this.apiCallCache.get(language).get(terms);
      }
    } else {
      this.apiCallCache.set(language, new Map<string, Observable<any[]>>());
    }
    const api = endpoints[terms];
    if (!api) {
      throw new Error(`endoint not defined for terms: ${terms}`);
    }
    const apiCall = new Subject<any>();
    this.viewService.get(api).subscribe({
      next: (data) => {
        if (this[`transform_${terms}`]) {
          data = this[`transform_${terms}`](data);
        }
        if (!this.cacheTerms.has(language)) {
          this.cacheTerms.set(language, new Map<string, any>());
        }
        this.cacheTerms.get(language).set(terms, data);
        this.apiCallCache.get(language).delete(terms);
        apiCall.next(data);
      },
      error: (error) => {
        apiCall.error(error);
      },
    });

    this.apiCallCache.get(language).set(terms, apiCall);
    return apiCall;
  }

  /** transformers part */

  /**
   * elaborate the qualification translations
   *
   * @param qualifications
   * @returns
   */
  private transform_qualifications(qualifications: any[]): QualificationCategory[] {
    return qualifications.map((qualification) => {
      const translated = qualification.description__value.replace(/<(\/|)p>|\n/g, '').split('<br />');
      if (translated.length > 1) {
        qualification.translatedName = translated[0];
        qualification.translatedAltName = translated[1];
      } else {
        qualification.translatedName = qualification.field_short_name;
        qualification.translatedAltName = qualification.field_alt_name;
      }
      qualification.locked = true;
      return qualification;
    });
  }
}
