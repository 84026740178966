<div class="overflow-scroll h-100 sidebar-container bg-primary-gradient p-3 d-flex flex-column">
  <gap-fixer-iphone></gap-fixer-iphone>
  <ul class="navbar-nav flex-grow-1" data-background-color="white">
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !exploreCollapse.collapsed }"
          (click)="exploreCollapse.toggle()"
        >
          <i class="icon-lg icon icon-globe-americas"></i>
          <span class="ms-3" translate>Explore</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div
        #exploreCollapse="ngbCollapse"
        [ngbCollapse]="
          !(
            centerLink.isActive ||
            membersLink.isActive ||
            coursesLink.isActive ||
            destinationsLink.isActive ||
            blogLink.isActive ||
            aboutLink.isActive
          )
        "
      >
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <li [ngClass]="{ 'bg-white rounded-3': centerLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #centerLink="routerLinkActive"
              [routerLink]="'/centers' | localize"
            >
              <span class="ms-3" translate>Find a Center</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': membersLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #membersLink="routerLinkActive"
              [routerLink]="'/members' | localize"
              translate
            >
              <span class="ms-3" translate>Find a Member</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': coursesLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              #coursesLink="routerLinkActive"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              [routerLink]="'/training-calendar' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
              translate
            >
              <span class="ms-3" translate>Courses</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': destinationsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #destinationsLink="routerLinkActive"
              [routerLink]="'/destinations' | localize"
              translate
            >
              <span class="ms-3" translate>Destinations</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': blogLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #blogLink="routerLinkActive"
              [routerLink]="'/blog' | localize"
              translate
            >
              <span class="ms-3" translate>Blog</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': aboutLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #aboutLink="routerLinkActive"
              [routerLink]="'/about' | localize"
              translate
            >
              <span class="ms-3" translate>About IKO</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !learnCollapse.collapsed }"
          (click)="learnCollapse.toggle()"
        >
          <i class="icon-lg icon icon-lightbulb"></i>
          <span class="ms-3" translate>Learn</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div
        #learnCollapse="ngbCollapse"
        [ngbCollapse]="
          !(
            videoLink.isActive ||
            ebooksLink.isActive ||
            ecourseLink.isActive ||
            vcardLink.isActive ||
            experienceLink.isActive ||
            windWindowLink.isActive ||
            kiteSignalsLink.isActive ||
            rightOfWayLink.isActive
          )
        "
      >
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <li [ngClass]="{ 'bg-white rounded-3': videoLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #videoLink="routerLinkActive"
              [routerLink]="'/elearning/videos' | localize"
            >
              <span class="ms-3" translate>Watch Tutorial Videos</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': ebooksLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #ebooksLink="routerLinkActive"
              [routerLink]="'/elearning/ebooks' | localize"
              translate
            >
              <span class="ms-3" translate>Read Handbooks</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': ecourseLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #ecourseLink="routerLinkActive"
              [routerLink]="'/ecourses' | localize"
              translate
            >
              <span class="ms-3" translate>Take an eCourse</span>
            </a>
          </li>
          <li class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              [href]="'https://www.ikointl.com/course/kiteboarder/discovery' | translate"
              translate
            >
              <span class="ms-3" translate>Become a Kiter</span>
            </a>
          </li>
          <li class="nav-item my-1 mx-0">
            <a class="nav-link py-2 px-3" href="https://www.ikointl.com/request-certification" translate>
              <span class="ms-3" translate>Get Certified</span>
            </a>
          </li>
          <li
            [ngClass]="{ 'bg-white rounded-3': vcardLink.isActive, 'd-none': userService.isLoggedIn }"
            class="nav-item my-1 mx-0"
          >
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #vcardLink="routerLinkActive"
              [routerLink]="'/vcard' | localize"
            >
              <span class="ms-3" translate>Get your vCard</span>
            </a>
          </li>
          <li
            [ngClass]="{ 'bg-white rounded-3': experienceLink.isActive, 'd-none': userService.isLoggedIn }"
            class="nav-item my-1 mx-0"
          >
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #experienceLink="routerLinkActive"
              [routerLink]="'/course/ease-your-kite-experience' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="ms-3" translate>Ease Your Kite Experience</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': windWindowLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #windWindowLink="routerLinkActive"
              [routerLink]="'/elearning/gallery/wind-window' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="ms-3" translate>Wind Window</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': kiteSignalsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #kiteSignalsLink="routerLinkActive"
              [routerLink]="'/elearning/gallery/kite-signals' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="ms-3" translate>Kite Signals</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': rightOfWayLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #rightOfWayLink="routerLinkActive"
              [routerLink]="'/elearning/gallery/right-of-way' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="ms-3" translate>Right of Way</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !careerCollapse.collapsed }"
          (click)="careerCollapse.toggle()"
        >
          <i class="icon-lg icon icon-suitcase"></i>
          <span class="ms-3" translate>Career</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div
        #careerCollapse="ngbCollapse"
        [ngbCollapse]="
          !(
            becomeInstructorLink.isActive ||
            becomeAssistantLink.isActive ||
            becomeCoachLink.isActive ||
            trainingPathLink.isActive ||
            jobsLink.isActive
          )
        "
      >
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <li [ngClass]="{ 'bg-white rounded-3': trainingPathLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #trainingPathLink="routerLinkActive"
              [routerLink]="'/courses' | localize"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="ms-3" translate>Training path</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': becomeInstructorLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #becomeInstructorLink="routerLinkActive"
              [href]="'https://www.ikointl.com/course/instructor-level-1' | translate"
              translate
            >
              <span class="ms-3" translate>Become an Instructor</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': becomeAssistantLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #becomeAssistantLink="routerLinkActive"
              [href]="'https://www.ikointl.com/course/kitesurf-assistant-training-course-atc' | translate"
              translate
            >
              <span class="ms-3" translate>Become an Assistant</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': becomeCoachLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #becomeCoachLink="routerLinkActive"
              href="https://www.ikointl.com/course/kitesurf-coach-level-1"
              translate
            >
              <span class="ms-3" translate>Become an Coach</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': jobsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #jobsLink="routerLinkActive"
              [routerLink]="'/jobs' | localize"
              translate
            >
              <span class="ms-3" translate>Find a Job</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !membershipCollapse.collapsed }"
          (click)="membershipCollapse.toggle()"
        >
          <i class="icon-lg icon icon-award"></i>
          <span class="ms-3" translate>Membership</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div
        #membershipCollapse="ngbCollapse"
        [ngbCollapse]="
          !(instructorLink.isActive || assistantLink.isActive || kiterLink.isActive || schoolLink.isActive)
        "
      >
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <li [ngClass]="{ 'bg-white rounded-3': kiterLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #kiterLink="routerLinkActive"
              href="https://www.ikointl.com/kiter-membership"
              translate
            >
              <span class="ms-3" translate>Kiter Membership</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': assistantLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #assistantLink="routerLinkActive"
              href="https://www.ikointl.com/assistant-renewal"
              translate
            >
              <span class="ms-3" translate>Assistant</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': instructorLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #instructorLink="routerLinkActive"
              href="https://www.ikointl.com/instructor-renewal"
              translate
            >
              <span class="ms-3" translate>Instructor</span>
            </a>
          </li>

          <li [ngClass]="{ 'bg-white rounded-3': schoolLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #schoolLink="routerLinkActive"
              href="https://www.ikointl.com/center-membership"
              translate
            >
              <span class="ms-3" translate>Affiliate Your School</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !shopCollapse.collapsed }"
          (click)="shopCollapse.toggle()"
        >
          <i class="icon-lg icon icon-bags-shopping"></i>
          <span class="ms-3" translate>Shop</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div #shopCollapse="ngbCollapse" [ngbCollapse]="!(merchandiseLink.isActive || dealsLink.isActive)">
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <!-- <li [ngClass]="{ 'bg-white rounded-3': insuranceLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #insuranceLink="routerLinkActive"
              href="https://www.ikointl.com/kitesurf-insurance"
            >
              <span class="ms-3" translate>Insurance</span>
            </a>
          </li> -->
          <li [ngClass]="{ 'bg-white rounded-3': merchandiseLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #merchandiseLink="routerLinkActive"
              [routerLink]="'/shop' | localize"
              translate
            >
              <span class="ms-3" translate>Merchandise</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': dealsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #dealsLink="routerLinkActive"
              [routerLink]="'/deals' | localize"
              translate
            >
              <span class="ms-3" translate>Member Deals</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item my-1 mx-0">
      <div>
        <button
          class="nav-link py-2 px-3 d-flex align-items-center w-100"
          [ngClass]="{ opened: !safetyCollapse.collapsed }"
          (click)="safetyCollapse.toggle()"
        >
          <i class="icon-lg icon icon-sheriff"></i>
          <span class="ms-3" translate>Safety</span>
          <div class="flex-fill"></div>
          <i class="icon icon-32 text-white icon-angle-up"></i>
        </button>
      </div>
      <div
        #safetyCollapse="ngbCollapse"
        [ngbCollapse]="!(reportLink.isActive || standardsLink.isActive || chartOfSactionsLink.isActive)"
      >
        <ul class="navbar-nav flex-grow-1" data-background-color="white">
          <li [ngClass]="{ 'bg-white rounded-3': reportLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #reportLink="routerLinkActive"
              [routerLink]="'/quality-check' | localize"
            >
              <span class="ms-3" translate>Report an Issue</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': standardsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #standardsLink="routerLinkActive"
              [routerLink]="'/documents/53' | localize"
              translate
            >
              <span class="ms-3" translate>Standards</span>
            </a>
          </li>
          <li [ngClass]="{ 'bg-white rounded-3': chartOfSactionsLink.isActive }" class="nav-item my-1 mx-0">
            <a
              class="nav-link py-2 px-3"
              routerLinkActive="text-primary"
              (click)="closeMenu()"
              #chartOfSactionsLink="routerLinkActive"
              [routerLink]="'/documents/53' | localize"
              translate
            >
              <span class="ms-3" translate>Chart of Sanctions</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
  <div class="flex-fill"></div>
  <app-language-select
    class="mb-2"
    [updateProfile]="false"
    [placement]="['top', 'top-left']"
    [updateUi]="true"
    (languageChanged)="closeMenu()"
  ></app-language-select>
</div>
