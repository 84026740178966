import { ChangeDetectorRef, Component, forwardRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AutocompleteInputItem } from './autocomplete-input-item';
import { AutoCompleteInputProvider } from './autocomplete-input-provider';

@Component({
  selector: 'autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent implements ControlValueAccessor {
  @Input() inputClass: string | string[];
  @Input() needOnlyId = true;
  @Input() showThumbnails = false;
  @Input() showRoundedThumbnails = false;
  @Input() noThumbnailsTemplate: TemplateRef<any>;
  @Input() provider: AutoCompleteInputProvider;
  @Input() items: AutocompleteInputItem[];
  @Input() placeholder: string;
  @Input() containerClass: string;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  currentItem: AutocompleteInputItem;
  isLoading = false;
  click$ = new Subject<string>();
  onChange;
  onTouch;
  isDisabled = false;
  currentItemModel = new FormControl(null);
  searching = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.currentItemModel.valueChanges.subscribe((value) => this.onChangeItem(value));
  }

  formatter = (x: AutocompleteInputItem) => x.label;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    return merge(debouncedText$, clicksWithClosedPopup$).pipe(
      tap(() => {
        this.setIsLoading(true);
      }),
      switchMap((term) => {
        if (this.provider) {
          return this.provider.filter(term) ?? of([]);
        }
        if (this.items) {
          return of(
            (term === ''
              ? this.items
              : this.items.filter((v) => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1)
            ).slice(0, 10)
          );
        }
        return of([]);
      }),
      tap(() => {
        this.setIsLoading(false);
      }),
      catchError((err) => {
        this.setIsLoading(false);
        console.error(err);
        return of([]);
      })
    );
  };

  private setIsLoading(isLoading) {
    this.isLoading = isLoading;
    this.cdr.markForCheck();
  }

  private onChangeItem(value: AutocompleteInputItem | string) {
    this.currentItem = null;
    if (!value || value === '') {
      this.onChange(null);
      return;
    }
    if (typeof value === 'string') {
      return;
    }
    this.isLoading = false;
    this.currentItem = value;
    this.onChange(this.needOnlyId ? value.value : value);
    this.cdr.markForCheck();
  }
}
