<ng-container [formGroup]="group">
  <autocomplete-input
    containerClass="mb-0"
    [placeholder]="model.placeholder"
    [formControlName]="model.id"
    [showThumbnails]="model.showThumbnails"
    [showRoundedThumbnails]="model.showRoundedThumbnails"
    [noThumbnailsTemplate]="model.noThumbnailsTemplate"
    [inputClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    [provider]="provider"
  ></autocomplete-input>
</ng-container>

<ng-template #noImageUser> <i class="icon icon-user-circle"></i> </ng-template>
<ng-template #noImageSchool>
  <img src="/assets/img/iko-logo-png" alt="iko-logo" />
</ng-template>
