import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from '@app/core/services/localstorage/localstorage.service';
import { BaseService, DrupalConstants, HttpOptions, UserService } from '@makiwin/ngx-drupal8-rest';
import { Observable, Subject, mergeMap, tap } from 'rxjs';
import { NavDataService } from '../nav-data/nav-data.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends BaseService {
  readonly defaultCurrency = 'USD';
  readonly storageKey = 'USER_CURRENCY';
  currencyChanged$: Subject<void> = new Subject<void>();

  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private navDataService: NavDataService,

    httpClient: HttpClient,
    @Inject(PLATFORM_ID) platform: string
  ) {
    super(httpClient, platform);
  }

  getCurrentCurrencySymbol(currency?: string): string {
    return (currency ?? this.getCurrencyFromStorage()) === 'EUR' ? '€' : '$';
  }

  getCurrencyFromStorage(): string | null {
    return this.localStorageService.getItem(this.storageKey) ?? this.defaultCurrency;
  }

  setCurrencyInStorage(currency: string): void {
    this.localStorageService.setItem(this.storageKey, currency);
  }

  setCurrency(currencyCode: string, reinitApp = true): Observable<boolean> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };

    let apiCalls: Observable<any> = this.request(httpOptions, '/api/set_currency', {
      currency_code: currencyCode,
    }).pipe(tap(() => this.currencyChanged$.next()));

    if (this.userService.isLoggedIn) {
      const uid = DrupalConstants.Connection.current_user.uid;
      const values: any = {
        field_preferred_currency: {
          target_id: currencyCode,
        },
      };
      // api calls should be called sequencly
      // so the get profile api doesn't get resolved before set currency
      // to prevent not fetching the newly selected language "from user profile" before finishing setting it up
      apiCalls = apiCalls.pipe(
        mergeMap(() =>
          this.userService.update(uid, values).pipe(
            mergeMap(() =>
              this.navDataService.initProfile().pipe(
                tap(() => {
                  if (reinitApp) {
                    this.navDataService.appReady = false;
                    setTimeout(() => {
                      this.navDataService.appReady = true;
                    });
                  }
                })
              )
            )
          )
        )
      );
    }
    return apiCalls;
  }
}
