import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ModalMapFormControlComponent } from './modal-map-form-control.component';
import { DistanceFromMapValue } from '@app/shared/forms';

@Injectable({
  providedIn: 'root',
})
export class ModalMapFormControlService {
  private modalRef: NgbModalRef;

  constructor(private ngbModal: NgbModal) {}

  open(initialDistance?: number): Observable<DistanceFromMapValue> {
    return new Observable((observer) => {
      this.modalRef = this.ngbModal.open(ModalMapFormControlComponent, {
        size: 'lg',
        centered: true,
        windowClass: 'modal-dialog-centered',
      });
      this.modalRef.componentInstance.modalRef = this.modalRef;
      this.modalRef.componentInstance.observer = observer;
      this.modalRef.componentInstance.initialDistance = initialDistance;
      this.modalRef.result.then().finally(() => {
        observer.complete();
      });
    });
  }
}
