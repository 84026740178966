import { Component } from '@angular/core';
import { NidTitle } from '@app/core/models';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { Observable } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-training-calendar-search',
  templateUrl: './training-calendar-search.component.html',
  styleUrls: ['./training-calendar-search.component.scss'],
})
export class TrainingCalendarSearchComponent {
  allCourses: NidTitle[] = [];
  courses: NidTitle[] = [];
  selectedCourse: NidTitle;
  selectedWhere: any;

  constructor(private viewService: ViewService) {
    this.searchWhere = this.searchWhere.bind(this);
  }

  nodeFormatter = (x: NidTitle) => x.title;

  searchWhere(textObservable: Observable<string>) {
    return textObservable.pipe(
      debounceTime(500),
      mergeMap((term) => this.getWhere(term))
    );
  }

  getWhere(titleSearch: string): Observable<any[]> {
    if (!titleSearch) {
      this.courses = this.allCourses;
      return;
    }
    const options: ViewOptions = {
      filters: { title: titleSearch },
    };

    return this.viewService.get('', options);
  }

  getCourses() {
    this.viewService.get('').subscribe((data) => {
      this.allCourses = data;
      this.courses = this.allCourses;
    });
  }

  selectWhere(where: any) {
    const courses: number[] = where.courses;
    this.courses = this.allCourses.filter((e) => courses.indexOf(e.nid) !== -1);
    this.selectedWhere = where;
    delete this.selectedCourse;
  }
}
