import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LikeButtonComponent {
  @Input() hasLike: boolean;
  @Input() likesNumber: number | string;
  @Input() showLoader: boolean;
  @Input() buttonSize = 'btn-lg';
  @Input() buttonClasses = '';
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
