import { Component, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { CourseTypeValues } from '../course-type-selector/course-type-selector.component';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
  StaticValues,
  StaticValuesListService,
} from '@app/core/services/static-values-list/static-values-list.service';
import { CourseTemplate, NameTid } from '@app/core/models';
import { TranslateService } from '@ngx-translate/core';

/**
 * Extends the Course template interface with the image for the selection.
 */
interface CourseTemplateWithImage extends CourseTemplate {
  image: string;
}

/**
 * Manual mapping of the images for the differente course templates.
 */
const mappingImage = {
  id3661: 'advanced',
  id3654: 'beginner',
  id3659: 'evolution',
  id3658: 'assistant',
  id3657: 'instructor',
};

/**
 * Manual sorting for the recreational templates.
 */
const sortRecreational = {
  id3654: 1,
  id3661: 2,
  id3659: 3,
};

/**
 * Object definition of the values returned by the component.
 */
export interface CourseTemplateSelectorValue {
  courseTemplate: string;
  evoType?: string;
}

@Component({
  selector: 'course-template-selector',
  templateUrl: './course-template-selector.component.html',
  styleUrls: ['./course-template-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CourseTemplateSelectorComponent),
      multi: true,
    },
  ],
})
export class CourseTemplateSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<CourseTemplateSelectorValue>, OnInit, OnDestroy
{
  @Input() placeHolder;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: CourseTemplateSelectorValue;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @Input() extraData?: BehaviorSubject<CourseTypeValues>;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;
  currentValue: CourseTemplateSelectorValue;
  isEvolution: boolean;
  courseTypes = CourseTypeValues;
  selectedPlaceHolder: string;
  recreationalType$: BehaviorSubject<CourseTemplateWithImage[]> = new BehaviorSubject<CourseTemplateWithImage[]>(null);
  proType$: BehaviorSubject<CourseTemplateWithImage[]> = new BehaviorSubject<CourseTemplateWithImage[]>(null);
  evolutionTypes$: BehaviorSubject<NameTid[]> = new BehaviorSubject<NameTid[]>([]);
  evolutionCourseTypeControl: FormControl = new FormControl('');
  private allTemplates: CourseTemplate[] = [];
  private defaultPlaceHolder = this.translateService.instant('Choose what to learn...');
  private unSubscribeAll: Subject<void> = new Subject<void>();

  /**
   *
   * @param staticValuesService
   * @param translateService
   */
  constructor(private staticValuesService: StaticValuesListService, private translateService: TranslateService) {
    this.staticValuesService.getValues<CourseTemplate>(StaticValues.courseTemplates).subscribe((templates) => {
      this.allTemplates = templates;
      this.placeHolder ??= this.defaultPlaceHolder;
      const recreational = [];
      const pro = [];
      for (const template of templates) {
        if (!mappingImage[`id${template.id}`]) {
          continue;
        }
        template.image = mappingImage[`id${template.id}`];
        switch (template.field_template_type_1.toString()) {
          case '126':
            recreational.push(template);
            break;
          case '125':
            template.title = template.title.replace('Course', '');
            pro.push(template);
            break;
        }
      }
      this.recreationalType$.next(
        recreational.sort(
          (templateA, templateB) => sortRecreational[`id${templateA.id}`] - sortRecreational[`id${templateB.id}`]
        )
      );
      this.proType$.next(pro);
    });

    /** actually we don't need the choose the evolution skill.s        
    this.staticValuesService
      .getValues<NameTid>(StaticValues.recreationalCourseType, { args: [480] })
       .subscribe((types) => this.evolutionTypes$.next(types));
    this.evolutionCourseTypeControl.valueChanges.subscribe((val) => {
       if (this.value) {
         this.value = { courseTemplate: this.value.courseTemplate, evoType: val };
       }
     }); */
  }

  get value(): CourseTemplateSelectorValue | null {
    return this.currentValue;
  }

  set value(val: CourseTemplateSelectorValue | null) {
    this.currentValue = val;
    this.isEvolution = this.currentValue?.courseTemplate === '3659';
    if (this.isInModal || !val) {
      this.setValue(val);
      this.onTouched();
    }
  }

  ngOnInit(): void {
    if (!this.isInModal) {
      this.extraData.pipe(takeUntil(this.unSubscribeAll)).subscribe((val) => {
        if (val) {
          this.clear();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeAll.next();
    this.unSubscribeAll.complete();
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: CourseTemplateSelectorValue | null) => {};

  /**
   * Handle the touch of the element.
   */
  onTouched = () => {};

  writeValue(value: CourseTemplateSelectorValue | null): void {
    this.currentValue = value;
    this.generatePlaceHolder();
  }

  /**
   * @inheritdoc
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @inheritdoc
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Clear the status of the component.
   *
   * @param $event
   */
  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    this.value = null;
    this.dropDown?.close();
  }

  /**
   * Confirm the selection of the user.
   * It works only on desktop.
   *
   */
  confirm() {
    this.setValue(this.currentValue);
    this.onTouched();
    this.dropDown.close();
  }

  /**
   * Set the current template selected by the user.
   *
   * @param {CourseTemplate} type
   */
  setType(type: CourseTemplate) {
    this.value = { courseTemplate: type.id.toString() };
  }

  /**
   *  Set the value of this form control.
   *
   * @param value
   *  value to set as current value.
   * @param notPropagate
   *  flag to propagate modify of the value to parent.
   */
  private setValue(value: CourseTemplateSelectorValue | null, notPropagate = false): void {
    if (!value) {
      // actualy we don't need to the evolution skill to set.
      //this.evolutionCourseTypeControl.reset();
      // this.isEvolution = false;
      if (this.value) {
        this.currentValue = null;
      }
    } else if (this.isInModal) {
      this.currentValue = value;
    }
    if (!notPropagate) {
      this.onChange(value);
    }
    this.generatePlaceHolder();
  }

  /**
   * Generate the placeholder of the component.
   *
   */
  private generatePlaceHolder(): void {
    if (!this.currentValue) {
      this.selectedPlaceHolder = null;
      return;
    }

    this.selectedPlaceHolder = this.allTemplates.find(
      (template) => template.id === this.currentValue.courseTemplate
    )?.title;
    if (this.currentValue.evoType) {
      this.selectedPlaceHolder +=
        ' - ' + this.evolutionTypes$.value.find((type) => type.tid.toString() === this.currentValue.evoType).name;
    }
  }
}
