import { Injectable } from '@angular/core';
import { BaseService, DrupalConstants, HttpOptions } from '@makiwin/ngx-drupal8-rest';
import { map, Observable } from 'rxjs';
import {
  MessageThread,
  MessageNormalChat,
  MessageSchoolChat,
  MessageSupportChat,
  MessageContent,
  MessageThreadEntity,
} from '@app/core/models';

/**
 * Service for drupal 8 iko_messages_api custom module
 *
 * @see web/modules/custom/iko_messages_api/README.md
 * TODO
 */

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseService {
  /**
   * Implements /private_messages/collection: GET
   */
  collection(searchText?: string): Observable<MessageThread[]> {
    const httpOptions: HttpOptions = {
      method: 'get',
    };
    let url = '/private_messages/collection';
    if (searchText) {
      url += `?full_search=${searchText}`;
    }
    return this.request(httpOptions, url);
  }

  /**
   * Implements /private_messages/by_thread/{thread_id}: GET
   *
   * @param threadId the thread id
   */
  byThread(threadId: number): Observable<MessageContent[]> {
    const httpOptions: HttpOptions = {
      method: 'get',
      frags: [threadId],
    };
    return this.request(httpOptions, '/private_messages/by_thread/{thread_id}');
  }

  /**
   * Implements /private_messages/create: POST
   *
   * @param message the message entity
   */
  create(message: MessageNormalChat | MessageSchoolChat | MessageSupportChat): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/private-message/create', message);
  }

  /**
   * Implements /private_message/{private_message}: DELETE
   *
   * @param messageId the message id
   */
  deleteMessageById(messageId: number): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'delete',
      frags: [messageId],
    };
    return this.request(httpOptions, '/private_message/{private_message}');
  }

  /**
   * Implements /private_message/{thread_id}: DELETE
   *
   * @param threadId the thread id
   */
  deleteThreadById(threadId: number): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'delete',
      frags: [threadId],
    };
    return this.request(httpOptions, '/private_message/{thread_id}');
  }

  setRead(threadId: number) {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/private_message/time', { thread_id: threadId });
  }

  getThreadForUser(uids: number[]): Observable<MessageThread> {
    uids.unshift(DrupalConstants.Connection.current_user.uid);
    const httpOptions: HttpOptions = {
      method: 'get',
      frags: [uids.join('+')],
    };
    return this.request(httpOptions, `/private_messages/members_thread.json/${uids.join(',')}`).pipe(
      map((mte: MessageThreadEntity) => ({
        id: mte.id[0].value,
        field_thread_type: mte.field_thread_type?.[0]?.target_id?.toString() ?? null,
        updated: mte.updated[0].value,
        last_message: '', // missing
        is_new: false, // missing
        thread_members: mte.members.map((m) => ({
          uid: m.target_id,
        })) as any, // missing fields
        field_issue_category: '', // missing
        field_pm_subject: `${mte.field_pm_subject?.[0]?.target_id ?? null}`, // title missing
      }))
    );
  }
}
