import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavDataService } from '@app/core/services';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { DistanceFromMapValue } from '@app/shared/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber } from 'rxjs/internal/Subscriber';

@Component({
  selector: 'app-modal-map-form-control',
  templateUrl: './modal-map-form-control.component.html',
  styleUrls: ['./modal-map-form-control.component.scss'],
})
export class ModalMapFormControlComponent {
  observer?: Subscriber<DistanceFromMapValue>;
  modalRef?: NgbModalRef;
  mapControl: FormControl = new FormControl();
  initialDistance?: number;
  protected navDataService: NavDataService = inject(NavDataService);
  protected translateService: TranslateService = inject(TranslateService);
  private notificationsService: NotificationsService = inject(NotificationsService);

  confirm() {
    if (!this.mapControl.value) {
      this.notificationsService.notifyError(this.translateService.instant('Please select a location'));
      return;
    }
    this.observer.next(this.mapControl.value);
    this.modalRef.close();
  }
}
