import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { CustomRegisterResponse, NotificationMessage } from '@app/core/models';
import { RolesService } from '@app/core/services/roles/roles.service';
import { environment } from '@env/environment';

import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { ASAuthorizationAppleIDRequest, SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import {
  BaseService,
  DrupalConstants,
  FileEntity,
  HttpOptions,
  LoginResponse,
  PushRegistration,
  PushService,
  UserService,
} from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { DeviceDetectService } from '../../device-detect/device-detect.service';
import { ImageToolsService } from '../../image-tools/image-tools.service';
import { LocalStorageService } from '../../localstorage/localstorage.service';
import { NavDataService } from '../../nav-data/nav-data.service';
import { SplashScreenService } from '../../splash-screen/splash-screen.service';
import { StorageCacheService } from '../../storage-cache/storage-cache.service';
import { SubscriptionPlansService } from '../../subscription-plans/subscription-plans.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { LocationService } from '../../location/location.service';
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class CustomService extends BaseService {
  connected = true;

  cardColor: string;
  notificationMessage: NotificationMessage = {
    message: '',
    type: 'success',
    close: true,
  };
  forceUpdateLocation = false;

  startAfterLogout$: Subject<void> = new Subject<void>();
  endAfterLogout$: Subject<void> = new Subject<void>();
  logOutInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentToken: string;
  private notificationsService: NotificationsService = inject(NotificationsService);
  private locationService: LocationService = inject(LocationService);

  constructor(
    httpClient: HttpClient,
    private navDataService: NavDataService,
    private deviceDetectService: DeviceDetectService,
    private userService: UserService,
    private firebaseX: FirebaseX,
    private pushService: PushService,
    @Inject(PLATFORM_ID) platform: string,
    private signInWithApple: SignInWithApple,
    private imageTools: ImageToolsService,
    private roles: RolesService,
    private storageCacheService: StorageCacheService,
    private translateService: TranslateService,
    private splashScreenService: SplashScreenService,
    private subscriptionPlansService: SubscriptionPlansService,
    private localStorageService: LocalStorageService
  ) {
    super(httpClient, platform);
  }

  // override to get token from memory if user is logged
  getToken(): Observable<string> {
    if (this.connected && window.navigator?.onLine) {
      return super.getToken();
    }
    return of(this.currentToken ?? (this.currentToken = this.localStorageService.getItem('token')));
  }

  resetPassword(body: {
    uid: number;
    timestamp: number;
    token: string;
    password: string;
  }): Observable<{ status: boolean; message: string }> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/reset_password', body);
  }

  appleLogin() {
    return this.getToken().pipe(
      mergeMap((token) =>
        from(
          this.signInWithApple.signin({
            requestedScopes: [
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
            ],
          })
        ).pipe(
          mergeMap((appleData: any) => {
            appleData.access_token = appleData.authorizationCode;
            const httpOptions: HttpOptions = {
              method: 'post',
              headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-CSRF-Token': token,
              },
              params: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _format: 'json',
                code: appleData.authorizationCode,
              },
            };
            let oldAppleData: any = this.localStorageService.getItem('appleData');
            if (oldAppleData && !appleData.email) {
              oldAppleData = JSON.parse(oldAppleData);
              oldAppleData.access_token = appleData.access_token;
              oldAppleData.authorizationCode = appleData.authorizationCode;
              oldAppleData.identityToken = appleData.identityToken;
              oldAppleData.user = appleData.user;
              appleData = oldAppleData;
            }
            this.localStorageService.setItem('appleData', JSON.stringify(appleData));
            return this.request(httpOptions, '/user/login/apple', appleData).pipe(
              map((response) => {
                this.saveConnection(response, token);
                return response;
              })
            );
          })
        )
      )
    );
  }

  /**
   * TODO: Waiting confirmation #WEB-2373
   */
  finalizeQuizResult(qid: number, qrid: number): Observable<boolean> {
    const httpOptions: HttpOptions = {
      method: 'patch',
      frags: [qid, qrid],
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    };
    // If the user is logged in, add the CSRF header token
    if (DrupalConstants.Connection && DrupalConstants.Connection.csrf_token) {
      httpOptions.headers['X-CSRF-Token'] = DrupalConstants.Connection.csrf_token;
    }
    return this.request(httpOptions, '/quiz/{quiz}/result/{quiz_result}/finalize');
  }

  forgetPassword(body: { input: string }): Observable<boolean> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/trigger_reset_email', body);
  }

  add_remove_member(body: { action: string; uid: string }): Observable<{ status: boolean; message: string }> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/school_member', body);
  }

  setJobEndDate(body: { member_id: number; end_date: number }): Observable<{ status: boolean; message: string }> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/set_employment', body);
  }

  searchUsers(term: string) {
    const httpOptions: HttpOptions = {
      method: 'get',
      params: {
        search: term,
      },
    };
    return this.request(httpOptions, '/api/v1/users_list.json');
  }

  /**
   * convert a blob file object to base64 string
   *
   * @param file file object to be converted
   * @return base64 string for the file
   */
  convertFileToBase64(file: Blob): Observable<string | ArrayBuffer> {
    return new Observable((observer) => {
      // get file reader
      const reader = new FileReader();
      // read as data url
      reader.readAsDataURL(file);
      // return reader result
      reader.onload = () => {
        observer.next(reader.result);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
        observer.complete();
      };
    });
  }

  getFileObjectFromImgData(imageData: string, fileName: string) {
    const contentType = 'image/jpeg';
    const sliceSize = 512;

    const byteCharacters = atob(imageData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob: any = new Blob(byteArrays, { type: contentType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob as File;
  }

  sendLog(logType: string, logMessage: string) {
    let uid = 0;
    if (this.isLoggedIn) {
      uid = DrupalConstants.Connection.current_user.uid;
    }
    if (this.deviceDetectService.isCordova) {
      this.firebaseX.setCrashlyticsUserId(uid.toString());
      this.firebaseX.logError(logMessage);
    }
    // prevent anonymous user to post to the backend
    // the error already logged to firebase in cordova
    if (+uid === 0) {
      return;
    }
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    this.request(httpOptions, '/api/log', { type: logType, message: logMessage }).subscribe();
  }

  sendFaqSearchEntry(searchTerm: string) {
    if (searchTerm) {
      const httpOptions: HttpOptions = {
        method: 'post',
      };
      this.request(httpOptions, '/entity/iko_faq_search', { search_entry: [searchTerm] }).subscribe();
    }
  }

  registerUser(registerContent: { mail: string; full_name: string }): Observable<CustomRegisterResponse> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/create_account_sync', registerContent).pipe(
      mergeMap((response) =>
        this.getToken().pipe(
          map((token) => {
            if (response.status) {
              const connection: LoginResponse = {
                current_user: {
                  uid: response.user.user.uid[0].value,
                  name: response.user.user.name[0].value,
                  roles: response.user.user.roles.map((r) => r.target_id.toString()),
                },
                csrf_token: response.user.csrf_token,
                logout_token: response.user.logout_token,
              };
              this.saveConnection(connection, token);
            }
            return response;
          })
        )
      )
    );
  }

  deactivateMyAccount(): Observable<{ deactivated: boolean }> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/account_deactivation', { status: 0 }).pipe(
      map((data) => {
        if (data.deactivated) {
          this.logout();
        }
        return data;
      })
    );
  }

  activityTracking(data: any): Observable<null> {
    if (!this.userService.isLoggedIn) {
      return of();
    }
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/activity_tracking', data);
  }

  postfeedback(data: any): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/recreational_feedback/submit', data);
  }

  registerEmail(data: { mail: string }): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/register-user', data).pipe(
      mergeMap((response) =>
        this.getToken().pipe(
          map((token) => {
            if (response) {
              const connection: LoginResponse = {
                current_user: {
                  uid: response.user.uid[0].value,
                  name: '',
                  roles: response.user.roles.map((r) => r.target_id.toString()),
                },
                csrf_token: response.csrf_token,
                logout_token: response.logout_token,
              };
              this.saveConnection(connection, token);
            }
            return response;
          })
        )
      )
    );
  }

  submitCourseTrainerAction(nid: number, state: string) {
    const content = {
      course_id: nid,
      state,
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/course_trainer_action', content);
  }

  applyToDeal(nid: number): Observable<any> {
    const content = {
      deal_id: nid,
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/apply_deal', content);
  }

  updateMailChimpSubs(subs: { [key: string]: boolean }): Observable<any> {
    const bodyData = {};
    Object.keys(subs).forEach((sub) => {
      bodyData[sub] = subs[sub] ? 1 : 0;
    });
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    console.log(subs);
    return this.request(httpOptions, '/api/subscription', bodyData);
  }

  getQualificationsCardColor(data?: { title: string }): string {
    if (!data || !data.title) {
      this.cardColor = 'gray';
    } else {
      const qualification = data.title;
      if (
        qualification.indexOf('Trainer') !== -1 ||
        qualification.indexOf('Coach') !== -1 ||
        qualification.indexOf('Examiner') !== -1
      ) {
        this.cardColor = 'orange';
      } else if (qualification.indexOf('Instructor') !== -1) {
        this.cardColor = 'blue';
      } else if (qualification.indexOf('Kiteboarder') !== -1) {
        this.cardColor = 'green';
      }
    }
    return this.cardColor;
  }

  replaceCommaWithDot(data: any) {
    let value = data.toString().replace(/,/g, '.');
    value = parseFloat(value);
    return value.toFixed(2);
  }

  initPush() {
    if (!this.deviceDetectService.isCordova) {
      return;
    }
    this.firebaseX.hasPermission().then((hasPermission) => {
      if (hasPermission) {
        this.registerFcmToken();
      } else {
        this.firebaseX.grantPermission().then((grantPermission) => {
          if (grantPermission) {
            this.registerFcmToken();
          }
        });
      }
    });
  }

  submitDeviceInfo() {
    if (this.localStorageService.getItem('deviceInfoSubmitted')) {
      return;
    }
    const device = {
      cordova: this.deviceDetectService.device.cordova,
      model: this.deviceDetectService.device.model,
      platform: this.deviceDetectService.device.platform,
      version: this.deviceDetectService.device.version,
      manufacturer: this.deviceDetectService.device.manufacturer,
      uuid: this.deviceDetectService.device.uuid,
    };
    if (!device.uuid) {
      device.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;

        // eslint-disable-next-line no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    if (!this.deviceDetectService.isCordova) {
      device.cordova = 'N/A';
      device.platform = 'browser';
      device.version = environment.version;
      device.model = window.navigator?.userAgent;
    }
    if (device.platform === 'browser') {
      let OSName = 'Unknown';
      if (window.navigator?.userAgent.indexOf('Windows NT 10.0') !== -1) {
        OSName = 'Windows 10';
      }
      if (window.navigator?.userAgent.indexOf('Windows NT 6.2') !== -1) {
        OSName = 'Windows 8';
      }
      if (window.navigator?.userAgent.indexOf('Windows NT 6.1') !== -1) {
        OSName = 'Windows 7';
      }
      if (window.navigator?.userAgent.indexOf('Windows NT 6.0') !== -1) {
        OSName = 'Windows Vista';
      }
      if (window.navigator?.userAgent.indexOf('Windows NT 5.1') !== -1) {
        OSName = 'Windows XP';
      }
      if (window.navigator?.userAgent.indexOf('Windows NT 5.0') !== -1) {
        OSName = 'Windows 2000';
      }
      if (window.navigator?.userAgent.indexOf('Mac') !== -1) {
        OSName = 'Mac/iOS';
      }
      if (window.navigator?.userAgent.indexOf('X11') !== -1) {
        OSName = 'UNIX';
      }
      if (window.navigator?.userAgent.indexOf('Linux') !== -1) {
        OSName = 'Linux';
      }
      device.manufacturer = OSName;
    }
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    this.request(httpOptions, '/device_info/create', device).subscribe(() => {
      this.localStorageService.setItem('deviceInfoSubmitted', 'true');
    });
  }

  uploadFile(
    entityType: string,
    bundle: string,
    idAndfieldName: string,
    file: File,
    maxWidth: number = null
  ): Observable<FileEntity> {
    const httpOptions: HttpOptions = {
      method: 'post',
      frags: [entityType, bundle, idAndfieldName],
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Disposition': `file; filename="${file.name}"`,
      },
    };

    // If the user is logged in, add the CSRF header token
    if (DrupalConstants.Connection && DrupalConstants.Connection.csrf_token) {
      httpOptions.headers['X-CSRF-Token'] = DrupalConstants.Connection.csrf_token;
    }

    return this.imageTools
      .resizeImageFile(file, maxWidth)
      .pipe(
        mergeMap((fileResized) =>
          this.request(httpOptions, '/api/file/upload/{entity_type_id}/{bundle}/{field_name}', fileResized)
        )
      );
  }

  facebookConnect(accessToken: string): Observable<LoginResponse> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/user/login/facebook', { access_token: accessToken }).pipe(
      mergeMap((response) =>
        this.getToken().pipe(
          map((token) => {
            this.saveConnection(response, token);
            return response;
          })
        )
      )
    );
  }

  logout(): void {
    this.logOutInProgress$.next(true);
    if (this.deviceDetectService.isCordova) {
      this.splashScreenService.show();
    }
    const pushTokenId = +this.localStorageService.getItem('pushTokenId');
    if (pushTokenId) {
      this.pushService
        .delete(pushTokenId)
        .pipe(
          finalize(() => {
            this.localStorageService.removeItem('pushTokenId');
            this.logoutFromBackend();
          })
        )
        .subscribe();
    } else {
      this.logoutFromBackend();
    }
  }

  logoutFromBackend() {
    this.userService.logout().subscribe({
      next: () => {
        this.afterLogout();
      },
      error: () => {
        this.afterLogout();
      },
    });
  }

  afterLogout() {
    // empty current session
    this.startAfterLogout$.next();
    DrupalConstants.Connection = undefined;
    DrupalConstants.Token = undefined;
    DrupalConstants.TokenInit = false;
    // delete this.navDataService.currentUserProfile;
    this.navDataService.logoutUser();
    this.navDataService.sideMenuOpen = false;
    this.subscriptionPlansService.delete();
    this.roles.clearRole();
    this.storageCacheService.removeAllCaches();
    this.locationService.clearSession();

    const isCookieAccepted = this.localStorageService.getItem('cookieAccepted');

    this.localStorageService.clear();

    // avoid to show accept cookie again
    if (isCookieAccepted === 'true') {
      this.localStorageService.setItem('cookieAccepted', isCookieAccepted);
    }
    this.deleteCookieSession();

    // this.navDataService.navigate(
    //   this.deviceDetectService.isCordova ? environment.mainPage.login : environment.mainPage.ssr
    // );
    this.logOutInProgress$.next(false);
    this.navDataService.navigate('/');
    this.notificationsService.notifySuccess(this.translateService.instant('Logged out successfully'));
    this.endAfterLogout$.next();
    if (this.deviceDetectService.isCordova) {
      this.splashScreenService.hide();
    }
  }

  verifyInAppBrowser(orderId: number, iapData: IAPProduct): Observable<boolean> {
    const data = {
      order_id: orderId,
      receipt_data: btoa(JSON.stringify(iapData)),
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/verify_apple_pay', data);
  }

  verifyPaypal(orderId: number, paypalOrderId: string): Observable<boolean> {
    const data = {
      order_id: orderId,
      remote_id: paypalOrderId,
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/verify_paypal', data);
  }

  createCertification(data: any) {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/certification', data);
  }

  updateCertification(id: number, data: any) {
    const httpOptions: HttpOptions = {
      method: 'patch',
      frags: [id],
    };
    return this.request(httpOptions, '/certification/{id}', data);
  }

  createCoursePayment(bookindId: number): Observable<any> {
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/course-fee', { booking: bookindId });
  }

  voteToDeal(nid: number, type: string, value: 1 | -1) {
    const content = {
      entity_id: nid,
      entity_type: type,
      vote_value: value,
    };
    const httpOptions: HttpOptions = {
      method: 'post',
    };
    return this.request(httpOptions, '/api/up_down', content);
  }

  /**
   * remove base64 type details from base64 string
   *
   * @param content base64 string to remove the type from it
   */
  removeBase64(content: string): string {
    return content.split('base64,')[1];
  }

  public isEntityFlagged(entity_type: string, entity_id: string, flag_id: string) {
    const httpOptions: HttpOptions = {
      method: 'get',
      frags: [entity_type, entity_id, flag_id],
    };
    return this.request(httpOptions, '/entity/{entity_type}/{entity_id}/flagged/{flag_id}');
  }

  private registerFcmToken() {
    this.firebaseX.getToken().then((tokenString) => {
      if (!tokenString) {
        return;
      }
      const pushObject: PushRegistration = {
        token: [{ value: tokenString }],
        network: [{ value: this.deviceDetectService.device.platform.toLowerCase() }],
      };
      this.pushService.register(pushObject).subscribe((response) => {
        this.localStorageService.setItem('pushTokenId', `${response.id[0].value}`);
      });
    });
  }

  private deleteCookieSession(): void {
    window.cookieManager?.clear();
  }
}
