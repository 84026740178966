import { inject, Pipe, PipeTransform } from '@angular/core';
import { ToolsService } from '@app/core/services/tools/tools.service';

@Pipe({ name: 'entitiesHtml' })
export class EntitiesHtmlPipe implements PipeTransform {
  private tools: ToolsService = inject(ToolsService);

  transform(text: string): string {
    return this.tools.encodeHTMLEntities(text);
  }
}
