import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageToolsService {
  constructor(private zone: NgZone) {}

  resizeImageFile(file: File, width: number = null): Observable<File> {
    return new Observable((observer) => {
      if (!width) {
        observer.next(file);
        observer.complete();
        return;
      }

      const img: HTMLImageElement = new Image();

      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const cw = img.width;
        const ch = img.height;
        const cx = 0;
        const cy = 0;
        // image doesn't need to resize
        if (img.width < width) {
          observer.next(file);
          observer.complete();
          return;
        }
        const canvas: any = document.getElementById('canvas-to-resize');
        const cContext = canvas.getContext('2d');
        //   Calculate new canvas size and x/y coorditates for image
        const factorScale = width / img.width;
        const newWidth = Math.round(cw * factorScale);
        const newHeight = Math.round(ch * factorScale);
        canvas.width = newWidth;
        canvas.height = newHeight;
        cContext.drawImage(img, cx, cy, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            // ADD ZONE IN THE CALLBACK TO PUT IN CHANGE DETECTION OF ANGULAR
            this.zone.run(() => {
              blob.name = file.name;
              observer.next(blob as File);
              cContext.clearRect(0, 0, canvas.width, canvas.height);
              observer.complete();
            });
          },
          file.type,
          0.4
        );
      };
    });
  }

  preloadImage(urlImage: string): Observable<string> {
    return new Observable((observer) => {
      const img: HTMLImageElement = new Image();
      img.src = urlImage;

      img.onload = () => {
        this.zone.run(() => {
          observer.next(urlImage);
          observer.complete();
        });
      };
    });
  }

  getFileObjectFromImgData(imageData: string, fileName: string) {
    const contentType = 'image/jpeg';
    const sliceSize = 512;

    const byteCharacters = atob(imageData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob: any = new Blob(byteArrays, { type: contentType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob as File;
  }
}
