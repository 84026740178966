import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/services/i18n/i18n.service';

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// const padNumber = (value: number) => {
//   if (isNumber(value)) {
//     return `0${value}`.slice(-2);
//   } else {
//     return '';
//   }
// };

const isNumber = (value: any): boolean => !isNaN(toInteger(value));

const toInteger = (value: any): number => parseInt(`${value}`, 10);

@Injectable()
export class NgbDateIKOParserFormatter extends NgbDateParserFormatter {
  constructor(private i18nservice: I18nService) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    // let stringDate = '';
    // if (date) {
    //   const dateObj = new Date(date.year, +date.month - 1, date.day);
    //   stringDate += isNumber(date.day) ? padNumber(date.day) + ' ' : '';
    //   stringDate += isNumber(date.month)
    //     ? dateObj.toLocaleString(this.i18nservice.language, { month: 'short' }) + ' '
    //     : '';
    //   stringDate += date.year;
    // }
    // return stringDate;
    return this.i18nservice.localizeDateAndFormat(date);
  }
}
