export enum ModalTypes {
  instructorCoachPromo = 'instructor-coach-promo',
  subscriptions = 'subscriptions',
  payment = 'payment',
  languageSuggest = 'language_suggest',
  studentCertInfo = 'student-cert-info',
  requestHomeAddress = 'request-home-address',
  renewMembership = 'renew-membership',
  help = 'help',
  confirm = 'confirm',
  bankTransferInfo = 'bank-transfer-info',
  standard = 'standard',
  mapSelector = 'map-selector',
  segmentationForm = 'segmentation-form',
}
