import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RoundedMultiselectForFiltersComponent } from '../rounded-multiselect-for-filters/rounded-multiselect-for-filters.component';

@Component({
  selector: 'accordion-multiselect-for-filters',
  templateUrl: './accordion-multiselect-for-filters.component.html',
  styleUrls: ['./accordion-multiselect-for-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccordionMultiselectForFiltersComponent),
      multi: true,
    },
  ],
})
export class AccordionMultiselectForFiltersComponent extends RoundedMultiselectForFiltersComponent {}
