import { Injectable } from '@angular/core';
import { StudentCertInfoComponent } from './student-cert-info.component';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';

@Injectable({
  providedIn: 'root',
})
export class StudentCertInfoService extends ModalServiceModel {
  protected component = StudentCertInfoComponent;
}
