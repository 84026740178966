import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '@env/environment';
export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.hostname,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#ffc100',
      text: '#000',
      link: '#000',
    },
    button: {
      background: '#28a745',
      text: '#fff',
      border: 'transparent',
    },
  },
  type: 'opt-in',
};
