<ngx-intl-tel-input
  class="d-block w-100"
  cssClass="form-control w-100 {{ cssClass }} {{ successStatus }}"
  [preferredCountries]="[
    countryISO.Italy,
    countryISO.Germany,
    countryISO.France,
    countryISO.Spain,
    countryISO.UnitedStates
  ]"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
  [selectFirstCountry]="false"
  [maxLength]="15"
  [phoneValidation]="true"
  [searchCountryPlaceholder]="'Search Country' | translate"
  [formControl]="phoneControl"
  (ngModelChange)="changedValue()"
  (countryChange)="changedCountry($event)"
></ngx-intl-tel-input>
