import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalManagerInterface } from './modal-manager-interface';

export class ModalComponent {
  /**
   * Reference to the modal.
   */
  modalRef: NgbModalRef;
  /**
   * Extra data to be passed to the modal component.
   */
  extraData: any;
  /**
   * Reference to the instance of the modal manager service.
   */
  modalManager: ModalManagerInterface;

  /**
   * Close the modal.
   *
   * @param result
   *  Result to be passed to the caller of the modal
   */
  close(result?: any): void {
    this.modalRef.close(result);
  }

  /**
   * Dismiss the modal.
   *
   */
  dismiss(): void {
    this.modalRef.dismiss();
  }

  beforeShow(): void {}
}
