<div class="modal-header pt-1 text-center hide-in-trasparent">
  <h4 class="modal-title mx-auto" id="modal-basic-title">
    <span>{{ title | translate }}</span>
    <button
      type="button"
      class="btn-close btn-red position-absolute"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    ></button>
  </h4>
</div>
<div class="modal-body p-3 hide-in-trasparent">
  <div class="d-flex flex-column choose-source align-item-center" *ngIf="currentStep === steps.chooseSource">
    <ng-container *ngIf="!options.excludeCamera">
      <button
        class="btn btn-success d-flex flex-row align-items-center justify-content-center"
        (click)="importPicture(sources.camera)"
      >
        <i class="icon icon-camera me-2"></i>
        <span translate>Take a picture</span>
      </button>
      <div class="separator d-flex flex-row align-items-center justify-content-center">
        <div class="line"></div>
        <div class="or text-dark" translate>Or</div>
        <div class="line"></div>
      </div>
    </ng-container>
    <button
      class="btn d-flex flex-row align-items-center justify-content-center"
      (click)="importPicture(sources.gallery)"
    >
      <i class="icon icon-image me-2"></i>
      <span translate>Choose from gallery/file</span>
    </button>
  </div>

  <div *ngIf="currentStep === steps.takeFromWebCam">
    <div class="webcam-container position-relative">
      <webcam
        class="webcam"
        [height]="468"
        [width]="468"
        [trigger]="triggerWebcamPicObservable"
        (imageCapture)="handleImageWebcam($event)"
        [videoOptions]="videoOptions"
        mirrorImage="always"
        [imageQuality]="1"
        (initError)="handleInitErrorWebCam($event)"
      ></webcam>
      <img class="biometric" *ngIf="!options.hideBiometricGrid" src="/assets/images/blank.png" alt="biometric blank" />
    </div>
    <div class="trigger-webcam text-center">
      <button class="icon-button text-success" (click)="triggerWebcamPic()">
        <i class="icon icon-camera icon-64"></i>
      </button>
    </div>
  </div>
  <div *ngIf="currentStep === steps.cropImage" class="d-flex flex-column align-items-center cropper">
    <image-cropper
      [ngClass]="{ 'd-none': cropperInLoading }"
      [imageURL]="imageToCropper"
      [resizeToWidth]="options.maxWidth"
      [resizeToHeight]="options.maxHeight"
      (imageCropped)="imageCroppedReceive($event)"
      [aspectRatio]="aspectRatio"
      [roundCropper]="options.rounded"
      [containWithinAspectRatio]="false"
      (imageLoaded)="imageLoaded($event)"
      [onlyScaleDown]="true"
      [format]="options.format"
    ></image-cropper>
    <circular-spinner *ngIf="cropperInLoading" iconClass="font-size-100 loader-cropper"></circular-spinner>
    <div class="title-preview mb-2" translate>Preview</div>
    <div class="mb-4 result {{ options.rounded ? 'rounded' : null }}" *ngIf="imageCropped">
      <img
        [src]="imageCropped"
        width="{{ options.previewWidth }}"
        height="{{ options.previewHeight ?? 150 }}"
        alt="image cropped"
      />
    </div>
    <div class="text-center mt-2">
      <button class="btn btn-success d-flex align-items-center" (click)="complete()">
        <i class="icon icon-check me-2"></i> <span translate>Save</span>
      </button>
    </div>
  </div>
</div>
<!-- WEB-3833: Allow pdf and videos -->
<input
  hidden
  id="image-picker-cross-file-input-picker"
  name="file-picture"
  type="file"
  (change)="readFile($event.target.files[0])"
  accept="image/*,.pdf,video/*"
/>
<div *ngIf="cameraOpened" class="text-center camera-overlay position-fixed lr-0 top-0 bottom-0">
  <div class="modal-header">
    <button type="button" class="btn-close position-absolute" aria-label="Close" (click)="disableCamera()"></button>
  </div>
  <div class="lr-0 d-flex flex-row h-100 align-items-center justify-content-center" *ngIf="!options.hideBiometricGrid">
    <img src="/assets/images/biometric_face.svg" class="overlay-face" alt="biometric_face" />
  </div>
  <div class="preview-actions-container position-absolute lr-0 bottom-2">
    <button class="icon-button fw-bold btn btn-icon rounded-1" type="button" (click)="takePreviewPicture()">
      <i class="icon icon-32 icon-camera text-success"></i>
    </button>
  </div>
</div>
