import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { SharedModule } from '@app/shared/shared.module';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavDataService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';

@Component({
  selector: 'app-map-selector',
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.scss'],
})
export class MapSelectorComponent extends ModalComponent {
  protected currentPosition: FormControl = new FormControl();
  protected navDataService: NavDataService = inject(NavDataService);
  protected translateService: TranslateService = inject(TranslateService);
  private notificationsService: NotificationsService = inject(NotificationsService);

  beforeShow(): void {
    if (this.extraData?.initialValue) {
      this.currentPosition.setValue(this.extraData.initialValue);
    }
  }

  /*
   * Handle the 'close' choice of the user;
   */
  reject(): void {
    this.close(null);
  }

  /**
   * Handle the 'Yes' choice of the user;
   */
  confirm(): void {
    if (!this.currentPosition.value) {
      this.notificationsService.notifyError(this.translateService.instant('You should select a place in the map!'));
      return;
    }
    this.close(this.currentPosition.value);
  }
}
