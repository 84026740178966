<div class="container-fluid d-flex flex-column p-0">
  <div class="row w-100">
    <div
      class="pe-0 align-self-center z-index-2 p-0"
      *ngIf="!deviceDetectService.isDesktop && navDataService.leftButton"
      [ngClass]="{
        'col-1': !deviceDetectService.isMobile && !deviceDetectService.isTablet,
        'col-2': deviceDetectService.isMobile || deviceDetectService.isTablet
      }"
    >
      <button
        class="fw-bold btn text-white btn-icon btn-round btn-link"
        type="button"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="navDataService.leftButton.action()"
        *ngIf="navDataService.leftButton"
      >
        <i class="icon icon-32" [ngClass]="navDataService.leftButton.iconClass"></i>
      </button>
    </div>

    <div
      class="col align-self-center d-flex p-0"
      [ngClass]="{
        'flex-wrap': !navDataService.isLogged,
        'justify-content-center': !navDataService.ikoLogoCenterMobileVar,
        'justify-content-between':
          (!rolesService.userHasRole() && !navDataService.ikoLogoCenterMobileVar) ||
          (navDataService.ikoLogoCenter && deviceDetectService.isDesktop),
        'justify-content-end !important': navDataService.ikoLogoCenterMobileVar,
        'col-12': !navDataService.leftButton || deviceDetectService.isDesktop
      }"
    >
      <div
        #logoDropdown
        *ngIf="deviceDetectService.isDesktopOrTabletLandscape || navDataService.ikoLogo"
        class="navbar-brand position-relative px-2 py-0 d-flex justify-content-center align-items-center text-white ms-2 my-2"
        (touchstart)="handleMobileTouch()"
        (mouseup)="handleDesktopClick()"
        (mouseenter)="logoDropdownOpened = true"
        [ngClass]="{
          'ms-auto me-0': navDataService.ikoLogo && navDataService.leftButton,
          'me-1': deviceDetectService.isMobile,
          'd-xl-flex': isLogged,
          opened: logoDropdownOpened,
          logo: navDataService.ikoLogoCenterMobileVar,
          'rounded logoDropdown dropdown dropdown-toggle bg-primary-dark': !navDataService.ikoLogoCenterMobileVar
        }"
      >
        <img class="navbar-image me-1" width="125" height="58" src="/assets/images/iko-logo.svg" alt="IKO Logo" />

        <div
          (touchend)="openIWO($event)"
          (mousedown)="openIWO($event)"
          class="d-none box-shadow position-absolute w-100 align-items-center bg-primary-dark px-2 rounded-bottom"
        >
          <img class="navbar-image no-hover" width="300" height="90" ngSrc="/assets/images/iwo-logo.svg" alt="IWO Logo" />
        </div>
      </div>
      <!-- <div
        *ngIf="navDataService.ikoLogoCenter && deviceDetectService.isDesktop"
        class="align-self-center text-center text-white flex-grow-1"
      >
        <a class="navbar-brand p-0 d-flex justify-content-center" routerLink="/">
          <img class="navbar-image ms-6" width="125" height="58" src="/assets/images/iko-logo.svg" alt="IKO Logo" />
        </a>
      </div> -->
      <div
        *ngIf="!navDataService.ikoLogo && !rolesService.userHasRole()"
        class="align-self-center text-center text-white flex-grow-1 d-block d-lg-none"
        [innerHTML]="navDataService.currentPageTitle"
        translate
      ></div>

      <div
        *ngIf="deviceDetectService.isDesktopOrTabletLandscape && !navDataService.ikoLogoCenter"
        class="text-white d-flex"
      >
        <div class="py-2 d-flex justify-content-center header-menu">
          <!-- <div
            appElementHover
            class="pointer rounded-3 d-flex justify-content-center align-items-center align-self-center py-2 col"
            routerLinkActive="text-primary bg-white"
            [routerLink]="['/courses'] | localize"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <i class="icon icon-16 me-2 icon-chalkboard-teacher"></i>
            <div class="d-inline" translate>Courses</div>
          </div> -->
          <div
            ngbDropdown
            [autoClose]="isTablet ? 'outside' : false"
            class="dropdown me-xl-1"
            placement="bottom-center"
            #exploreMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(exploreMenu)"
            (mouseleave)="handleMouseLeave(exploreMenu)"
          >
            <div
              class="pointer rounded-3 text-decoration-none d-flex justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              (click)="handleDropDownMenu(exploreMenu)"
              [ngClass]="{
                'text-primary bg-white':
                  centerLink.isActive ||
                  membersLink.isActive ||
                  coursesLink.isActive ||
                  destinationsLink.isActive ||
                  blogLink.isActive ||
                  aboutLink.isActive
              }"
            >
              <i class="icon-lg icon icon-globe-americas"></i>
              <span class="ms-1" translate>Explore</span>
            </div>
            <div ngbDropdownMenu (click)="exploreMenu.close()">
              <a
                #centerLink="routerLinkActive"
                class="dropdown-item"
                [routerLink]="'/centers' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Find a Center</a
              >
              <a
                class="dropdown-item"
                #membersLink="routerLinkActive"
                [routerLink]="'/members' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Find a Member</a
              >
              <a
                class="dropdown-item"
                #coursesLink="routerLinkActive"
                [routerLink]="'/training-calendar' | localize"
                [routerLinkActive]="'active text-white'"
                [routerLinkActiveOptions]="{ exact: true }"
                translate
                >Find a Course</a
              >
              <a
                class="dropdown-item"
                #destinationsLink="routerLinkActive"
                [routerLink]="'/destinations' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Destinations</a
              >
              <a
                class="dropdown-item"
                #blogLink="routerLinkActive"
                [routerLink]="'/blog' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Blog</a
              >
              <a
                class="dropdown-item"
                #aboutLink="routerLinkActive"
                [routerLink]="'/about' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >About IKO</a
              >
            </div>
          </div>
          <div
            ngbDropdown
            [autoClose]="isTablet ? 'outside' : false"
            class="dropdown me-xl-1"
            placement="bottom-center"
            #learnMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(learnMenu)"
            (mouseleave)="handleMouseLeave(learnMenu)"
          >
            <div
              class="pointer text-decoration-none rounded-3 d-flex justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              (click)="handleDropDownMenu(learnMenu)"
              [ngClass]="{
                'text-primary bg-white':
                  videoLink.isActive ||
                  ebooksLink.isActive ||
                  ecourseLink.isActive ||
                  vcardLink.isActive ||
                  experienceLink.isActive ||
                  windWindowLink.isActive ||
                  kiteSignalsLink.isActive ||
                  rightOfWayLink.isActive ||
                  kiterboarderCourseLink.isActive ||
                  requestCertification.isActive
              }"
            >
              <i class="icon-lg icon icon-lightbulb"></i>
              <span class="ms-1" translate>Learn</span>
            </div>
            <div ngbDropdownMenu (click)="learnMenu.close()">
              <a class="dropdown-item" [href]="'/training-calendar' | translate" translate>Book a Lesson</a>
              <a
                class="dropdown-item"
                #videoLink="routerLinkActive"
                [routerLink]="'/elearning/videos' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Watch Tutorial Videos</a
              >
              <a
                class="dropdown-item"
                #ebooksLink="routerLinkActive"
                [routerLink]="'/elearning/ebooks' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Read Handbooks</a
              >
              <a
                class="dropdown-item"
                #ecourseLink="routerLinkActive"
                [routerLink]="'/ecourses' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Take an eCourse</a
              >
              <a
                class="dropdown-item"
                #kiterboarderCourseLink="routerLinkActive"
                [routerLink]="'/course/kiteboarder' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Become a Kiter</a
              >
              <a
                class="dropdown-item"
                #requestCertification="routerLinkActive"
                [routerLink]="'/request-certification' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Get Certified</a
              >
              <a
                class="dropdown-item {{ navDataService.isLogged ? 'd-none' : '' }}"
                #vcardLink="routerLinkActive"
                [routerLink]="'/vcard' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Get your vCard</a
              >
              <a
                class="dropdown-item"
                #experienceLink="routerLinkActive"
                [routerLink]="'/course/ease-your-kite-experience' | localize"
                [routerLinkActive]="'active text-white'"
                [routerLinkActiveOptions]="{ exact: true }"
                translate
                >Ease Your Kite Experience</a
              >
              <a
                class="dropdown-item"
                #windWindowLink="routerLinkActive"
                [routerLink]="'/elearning/gallery/wind-window' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Wind Window</a
              >
              <a
                class="dropdown-item"
                #kiteSignalsLink="routerLinkActive"
                [routerLink]="'/elearning/gallery/kite-signals' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Kite Signals</a
              >
              <a
                class="dropdown-item"
                #rightOfWayLink="routerLinkActive"
                [routerLink]="'/elearning/gallery/right-of-way' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Right of Way</a
              >
            </div>
          </div>
          <div
            ngbDropdown
            [autoClose]="isTablet ? 'outside' : false"
            class="dropdown me-xl-1"
            placement="bottom-center"
            #careerMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(careerMenu)"
            (mouseleave)="handleMouseLeave(careerMenu)"
          >
            <div
              class="pointer rounded-3 text-decoration-none d-flex justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              [routerLinkActive]="'text-primary bg-white'"
              (click)="handleDropDownMenu(careerMenu)"
              [ngClass]="{
                'text-primary bg-white':
                  becomeInstructorLink.isActive ||
                  becomeAssistantLink.isActive ||
                  becomeCoachLink.isActive ||
                  trainingPathLink.isActive ||
                  jobsLink.isActive
              }"
            >
              <i class="icon-lg icon icon-suitcase"></i>
              <span class="ms-1" translate>Career</span>
            </div>
            <div ngbDropdownMenu (click)="careerMenu.close()">
              <a
                #trainingPathLink="routerLinkActive"
                class="dropdown-item"
                [routerLink]="'/courses' | localize"
                [routerLinkActive]="'active text-white'"
                [routerLinkActiveOptions]="{ exact: true }"
                translate
                >Training Path</a
              >
              <a
                class="dropdown-item"
                #becomeAssistantLink="routerLinkActive"
                [routerLink]="'/course/assistant' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Become an Assistant</a
              >
              <a
                class="dropdown-item"
                #becomeInstructorLink="routerLinkActive"
                [routerLink]="'/course/instructor' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Become an Instructor</a
              >
              <a
                class="dropdown-item"
                #becomeCoachLink="routerLinkActive"
                [routerLink]="'/course/coach-level-1' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Become a Coach</a
              >
              <a
                class="dropdown-item"
                #jobsLink="routerLinkActive"
                [routerLink]="'/jobs' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Find a Job</a
              >
            </div>
          </div>
          <div
            ngbDropdown
            class="dropdown me-xl-1"
            placement="bottom-center"
            #membershipMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(membershipMenu)"
            (mouseleave)="handleMouseLeave(membershipMenu)"
            [autoClose]="isTablet ? 'outside' : false"
          >
            <div
              class="pointer rounded-3 d-flex text-decoration-none justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              (click)="handleDropDownMenu(membershipMenu)"
            >
              <i class="icon-lg icon icon-award"></i>
              <span class="ms-1" translate>Membership</span>
            </div>
            <div ngbDropdownMenu (click)="membershipMenu.close()">
              <a class="dropdown-item" (click)="modalManagerService.open()" translate>Kiter Membership</a>
              <a class="dropdown-item" (click)="modalManagerService.open()" translate>Assistant Membership</a>
              <a class="dropdown-item" (click)="modalManagerService.open()" translate>Instructor Membership</a>
              <a class="dropdown-item" [href]="'https://www.ikointl.com/center-membership' | translate" translate
                >Affiliate Your School</a
              >
            </div>
          </div>
          <div
            ngbDropdown
            class="dropdown me-xl-1"
            placement="bottom-center"
            #shopMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(shopMenu)"
            (mouseleave)="handleMouseLeave(shopMenu)"
            [autoClose]="isTablet ? 'outside' : false"
          >
            <div
              class="pointer rounded-3 d-flex text-decoration-none justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              (click)="handleDropDownMenu(shopMenu)"
              [routerLinkActive]="'text-primary bg-white'"
              [ngClass]="{
                'text-primary bg-white': merchandiseLink.isActive || dealsLink.isActive || shopHiddenLink.isActive
              }"
            >
              <i class="icon-lg icon icon-bags-shopping"></i>
              <span class="ms-1" translate>Shop</span>
            </div>

            <div ngbDropdownMenu (click)="membershipMenu.close()">
              <!-- <a class="dropdown-item" [href]="'https://www.ikointl.com/kitesurf-insurance' | translate" translate
                >Insurance</a
              > -->
              <a
                class="dropdown-item"
                #merchandiseLink="routerLinkActive"
                [routerLink]="'/shop' | localize"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLinkActive]="'active text-white'"
                translate
                >Merchandise</a
              >

              <a
                class="dropdown-item d-none"
                #shopHiddenLink="routerLinkActive"
                [routerLink]="'/shop' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >shop link hidden</a
              >
              <a
                class="dropdown-item"
                #dealsLink="routerLinkActive"
                [routerLink]="'/deals' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Member Deals</a
              >
              <!-- <a
                class="dropdown-item"
                [ngClass]="{ 'd-none': !isLogged }"
                #myOrdersLink="routerLinkActive"
                [routerLink]="'/shop/orders' | localize"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLinkActive]="'active text-white'"
                [queryParams]="{ 'stay-on-page': 1 }"
                translate
                >My orders</a
              > -->
            </div>
          </div>
          <div
            ngbDropdown
            class="dropdown"
            placement="bottom-center"
            #safetyMenu="ngbDropdown"
            (mouseenter)="handleMouseEnter(safetyMenu)"
            (mouseleave)="handleMouseLeave(safetyMenu)"
            [autoClose]="isTablet ? 'outside' : false"
          >
            <div
              class="pointer rounded-3 d-flex text-decoration-none justify-content-center align-items-center align-self-center py-2 col dropdown-toggle"
              (click)="handleDropDownMenu(safetyMenu)"
              [ngClass]="{
                'text-primary bg-white':
                  reportLink.isActive ||
                  standardsLink.isActive ||
                  chartOfSactionsLink.isActive ||
                  insuranceLink.isActive
              }"
            >
              <i class="icon-lg icon icon-sheriff"></i>
              <span class="ms-1" translate>Safety</span>
            </div>
            <div ngbDropdownMenu (click)="safetyMenu.close()">
              <a
                class="dropdown-item"
                #insuranceLink="routerLinkActive"
                [routerLink]="'/kitesurf-insurance' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Insurance</a
              >
              <a
                class="dropdown-item"
                #reportLink="routerLinkActive"
                [routerLink]="['/gutenberg', 301] | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Report an Issue</a
              >
              <a
                class="dropdown-item"
                #standardsLink="routerLinkActive"
                [routerLink]="'/documents/53' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Standards</a
              >
              <a
                class="dropdown-item"
                #chartOfSactionsLink="routerLinkActive"
                [routerLink]="'/documents/53' | localize"
                [routerLinkActive]="'active text-white'"
                translate
                >Chart of Sanctions</a
              >
              <a class="dropdown-item" (click)="openSpecialAdvisories()" translate>Special Advisories</a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex text-center text-white justify-content-center align-items-center me-2"
        *ngIf="!rolesService.userHasRole()"
      >
        <ng-container *ngIf="deviceDetectService.isDesktopOrTabletLandscape">
          <app-language-select
            [isContent]="urlIsContent"
            *ngIf="navDataService.displayLanguage"
            [isHeader]="true"
          ></app-language-select>
          <app-currency-select *ngIf="navDataService.displayCurrency" class="ms-2"></app-currency-select>
        </ng-container>
        <ng-container *ngFor="let action of navDataService.rightHeaderActions">
          <ng-container *ngIf="action.display">
            <div *ngIf="action.type === 'dropdown'" ngbDropdown class="dropdown ms-1" display="dynamic">
              <div *ngIf="action.iconClass; else currencyDropdown">
                <a
                  class="btn btn-icon btn-icon-mini dropdown-toggle position-relative ps-1 bg-light-transparent-1 text-white"
                  [ngClass]="action.btnClass"
                  ngbDropdownToggle
                >
                  <i class="icon-32 icon" [ngClass]="action.iconClass"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-font m-0 p-0" ngbDropdownMenu>
                  <li>
                    <a class="dropdown-item position-relative text-primary p-0 m-0">
                      <div class="li">
                        <button
                          *ngFor="let option of action.options"
                          class="btn-primary btn my-0"
                          (click)="option.action()"
                        >
                          <span [ngClass]="option.optionClass" [innerHTML]="option.content"></span>
                        </button>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <ng-template #currencyDropdown>
                <div *ngIf="deviceDetectService.isDesktopOrTabletLandscape">
                  <div class="btn dropdown-toggle bg-light-transparent-1" ngbDropdownToggle>
                    <span> {{ selectedCurrency }}</span>
                  </div>
                  <ul class="dropdown-menu-font dropdown-menu me-4" ngbDropdownMenu>
                    <li *ngFor="let option of action.options">
                      <div class="dropdown-item" (click)="selectedCurrency = option.content">
                        <span class="">{{ option.content }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </div>

            <button
              *ngIf="action.type === 'button'"
              [ngClass]="action.btnClass"
              class="btn btn-icon fw-bold btn-link text-white px-4 d-flex justify-content-center align-items-center align-self-center my-0"
              (click)="action.action()"
            >
              <i class="icon icon-32" [ngClass]="action.iconClass"></i>
              <span
                *ngIf="action.iconClass === 'icon-shopping-cart'"
                class="badge badge-success position-absolute ms-2"
                >{{ navDataService.cartCount }}</span
              >
            </button>
          </ng-container>
        </ng-container>
      </div>

      <div
        class="flex-grow-2 d-flex justify-content-end align-items-center"
        [ngClass]="{ 'flex-grow-1': deviceDetectService.isMobile }"
        *ngIf="rolesService.userHasRole()"
      >
        <div class="d-flex justify-content-end me-2">
          <a
            class="rounded-1 btn btn-outline p-2 border bg-transparent me-2 active text-decoration-none"
            [routerLink]="'/user/register' | localize"
            routerLinkActive="active-page"
          >
            <div translate>Join Now</div>
          </a>
          <a
            class="rounded-1 btn btn-outline p-2 border bg-transparent text-decoration-none active"
            [routerLink]="'/user/login' | localize"
            routerLinkActive="active-page"
          >
            <div translate>Login</div>
          </a>
          <app-language-select
            class="ms-2 d-none d-xl-block"
            [updateProfile]="false"
            *ngIf="navDataService.displayLanguage"
            [updateUi]="true"
          ></app-language-select>
          <ng-container *ngFor="let action of navDataService.rightHeaderActions">
            <ng-container *ngIf="action.display">
              <button
                *ngIf="action.type === 'button'"
                [ngClass]="action.btnClass"
                class="btn btn-icon fw-bold btn-link text-white px-4 ms-2 me-2 d-flex justify-content-center align-items-center align-self-center my-0"
                (click)="action.action()"
              >
                <i class="icon icon-32" [ngClass]="action.iconClass"></i>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
