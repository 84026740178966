<div [formGroup]="group" class="form-check" [class.disabled]="model.disabled">
  <label class="form-check-label">
    <input
      type="checkbox"
      class="form-check-input"
      [checked]="model.checked"
      [class.is-invalid]="showErrorMessages"
      [formControlName]="model.id"
      [id]="id"
      [indeterminate]="model.indeterminate"
      [name]="model.name"
      [ngClass]="getClass('element', 'control')"
      [required]="model.required"
      [tabindex]="model.tabIndex"
      [value]="model.value"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
    />
    <span class="form-check-sign" [ngClass]="{ checked: model.value }"></span>
    <span [innerHTML]="model.label" [ngClass]="[getClass('element', 'label'), getClass('grid', 'label')]"></span>
  </label>
</div>
