<div class="modal-header pt-1 text-center hide-in-trasparent poisition-relative">
  <h3 class="modal-title mx-auto" id="modal-basic-title">
    <span translate>Bank transfer info</span>
  </h3>
  <button type="button" class="btn-close btn-red position-absolute" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body p-3 p-md-4 mt-3 hide-in-trasparent">
  <p>
    {{ 'BANK_TRANSFER_WORKING_DAYS' | translate }}
  </p>
  <strong class="text-muted">
    {{ 'BANK_TRANFER_TITLE_SEPA' | translate }}
  </strong>
  <div class="bank-info">
    <table class="w-100">
      <tr>
        <th class="width-145">{{ 'BANK_BANK_NAME' | translate }}</th>
        <td>TransferWise Europe SA</td>
        <td class="width-32" (click)="copy('TransferWise Europe SA')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>
          {{ 'BANK_IBAN' | translate }}
        </th>
        <td>BE90 9670 4426 7732</td>
        <td (click)="copy('BE90 9670 4426 7732')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>{{ 'BANK_SWIFT_CODE' | translate }}</th>
        <td>TRWIBEB1XXX</td>
        <td (click)="copy('TRWIBEB1XXX')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>{{ 'BANK_ACCOUNT_HOLDER' | translate }}</th>
        <td>International Kiteboarding Organization, S.R.L.</td>
        <td (click)="copy('International Kiteboarding Organization, S.R.L.')"><i class="icon icon-copy"></i></td>
      </tr>
    </table>
  </div>
  <strong class="text-muted">
    {{ 'BANK_TRANFER_TITLE_ACH' | translate }}
  </strong>
  <div class="bank-info">
    <table class="w-100">
      <tr>
        <th class="width-145">{{ 'BANK_BANK_NAME' | translate }}</th>
        <td>WELLS FARGO BANK, N.A.</td>
        <td class="width-32" (click)="copy('WELLS FARGO BANK, N.A.')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>{{ 'BANK_ROUTING_NUMBER' | translate }}</th>
        <td>121000248</td>
        <td (click)="copy('121000248')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>
          {{ 'BANK_ACCOUNT_NUMBER' | translate }}
        </th>
        <td>40630129535970817</td>
        <td (click)="copy('40630129535970817')"><i class="icon icon-copy"></i></td>
      </tr>
      <tr>
        <th>{{ 'BANK_SWIFT_CODE' | translate }}</th>
        <td>WFBIUS6S</td>
        <td (click)="copy('WFBIUS6S')"><i class="icon icon-copy"></i></td>
      </tr>
    </table>
  </div>
  <div>
    <em
      >{{ 'BANK_SEND_PROOF' | translate }}
      <a class="text-success" href="mailto:accounting@ikointl.com">accounting@ikointl.com</a></em
    >
  </div>
</div>
