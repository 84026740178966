import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  protected splashScreenEl: any;
  protected player: AnimationPlayer;
  protected isOpen = false;

  constructor(private animationBuilder: AnimationBuilder, @Inject(DOCUMENT) private document: any) {
    this.splashScreenEl = this.document.body.querySelector('#iko-splash-screen');
  }

  hide(): void {
    if (!this.splashScreenEl) {
      return;
    }
    this.player = this.animationBuilder
      .build([
        style({ opacity: '1' }),
        animate(
          '400ms ease',
          style({
            opacity: '0',
            zIndex: '-10',
          })
        ),
      ])
      .create(this.splashScreenEl);
    this.isOpen = false;
    setTimeout(() => {
      this.player.play();
    }, 0);
  }

  show(): void {
    if (this.isOpen || !this.splashScreenEl) {
      return;
    }
    this.isOpen = true;
    this.player = this.animationBuilder
      .build([
        style({ opacity: '0', zindex: '-10' }),
        animate(
          '400ms ease',
          style({
            opacity: '1',
            zIndex: '9999',
          })
        ),
      ])
      .create(this.splashScreenEl);

    setTimeout(() => {
      this.player.play();
    }, 0);
  }
}
