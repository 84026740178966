import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { CustomService } from '../drupal8/custom/custom.service';
import { NavDataService } from '../nav-data/nav-data.service';
import { StorageCacheService } from '../storage-cache/storage-cache.service';
import { NotificationsService } from '../notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptorService implements HttpInterceptor {
  private notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    private navDataService: NavDataService,
    private deviceDetectService: DeviceDetectService,
    private customService: CustomService,
    private storageCacheService: StorageCacheService,
    private translateService: TranslateService
  ) {}

  /**
   * intercept the http requests and add loading progress and handle error
   *
   * @param req the current request
   * @param next the next http request handler
   * @return observable http event
   * @see https://angular.io/api/common/http/HttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const dupReq: HttpRequest<any> = req;
    let resourceName: string;
    // if the request is sent to the backend
    if (req.url.indexOf(DrupalConstants.backEndUrl) !== -1) {
      // add nginx config for stage
      // dupReq = req.clone({ headers: req.headers.set('Authorization', `Basic ${btoa('stage:St@g#123!')}`) });
      // get the resource name without special chars and the backend url
      resourceName = dupReq.url
        .replace(DrupalConstants.backEndUrl, '')
        .replace(new RegExp('/', 'g'), '_')
        .split('?')[0];
    }
    let isLocal = true;
    // if resource is not a local request
    if (dupReq.url.indexOf('./assets') === -1 && dupReq.url.indexOf('/api/log') === -1) {
      // start our loader here
      this.navDataService.httpRequestDetails.number++;
      isLocal = false;
    }
    // create new observable
    const source: Observable<HttpEvent<any>> = new Observable((observer: Observer<HttpEvent<any>>) => {
      // if the app is already offline and cordova available
      if (
        (!this.customService.connected || !window.navigator?.onLine) &&
        this.deviceDetectService.isCordova &&
        resourceName !== '_user_login' &&
        resourceName !== null
      ) {
        // get the cached event
        this.getFromCache(observer, resourceName);
        return;
      }
      // if the user is online or cordova not available, send the request as normal
      const subScription = next
        .handle(dupReq)
        .pipe(
          tap({
            next: (event: HttpEvent<any>) => {
              // set connected flag
              this.customService.connected = true;
              // in case of response
              if (event instanceof HttpResponse) {
                // send the event and finish the observer
                observer.next(event);
                observer.complete();
                // if cordova available cache the content
                if (this.deviceDetectService.isCordova && resourceName) {
                  // cache the file
                  this.storageCacheService.cacheHttpResponse(event, resourceName);
                }
              }
              // display or hide loading
              this.showEventMessage(event, isLocal);
            },
            error: (err) => {
              // in case of error and cordova available
              if (
                this.deviceDetectService.isCordova &&
                err.status === 0 &&
                resourceName &&
                resourceName.indexOf('_user_login') === -1
              ) {
                this.getFromCache(observer, resourceName, true);
                return err;
              } else if (err.status === 403) {
                if (err.url.indexOf('/user/login') === -1) {
                  this.navDataService.initProfile().subscribe((profile) => {
                    this.removeLoading();
                    if (!profile) {
                      this.customService.logout();
                      return;
                    }
                    observer.error(err);
                    observer.complete();
                    if (err.url.indexOf('apply_deal') === -1 && err.url.indexOf('job') === -1) {
                      this.notificationsService.notifyError(this.translateService.instant('Access denied'));
                    }
                  });
                }
              } else {
                // if cordova not available let service worker do the job
                this.removeLoading();
                observer.error(err);
                observer.complete();
              }
              // if cordova not available let service worker do the job
              this.removeLoading();
              observer.error(err);
              observer.complete();
            },
          })
        )
        .subscribe();

      return () => {
        subScription.unsubscribe();
      };
    });
    return source;
  }

  /**
   * get single resource from cache and sent the data
   *
   * @param observer the event observer to send the data with it
   * @param resourceName resource or file name
   */
  getFromCache(observer: Observer<any>, resourceName: string, noRedirect = false) {
    // offline flag
    // this.customService.connected = false;

    // get from custom service by resource name
    this.storageCacheService.getCachedHttpResponse(resourceName).subscribe({
      next: (data: any) => {
        // this line cost about 4 hours of warking, holy
        if (!data) {
          observer.complete();
          return;
        }
        const event = new HttpResponse(data);

        observer.next(event);
        observer.complete();
        this.removeLoading();
      },
      error: (err) => {
        console.error('no cache', err, resourceName);
        // in case of file not found, display no internet message and navigate back
        this.generateErrorCache(err, observer, noRedirect);
      },
    });
  }

  generateErrorCache(err: any, observer: Observer<any>, noRedirect): void {
    this.notificationsService.notifyError(this.translateService.instant('No internet connection'));
    if (!noRedirect) {
      this.navDataService.navigateBack();
    }
    observer.error(err);
    observer.complete();
    this.removeLoading();
  }

  /**
   * remove loading
   */
  removeLoading(isLocal = false) {
    if (!isLocal && this.navDataService.httpRequestDetails.number > 0) {
      this.navDataService.httpRequestDetails.number--;
    }
    delete this.navDataService.httpRequestDetails.progress;
  }

  /**
   * Return distinct message for sent, upload progress, & response events
   */
  private showEventMessage(event: HttpEvent<any>, isLocal: boolean) {
    // check for each even type
    switch (event.type) {
      case HttpEventType.Response:
        // when response event happened decrease the number of current requests
        this.removeLoading(isLocal);
        break;

      case HttpEventType.DownloadProgress:
        this.showPrecentage(event);
        break;

      case HttpEventType.UploadProgress:
        this.showPrecentage(event);
        break;
    }
  }

  private showPrecentage(event: HttpProgressEvent) {
    // if there is not content length set
    if (!event.total) {
      // if the size is setted manually
      if (this.navDataService.storage && this.navDataService.storage.content_size) {
        // getting the content size and add 50K for type convert and encode
        const size = +this.navDataService.storage.content_size;
        // add 1.3 of the size because of double encode
        event.total = Math.round(size + size / 1.3);
      }
    }
    // check if the content size available and size is large enough to display loader
    // content larger than 10 KB
    if (event.total && event.total > 10000) {
      // getting precentege done
      const downloadPercentDone = Math.round((100 * event.loaded) / event.total);
      // display download progress message
      this.navDataService.httpRequestDetails.progress = downloadPercentDone;
    }
  }
}
