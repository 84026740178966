import { Component, NgZone, OnInit } from '@angular/core';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
declare const Keyboard: any;
@Component({
  selector: 'gap-fixer-iphone',
  templateUrl: './gap-fixer-iphone.component.html',
  styleUrls: ['./gap-fixer-iphone.component.scss'],
})
export class GapFixerIphoneComponent implements OnInit {
  gapHeight = 0;
  bootHeight: number;
  onKeyboardHideBinded: () => void;
  constructor(private zone: NgZone, private deviceDetect: DeviceDetectService) {}

  ngOnInit(): void {
    if (!this.deviceDetect.isIOS) {
      return;
    }
    if (!Keyboard.isVisible) {
      this.bootHeight = window.innerHeight;
    } else {
      this.onKeyboardHideBinded = this.onKeyboardHide.bind(this);
      window.addEventListener('keyboardDidHide', this.onKeyboardHideBinded);
    }
    window.visualViewport?.addEventListener('resize', () => {
      if (!this.bootHeight) {
        return;
      }
      this.zone.run(() => {
        this.gapHeight = window.innerHeight - this.bootHeight;
        this.gapHeight = this.gapHeight < 0 ? 0 : this.gapHeight;
      });
    });
  }

  onKeyboardHide(): void {
    if (!this.bootHeight) {
      this.bootHeight = window.innerHeight;
      window.removeEventListener('keyboardDidHide', this.onKeyboardHideBinded);
    }
  }
}
