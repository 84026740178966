<div
  class="h-100 d-flex flex-column justify-content-center align-items-center"
  *ngIf="(googleMapsService.mapsLoaded$ | async) === false"
>
  <circular-spinner iconClass="success icon-64"></circular-spinner>
</div>
<div class="flex-will h-100 w-100 position-relative" *ngIf="googleMapsService.mapsLoaded$ | async">
  <google-map
    width="100%"
    height="35vh"
    class="h-100 w-100 flex-fill"
    (mapClick)="mapClick($event)"
    [(zoom)]="currentZoom"
    [options]="mapOptions"
    [center]="mapCenter$ | async"
    *ngIf="googleMapsService.mapsLoaded$ | async"
  >
    <map-marker #markerElem="mapMarker" *ngIf="value" [position]="value"> </map-marker>
    <map-circle
      [options]="{
        fillColor: '#005EB8',
        strokeColor: '#005EB8'
      }"
      *ngIf="value && showDistanceSelector"
      [center]="value"
      [radius]="value.distance * 1000"
    ></map-circle>
  </google-map>
  <div class="input-group address-searcher position-absolute" *ngIf="showAdressSearchBar">
    <div class="input-group-text"><i class="text-info icon icon-search"></i></div>
    <input
      appGooglePlacesAutocomplete
      class="form-control bg-white"
      (blur)="handleAddressChange()"
      (addressChange)="handleAddressChange($event)"
      [(ngModel)]="currentAutoCompleteText"
      [placeholder]="'Insert an address or location...' | translate"
      maxlength="128"
    />
  </div>
</div>
<div class="col-12" *ngIf="showDistanceSelector">
  <div class="form-group mb-5 custom-slider">
    <label class="text-primary d-flex align-items-center">
      <span translate>Distance (Km)</span>
    </label>
    <div class="d-flex flex-row align-items-center">
      <a
        class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
        (click)="decreaseDistance()"
      >
        <div>&minus;</div></a
      >
      <div class="px-2">{{ distanceValues.min }}</div>
      <div class="flex-fill">
        <nouislider [config]="distanceConfig" class="slider slider-success" [formControl]="distance"></nouislider>
      </div>
      <div class="px-2">{{ distanceValues.max }}</div>
      <a
        class="text-success d-flex align-items-center justify-content-center button-slider border border-success text-center rounded-circle"
        (click)="increaseDistance()"
      >
        <div>&plus;</div>
      </a>
    </div>
  </div>
</div>
