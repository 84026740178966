<div
  class="overflow-scroll h-100 sidebar-container bg-primary-gradient p-3 d-flex flex-column overflow-auto"
  *ngIf="!ereaderControllerService.displayToc"
  appBottomSafeArea
>
  <gap-fixer-iphone></gap-fixer-iphone>
  <app-profile-progress class="mb-2"></app-profile-progress>

  <ul class="navbar-nav flex-grow-1" data-background-color="white">
    <!-- fixing bookings urls -->
    <a
      #accountTrainingLink="routerLinkActive"
      routerLinkActive=""
      [routerLink]="['/account/training'] | localize"
      [routerLinkActiveOptions]="{ exact: false }"
      class="d-none"
    ></a>
    <a
      #accountRecTrainingLink="routerLinkActive"
      routerLinkActive=""
      [routerLink]="['/account/recreational-training'] | localize"
      [routerLinkActiveOptions]="{ exact: false }"
      class="d-none"
    ></a>
    <li
      [ngClass]="{
        'bg-white rounded-3': dashboard.isActive && !accountTrainingLink.isActive && !accountRecTrainingLink.isActive
      }"
      class="nav-item mx-0 link-margin"
    >
      <a
        #dashboard="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/account'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        [ngClass]="{
          'text-white': accountTrainingLink.isActive,
          'text-primary': !accountTrainingLink.isActive && !accountRecTrainingLink.isActive && dashboard.isActive
        }"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-home"></i>
        <span class="ms-3" translate>Dashboard</span>
      </a>
    </li>
    <li
      class="nav-item mx-0 link-margin"
      *ngIf="rolesService.isProfessional"
      [ngClass]="{
        'bg-white rounded-3': students.isActive
      }"
    >
      <a
        #students="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLink]="
          (rolesService.userHasRole(['assistant_basic', 'assistant_plus', 'assistant_premium'], true)
            ? ['/gutenberg/947']
            : ['/students']
          ) | localize
        "
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-graduation-cap"></i>
        <span class="ms-3" translate>Students</span>
      </a>
    </li>
    <!-- [ngClass]="{ 'bg-white rounded-3': messagesLink.isActive }" #messagesLink="routerLinkActive" routerLinkActive="text-primary" [routerLink]="['/messages'] | localize" [routerLinkActiveOptions]="{ exact: false }"-->
    <li *ngIf="!env.inAppBrowsing.messages; else messageExternal" class="nav-item mx-0 link-margin">
      <a (click)="navDataService.sideMenuOpen = false; openCrispChat()" class="nav-link py-2 px-3">
        <i class="icon-lg icon icon-envelope" aria-hidden="true"></i>
        <span class="ms-3" translate>Messages</span>
      </a>
    </li>
    <ng-template #messageExternal>
      <li class="nav-item mx-0 link-margin">
        <a
          [ngClass]="{ 'text-primary': navDataService.currentPage.indexOf('messages') !== -1 }"
          class="nav-link py-2 px-3"
          (click)="navDataService.navigate('/account/messages', true)"
        >
          <i class="icon-lg icon icon-envelope" aria-hidden="true"></i>
          <span class="ms-3" translate>Messages</span>
        </a>
      </li>
    </ng-template>
    <li
      [ngClass]="{
        'bg-white rounded-3': elearningLink.isActive || downloadsLink.isActive || ecoursesLink.isActive
      }"
      class="nav-item mx-0 link-margin"
    >
      <a
        [ngClass]="{
          'text-primary': elearningLink.isActive || downloadsLink.isActive || ecoursesLink.isActive
        }"
        class="nav-link py-2 px-3"
        #elearningLink="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/elearning'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navDataService.sideMenuOpen = false"
      >
        <i class="icon-lg icon icon-lightbulb"></i>
        <span class="ms-3" translate>eLearning</span>
      </a>
      <a
        #downloadsLink="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/documents'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        class="d-none"
      >
      </a>
      <a
        #ecoursesLink="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/ecourses'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        class="d-none"
      >
      </a>
    </li>
    <a
      #coursesTrainerLink="routerLinkActive"
      routerLinkActive=""
      [routerLink]="['/courses/trainer'] | localize"
      [routerLinkActiveOptions]="{ exact: false }"
      class="d-none"
    ></a>
    <li
      class="nav-item mx-0 link-margin"
      [ngClass]="{
        'bg-white rounded-3': accountTrainingLink.isActive || coursesLink.isActive || coursesTrainerLink.isActive
      }"
      *ngIf="rolesService.userHasRole(['assistant_trainer', 'examiner', 'school'], true)"
    >
      <a
        #coursesLink="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navigateToCourses()"
        [ngClass]="{ 'text-primary': accountTrainingLink.isActive || coursesTrainerLink.isActive }"
        class="nav-link py-2 px-3 hiding-stage"
      >
        <i class="icon-lg icon icon-sheriff"></i>
        <span class="ms-3" translate>Courses</span>
      </a>
    </li>
    <li
      class="nav-item mx-0 link-margin"
      [ngClass]="{
        'bg-white rounded-3': bookingsLink.isActive || accountRecTrainingLink.isActive
      }"
      *ngIf="!rolesService.userHasRole(['school_free'])"
    >
      <div
        [ngClass]="{ 'text-primary': bookingsLink.isActive || accountRecTrainingLink.isActive }"
        class="nav-link py-2 px-3 cursor-pointer"
        (click)="navigateToBookings()"
      >
        <i class="icon-lg icon icon-calendar-alt"></i>
        <span class="ms-3" translate>Bookings</span>
      </div>
      <a
        #bookingsLink="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/bookings'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        class="d-none"
      >
      </a>
    </li>
    <li
      class="nav-item mx-0 link-margin"
      [ngClass]="{
        'bg-white rounded-3': sanctionLink.isActive
      }"
    >
      <a
        #sanctionLink="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLink]="['/sanction'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-sheriff"></i>
        <span class="ms-3" translate>Quality</span>
      </a>
    </li>
    <li class="nav-item mx-0 link-margin" [ngClass]="{ 'bg-white rounded-3': shopLink.isActive }">
      <a
        [ngClass]="{ 'text-primary': shopLink.isActive }"
        [routerLink]="['/shop', 'orders'] | localize"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-bags-shopping"></i>
        <span class="ms-3" translate>Shop</span>
      </a>
      <a
        #shopLink="routerLinkActive"
        routerLinkActive=""
        [routerLink]="['/shop'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        class="d-none"
      >
      </a>
    </li>
    <li [ngClass]="{ 'bg-white rounded-3': dealsLink.isActive }" class="nav-item mx-0 link-margin">
      <a
        #dealsLink="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLink]="['/deals'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="iko icon-lg icon icon-gift" aria-hidden="true"></i>
        <span class="ms-3" translate>Deals</span>
      </a>
    </li>
    <li
      *ngIf="
        rolesService.userHasRole(
          ['kiter', 'assistant', 'examiner', 'coach', 'instructor', 'school', 'admin', 'office'],
          true
        ) && !rolesService.userHasRole('school_premium')
      "
      [ngClass]="{ 'bg-white rounded-3': jobsLink.isActive }"
      class="nav-item mx-0 link-margin"
    >
      <a
        #jobsLink="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLink]="
          (rolesService.userHasRole(['school_basic', 'school_plus', 'school_free'])
            ? ['/jobs/job-applications']
            : ['/jobs']
          ) | localize
        "
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="
          rolesService.userHasRole(['school_pending', 'school_expired', 'school_suspended'])
            ? modalManagerService.toggle()
            : (navDataService.sideMenuOpen = false)
        "
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-suitcase" aria-hidden="true"></i>
        <span class="ms-3" translate>Jobs</span>
      </a>
    </li>
    <li [ngClass]="{ 'bg-white rounded-3': helpLink.isActive }" class="nav-item mx-0 link-margin">
      <a
        #helpLink="routerLinkActive"
        routerLinkActive="text-primary"
        [routerLink]="['/help'] | localize"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="navDataService.sideMenuOpen = false"
        class="nav-link py-2 px-3"
      >
        <i class="icon-lg icon icon-info-circle"></i>
        <span class="ms-3" translate>Help</span>
      </a>
    </li>
  </ul>

  <div class="row text-white lr-0 px-3">
    <app-user-actions class="col-12"></app-user-actions>
    <div class="col-12 d-flex justify-content-between">
      <button class="col-5 btn bg-light-transparent-3 rounded-1" (click)="navigateToProfile()">
        <i class="icon-lg icon icon-user-edit"></i>
        <span class="ms-1" *ngIf="rolesService.userHasRole('school', true)" translate>My Center</span>
        <span class="ms-1" *ngIf="!rolesService.userHasRole('school', true)" translate>My Profile</span>
      </button>
      <button
        id="logout"
        class="col-5 btn bg-light-transparent-3 rounded-1 text-danger"
        (click)="logout()"
        *appNgVar="customService.logOutInProgress$ | async as logOutInProgress"
      >
        <i class="icon-lg icon icon-power-off" *ngIf="!logOutInProgress; else loaderLogout"></i>
        <span class="ms-1" translate>Logout</span>
      </button>
    </div>
  </div>
</div>

<div
  class="overflow-scroll h-100 sidebar-container bg-primary-gradient p-3 d-flex flex-column"
  *ngIf="ereaderControllerService.displayToc"
>
  <app-table-of-content class="d-flex flex-column"> </app-table-of-content>
</div>
<ng-template #loaderLogout>
  <circular-spinner iconClass="text-white"></circular-spinner>
</ng-template>
