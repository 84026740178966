import { trigger, state, style, transition, animate } from '@angular/animations';

export const sideInOut = trigger('slideInOut', [
  state(
    'true',
    style({
      right: '0',
    })
  ),
  state(
    'false',
    style({
      right: '-105%',
      left: 'auto',
    })
  ),
  transition('true => false', animate('200ms ease-in-out')),
  transition('false => true', animate('200ms ease-in-out')),
]);
