<ng-template #rtCountry let-country="result" let-t="term">
  <span class="d-inline-block iti-flag" [ngClass]="getFlagClass(country.code)"></span>
  <ngb-highlight class="ms-3" [result]="country.name" [term]="t"></ngb-highlight>
</ng-template>
<div class="input-group" [ngClass]="{ 'not-valid-field': showError }">
  <div class="input-group-text">
    <i class="icon {{ iconPrepend }}" *ngIf="iconPrepend && !currentCountry"></i>
    <span class="d-inline-block iti-flag" *ngIf="currentCountry" [ngClass]="getFlagClass(currentCountry?.code)"></span>
  </div>
  <input
    id="typeahead-focus"
    type="text"
    class="form-control no-border-left round-border-right"
    [ngClass]="{ 'is-invalid': showError }"
    [ngbTypeahead]="searchCountry"
    [value]="currentCountry?.name"
    (focus)="focusCountry$.next($any($event).target.value)"
    (click)="clickCountry$.next($any($event).target.value)"
    #input_country="ngbTypeahead"
    [resultTemplate]="rtCountry"
    (selectItem)="changedCountry($event)"
    [inputFormatter]="countryFormmatter"
    [placement]="placementCountryAutocomplete"
    [placeholder]="placeholder"
    popupClass="small-popup country-selector"
  />
</div>
