/* eslint-disable @typescript-eslint/naming-convention */
export interface SupportedLanguage {
  name: string;
  englishName: string;
  flag: string;
  mainLanguage?: boolean;
  pagesToShow?: ExtraLanguagePages[];
  // not sure if its required but just to not suddenly appears where is shouldn't
  // TODO: check and remove the filter in i18n service if not needed
  disabled?: boolean;
}

export interface SupportedLanguages {
  [key: string]: SupportedLanguage;
}

export enum ExtraLanguagePages {
  Ebook,
  WindWindows,
  KiteSignals,
  RightOfWay,
  Downloads,
  Ecourses_assistant,
}

export const supportedLanguages: SupportedLanguages = {
  en: {
    name: 'English',
    englishName: 'English',
    flag: 'gb',
    mainLanguage: true,
  },
  de: {
    name: 'Deutsch',
    englishName: 'German',
    flag: 'de',
    mainLanguage: true,
  },
  es: {
    name: 'Español',
    englishName: 'Spanish',
    flag: 'es',
    mainLanguage: true,
  },
  fr: {
    name: 'Français',
    englishName: 'French',
    flag: 'fr',
    mainLanguage: true,
  },
  it: {
    name: 'Italiano',
    englishName: 'Italian',
    flag: 'it',
    mainLanguage: true,
  },
  'pt-br': {
    name: 'Português',
    englishName: 'Portuguese',
    flag: 'pt',
    mainLanguage: true,
  },
  pl: {
    name: 'Polski',
    englishName: 'Polish',
    flag: 'pl',
    pagesToShow: [
      ExtraLanguagePages.Ebook,
      ExtraLanguagePages.Downloads,
      ExtraLanguagePages.KiteSignals,
      ExtraLanguagePages.RightOfWay,
      ExtraLanguagePages.WindWindows,
      ExtraLanguagePages.Ecourses_assistant,
    ],
  },
  ar: {
    name: 'العربية',
    englishName: 'Arabic',
    flag: 'sa',
    pagesToShow: [
      ExtraLanguagePages.Downloads,
      ExtraLanguagePages.KiteSignals,
      ExtraLanguagePages.RightOfWay,
      ExtraLanguagePages.WindWindows,
    ],
  },
  ru: {
    name: 'Pусский',
    englishName: 'Russian',
    flag: 'ru',
    pagesToShow: [
      ExtraLanguagePages.Downloads,
      ExtraLanguagePages.KiteSignals,
      ExtraLanguagePages.RightOfWay,
      ExtraLanguagePages.WindWindows,
    ],
  },
  'zh-hans': {
    name: '汉语',
    englishName: 'Chinese',
    flag: 'cn',
    pagesToShow: [
      ExtraLanguagePages.Downloads,
      ExtraLanguagePages.KiteSignals,
      ExtraLanguagePages.RightOfWay,
      ExtraLanguagePages.WindWindows,
    ],
  },
  ja: {
    name: '日本語',
    englishName: 'Japanese',
    flag: 'jp',
    pagesToShow: [ExtraLanguagePages.KiteSignals, ExtraLanguagePages.RightOfWay],
  },
  nl: {
    name: 'Nederlandsk',
    englishName: 'Dutch',
    flag: 'nl',
    pagesToShow: [
      ExtraLanguagePages.Downloads,
      ExtraLanguagePages.KiteSignals,
      ExtraLanguagePages.RightOfWay,
      ExtraLanguagePages.WindWindows,
    ],
  },
  uk: {
    name: 'Українська',
    englishName: 'Ukranian',
    flag: 'ua',
    pagesToShow: [ExtraLanguagePages.Downloads],
  },
  el: {
    name: 'ελληνικά',
    englishName: 'Greek',
    flag: 'gr',
    pagesToShow: [ExtraLanguagePages.KiteSignals, ExtraLanguagePages.RightOfWay],
  },
  da: {
    name: 'Dansk',
    englishName: 'Danish',
    flag: 'dk',
    pagesToShow: [ExtraLanguagePages.KiteSignals, ExtraLanguagePages.RightOfWay],
  },
  ca: {
    name: 'Catalan',
    englishName: 'Catalan',
    flag: 'cat',
    disabled: true,
  },
  ast: {
    name: 'Austria',
    englishName: 'Austria',
    flag: 'at',
    disabled: true,
  },
  sv: {
    name: 'Swedish',
    englishName: 'Swedish',
    flag: 'se',
    disabled: true,
  },
  ml: {
    name: 'Malayalam',
    englishName: 'Malayalam',
    flag: 'in',
    disabled: true,
  },
  zh: {
    name: '汉语',
    englishName: 'Chinese',
    flag: 'cn',
    disabled: true,
  },
};
