<div>
  <div class="input-group" *ngIf="ereaderControllerService.instance && ereaderControllerService.type === 'epub'">
    <input
      type="text"
      class="form-control placeholder-white text-white bg-light-transparent-4 border-0"
      id="search"
      [placeholder]="'Search' | translate"
      [formControl]="searchField"
      (change)="ereaderControllerService.search(searchField.value)"
      (keydown.enter)="ereaderControllerService.navigateNextResult()"
    />
    <span
      class="input-group-text text-white bg-light-transparent-4 border-0 m-0"
      (click)="ereaderControllerService.navigateNextResult()"
    >
      <i class="icon icon-search" *ngIf="!ereaderControllerService.results"></i>
      <i class="icon icon-arrow-circle-down" *ngIf="ereaderControllerService.results"></i>
    </span>
  </div>
</div>

<ul
  class="flex-grow-1 overflow-scroll table-of-content-list text-white"
  *ngIf="ereaderControllerService.type === 'epub'"
>
  <ng-container *ngTemplateOutlet="tagTemplate"></ng-container>
</ul>

<ol
  class="flex-grow-1 overflow-scroll table-of-content-list text-white"
  *ngIf="ereaderControllerService.type === 'quiz'"
>
  <h3 *ngIf="ereaderControllerService.tocTitle">{{ ereaderControllerService.tocTitle }}</h3>
  <ng-container *ngTemplateOutlet="tagTemplate"></ng-container>
</ol>

<ng-template #tagTemplate>
  <li *ngFor="let item of ereaderControllerService.tableOfContent">
    <a (click)="ereaderControllerService.navigate(item.destination)">
      {{ item.title }}
    </a>
  </li>
</ng-template>
