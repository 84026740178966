import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { debounceTime, distinctUntilChanged, Observable, OperatorFunction, switchMap } from 'rxjs';

export interface Coach {
  uid: string;
  field_first_name: string;
  field_surname: string;
  name: string;
  user_picture: any;
}

@Component({
  selector: 'coach-selector',
  templateUrl: './coach-selector.component.html',
  styleUrls: ['./coach-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoachSelectorComponent),
      multi: true,
    },
  ],
})
export class CoachSelectorComponent implements ControlValueAccessor {
  @Input() inputClass: string;
  @Input() needOnlyId = false;
  currentCoach: Coach;
  currentCoachModel = new FormControl(null);
  isDisabled = false;
  onChange;
  onTouch;

  constructor(private viewService: ViewService) {
    this.currentCoachModel.valueChanges.subscribe((value) => this.onChangeCoach(value));
  }

  formatter = (x: Coach) => x.field_first_name + ' ' + x.field_surname;

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.viewService.get('/api/v1/coaches-search', {
          filters: { search: term },
          pagination: { items_per_page: 50 },
        })
      )
    );

  writeValue(obj: any): void {
    this.currentCoachModel.setValue(obj);
    // TODO set the value of the instructor
    // in case of the Coach object is fine,
    // in casae of the id we need to fetch the coach by id
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onChangeCoach(value) {
    if (!this.onChange) {
      return;
    }
    if (!value || value === '') {
      this.onChange(null);
    }
    if (typeof value === 'string') {
      return;
    }
    this.onChange(this.needOnlyId ? value.uid : value);
  }
}
