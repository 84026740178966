import { Component, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ItemListForFilters } from '@app/shared/forms/models/item-list-for-filters.model';
import { ResponsiveSearchFiltersInterface } from '@app/shared/forms/responsive-search-filters/responsive-search-filters-component.interface';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

export interface ChipFiltersExtraData {
  multiSelect?: boolean;
}

@Component({
  selector: 'chip-filters',
  templateUrl: './chip-filters.component.html',
  styleUrls: ['./chip-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipFiltersComponent),
      multi: true,
    },
  ],
})
export class ChipFiltersComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<ItemListForFilters>, OnDestroy
{
  @Input() placeHolder;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: ItemListForFilters;
  @Input() replaceMobileButton = false;
  @Input() extraData?: BehaviorSubject<any>;

  protected itemsSelected: ItemListForFilters[] = [];
  protected internalItems: ItemListForFilters[];
  protected isMultiSelect = false;
  private unSubscribeAll: Subject<void> = new Subject<void>();

  get value(): any[] {
    const values = this.itemsSelected.map((item) => item.value);
    return values.length > 0 ? (this.isMultiSelect ? values : values[0]) : null;
  }

  @Input() set items(items: ItemListForFilters[] | Observable<ItemListForFilters[]>) {
    if (items instanceof Observable) {
      items.pipe(takeUntil(this.unSubscribeAll)).subscribe((itemsAsync) => this.handleItems(itemsAsync));
      return;
    }
    this.handleItems(items);
  }

  @Input() set extraDataValue(extraData: ChipFiltersExtraData) {
    this.isMultiSelect = extraData?.multiSelect ?? false;
  }
  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: any | null) => {};

  /**
   * Handle the touch of the element.
   */
  onTouched = () => {};

  writeValue(value: any | null): void {
    if (!Array.isArray(value)) {
      value = [value];
    }
    this.convertValues(value);
  }

  /**
   * @inheritdoc
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * @inheritdoc
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @inheritdoc
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.unSubscribeAll.next();
    this.unSubscribeAll.complete();
  }

  /**
   * Clear the status of the component.
   *
   * @param $event
   */
  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    $event.preventDefault();
    this.itemsSelected = [];
    this.internalItems.forEach((item) => (item.selected = false));
  }

  /**
   * Handle the click/tap of the cbip
   *
   * @param item
   */
  toggleCheck(item: any) {
    item.selected = !item.selected;
    if (this.isMultiSelect) {
      if (item.selected) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        if (index !== -1) {
          this.itemsSelected.splice(index, 1);
        }
      }
    } else {
      this.internalItems.forEach((internalItem) =>
        internalItem.id !== item.id ? (internalItem.selected = false) : null
      );
      if (item.selected) {
        this.itemsSelected = [item];
      } else {
        this.itemsSelected = [];
      }
    }
    this.onChange(this.value);
  }

  /**
   * Converted the ids in selected items
   *
   * @param val
   *  values
   */
  protected convertValues(val: any[]) {
    this.itemsSelected = [];

    this.internalItems?.forEach((item) => (item.selected = false));
    if (val && Array.isArray(val)) {
      val.forEach((value) => {
        const item = this.internalItems?.find((internalItem) => +internalItem.value === +value);
        if (!item) {
          return;
        }
        item.selected = true;
        this.itemsSelected.push(item);
      });
    }
  }

  private handleItems(items: ItemListForFilters[]) {
    this.internalItems = items;
    if (this.initialValue) {
      this.convertValues(Array.isArray(this.initialValue) ? this.initialValue : [this.initialValue]);
      this.initialValue = null;
    }
  }
}
