<div
  *ngIf="isModal; else content"
  [ngClass]="{
    'bg-primary-gradient text-white': deviceDetectService.isMobile
  }"
>
  <ng-container [ngTemplateOutlet]="content"> </ng-container>
</div>
<ng-template #loader>
  <div class="modal-body text-center py-5">
    <circular-spinner></circular-spinner>
  </div>
</ng-template>

<ng-template #content>
  <ng-container *ngIf="membershipPlans$ | async as membershipPlans; else loader">
    <div class="modal-header pt-1 mb-1">
      <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
        {{
          membershipPlans && membershipPlans[0] ? membershipPlans[0].product_title : ('Membership plans' | translate)
        }}
      </h3>
      <button class="btn-close btn-red" (click)="dismiss()" *ngIf="isModal"></button>
    </div>
    <div class="modal-body text-center py-2">
      <div class="extra-small" *ngIf="membershipPlans" [innerHTML]="membershipPlans[0]?.body"></div>
      <div class="d-block d-md-none swiper-benefit">
        <swiper class="swiper pb-5" [config]="swiperConfig" *ngIf="activeProduct">
          <ng-template swiperSlide *ngFor="let benefit of activeProduct?.field_benefits_export">
            <div class="row justify-content-center mb-4">
              <div class="col-10">
                <image-srcset [item]="benefit.image"></image-srcset>
              </div>
            </div>
            <div class="extra-small my-3" [innerHTML]="benefit.details"></div>
          </ng-template>
          <span
            slot="container-start"
            class="button-prev start-0 position-absolute button-navigation-swiper l-0 pointer"
          >
            <i class="icon icon-64 icon-angle-left"></i>
          </span>
          <span slot="container-end" class="button-next position-absolute button-navigation-swiper r-0 pointer">
            <i class="icon icon-64 icon-angle-right"></i>
          </span>
          <div class="swiper-pagination my-3"></div>
        </swiper>
      </div>
      <div class="d-none d-md-block swiper-benefit">
        <swiper class="swiper" [config]="swiperConfig" *ngIf="activeProduct">
          <ng-template swiperSlide *ngFor="let benefit of activeProduct?.field_benefits_export">
            <div class="col-12">
              <div class="justify-content-center">
                <image-srcset [item]="benefit.image"></image-srcset>
                <div class="extra-small my-3" [innerHTML]="benefit.details"></div>
              </div>
            </div>
          </ng-template>
          <span
            slot="container-start"
            class="button-prev start-0 position-absolute button-navigation-swiper l-0 pointer"
          >
            <i class="icon icon-64 icon-angle-left"></i>
          </span>
          <span slot="container-end" class="button-next position-absolute button-navigation-swiper r-0 pointer">
            <i class="icon icon-64 icon-angle-right"></i>
          </span>
        </swiper>
      </div>
      <div *ngIf="hours !== '00' || minutes !== '00' || seconds !== '00'" class="row justify-content-center">
        <div *ngIf="hours" class="col-3 col-lg-1 mb-5 mx-1 p-0">
          <h2 class="m-0 text-danger text-shadow">{{ hours }}</h2>
          <div class="small">Hours</div>
        </div>
        <div *ngIf="minutes" class="col-3 col-lg-1 mb-5 mx-1 p-0">
          <h2 class="m-0 text-danger text-shadow">{{ minutes }}</h2>
          <div class="small">Minutes</div>
        </div>
        <div *ngIf="seconds" class="col-3 col-lg-1 mb-5 mx-1 p-0">
          <h2 class="m-0 text-danger text-shadow">{{ seconds }}</h2>
          <div class="small">Seconds</div>
        </div>
      </div>
      <div class="mt-5 d-block d-md-none">
        <div class="row">
          <div class="col px-0" *ngFor="let product of membershipPlans; let i = index">
            <div
              (click)="selectProduct(product)"
              class="p-2 rounded-top"
              [ngClass]="{
                'bg-light-transparent-4': activeProduct?.variation_id === product.variation_id,
                'mt-n5': i === 1
              }"
            >
              <span class="small" *ngIf="i === 1" translate>MOST POPULAR<br /></span>
              <h4 class="mb-2 mt-0">{{ product.field_membership_plan }}</h4>
              <div *ngIf="activeProduct?.variation_id !== product.variation_id">
                <span *ngIf="product.price_discount !== product.price">
                  <s>{{ product.price }} {{ product.price__currency_code }}</s>
                  <span>{{ product.price_discount }} {{ product.price__currency_code }}</span>
                </span>
                <span *ngIf="product.price_discount === product.price"
                  >{{ product.price }} {{ product.price__currency_code }}</span
                >
              </div>
              <button
                *ngIf="activeProduct?.variation_id === product.variation_id"
                class="mb-0 py-2 box-shadow btn btn-success rounded btn-sm btn-block"
                (click)="pay(product)"
              >
                <span *ngIf="product.price_discount !== product.price">
                  <s>{{ product.price }} {{ product.price__currency_code }}</s>
                  <span>{{ product.price_discount }} {{ product.price__currency_code }}</span>
                </span>
                <span *ngIf="product.price_discount === product.price">
                  {{ product.price }} {{ product.price__currency_code }}</span
                >
              </button>
              <i
                class="icon icon-32 icon-angle-right rotate-90"
                *ngIf="activeProduct?.variation_id === product.variation_id"
              ></i>
            </div>
          </div>
        </div>
        <div class="row text-start bg-light-transparent-4 rounded" *ngIf="activeProduct" [ngClass]="featuresRadious">
          <div class="col-12">
            <h3 class="text-center mt-3" translate>Features</h3>
          </div>
          <div class="col-12 mb-3 px-2" *ngFor="let feature of activeProduct?.field_features_export; last as isLast">
            <div [ngClass]="{ 'border-bottom border-white': !isLast }">
              <div class="row px-2">
                <div class="col-2 px-1 small text-center"><i class="icon icon-32" [ngClass]="feature.icon"></i></div>
                <div class="col-8 px-1 extra-small" [innerHTML]="feature.details"></div>
                <div class="col-2 px-1 small align-self-center text-center">
                  <i class="icon icon-32 icon-check" *ngIf="+feature.included"></i
                  ><i class="icon icon-32 icon-times" *ngIf="!+feature.included"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-3" translate>Billed yearly<br /><span translate>Cancel anytime</span></div>
      </div>
      <div class="d-none d-md-block">
        <div class="row mt-5">
          <div class="col-md-4" [ngClass]="{ 'mt-n5': i === 1 }" *ngFor="let memberS of membershipPlans; let i = index">
            <div class="card card-pricing bg-primary-gradient price-card">
              <div class="card-body">
                <h6 class="category text-white">
                  <span *ngIf="i === 1" translate>MOST POPULAR<br /></span>{{ memberS.field_membership_plan }}
                </h6>
                <div class="icon icon-primary mb-6">
                  <i class="icon icon-star m-auto py-0"></i>
                </div>
                <button
                  class="py-2 box-shadow btn btn-success rounded-1 btn-sm btn-block mb-2"
                  (click)="pay(memberS)"
                  translate
                >
                  <span *ngIf="memberS.price_discount !== memberS.price">
                    <s>{{ memberS.price }} {{ memberS.price__currency_code }}</s>
                    <span>{{ memberS.price_discount }} {{ memberS.price__currency_code }}</span>
                  </span>
                  <span *ngIf="memberS.price_discount === memberS.price"
                    >{{ memberS.price }} {{ memberS.price__currency_code }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 border p-3">
          <div class="p-0 col-6">
            <div class="rounded-1">
              <h5 class="m-0 border-bottom fw-bold text-primary text-start" translate>Benefits</h5>
              <ul class="p-0 m-0 text-start list-unstyled">
                <li
                  class="border-bottom text-start p-0 mt-3 min-height-135"
                  *ngFor="let feature of membershipPlans[membershipPlans.length - 1]?.field_features_export"
                >
                  <p class="m-0 extra-small" [innerHTML]="feature.title" translate></p>
                </li>
              </ul>
            </div>
          </div>

          <div class="p-0 col-2" *ngFor="let membership of membershipPlans">
            <div class="rounded-1">
              <h5 class="m-0 border-bottom fw-bold text-primary">
                {{ membership.field_membership_plan }}
              </h5>
              <ul class="m-0 list-unstyled">
                <li class="border-bottom p-0 mt-3 min-height-135" *ngFor="let fet of membership.field_features_export">
                  <i class="icon icon-32 icon-check text-green" *ngIf="+fet.included"></i
                  ><i class="icon icon-32 icon-times text-danger" *ngIf="!+fet.included"></i>
                  <p class="m-0 extra-small" [innerHTML]="fet.description" translate></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="my-4 text-center" *ngIf="membershipPlans.length === 0">
        <a
          class="btn btn-success rounded-1"
          (click)="navDataService.navigate('https://www.ikointl.com/roles-fees-management', true)"
          translate=""
        >
          Renew Membership
        </a>
      </div>
      <h3 class="ms-6" translate>
        FAQ
        <span class="pull-right small mt-2" (click)="dismiss(); navDataService.navigate('/help/faq')" translate
          >See All</span
        >
      </h3>
      <div class="row text-start">
        <div *ngFor="let question of faq; let i = index" class="col-12 col-md-6">
          <ngb-accordion class="d-block my-1">
            <ngb-panel [id]="'faq-panel-' + i">
              <ng-template ngbPanelTitle>
                <div class="row align-items-center">
                  <div class="col-1 text-info text-start">
                    <i class="icon icon-16 icon-info-circle text-white-mobile"></i>
                  </div>
                  <div class="col icon-16 text-white-mobile" [innerHTML]="question.title"></div>
                  <div class="col-1 text-end p-0 pe-3 text-white-mobile">
                    <i class="icon icon-32 text-white icon-angle-up"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="extra-small" [innerHTML]="question.body"></div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <!-- <div class="d-none d-md-block">
    <div class="row mt-5">
      <div
        class="col-md-4"
        [ngClass]="{ 'mt-n5': i === 1 }"
        *ngFor="let memberS of membershipPlans; let i = index"
      >
        <div class="card card-pricing bg-primary-gradient price-card">
          <div class="card-body">
            <h6 class="category text-white">
              <span *ngIf="i === 1" translate>MOST POPULAR<br /></span>{{ memberS.field_membership_plan }}
            </h6>
            <div class="icon icon-primary mb-6">
              <i class="icon icon-star m-auto py-0"></i>
            </div>
            <button
              class="py-2 box-shadow btn btn-success rounded-1 btn-sm btn-block mb-2"
              (click)="pay(memberS)"
              translate
            >
              <span *ngIf="memberS.price_discount !== memberS.price">
                <s>{{ memberS.price }} {{ memberS.price__currency_code }}</s>
                <span>{{ memberS.price_discount }} {{ memberS.price__currency_code }}</span>
              </span>
              <span *ngIf="memberS.price_discount === memberS.price"
                >{{ memberS.price }} {{ memberS.price__currency_code }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
      <!-- <div class="row mt-6 d-md-none d-flex">
    <div class="col px-1" *ngFor="let product of membershipPlans; let i = index">
      <div
        (click)="selectProduct(product)"
        class="p-2 rounded"
        [ngClass]="{ 'bg-light-transparent-4': activeProduct?.variation_id === product.variation_id, 'mt-n5': i === 1 }"
      >
        <span class="small" *ngIf="i === 1" translate>MOST POPULAR<br /></span>
        <h4 class="mb-2 mt-0">{{ product.field_membership_plan }}</h4>
        <div *ngIf="activeProduct?.variation_id !== product.variation_id">
          <span *ngIf="product.price_discount !== product.price">
            <s>{{ product.price }} {{ product.price__currency_code }}</s>
            <span>{{ product.price_discount }} {{ product.price__currency_code }}</span>
          </span>
          <span *ngIf="product.price_discount === product.price"
            >{{ product.price }} {{ product.price__currency_code }}</span
          >
        </div>
        <button
          *ngIf="activeProduct?.variation_id === product.variation_id"
          class="py-2 box-shadow btn btn-success rounded btn-sm btn-block"
          (click)="pay(product)"
        >
          <span *ngIf="product.price_discount !== product.price">
            <s>{{ product.price }} {{ product.price__currency_code }}</s>
            <span>{{ product.price_discount }} {{ product.price__currency_code }}</span>
          </span>
          <span *ngIf="product.price_discount === product.price"
            >{{ product.price }} {{ product.price__currency_code }}</span
          >
        </button>
      </div>
    </div>
  </div> -->
    </div>
  </ng-container>
</ng-template>
