<div class="d-flex flex-column flex-grow-1 overflow-scroll text-center">
  <div class="modal-header pt-1 mb-1">
    <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
      <span class="d-inline-block align-middle ms-1" translate></span>
    </h3>
    <button class="btn-close btn-red" (click)="dismiss()"></button>
  </div>
  <div class="modal-body text-center" translate>
    <div><i class="icon icon-exclamation-triangle icon-warning icon-48"></i></div>
    <div translate>To complete the order you need to set your home address.</div>
    <div translate>Do you want to do it now?</div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-info rounded-1" (click)="dismiss()" translate>No</button>
      <button class="btn btn-success rounded-1" (click)="openSettingsLocation()" translate>Yes</button>
    </div>
  </div>
</div>
