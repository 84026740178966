/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DynamicFormControlComponent,
  DynamicFormControlLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';
import { DynamicMarkupModel } from './dynamic-markup.model';

@Component({
  selector: 'app-dynamic-markup',
  templateUrl: './dynamic-markup.component.html',
  styleUrls: ['./dynamic-markup.component.scss'],
})
export class DynamicMarkupComponent extends DynamicFormControlComponent {
  @Input() group!: FormGroup;
  @Input() layout?: DynamicFormControlLayout;
  @Input() model!: DynamicMarkupModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }
}
