import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, fromEvent, takeUntil } from 'rxjs';

import { DeviceDetectService } from '@app/core/services';
import { untilDestroyed } from '@app/core/until-destroyed';
import {
  ResponsiveSearchFiltersConfiguration,
  ResponsiveSearchFiltersFieldConfiguration,
  ResponsiveSearchFiltersFieldType,
} from './responsive-search-filters-configuration';

@Component({
  selector: 'responsive-search-filters',
  templateUrl: './responsive-search-filters.component.html',
  styleUrls: ['./responsive-search-filters.component.scss'],
})
export class ResponsiveSearchFiltersComponent implements OnDestroy {
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalFilterMobile', { static: false }) modalMobile: TemplateRef<ElementRef>;
  @ViewChild('modalFilter', { static: false }) modalFilter: TemplateRef<ElementRef>;
  onFinishIntialization$: Subject<void> = new Subject<void>();
  protected configuration: ResponsiveSearchFiltersConfiguration;
  protected formToShow: FormGroup;
  protected formModal: FormGroup;
  protected hasToShowMoreFields: boolean;
  protected fieldsHasToShow: ResponsiveSearchFiltersFieldConfiguration[];
  protected replacedMobileButton: ResponsiveSearchFiltersFieldConfiguration;
  protected replacedMobileButtonControl: FormControl;
  protected mustShowFields: boolean;
  protected fieldTypes = ResponsiveSearchFiltersFieldType;
  protected mobileFilters = 0;
  protected fieldsForModal: ResponsiveSearchFiltersFieldConfiguration[] = [];
  protected hasInitialized = false;
  protected multiRowsMobileFields: ResponsiveSearchFiltersFieldConfiguration[];
  protected multiRowsMobileFieldsControlsName: string[];
  protected multiRowsMobileForm: FormGroup;
  protected multiRowsMobile = false;
  private resetOnMobileButtonChanges: ResponsiveSearchFiltersFieldConfiguration[] = [];
  private unsubscribeAll = new Subject<void>();
  private isResetting = false;
  private valuesToSet: any;
  private isSettingValues = false;
  private isCurrentMobile = this.deviceDetect.isMobile;

  constructor(private modalService: NgbModal, private deviceDetect: DeviceDetectService) {
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.changeConfigurationAfterResize();
      });
  }

  /**
   * Set the configuration for the component.
   * @param configuration
   * Configuration for the component.
   */
  @Input() set config(configuration: ResponsiveSearchFiltersConfiguration) {
    this.configuration = configuration;
    this.initConfig();
  }

  /**
   * Show the button to show more filters.
   */
  showMoreButton() {
    this.hasToShowMoreFields = true;
  }

  /**
   * Hide the button to show more filters.
   */
  hideMoreButton() {
    this.hasToShowMoreFields = false;
  }

  /**
   * Set the current values of hte filters
   *
   * @param values
   *  Values to set in the forms.
   */
  setCurrentValues(values: any) {
    this.valuesToSet = values;
    if (this.hasInitialized) {
      this.setValues();
    }
  }

  /**
   * @inheritdoc
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /**
   * Open the modal with the filters in the mobile version.
   */
  openModalMobileFilters(): void {
    this.modalService.open(this.modalMobile, { ariaLabelledBy: 'modal-filters' }).result.then(
      () => {},
      () => {}
    );
  }

  /**
   * Open the modal with the filters.
   */
  openModalFilters(): void {
    this.modalService.open(this.modalFilter, { ariaLabelledBy: 'modal-filters' }).result.then(
      () => {},
      () => {}
    );
  }
  /**
   * Clear all the filters.
   */
  clear(): void {
    if (this.deviceDetect.isMobile) {
      this.eraseMobileFilters();
    } else {
      this.clearDesktop();
    }
  }

  /**
   * Clear the filters in the desktop version.
   */
  clearDesktop(): void {
    this.isResetting = true;
    this.formToShow.reset();
    this.formModal.reset();
    this.isResetting = false;
    this.changedValues();
    this.calculateMobileFilters();
  }

  /**
   * Clear the filters in the modal
   */
  eraseModalFilters(): void {
    this.isResetting = true;
    this.formModal.reset();
    this.isResetting = false;
    this.changedValues();
    this.calculateMobileFilters();
  }

  /**
   * Clear the filters in the mobile version.
   */
  eraseMobileFilters(): void {
    this.isResetting = true;
    this.formModal.reset();
    this.isResetting = false;
    this.multiRowsMobileForm?.reset();
    this.changedValues();
    this.mobileFilters = 0;
    this.modalService.dismissAll();
  }

  /**
   * Handle for the button confirm in the modal.
   */
  confirmModal(): void {
    this.changedValues();
    this.calculateMobileFilters();
    this.modalService.dismissAll();
  }

  /**
   * Get a control by name from the forms, if the control is not in the forms, it will return null.
   *
   * @param name
   *   Control name
   * @returns
   *  The control if exists, null otherwise.
   */
  getControlByName(name: string): AbstractControl<any> | null {
    if (this.replacedMobileButton?.name === name) {
      return this.replacedMobileButtonControl;
    }
    if (this.formToShow?.controls[name]) {
      return this.formToShow.controls[name];
    }

    if (this.formModal?.controls[name]) {
      return this.formModal.controls[name];
    }

    return this.multiRowsMobileForm?.controls[name];
  }

  /**
   * Emit the changes of values of the forms.
   */
  changedValues() {
    if (this.isResetting || this.isSettingValues) {
      return;
    }
    this.changed.emit(this.getValues());
  }

  /**
   * Extact the values of the fields in the forms.
   *
   * @returns
   *  Object with the values of the fields.
   */
  getValues(): any {
    let values = {};
    if (this.formToShow) {
      values = { ...this.formToShow.value };
    }
    if (this.multiRowsMobileForm) {
      values = { ...this.multiRowsMobileForm.value };
    }
    if (this.replacedMobileButton) {
      values = { ...values, ...{ [this.replacedMobileButton.name]: this.replacedMobileButtonControl.value } };
    }
    values = { ...values, ...{ ...this.formModal.value } };
    for (const field of this.configuration.fields) {
      if (!field.disabled) {
        continue;
      }
      delete values[field.name];
    }
    return values;
  }

  /**
   * Change the configuration of the filters after the resize of the window.
   */
  protected changeConfigurationAfterResize(): void {
    if (this.isCurrentMobile === this.deviceDetect.isMobile) {
      return;
    }
    this.isCurrentMobile = this.deviceDetect.isMobile;
    this.valuesToSet = this.getValues();
    this.formToShow = null;
    this.formModal = null;
    this.multiRowsMobileForm = null;
    this.initConfig();
  }

  /**
   * Parse the configuration to create the formgroups;
   */
  private initConfig(): void {
    if (!this.deviceDetect.isMobile) {
      // handle desktop UI.
      this.fieldsHasToShow = this.configuration.fields.filter((field) => field.mustShow);
      this.mustShowFields = this.fieldsHasToShow.length > 0;
      // has field to show in the bar
      if (this.mustShowFields) {
        if (!this.configuration.handleShowMoreButton) {
          this.hasToShowMoreFields = this.fieldsHasToShow.length - this.configuration.fields.length < 0;
        }
        this.formToShow = this.createGroupForm(this.fieldsHasToShow);
        this.formToShow.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.changedValues());
        // this.configuration.fields = this.configuration.fields.filter((field) => !field.mustShow);
        this.fieldsForModal = this.configuration.fields.filter((field) => !field.mustShow);
      } else {
        this.fieldsForModal = this.configuration.fields;
      }
    } else {
      // handle mobile UI.
      if (this.configuration.multiRowsMobile !== 0) {
        this.multiRowsMobileConfiguration();
      } else {
        this.standarMobileConfiguration();
      }
    }
    this.formModal = this.createGroupForm(this.fieldsForModal);
    this.hasInitialized = true;
    if (this.valuesToSet) {
      this.setValues();
    }
    this.onFinishIntialization$.next();
  }

  /**
   * Create the configuration for mobile with multi rows.
   */
  private multiRowsMobileConfiguration(): void {
    const multiRowButtons = this.configuration.fields
      .filter((field) => field.multiRowsMobilePosition)
      .sort((fieldA, fieldB) => fieldA.multiRowsMobilePosition - fieldB.multiRowsMobilePosition);
    this.multiRowsMobile = false;
    this.fieldsHasToShow = [];
    if (multiRowButtons.length) {
      this.multiRowsMobile = true;
      this.multiRowsMobileFields = multiRowButtons;
      this.multiRowsMobileFieldsControlsName = this.multiRowsMobileFields.map((field) => field.name);
      this.multiRowsMobileForm = this.createGroupForm(this.multiRowsMobileFields);
      this.multiRowsMobileForm.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.changedValues());
      this.fieldsForModal = this.configuration.fields.filter((field) => !field.multiRowsMobilePosition);
      if (!this.configuration.handleShowMoreButton) {
        this.hasToShowMoreFields = this.fieldsForModal.length > 0;
      }
    } else {
      this.fieldsForModal = this.configuration.fields;
    }
  }

  /**
   * Create the standard configuration for mobile.
   */
  private standarMobileConfiguration(): void {
    const replaceButton = this.configuration.fields.filter((field) => field.replaceStandardButtonMobile);
    this.mustShowFields = false;
    this.fieldsHasToShow = [];
    if (replaceButton.length) {
      this.replacedMobileButton = replaceButton[0];
      this.replacedMobileButtonControl = new FormControl(this.replacedMobileButton.initialValue);
      this.replacedMobileButtonControl.valueChanges.subscribe(() => {
        this.resetOnMobileButtonChanges.forEach((field) => this.formModal.controls[field.name].reset());
        this.calculateMobileFilters();
        this.changedValues();
      });
      this.fieldsForModal = this.configuration.fields.filter((field) => !field.replaceStandardButtonMobile);
      this.resetOnMobileButtonChanges = this.configuration.fields.filter(
        (field) => field.resetOnStandardButtonMobileChange
      );
    } else {
      this.fieldsForModal = this.configuration.fields;
    }
  }

  /**
   *
   *  Create a form group with the fields from the configuration passed by parameter.
   *
   * @param fields
   *   Fields to create the form group.
   * @returns
   *  The form group.
   */
  private createGroupForm(fields: ResponsiveSearchFiltersFieldConfiguration[]): FormGroup {
    const group: any = {};
    fields.forEach((field) => {
      group[field.name] = new FormControl(field.initialValue);
    });
    return new FormGroup(group);
  }

  /**
   * Calculate the number of filters that are active in the mobile version
   * and set the value to the mobileFilters variable.
   *
   **/
  private calculateMobileFilters() {
    this.mobileFilters = Object.values(this.formModal.value).reduce(
      (currentCount: number, currentValue) => (currentCount += currentValue ? 1 : 0),
      0
    ) as number;
  }

  /**
   * Set the values to the forms.
   */
  private setValues() {
    if (!this.valuesToSet) {
      return;
    }
    this.isSettingValues = true;

    for (const key of Object.keys(this.valuesToSet)) {
      this.getControlByName(key)?.setValue(this.valuesToSet[key]);
    }

    this.calculateMobileFilters();

    delete this.valuesToSet;
    this.isSettingValues = false;
  }
}
