import { Component } from '@angular/core';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { UserService } from '@makiwin/ngx-drupal8-rest';

@Component({
  selector: 'app-annonymous-sidebar',
  templateUrl: './annonymous-sidebar.component.html',
  styleUrls: ['./annonymous-sidebar.component.scss'],
})
export class AnnonymousSidebarComponent {
  constructor(public navDataService: NavDataService, public userService: UserService) {}

  closeMenu() {
    setTimeout(() => {
      this.navDataService.sideMenuOpen = false;
    }, 0);
  }
}
