import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'beta-bar',
  templateUrl: './beta-bar.component.html',
  styleUrls: ['./beta-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetaBarComponent implements OnInit {
  readonly isBeta = signal<boolean>(window.location?.href?.toLowerCase()?.indexOf(environment.betaUrl) !== -1);
  @Output() willShow = new EventEmitter<boolean>();

  ngOnInit() {
    this.willShow.emit(this.isBeta());
  }
}
