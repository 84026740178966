<div ngbDropdown class="dropdown custom-width">
  <div
    class="btn btn-default dropdown-toggle position-relative d-flex justify-content-center align-items-center align-self-center"
    [ngClass]="btnClass"
    ngbDropdownToggle
  >
    <span *ngIf="!saving">{{ selectedCurrency }}</span>
    <span translate *ngIf="saving">Saving...</span>
  </div>
  <ul
    class="dropdown-menu dropdown-menu-end bg-white currency-div dropdown-height"
    [ngClass]="{ 'currency-footer': navDataService.currentPage.indexOf('gutenberg') === 1 }"
    ngbDropdownMenu
  >
    <li *ngFor="let currency of currencies">
      <div
        class="dropdown-item position-relative justify-content-center align-items-center align-self-center"
        (click)="setCurrency(currency.code)"
      >
        <span>{{ currency.code }} - {{ currency.name }}</span>
      </div>
    </li>
  </ul>
</div>
