import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderWithWordsService } from './loader-with-words.service';

@Component({
  selector: 'loader-with-words',
  templateUrl: './loader-with-words.component.html',
  styleUrls: ['./loader-with-words.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderWithWordsComponent implements OnInit {
  visible$: Observable<boolean> = this.service.visible$.pipe(
    tap((visible) => (visible ? this.startScene() : this.cleanScene()))
  );
  word$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  points$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pPoints = '';
  private words: string[] = [];
  private currentIndex = -1;
  private readonly timeoutPointsAnimate = 500;
  private timeOutPoints: NodeJS.Timeout;

  constructor(private service: LoaderWithWordsService) {}

  ngOnInit(): void {
    this.service.getWords().subscribe((words) => (this.words = words));
  }

  private changeWord(): void {
    if (this.currentIndex + 1 < this.words.length) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
    this.word$.next(this.words[this.currentIndex]);
  }

  private animatePoints(): void {
    if (this.pPoints.length < 3) {
      this.pPoints += '.';
    } else {
      this.pPoints = '';
    }

    this.points$.next(this.pPoints);
    if (this.pPoints === '') {
      this.changeWord();
    }
    this.timeOutPoints = setTimeout(() => this.animatePoints(), this.timeoutPointsAnimate);
  }

  private startScene(): void {
    // shuffling the words
    this.words = this.words.sort(() => 0.5 - Math.random());
    this.changeWord();
    this.animatePoints();
  }

  private cleanScene(): void {
    if (this.timeOutPoints) {
      clearTimeout(this.timeOutPoints);
    }
    this.currentIndex = 0;
    this.pPoints = '';
  }
}
