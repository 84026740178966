import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';

@Component({
  selector: 'modal-standard',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
})
export class StandardComponent extends ModalComponent {}
