<div class="bg-primary-gradient text-white d-flex height-payment flex-grow-1 flex-column rounded">
  <div class="modal-header pt-3 mb-1">
    <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
      <i class="icon icon-32 icon-shield-check d-inline-block align-middle"></i>
      <span class="d-inline-block align-middle ms-1" translate>Secure Payment</span>
    </h3>
    <button class="btn btn-close text-white" (click)="dismiss()"></button>
  </div>
  <div class="modal-body text-center" *ngIf="!extraData?.products?.length" translate>No products found</div>

  <div
    class="modal-body flex-fill flex-column row mx-auto body-payments"
    *ngIf="!displayIap && extraData?.products?.length && totalPrice > 0"
  >
    <div class="col-12 py-1 px-2 pointer">
      <div class="mx-auto w-100 rounded">
        <div class="pt-3">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
      </div>
    </div>

    <div class="col-12 p-2">
      <button
        class="btn btn-block btn-bank-transfer d-flex align-items-center justify-content-center font-size-22 rounded"
        (click)="openBankTransferInfo()"
      >
        <img src="/assets/images/bank-transfer.svg" class="me-2" /> <strong translate>Bank transfer</strong>
      </button>
    </div>

    <!-- <div class="col-12 p-2 pointer" *ngIf="displayIap">
      <button class="btn btn-block btn-default" translate (click)="payWithInAppPurchase()">
        Pay with In App Purchase
      </button>
    </div> -->

    <app-cookie-consent-warning
      (cookieStatusChange)="onCookieStatusChange($event)"
      class="flex-fill flex-column d-flex"
    >
      <ng-container *ngIf="stripeService">
        <div class="col-12 p-2 pointer">
          <ngx-stripe-payment-request-button
            [paymentOptions]="requestOptions"
            [elementsOptions]="elementsOptions"
            (change)="requestUpdated($event)"
          >
          </ngx-stripe-payment-request-button>
        </div>

        <div class="col-12 pe-0" *ngIf="stripeAvailable">
          <div class="row justify-content-center">
            <div class="col-5 pe-0 mt-3">
              <div class="h-1 bg-light-transparent-5"></div>
            </div>
            <div class="col-1 p-0">
              <h4 class="m-0" translate>Or</h4>
            </div>
            <div class="col-5 ps-0 mt-3">
              <div class="h-1 bg-light-transparent-5"></div>
            </div>
          </div>
        </div>

        <div class="col-12 py-2 px-0 mt-2" *ngIf="stripeAvailable">
          <div class="bg-light-transparent-2 border border-white rounded p-2">
            <div class="row">
              <div class="col-12 col-lg-6 text-lg-left" translate>Pay with Credit Card</div>
              <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                <i class="align-self-center icon icon-32 icon-cc-visa mx-1"></i>
                <i class="align-self-center icon icon-32 icon-cc-mastercard mx-1"></i>
                <i class="align-self-center icon icon-32 icon-cc-amex mx-1"></i>
                <i class="align-self-center icon icon-32 icon-cc-discover mx-1"></i>
              </div>
            </div>
            <div class="w-100 mx-auto mt-3">
              <ngx-stripe-card
                #stripeCard
                class="stripe-card"
                [options]="cardOptions"
                [elementsOptions]="elementsOptions"
                (change)="cardUpdated($event)"
                (error)="cardError = $event"
              >
              </ngx-stripe-card>
              <div class="error" *ngIf="cardError">
                {{ cardError.message }}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!displayIap" class="modal-footer w-100 py-2 px-0 text-white mt-auto">
          <form
            class="w-100 row justify-content-center"
            *ngIf="paymentForm"
            [formGroup]="paymentForm"
            (ngSubmit)="payWithCard()"
          >
            <div class="col-12 mt-2 mb-4">
              <div class="form-check">
                <label class="form-check-label success-checkbox-border">
                  <input class="form-check-input" type="checkbox" formControlName="termsCheck" />
                  <span class="form-check-sign"></span>
                  <div class="text-info small">
                    <span translate>By proceeding, you agree to the</span>
                    <a class="text-success" (click)="navDataService.navigate('/gutenberg/367099')" translate>
                      Terms of Use
                    </a>
                    <span translate> and </span>
                    <a class="text-success" (click)="navDataService.navigate('/gutenberg/305')" translate>
                      Privacy Policy
                    </a>
                  </div>
                </label>
              </div>
            </div>
            <div class="flex-fill"></div>
            <div class="col-12 py-2 px-0">
              <button
                id="payment-button"
                type="submit"
                [disabled]="processing || (totalPrice !== 0 && (!cardValid || !paymentForm.valid))"
                class="mx-auto w-100 box-shadow btn btn-secondary rounded btn-sm btn-block mb-1 p-2 btn-green"
              >
                <span *ngIf="processing" translate> Processing… </span>
                <span *ngIf="!processing && totalPrice > 0">
                  <span class="me-1" translate>Pay</span>
                  <span>{{ totalPrice }} {{ this.payPalConfig.currency }}</span>
                </span>
                <span *ngIf="totalPrice === 0" translate>Your order is free<br />click to confirm</span>
              </button>
              <div class="small text-center">
                <label class="m-0 small" translate>Payments are securely proceed by Stripe.</label>
                <label class="small">We do not store credit card information.</label>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </app-cookie-consent-warning>
  </div>
</div>
