import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'student-cert-info',
  templateUrl: './student-cert-info.component.html',
  styleUrls: ['./student-cert-info.component.scss'],
})
export class StudentCertInfoComponent extends ModalComponent {
  constructor(public navDataService: NavDataService) {
    super();
  }

  get trainingLevelNames(): string {
    return this.extraData?.training_level.map((l) => l.title).join(', ');
  }

  get skillTitles(): string {
    return this.extraData?.certification_skills.map((s) => s.title).join(', ');
  }

  get studentNamesEmails(): string {
    return this.extraData?.students.map((s) => `${s.uid} - ${s.name} (${s.mail})`).join(', ');
  }
}
