import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { NotificationsService } from '../notifications/notifications.service';

export interface TimeDifference {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  constructor(
    private translateService: TranslateService,
    private deviceDetectService: DeviceDetectService,
    private notificationsService: NotificationsService
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  share(URL: string, titleToShare: string, textToShare: string): void {
    // link to the app store for sharing
    if (this.deviceDetectService.isCordova) {
      URL = 'http://onelink.to/gqrxw2';
    } else {
      // until the new website will be ready
      // WEB-2848
      // URL = 'https://www.ikointl.com';
    }
    const nav: any = window.navigator || {};
    if (!(nav && nav.share && nav.canShare && nav.canShare())) {
      return this.copyToClipboard(URL);
    }
    nav
      .share({
        // title: titleToShare,
        // text: textToShare,
        url: URL,
      })
      .then(() => console.log('Successful share'))
      // need to wait for the function i made to show the error
      .catch(() => alert(this.translateService.instant('Cant open sharing box')));
  }

  copyToClipboard(textToClipboard: string): void {
    const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToClipboard;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationsService.notifySuccess(this.translateService.instant('Link copied'));
  }

  isNumeric(toCheck: string | number): boolean {
    return !isNaN(Number(toCheck));
  }

  isConnected$(): Observable<boolean> {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  timeDifference(date1: number, date2: number): TimeDifference {
    let difference = date1 - date2;
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    const secondsDifference = Math.floor(difference / 1000);

    return {
      days: daysDifference,
      hours: hoursDifference,
      minutes: minutesDifference,
      seconds: secondsDifference,
    };
  }

  formatNumberForBadges(number: number | string): string {
    number = Number(number);
    switch (true) {
      case number === 0:
        return '0';
      case number >= 1 && number <= 9:
        return '1+';
    }
    number = number.toString();
    const firstNumber = Number(number[0]);
    number = firstNumber * Math.pow(10, number.length - 1);
    return number.toLocaleString('en').replace(',', ' ') + '+';
  }

  convertNgbDateToIsoDate(date: NgbDate): string {
    return date
      ? date.year + '-' + date.month.toString().padStart(2, '0') + '-' + date.day.toString().padStart(2, '0')
      : '';
  }

  convertstringDatetoNgbDate(date: string): NgbDate | null {
    if (!date) {
      return null;
    }
    const elements = date.split('-');
    if (elements.length > 2) {
      return new NgbDate(Number(elements[0]), Number(elements[1]), Number(elements[2]));
    }
  }

  convertDatetoNgbDate(date: Date): NgbDate | null {
    if (!date) {
      return null;
    }
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  confirmReloadWebForm(): boolean {
    return window.confirm(
      this.translateService.instant(
        'Your language has changed, we are changing the language of the form too, but you will lose all the new data inserted. Do you want continue?'
      )
    );
  }
  encodeHTMLEntities(text: string): string {
    const textArea = document.createElement('div');
    textArea.innerHTML = text;
    return textArea.textContent;
  }

  arrayBufferToBase64(buffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  markFormFieldsDirty(form: FormGroup): void {
    for (const controlKey of Object.keys(form.controls)) {
      form.controls[controlKey].markAsDirty();
      form.controls[controlKey].markAsTouched();
      form.controls[controlKey].updateValueAndValidity();
    }
  }

  fieldIsInvalid(form: FormGroup, fieldName: string): boolean {
    const control = form.get(fieldName);
    return control.invalid && control.dirty && control.touched;
  }

  fieldHasError(form: FormGroup, fieldName: string, typeError: string): boolean {
    const control = form.get(fieldName);
    return control.errors?.[typeError] && control.dirty && control.touched;
  }

  /**
   * Load a script dynamically from the url.
   *
   * @param url
   * Url of the script to load.
   * @returns
   * Observable that emits true when the script is loaded.
   */
  loadScript(url: string): Observable<boolean> {
    return new Observable((observer) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        observer.next(true);
        observer.complete();
      };
      script.onerror = () => {
        observer.error('error loading script');
        observer.complete();
      };
      document.body.appendChild(script);
    });
  }
}
