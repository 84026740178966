/* eslint-disable @typescript-eslint/naming-convention */
import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideDown = trigger('slideDown', [
  state(
    'true',
    style({
      transform: 'translate3d(0, 0, 0)',
      opacity: '1',
      'z-index': '1',
      position: 'relative',
      'max-height': 'none',
    })
  ),
  state(
    'false',
    style({
      transform: 'translate3d(0, -30%, 0)',
      opacity: 0,
      'max-height': '0px',
      'z-index': '-1',
      position: 'absolute',
      overflow: 'hidden',
    })
  ),
  transition('true => false', animate('200ms ease-in-out')),
  transition('false => true', animate('200ms ease-in-out')),
]);
