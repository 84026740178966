import {
  IdTitle,
  LatLon,
  ResponsiveImageResource,
  IdDisplayName,
  SpotForecast,
  IdTitleNameShortName,
} from './universal';

/**
 * @for view: centers
 * @path /api/v1/centers.json
 */

export interface CenterBrand extends IdTitle {
  brand_logo?: ResponsiveImageResource;
}

export interface CenterList {
  nid: number;
  title: string;
  field_school_logo: string;
  field_school_address_country_code: string;
  field_school_address_locality: string;
  field_school_address_address_line1: string;
  field_school_gps_export: LatLon;
  rate_value: number;
  rate_count: number;
  field_school_address_address_line2?: string;
  field_school_address_dependent_locality?: string;
  field_school_address_administrative_area?: string;
  roles_target_id?: string;
  url_alias: string;
}
/**
 * Interface for school profile
 *
 * @path /api/v1/school_details.json
 */
export interface Center {
  nid: number;
  flagged: string;
  flagging_id: string;
  flag_count: string;
  uid: number;
  title: string;
  body: string;
  mail: string;
  field_school_address_country_code: string;
  field_school_address_country_name: string;
  field_school_address_address_line1?: string;
  field_school_address_administrative_area?: string;
  field_school_address_locality?: string;
  field_school_address_postal_code?: string;
  field_school_gps: LatLon;
  field_school_gps_export: LatLon;
  field_school_affiliated_since: string;
  field_school_logo: ResponsiveImageResource;
  field_school_cover: ResponsiveImageResource;
  field_school_gallery: ResponsiveImageResource[];
  field_school_season: string[];
  field_school_season_tranformed: { from: Date; to: Date }[];
  field_school_brands: CenterBrand[];
  field_school_facilities: IdTitleNameShortName[];
  field_school_equipment_export: IdTitle[];
  field_school_phone: string[];
  field_school_whatsapp: string;
  field_school_facebook: string;
  field_school_instagram: string;
  field_school_skype: string;
  field_school_tripadvisor: string;
  field_school_url: string;
  rate_value: number;
  school_spot_forecast: SpotForecast;
  rate_count?: number;
  field_tag_line?: string;
  affiliation_id?: number;
  url_alias: string;
  roles_target_id: string;
}

export interface CenterMember {
  uid: number;
  username: string;
  field_first_name: string;
  field_surname: string;
  mail: string;
  current_role: IdDisplayName;
  field_teaching_hours: number;
  field_training_hours: number;
  field_certified_students: number;
  employment_from: string;
  rate_value: number;
  employment_to?: string;
  rate_count?: number;
  user_picture?: ResponsiveImageResource;
}

export interface TeamMemberCenter {
  uid: string;
  username: string;
  current_role: IdDisplayName;
  field_first_name: string;
  field_surname: string;
  field_teaching_hours: string;
  field_training_hours: string;
  field_certified_students: string;
  employment_from: string;
  employment_to: string;
  rate_value: string;
  rate_count: string;
  field_training_level_skills: {
    id: string;
    label: string;
    weight: string;
    code: string;
    category_id: string;
    svg: string;
  };
  user_picture: ResponsiveImageResource;
  field_user_cover_export: ResponsiveImageResource[];
  field_user_insured: string;
  field_top_qualification_export: string;
  active?: boolean;
}

export interface SchoolStatistics {
  nid: number;
  title: string;
  affiliated: string;
  memberships: number;
  certifications: number;
  credits: number;
  packages: number;
  confirmed: number;
  reviews_average: number;
  reviews_count: number;
  reservations?: string;
  bookings?: string;
}
