import { EmploymentHistory } from './job';
import { ProfileTrainingSkill } from './profile';
import { Country, IdDisplayName, ResponsiveImageResource } from './universal';

export interface MemberList {
  uid: number;
  field_first_name: string;
  field_language_spoken: string;
  field_surname: string;
  name: string;
  user_picture: string;
  field_teaching_hours: string;
  field_training_hours: string;
  field_certified_students: string;
  field_top_qualification: string;
  rank: number;
  voting_average: string;
  voting_count: number;

  field_training_level: string;
  color: string;
  bg_image: string;
  field_user_cover?: string;
  user_expired: string;
  user_suspended: string;
  field_user_insured: number;
}

export interface MemberListMap {
  uid: string;
  field_first_name: string;
  field_surname: string;
  name: string;
  field_language_spoken?: {
    code: string;
    name: string;
  }[];
  field_certified_students: string;
  field_teaching_hours: string;
  field_training_hours: string;
  voting_average: string;
  voting_count: string;
  field_user_insured: string;
  user_picture?: ResponsiveImageResource;
  field_spot_gps_export?: {
    lat: number;
    long: number;
    lon: number;
  };
  field_gps_position?: {
    lat: number;
    long: number;
  };
}

// export interface MemberListMap {
//   uid: number;
//   field_first_name: string;
//   field_surname: string;
//   user_picture: string;
//   name: string;
//   field_gps_position?:
// {
//   lat: number;
//   long: number;
// };
// }

/**
 * Interface for public user profile
 *
 * @path /api/v1/public_user_profile.json
 */
export interface Member {
  uid: number;
  name: string;
  current_role: IdDisplayName;
  field_first_name: string;
  field_surname: string;
  field_training_level_skills: ProfileTrainingSkill[];
  field_top_qualification?: string;
  rating_value: number;
  field_employment_history: EmploymentHistory[];
  likes: number;
  flagged: number;
  field_address_administrative_area?: string;
  field_address_country_code?: string;
  field_nationality?: string;
  user_picture_multiple_json?: ResponsiveImageResource;
  field_user_cover?: ResponsiveImageResource[];
  iko_skills?: string;
  field_teaching_hours?: number;
  field_training_hours?: number;
  field_certified_students?: number;
  field_teaching_hours_badge?: string;
  field_training_hours_badge?: string;
  field_certified_students_badge?: string;
  field_language_spoken?: Country[];
  field_facebook_url?: string;
  subscription_state?: string;
  subscription_starts?: string;
  subscription_expiry?: string;
  rating_count?: string;
  field_user_insured?: string;
  last_certification_date?: string;
  field_user_booked_yet?: boolean;
  flagging_id?: number;
  flag_members_id?: string;
  flag_school_id?: string;
  flag_spot_id?: string;
  flag_destination_id?: string;
  user_expired: number;
  user_suspended: number;
  field_instructor_of_the_week?: string | boolean;
  member_of_school?: string;
}

export interface UserSearch {
  uid: number;
  mail: string;
  field_first_name: string;
  field_surname: string;
  uri?: string; // picture
}
