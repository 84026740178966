<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="formatter(r)" class="text-autocomplete-result" [term]="t"></ngb-highlight>
</ng-template>
<div
  class="input-group position-relative"
  [ngClass]="{ 'border-success': currentCenterModel.value && highlightOnValue }"
>
  <span
    class="input-group-text border-0"
    *ngIf="iconPrepend"
    [ngClass]="{ 'text-success': currentCenterModel.value && highlightOnValue }"
  >
    <i class="icon {{ iconPrepend }}"></i>
  </span>

  <input
    type="text"
    class="form-control centers {{ currentCenterModel.value && highlightOnValue ? 'text-success' : '' }}"
    [ngClass]="inputClass"
    [formControl]="currentCenterModel"
    [ngbTypeahead]="search"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    [disabled]="isDisabled"
    popupClass="centers-selector"
    placeholder="{{ 'Search for a center' | translate }}"
  />
  <span class="input-group-text suffix-icon pointer">
    <i
      *ngIf="currentCenterModel.value"
      (click)="currentCenterModel.reset()"
      class="{{ highlightOnValue ? 'text-success' : 'text-danger' }} icon icon-times"
    ></i>
  </span>
</div>
