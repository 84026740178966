import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { formatDate, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePtBr from '@angular/common/locales/pt';
import { ActivatedRoute, Router } from '@angular/router';
import ar from '@app/../assets/i18n/ar.json';
import de from '@app/../assets/i18n/de.json';
import en from '@app/../assets/i18n/en.json';
import es from '@app/../assets/i18n/es.json';
import fr from '@app/../assets/i18n/fr.json';
import it from '@app/../assets/i18n/it.json';
import ptbr from '@app/../assets/i18n/pt-br.json';
import {
  ExtraLanguagePages,
  SupportedLanguage,
  SupportedLanguages,
  supportedLanguages,
} from '@app/../translations/languages';
import { Country } from '@app/core/models';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const languageKey = 'language';

export interface TypeOfPage {
  ebook?: boolean;
  contentOnly?: boolean;
}

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 *
 * @param s The string to extract for translation.
 * @return The same string.
 */
export const extract = (s: string) => s;

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  // default app language
  defaultLanguage: string;
  // All languages
  allLanguages: SupportedLanguages;
  languagesToShow$: BehaviorSubject<SupportedLanguages> = new BehaviorSubject<SupportedLanguages>(null);
  // supported languages
  supportedLanguages: SupportedLanguages;
  changedSelectedLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private langChangeSubscription!: Subscription;
  private tempLang: string;

  constructor(
    public translateService: TranslateService,
    private localizeRouter: LocalizeRouterService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en', en);
    translateService.setTranslation('fr', fr);
    translateService.setTranslation('de', de);
    translateService.setTranslation('it', it);
    translateService.setTranslation('pt-br', ptbr);
    translateService.setTranslation('es', es);
    translateService.setTranslation('ar', ar);
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localePtBr, 'pt-br');
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   *
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  // init(defaultLanguage: string, supportedLanguages: SupportedLanguages) {
  //   // set default lanaugage
  //   this.defaultLanguage = defaultLanguage;
  //   // set supported languages
  //   this.supportedLanguages = { ...supportedLanguages };
  //   this.allLanguages = { ...supportedLanguages };
  //   // set current language to default
  //   // this.language = '';

  //   // Warning: this subscription will always be alive for the app's lifetime
  //   this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
  //     localStorage.setItem(languageKey, event.lang);
  //   });
  // }

  get supportedLanguagesKeys(): string[] {
    return Object.keys(this.supportedLanguages);
  }

  get allLanguagesKeys(): string[] {
    return Object.keys(this.allLanguages);
  }

  /**
   * Gets the current language.
   *
   * @return The current language code.
   */
  get language(): string {
    return this.tempLang ?? this.translateService.currentLang;
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   *
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language =
      language || this.localStorageService.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    if (!language) {
      return;
    }

    if (this.supportedLanguages) {
      let languageSupported = this.getSupportedLanguage(language);
      let isSupportedLanguage = languageSupported?.mainLanguage;
      // If no exact match is found, search without the region
      if (language && !isSupportedLanguage) {
        language = language.split('-')[0].toLowerCase();
        language =
          this.supportedLanguagesKeys.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
        languageSupported = this.getSupportedLanguage(language);
        isSupportedLanguage = languageSupported?.mainLanguage;
      }

      if (isSupportedLanguage) {
        // language = this.defaultLanguage;
        this.tempLang = null;
        this.localizeRouter.changeLanguage(language, { fragment: this.route.snapshot.fragment }, true);
      } else {
        this.tempLang = language;
      }
    } else {
      // Fallback if language is not supported
      language = language ?? this.defaultLanguage ?? 'en';

      this.localizeRouter.changeLanguage(language, { fragment: this.route.snapshot.fragment }, true);
      // language = language ?? this.defaultLanguage ?? 'en';
    }

    // this.translateService.use(language);
    this.changedSelectedLanguage$.next(language);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   *
   * @param defaultLanguage The default language to use.
   * @param appSupportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, appSupportedLanguages: SupportedLanguages) {
    // set default lanaugage
    this.defaultLanguage = defaultLanguage;
    // set supported languages
    // enabled languages only
    const enabledLanguages = {};
    Object.keys(appSupportedLanguages).forEach((code) => {
      const lang = appSupportedLanguages[code];
      if (!lang.disabled) {
        enabledLanguages[code] = lang;
      }
    });
    this.supportedLanguages = { ...enabledLanguages };
    this.allLanguages = { ...enabledLanguages };
    this.setPage();
    // set current language to default
    // this.language = '';

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changedSelectedLanguage$.next(event.lang);
      this.localStorageService.setItem(languageKey, event.lang);
    });
  }

  setPage(page?: ExtraLanguagePages) {
    const languages: SupportedLanguages = {};
    for (const langKey of Object.keys(this.allLanguages)) {
      const lang = this.allLanguages[langKey];
      if (lang.mainLanguage) {
        languages[langKey] = lang;
        continue;
      }
      if (!page && !lang.pagesToShow) {
        continue;
      }
      if (lang.pagesToShow.includes(page)) {
        languages[langKey] = lang;
        continue;
      }
    }
    if (!page) {
      this.tempLang = null;
      this.changedSelectedLanguage$.next(this.translateService.currentLang);
    }
    this.languagesToShow$.next(languages);
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  getSupportedLanguage(langCode: string): SupportedLanguage {
    return this.allLanguages[langCode];
  }

  mapLangFlags(langs: Country[]) {
    if (langs) {
      langs = langs.map((l) => {
        const spLang = supportedLanguages[l.code];
        l.code = spLang ? spLang.flag : l.code;
        return l;
      });
    }
    return langs;
  }

  // WEB-4156
  // add dot after the day in German only
  localizeDateAndFormat(date: string | Date | NgbDateStruct, format = 'dd MMMM yyyy') {
    if (!date) {
      return '';
    }
    let dateObj: any = date;
    if (!(date instanceof Date) && typeof date !== 'string') {
      dateObj = new Date(dateObj.year, +dateObj.month - 1, dateObj.day);
    }
    let localizedFormat = format;
    if (localizedFormat.indexOf('dd ') !== -1 && this.language === 'de') {
      localizedFormat = localizedFormat.replace('dd ', 'dd. ');
    }
    return formatDate(dateObj, localizedFormat, this.language);
  }
}
