import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ImagePickerCropperOptions } from './image-picker-cropper-options.interface';
import { ImagePickerCropperComponent } from './image-picker-cropper.component';

@Injectable({
  providedIn: 'root',
})
export class ImagePickerCropperService {
  private pickerComponent: ImagePickerCropperComponent;
  private modalRef: NgbModalRef;

  private readonly defaultParams: ImagePickerCropperOptions = {
    maxHeight: 512,
    maxWidth: 512,
    rounded: false,
    noCropping: false,
    previewWidth: 150,
    previewHeight: 150,
    format: 'png',
    excludeCamera: false,
  };

  constructor(private ngbModal: NgbModal) {}

  open(params?: ImagePickerCropperOptions): Observable<string> {
    return new Observable((observer) => {
      params = this.checkDefaultParams(params);
      this.modalRef = this.ngbModal.open(ImagePickerCropperComponent, {
        size: 'md',
        centered: true,
        windowClass: 'modal-dialog-centered modal-image-picker',
      });
      this.pickerComponent = this.modalRef.componentInstance;
      this.pickerComponent.options = params;
      this.pickerComponent.resetSteps();
      this.modalRef.result.then((urlImage) => observer.next(urlImage)).finally(() => observer.complete());
    });
  }

  private checkDefaultParams(params: ImagePickerCropperOptions): ImagePickerCropperOptions {
    const paramsDef = params || this.defaultParams;

    paramsDef.maxHeight = paramsDef.maxHeight ?? this.defaultParams.maxHeight;
    paramsDef.maxWidth = paramsDef.maxWidth ?? this.defaultParams.maxWidth;
    paramsDef.rounded = paramsDef.rounded ?? this.defaultParams.rounded;
    paramsDef.previewHeight ??= this.defaultParams.previewHeight;
    paramsDef.previewWidth ??= this.defaultParams.previewHeight;
    paramsDef.format ??= this.defaultParams.format;
    paramsDef.excludeCamera ??= this.defaultParams.excludeCamera;

    return paramsDef;
  }
}
