import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';

interface cacheUrlItems {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root',
})
export class UrlResolverService {
  public readonly fakeUrlChanged: Observable<void> = new Subject<void>();
  private cacheresolve: cacheUrlItems = {};
  private languagePrefixChecker = new RegExp('^/(it|en|es|fr|de|pt-br|pl)/');
  private languagePrefixCheckerHome = /\/(it|en|es|fr|de|pt-br|pl)$/i;
  private notificationsService: NotificationsService = inject(NotificationsService);
  private translateService: TranslateService = inject(TranslateService);
  constructor(private viewService: ViewService, private localize: LocalizeRouterService, private router: Router) {}

  resolve(url: string): Observable<number> {
    if (this.cacheresolve[url]) {
      return of(this.cacheresolve[url]);
    }

    const viewOptions: ViewOptions = {
      filters: { alias: decodeURI(url) },
    };

    return this.viewService.get('/api/nid_by_alias', viewOptions).pipe(
      map((nid) => {
        if (!nid || nid.toString() === '') {
          throw new Error('url not found');
        }
        this.cacheresolve[url] = +nid;
        return +nid;
      }),
      catchError((err) => {
        // instantly display network error on disconnect.
        // only reacheable by a cached version via service worker or file cache.
        if (err.status === 0) {
          this.notificationsService.notifyError(
            this.translateService.instant('No internet connection, please check your network')
          );
        }
        throw err;
      })
    );
  }

  cleanLanguagePrefix(url: string): string {
    if (this.languagePrefixCheckerHome.test(url)) {
      return '';
    }
    if (!this.languagePrefixChecker.test(url)) {
      return url;
    }

    const pos = url.indexOf('/', 1);
    return url.substring(pos);
  }

  setFakeUrl(url: string): void {
    const urlTranslated = this.localize.translateRoute(this.cleanLanguagePrefix(url)).toString().replace('//', '/');
    this.router.navigateByUrl(urlTranslated);
  }
}
