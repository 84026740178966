import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar-v2';
import { AnnonymousSidebarComponent } from './annonymous-sidebar/annonymous-sidebar.component';
import { BetaBarComponent } from './beta-bar/beta-bar.component';
import { HeaderComponent } from './header/header.component';
import { OfflineBarComponent } from './offline-bar/offline-bar.component';
import { ShellComponent } from './shell.component';
import { ProfileProgressComponent } from './sidebar/profile-progress/profile-progress.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableOfContentComponent } from './sidebar/table-of-content/table-of-content.component';
import { UserActionsComponent } from './sidebar/user-actions/user-actions.component';
import { VcardComponent } from './vcard/vcard.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    LocalizeRouterModule,
    SharedModule,
    ReactiveFormsModule,
    SidebarModule,
    NgbCollapseModule,
    DirectivesModule,
    NgOptimizedImage,
  ],
  declarations: [
    ShellComponent,
    HeaderComponent,
    SidebarComponent,
    ProfileProgressComponent,
    TableOfContentComponent,
    VcardComponent,
    AnnonymousSidebarComponent,
    UserActionsComponent,
    BetaBarComponent,
    OfflineBarComponent,
  ],
  exports: [ShellComponent],
})
export class ShellModule {}
