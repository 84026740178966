/* eslint-disable @angular-eslint/no-output-native */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DynamicCheckboxGroupModel,
  DynamicCheckboxModel,
  DynamicFormControlComponent,
  DynamicFormControlLayout,
  DynamicFormLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';

@Component({
  selector: 'dynamic-nowui-checkbox-group',
  templateUrl: './dynamic-nowui-checkbox-group.component.html',
  styleUrls: ['./dynamic-nowui-checkbox-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicNowUiCheckboxGroupComponent extends DynamicFormControlComponent {
  @Input() formLayout?: DynamicFormLayout;
  @Input() group!: FormGroup;
  @Input() layout?: DynamicFormControlLayout;
  @Input() model!: DynamicCheckboxGroupModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }

  getCheckboxId(model: DynamicCheckboxModel) {
    return this.layoutService.getElementId(model);
  }

  onCheckboxChange($event: Event, model: DynamicCheckboxModel) {
    this.onChange($event);
    model.value = ($event.target as HTMLInputElement).checked;
  }
}
