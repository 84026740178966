import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToCommaSeprated',
})
export class ArrayToCommaSepratedPipe implements PipeTransform {
  constructor() {}

  transform(array: any, key: string) {
    return array.map((el) => el[key]).join(', ');
  }
}
