import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/localstorage/localstorage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencySelectorBarService {
  mustShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected readonly storageKey = 'currency-selector-bar';

  constructor(private localStorageService: LocalStorageService) {
    this.checkStatus();
  }

  closeBar(): void {
    this.mustShow$.next(false);
    this.localStorageService.setItem(this.storageKey, 'closed');
  }

  private checkStatus(): void {
    this.mustShow$.next(!this.localStorageService.getItem(this.storageKey));
  }
}
