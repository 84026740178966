import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, Host, Input, OnChanges } from '@angular/core';
import { ResponsiveImageResource, imageMinStyleWidths, imageStyleWidths } from '@app/core/models';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';

interface Picture {
  media: string;
  srcset: string;
}

const scaleSize = ['uhd', 'fhd', 'hd', 'md', 'sd'];

@Component({
  selector: 'image-srcset',
  standalone: true,
  imports: [CommonModule, PipesModule, NgOptimizedImage],
  templateUrl: './image-srcset.component.html',
  styleUrls: ['./image-srcset.component.scss'],
})
export class ImageSrcsetComponent implements OnChanges {
  @Input()
  imgClass = '';
  @Input() imgId = '';
  @Input()
  propertyName: string;
  @Input()
  calculateRatio = false;
  @Input()
  forceOnlyOneSize: string;
  @Input()
  alt: string;
  @Input()
  onErrorImage: string;
  @Input()
  tempImg: string;
  @Input() width = 300;
  @Input() height = 300;
  // gridItems: number;
  src: string;
  srcset: string;
  fullPath = false;
  host: ElementRef;
  safeType = 'url';
  sizes: string;
  picturesSet: Picture[] = [];
  biggestSize: string;

  private imageItem: ResponsiveImageResource;

  private ratioScreen: number;

  constructor(@Host() hostElement: ElementRef) {
    this.host = hostElement;
  }

  @Input() set item(item: ResponsiveImageResource) {
    this.imageItem = item;
    this.setItem();
  }

  ngOnChanges(): void {
    this.setItem();
  }

  handleImgError(e, path) {
    e.target.onerror = null;
    Array.from(e.target.parentNode.children).forEach((element: any) => {
      element.srcset = path;
    });
    e.target.src = path;
  }

  private setItem() {
    if (this.calculateRatio) {
      this.ratioScreen = this.host.nativeElement.offsetWidth / window.innerWidth;
      this.ratioScreen = this.ratioScreen === 0 ? 1 : this.ratioScreen;
    } else {
      this.ratioScreen = 1;
    }

    this.picturesSet = [];

    if (!this.imageItem) {
      const tempImg = this.tempImg ?? '/assets/images/default-image.jpeg';
      this.setNormalImage(tempImg);
      return;
    } else if (typeof this.imageItem === 'string') {
      this.setNormalImage(this.imageItem);
      return;
    }

    const frags = Object.keys(this.imageItem)[0].split('_');
    frags.pop();
    if (!this.propertyName) {
      this.propertyName = frags.join('_');
    }
    if (this.propertyName) {
      this.fullPath =
        this.imageItem[`${this.propertyName}_md`] && this.imageItem[`${this.propertyName}_md`].indexOf('http') !== -1;
      if (this.fullPath) {
        this.src = this.forceOnlyOneSize
          ? `${this.imageItem[`${this.propertyName}_${this.forceOnlyOneSize}`]}`
          : `${this.imageItem[`${this.propertyName}_md`]}`;
        // WEB-552 need to sanitize blob image
        this.safeType = this.src.indexOf('blob:') !== -1 ? 'resourceUrl' : 'url';
      } else {
        this.src = this.forceOnlyOneSize
          ? `${DrupalConstants.backEndUrl}${this.imageItem[`${this.propertyName}_${this.forceOnlyOneSize}`]}`
          : `${DrupalConstants.backEndUrl}${this.imageItem[`${this.propertyName}_md`]}`;
      }
    }
    this.setBigSize();
    if (!this.forceOnlyOneSize) {
      this.initSet();
    }
  }

  private initSet(): void {
    // WEB-552 if the src is a blob it's better to use only a src
    // instead due to a problem to sanitize srcst
    if (this.safeType === 'resourceUrl') {
      this.srcset = null;
      return;
    }
    // let style = '';
    // const sizes = [];
    let privateImage = '';

    Object.keys(imageStyleWidths).forEach((styleWidthName) => {
      if (styleWidthName.indexOf(this.propertyName) === -1) {
        return;
      }
      const styleValue = Math.round(imageStyleWidths[styleWidthName] / this.ratioScreen);
      if (!this.imageItem[styleWidthName]) {
        return;
      }
      // if (!this.fullPath) {
      //   style += DrupalConstants.backEndUrl;
      // }
      // const ratioCalc = Math.round((styleValue * devicePixelRatio) / this.ratioScreen);
      // style += `${this.imageItem[styleWidthName]} ${styleValue}w,`;
      const pre = imageMinStyleWidths[styleWidthName]
        ? `(min-width: ${imageMinStyleWidths[styleWidthName] / this.ratioScreen}px) and `
        : '';
      // let size = `(max-width: ${styleValue}px) ${styleValue}px`;
      let url = this.imageItem[styleWidthName];
      if (!this.fullPath) {
        url = DrupalConstants.backEndUrl + url;
      }
      this.picturesSet.push({
        media:
          this.biggestSize === styleWidthName && pre ? pre.replace('and', '') : `${pre} (max-width: ${styleValue}px)`,
        srcset: url,
      });
      // if (pre !== '') {
      //   size = `(${pre} (max-width: ${styleValue}px)) ${styleValue}px`;
      // }

      // sizes.push(size);
      if (imageStyleWidths[styleWidthName] <= window.innerWidth) {
        privateImage = this.imageItem[styleWidthName];
        if (!this.fullPath) {
          privateImage += DrupalConstants.backEndUrl;
        }
      }
    });

    if (privateImage && this.calculateRatio) {
      this.setNormalImage(privateImage);
    }
    // else {
    // this.sizes = sizes.join(',');
    // this.srcset = style.slice(0, -1);
    // }
  }

  private setNormalImage(url: string): void {
    this.src = url;
    // this.srcset = url;
  }

  private setBigSize() {
    if (!this.imageItem) {
      return;
    }
    for (const size of scaleSize) {
      if (!this.imageItem[this.propertyName + '_' + size]) {
        continue;
      }
      this.biggestSize = this.propertyName + '_' + size;
      return;
    }
  }
}
