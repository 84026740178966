import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCloseKeyboardOnEnter]',
})
export class CloseKeyboardOnEnterDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    if (e.keyCode === 13) {
      this.elRef.nativeElement.blur();
    }
  }
}
