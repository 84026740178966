import { Observable } from 'rxjs';
import { ModalTypes } from './modal-types.enum';

export enum ConfirmModalResult {
  yes,
  no,
  closedByUser, // user close modal from the X button
}

export interface ExtraOptionsConfirmModal {
  extraMessage?: string;
  buttonYesLabel?: string;
  buttonNoLabel?: string;
}

export interface ModalManagerInterface {
  /**
   * Will dismiss all the modals opened.
   */
  dismissAll(): void;
  /**
   * Will open a modal dialog Yes/no and will return an observable the will emit the response of the client
   *
   * @param {string} title
   * Title of the modal.
   * @param {string} message
   * Body/message of the modal.
   * @param {string} extraMessage
   * Sub text/HTML of the modal.
   * @returns
   * Observable that emit a ConfirmModalResult
   */
  openConfirmModal(title: string, message: string, options?: ExtraOptionsConfirmModal): Observable<ConfirmModalResult>;

  /**
   * Open a modal dialog with the type passed as parameter, and with extra data if needed.
   *
   * @param type
   * Type of the modal to open.
   * @param extraData
   * Extra data to pass to the modal.
   * @returns
   * Observable that emit the response of the closing status of the modal.
   */
  open(type: ModalTypes, extraData?: any): Observable<any>;

  /**
   * Open a modal dialog with the type passed as parameter, and with extra data if needed,
   * and will close all the other modial dialog opened.
   * @param type
   * Type of the modal to open.
   * @param extraData
   * Extra data to pass to the modal.
   * @returns
   * Observable that emit the response of the closing status of the modal.
   */
  toggle(type?: ModalTypes, extraData?: any): Observable<any>;
}
