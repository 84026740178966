import { Injectable } from '@angular/core';
import { DrupalConstants } from '@makiwin/ngx-drupal8-rest';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roleName: string;
  isInstructor = false;
  isProfessional = false;
  isSchool = false;
  isCoach = false;
  private roles: string[] = [];

  private rolesInstructor = [
    'instructor_candidate',
    'instructor_level_1_basic',
    'instructor_level_1_plus',
    'instructor_level_1_premium',
    'instructor_level_2_basic',
    'instructor_level_2_plus',
    'instructor_level_2_premium',
    'instructor_level_3_basic',
    'instructor_level_3_plus',
    'instructor_level_3_premium',
    'coach_basic',
    'coach_plus',
    'coach_premium',
    'coach_candidate',
    'assistant_trainer_candidate',
    'assistant_trainer_basic',
    'assistant_trainer_plus',
    'assistant_trainer_premium',
    'assistant_trainer_coach_basic',
    'assistant_trainer_coach_plus',
    'assistant_trainer_coach_premium',
    'assistant_trainer_coach_trainer_basic',
    'assistant_trainer_coach_trainer_plus',
    'assistant_trainer_coach_trainer_premium',
    'coach_trainer_candidate',
    'coach_trainer_basic',
    'coach_trainer_plus',
    'coach_trainer_premium',
    'examiner',
    'examiner_mfa',
    'examiner_coach_trainer',
    'examiner_coach',
    'examiner_coach_trainer_mfa',
    'examiner_coach_mfa',
  ];

  private rolesProfessional = [
    ...this.rolesInstructor,
    'member_expired',
    'school_pending',
    'school_expired',
    'assistant_candidate',
    'assistant_basic',
    'assistant_plus',
    'assistant_premium',
    'school_free',
    'school_basic',
    'school_plus',
    'translator',
    'editor',
    'administrator',
    'office',
    'assistant_instructor_candidate',
    'assistant_instructor_basic',
    'assistant_instructor_plus',
    'assistant_instructor_premium',
  ];

  constructor() {}

  init() {
    if (
      !DrupalConstants.Connection ||
      !DrupalConstants.Connection.current_user ||
      !DrupalConstants.Connection.current_user.roles
    ) {
      return;
    }
    this.roles = DrupalConstants.Connection.current_user.roles;
    this.roleName = this.roles.join(', ').replace('authenticated, ', '');
    this.checkIsInstructor();
    this.isProfessional = this.checkIsProfessional();
    this.isSchool = this.checkIsSchool();
    this.isCoach = this.checkIsCoach();
  }

  /**
   * check if user has a role
   *
   * @param roleMachineName role machine name or array of machine names
   * if this param is empty, it will check for anonymous user
   * @param contains if the role contains name or not, default to false
   */
  userHasRole(roleMachineName?: string | string[], contains = false, roles?: string[]): boolean {
    // for anonymous user
    if (!roleMachineName) {
      return this.roles.length === 0;
    }
    // handle multiple roles
    if (Array.isArray(roleMachineName)) {
      let roleContains = false;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      for (const role of roleMachineName) {
        roleContains = roleContains || this.checkStringForRole(role, contains, roles);
      }
      return roleContains;
    } else {
      // handle single role
      return this.checkStringForRole(roleMachineName, contains, roles);
    }
  }

  /**
   * check if is the current user has the coach level unlocked
   *
   * @return {boolean}
   */
  checkIsCoach(roles?: string[]): boolean {
    return (
      this.userHasRole(['coach', 'assistant_trainer', 'examiner'], true, roles) &&
      !this.userHasRole('coach_candidate', false, roles)
    );
  }

  clearRole(): void {
    this.roles = [];
    this.roleName = '';
  }

  checkIsProfessional(roles?: string[]): boolean {
    for (const role of this.rolesProfessional) {
      if (this.checkStringForRole(role, false, roles)) {
        return true;
      }
    }
    return false;
  }

  checkIsMembershipExpired() {
    return this.checkStringForRole('member_expired', true);
  }

  getMembersQualificationsCardColor(title: string): string {
    if (!title) {
      return 'green-bg-gradient';
    }
    title = title.toLowerCase();
    switch (true) {
      case title.indexOf('assistant trainer') !== -1:
      case title.indexOf('trainer') !== -1:
        return 'bg-orange-gradient';
      case title.indexOf('assistant') !== -1:
      case title.indexOf('instructor') !== -1:
        return 'blue-bg-gradient-light';
      case title.indexOf('examiner') !== -1:
        return 'blue-bg-gradient-dark';
      case title.indexOf('coach') !== -1:
      case title.indexOf('coaches') !== -1:
        return 'bg-purple-gradient';
      default:
        return 'green-bg-gradient';
    }
  }

  /**
   * check if is the current user is as school
   *
   * @return {boolean}
   */
  private checkIsSchool(): boolean {
    return this.checkStringForRole('school', true);
  }

  /**
   * TODO: redundant, can use userHasRole
   *
   * @Wassem it's better to use this because this flag is defined once time
   */
  private checkIsInstructor(): void {
    for (const role of this.rolesInstructor) {
      if (this.checkStringForRole(role, false)) {
        this.isInstructor = true;
        this.isProfessional = true;
        return;
      }
    }
  }

  private checkStringForRole(string: string, contains: boolean, roles?: string[]): boolean {
    if (!roles) {
      roles = this.roles;
    }
    if (contains) {
      for (const role of roles) {
        if (role.indexOf(string) !== -1) {
          return true;
        }
      }
    }
    return roles.indexOf(string) !== -1;
  }
}
