<!-- <div id="loading-container" class="text-center text-white row h-100 w-100 mx-auto">
  <div class="col align-self-center"> -->
<div class="text-center">
  <div *ngIf="!navDataService.httpRequestDetails.progress" class="loader">
    <i class="icon icon-kiter icon-64"></i>
    <div translate>Loading...</div>
  </div>
  <div *ngIf="navDataService.httpRequestDetails.progress" class="px-5">
    <div class="radial-progress m-auto" [ngClass]="'data-progress-' + navDataService.httpRequestDetails.progress">
      <div class="circle">
        <div class="mask full">
          <div class="fill"></div>
        </div>
        <div class="mask half">
          <div class="fill"></div>
          <div class="fill fix"></div>
        </div>
        <div class="shadow"></div>
      </div>
      <div class="inset">
        <div class="numbers pt-2 text-center">
          <div class="loader">
            <i class="icon icon-kiter icon-64"></i>
          </div>
          <div class="progress-text">{{ navDataService.httpRequestDetails.progress }}%</div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">{{ navDataService.httpRequestDetails.message }}</div>
</div>
<!-- </div>
</div> -->
