import { ApplicationRef, Component, HostListener, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';

import {
  CustomService,
  DeviceDetectService,
  I18nService,
  InternetConnectionService,
  NavDataService,
  RolesService,
} from '@app/core/services';
import { environment } from '@env/environment';
import { DrupalConstants, UserService } from '@makiwin/ngx-drupal8-rest';

import { isPlatformServer } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
// import { CodePush, InstallMode, SyncStatus } from '@ionic-native/code-push/ngx';
import { File as CordovaFile } from '@ionic-native/file/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { supportedLanguages } from '../translations/languages';
import { LocationService } from './core/services/location/location.service';
import { ModalManagerService, ModalTypes } from './core/services/modal-manager';
import { StorageCacheService } from './core/services/storage-cache/storage-cache.service';
import { StorageDocumentsService } from './core/services/storage-documents/storage-documents.service';
import { SubscriptionPlansService } from './core/services/subscription-plans/subscription-plans.service';
import { LocalStorageService } from './core/services/localstorage/localstorage.service';
import { CrispChatService } from './core/services/crisp-chat/crisp-chat.service';
import { browserAnimationsModuleConfig } from './app.module';

declare let wkWebView: any;
declare const cordova;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readyToClose = false;
  private timesNotNetwork = 0;

  constructor(
    public navDataService: NavDataService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private zone: NgZone,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private i18nService: I18nService,
    private deviceDetectService: DeviceDetectService,
    private toast: Toast,
    private network: Network,
    private mobileAccessibility: MobileAccessibility,
    private rolesService: RolesService,
    private userService: UserService,
    private customService: CustomService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    @Inject(PLATFORM_ID) private platformId: any,
    private modalManagerService: ModalManagerService,
    private internetConnectionService: InternetConnectionService,
    private applicationRef: ApplicationRef,
    // private codePush: CodePush,
    private keyBoard: Keyboard,
    private router: Router,
    private firebaseX: FirebaseX,
    private file: CordovaFile,
    private swUpdate: SwUpdate,
    private storageCacheService: StorageCacheService,
    private storageDocumentsService: StorageDocumentsService,
    private locationService: LocationService,
    private subscriptionPlansService: SubscriptionPlansService,
    private localStorageService: LocalStorageService,
    private ccService: NgcCookieConsentService,
    private crispChatService: CrispChatService
  ) {
    // this.locationService.fetchMyLocation();
  }

  @HostListener('document:click', ['$event'])
  onClickHandler(event: MouseEvent) {
    const target = event.target as HTMLLinkElement;
    if (target.localName === 'a' && target.href && target.href.indexOf(window.location?.origin) === -1) {
      event.preventDefault();
      this.navDataService.navigate(target.href, true);
    }
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initCookie();

    this.internetConnectionService.isOnline$.subscribe((online) => {
      this.zone.run(() => {
        if ((this.customService.connected = online)) {
          this.timesNotNetwork = 0;
        } else {
          this.timesNotNetwork++;
        }
        // TODO: to implement a warning to show when is offline
        // if (!this.customService.connected && !this.navDataService.notificationMessage) {
        //   //  && this.timesNotNetwork >= 5) {
        //   this.navDataService.notificationMessage = {
        //     message: this.translateService.instant('No internet connection, please check your network'),
        //     type: 'danger',
        //     autoHide: false,
        //     close: true,
        //     connectionWarning: true,
        //     // icon: 'icon icon-times',
        //   };
        // } else {
        //   if (
        //     this.customService.connected &&
        //     this.navDataService.notificationMessage &&
        //     this.navDataService.notificationMessage.connectionWarning
        //   ) {
        //     delete this.navDataService.notificationMessage;
        //   }
        // }
      });
    });

    // monitor the navigation to set the active navigation item
    this.navDataService.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('#') === -1) {
          this.navDataService.navigationHistory.push(event.url);
        }
        this.navDataService.currentPage = event.url;
      }
    });
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, supportedLanguages);
    this.i18nService.changedSelectedLanguage$.subscribe(() => {
      if (this.localStorageService.getItem('cookieAccepted') !== 'true') {
        this.translateCookieBar();
      }
    });
    if (this.userService.isLoggedIn && this.navDataService.currentUserProfile) {
      // const currentLanguage = this.navDataService.currentUserProfile.preferred_langcode;
      // if (currentLanguage) {
      //   this.i18nService.language = currentLanguage.code;
      // }
      this.subscriptionPlansService.get().subscribe();
      this.rolesService.init();
      // if (this.deviceDetectService.isCordova) {
      const currentLanguage = this.navDataService.currentUserProfile.preferred_langcode;
      if (currentLanguage) {
        this.i18nService.language = currentLanguage.code.toLowerCase();
      }
      // this.customService.trackLocation();
      // }
    }

    this.suggestLanguage();

    const onNavigationEnd = this.navDataService.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    // Cordova platform and plugins initialization
    if (!this.deviceDetectService.isCordova) {
      if (this.swUpdate.isEnabled) {
        if (this.swUpdate.isEnabled) {
          this.swUpdate.checkForUpdate().then((newVersion) => {
            if (newVersion) {
              this.swUpdate.activateUpdate().then(() => {
                window.location?.reload();
              });
            }
          });
        }
      }
    }
    document.addEventListener(
      'deviceready',
      () => {
        this.zone.run(() => this.onCordovaReady());
      },
      false
    );

    if (!this.navDataService.currentUserProfile) {
      setTimeout(
        () =>
          this.navDataService.initProfile().subscribe((profile) => {
            if (!profile && !this.navDataService.isPublicUrl(this.router.url)) {
              this.navDataService.navigate(this.deviceDetectService.isCordova ? '/user/login' : '/');
            }
          }),
        500
      );
    }
    // on window resize if we are on browser or windows platform reinit the app
    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        if (
          this.deviceDetectService.isTabletOrientationLandscape &&
          this.deviceDetectService.isDesktop &&
          this.navDataService.navigationHistory.indexOf('/elearning/videos') !==
            this.navDataService.navigationHistory.length - 1
        ) {
          this.navDataService.appReady = false;
          // this.modalManagerService.dismiss();
          setTimeout(() => {
            this.navDataService.appReady = true;
          });
        }
      });
  }

  initCookie() {
    // if (this.deviceDetectService.isIOS) {
    //   return true;
    // }

    const supportedLanguag = Object.keys(supportedLanguages).join('|');

    if (this.localStorageService.getItem('cookieAccepted') === 'true') {
      this.checkTagManager();
    } else {
      this.initGA();
      this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // cookieconsent_status
        if (event.status === 'allow') {
          this.localStorageService.setItem('cookieAccepted', 'true');
          this.initGTM();
          this.ccService.destroy();
        }
      });
      const browserLang = this.translateService.getBrowserLang().toLowerCase();
      // Provide all supported languages
      if (this.deviceDetectService.isCordova) {
        this.translateService.use(browserLang.match('/' + supportedLanguag + '/') ? browserLang : 'en');
      }
      this.translateCookieBar();
    }
  }

  translateCookieBar() {
    this.ccService.getConfig().content = this.ccService.getConfig().content || {};
    // Override default messages with the translated ones
    this.ccService.getConfig().content.message = this.translateService.instant('content.message');
    this.ccService.getConfig().content.allow = this.translateService.instant('content.allow');
    this.ccService.getConfig().content.deny = this.translateService.instant('content.deny');
    this.ccService.getConfig().content.link = this.translateService.instant('content.link');
    this.ccService.getConfig().content.href = this.translateService.instant('content.href');
    this.ccService.getConfig().content.policy = this.translateService.instant('content.policy');
    this.ccService.destroy(); // remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
  }

  onCordovaReady() {
    // this.codePush.sync({ installMode: InstallMode.IMMEDIATE }).subscribe((status) => {
    //   if (
    //     status === SyncStatus.DOWNLOADING_PACKAGE ||
    //     status === SyncStatus.INSTALLING_UPDATE ||
    //     status === SyncStatus.IN_PROGRESS
    //   ) {
    //     this.navDataService.notificationMessage = {
    //       message: this.translateService.instant('Downloading updates, Please wait...'),
    //       type: 'info',
    //       autoHide: false,
    //       close: false,
    //     };
    //   } else if (status === SyncStatus.UP_TO_DATE) {
    //     delete this.navDataService.notificationMessage;
    //   }
    // });
    if (this.userService.isLoggedIn) {
      // this.customService.trackLocation();
      if (!this.localStorageService.getItem('deviceInfoSubmitted')) {
        this.customService.submitDeviceInfo();
      }
    } else {
      this.i18nService.language = window.navigator?.language;
    }
    this.deviceDetectService.enableOrientation();
    // this.initGTM();
    this.splashScreen.hide();
    this.storageCacheService.initCacheDirectory();
    this.mobileAccessibility.usePreferredTextZoom(false);

    this.checkNetworkConnection();
    this.statusBar.styleLightContent();
    this.statusBar.overlaysWebView(false);
    this.statusBar.backgroundColorByHexString('#005EB8');
    // handle back button
    this.handleBackButton();

    if (this.deviceDetectService.isIOS) {
      browserAnimationsModuleConfig.disableAnimations = true;
      wkWebView.injectCookie(DrupalConstants.backEndUrl + '/');
      this.keyBoard.hideFormAccessoryBar(false);
      this.initIDFA();
      this.storageDocumentsService.storageLocation = cordova.file.documentsDirectory;
      // WEB-3990 - fix scroll active element on the viewPort on iOS
      window.addEventListener('keyboardDidShow', () => {
        document.activeElement.scrollIntoView();
      });
    } else {
      this.storageDocumentsService.storageLocation = cordova.file.externalRootDirectory + '/Download';
      // this.storageDocumentsService.storageLocation = cordova.file.externalDataDirectory;
    }
    this.storageDocumentsService.setDownloadEntry();

    // using language suggest for web and cordova
    // if (!localStorage.getItem('mainLanguage')) {
    //   // set app default language
    //   this.setPreferredLanguage();
    // }

    document.addEventListener(
      'resume',
      () => {
        setTimeout(() => {
          this.zone.run(() => {
            this.navDataService.initProfile().subscribe();
          });
        }, 0);
      },
      false
    );
    this.firebaseX.onMessageReceived().subscribe((notification) => {
      if (notification.url_to_show && notification.tap) {
        this.navDataService.navigate(notification.url_to_show, true);
      }
    });
  }

  checkNetworkConnection() {
    // watch network for a disconnect
    this.network.onDisconnect().subscribe(() => {
      this.customService.connected = false;
    });

    // watch network for a connection
    this.network.onConnect().subscribe(() => {
      this.customService.connected = true;
    });
  }

  /**
   * suggest the default app language on first start
   */
  suggestLanguage() {
    const defaultLang = navigator.language.split('-')[0];
    // only once, browser language supported, not equals the current language
    if (
      !this.deviceDetectService.isCordova &&
      !this.localStorageService.getItem('language_suggested') &&
      this.i18nService.getSupportedLanguage(defaultLang) &&
      defaultLang !== this.i18nService.language
    ) {
      setTimeout(() => {
        this.modalManagerService.open(ModalTypes.languageSuggest);
      }, 5000);
    }
    // if (this.userService.isLoggedIn) {
    //   const uid = DrupalConstants.Connection.current_user.uid;
    //   const values: any = {
    //     preferred_langcode: this.i18nService.language.toLowerCase(),
    //   };
    //   this.userService.update(uid, values).subscribe();
    // }
  }

  /**
   * Override cordova android back btn
   * navigate back if not on home page, and close app if on home page after displaying a confirm message
   */
  handleBackButton() {
    // exit app if user at home, otherwhise will work as a normal back btn
    document.addEventListener(
      'backbutton',
      (e: any) => {
        e.preventDefault();
        if (this.navDataService.router.url.indexOf('/account') !== -1) {
          if (this.readyToClose) {
            (navigator as any).app.exitApp();
          } else {
            this.toast
              .show(this.translateService.instant(`Press back again to exit`), '3000', 'bottom')
              .subscribe((toast) => {
                if (toast.event) {
                  this.readyToClose = false;
                }
              });
            this.readyToClose = true;
          }
        } else {
          this.zone.run(() => {
            this.navDataService.navigateBack();
            setTimeout(() => {
              this.applicationRef.tick();
            }, 0);
          });
        }
        return false;
      },
      false
    );
  }

  /**
   * init dynamic GTM based on current platform
   */
  initGTM() {
    const headerScript = document.createElement('script');
    const bodyScript = document.createElement('noscript');

    // <!-- Google Tag Manager -->
    // Add script content
    headerScript.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${environment.googleTagManagerId}');`;
    // <!-- End Google Tag Manager -->
    // <!-- Google Tag Manager (noscript) -->
    bodyScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=
    ${environment.googleTagManagerId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    // <!-- End Google Tag Manager (noscript) -->
    // Append
    document.head.appendChild(headerScript);
    document.body.appendChild(bodyScript);
    // start angulartics tracking
    if (this.userService.isLoggedIn) {
      this.angulartics2GoogleTagManager.setUsername(`${DrupalConstants.Connection.current_user.uid}`);
    }
    this.angulartics2GoogleTagManager.startTracking();
    this.router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        this.angulartics2GoogleTagManager.pageTrack(item.url);
      }
    });

    this.initHotJar();
    if (!this.deviceDetectService.isCordova) {
      this.crispChatService.initChatBot();
    }
  }

  initGA() {
    let uid = 0;
    if (this.userService.isLoggedIn) {
      uid = DrupalConstants.Connection.current_user.uid;
    }
    const headerScript = document.createElement('script');
    headerScript.innerHTML = `(function(w, d, s, l, i){window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${environment.googleAnalyticId}', { 'anonymize_ip': true,
    'user_id': '${uid}', cookie_update: false});
    var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtag/js?id=' + i + dl; f.parentNode.insertBefore(j, f);})
      (window, document, 'script', 'dataLayer', '${environment.googleAnalyticId}');`;
    // Append
    document.head.appendChild(headerScript);
  }

  private checkTagManager() {
    // TODO: something wrong with the status returned type, the actual value is a string.
    if (this.ccService.open()?.hasConsented()) {
      this.initGTM();
    } else {
      this.initGA();
    }
    this.ccService.destroy();
  }

  private initIDFA(): void {
    const idfaPlugin = cordova.plugins.idfa;

    idfaPlugin
      .getInfo()
      .then((info) => {
        if (!info.trackingLimited) {
          return info.idfa || info.aaid;
        } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
          return idfaPlugin.requestPermission().then((result) => {
            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
              return idfaPlugin.getInfo().then((infoResult) => infoResult.idfa || infoResult.aaid);
            }
            return false;
          });
        }
      })
      .then((idfaOrAaid) => {
        this.initGA();
        if (idfaOrAaid) {
          this.initGTM();
        }
      });
  }

  private initHotJar(): void {
    const headerScript = document.createElement('script');
    headerScript.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2949317,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    document.head.appendChild(headerScript);
  }
}
