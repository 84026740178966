/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FAQ, MembershipProduct, NameTid } from '@app/core/models/drupal8';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { CustomService } from '@app/core/services/drupal8/custom/custom.service';
import { ModalTypes } from '@app/core/services/modal-manager';
import { ModalManagerService } from '@app/core/services/modal-manager/modal-manager.service';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import SwiperCore, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';

import { CommonModule } from '@angular/common';
import { ModalComponent } from '@app/core/services/modal-manager/modal-component';
import { SubscriptionPlansService } from '@app/core/services/subscription-plans/subscription-plans.service';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'swiper/angular';
import { CircularSpinnerComponent } from '@app/shared/components/circular-spinner/circular-spinner.component';
import { ImageSrcsetComponent } from '@app/shared/components/image-srcset/image-srcset.component';
import { environment } from '@env/environment';

SwiperCore.use([Navigation, Autoplay, Pagination]);
@Component({
  standalone: true,
  imports: [
    CommonModule,
    SwiperModule,
    NgbAccordionModule,
    TranslateModule,
    CircularSpinnerComponent,
    ImageSrcsetComponent,
  ],
  selector: 'membership-subscriptions',
  templateUrl: './membership-subscriptions.component.html',
  styleUrls: ['./membership-subscriptions.component.scss'],
})
export class MembershipSubscriptionsComponent extends ModalComponent implements OnInit, OnDestroy {
  @Input() isModal = true;
  categoryTerms: NameTid[] = [
    {
      name: 'kiter',
      tid: 407,
      // tid: 405, // stage
    },
    {
      name: 'instructor',
      tid: 408,
      // tid: 406, // stage
    },
    {
      name: 'assistant',
      tid: 409,
      // tid: 407, // stage
    },
    {
      name: 'examiner',
      tid: 410,
      // tid: 408, // stage
    },
  ];
  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      reverseDirection: false,
      disableOnInteraction: false,
    },
    pagination: true,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
      disabledClass: 'disabled',
    },
    loop: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      736: {
        slidesPerView: 2,
      },
      690: {
        slidesPerView: 3,
      },
      480: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };
  faq: FAQ[] = [];
  activeProduct: MembershipProduct;
  featuresRadious: string;
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  countDown: any;
  membershipPlans$: Observable<MembershipProduct[]> = this.subscriptionPlansService.get().pipe(
    tap((plans) => {
      this.membershipPlans = plans;
      this.selectProduct(this.membershipPlans[1]);
    })
  );
  private membershipPlans: MembershipProduct[] = [];
  constructor(
    public rolesService: RolesService,
    public deviceDetectService: DeviceDetectService,
    public navDataService: NavDataService,
    private viewService: ViewService,
    private modalManagerService: ModalManagerService,
    private customService: CustomService,
    private translate: TranslateService,
    private subscriptionPlansService: SubscriptionPlansService
  ) {
    super();
  }

  ngOnInit() {
    this.getFaq();
    this.getPromotionDate();
  }

  ngOnDestroy() {
    clearInterval(this.countDown);
  }

  getPromotionDate() {
    this.viewService
      .get('/api/membership_promotion_date')
      .pipe(catchError(() => of(false)))
      .subscribe((data: any) => {
        if (data) {
          this.timeLeft(data.date);
        }
      });
  }

  timeLeft(dateString: string) {
    this.countDown = setInterval(() => {
      const startDate = new Date();
      const endDate = new Date(dateString);
      // get total seconds between the times
      if (endDate > startDate) {
        let delta = Math.abs(endDate.valueOf() - startDate.valueOf()) / 1000;

        this.days = Math.floor(delta / 86400);
        delta -= this.days * 86400;
        this.days = this.days * 24;

        this.hours = Math.floor(delta / 3600) % 24;
        delta -= this.hours * 3600;
        this.hours += this.days;
        this.hours = String(this.hours).padStart(2, '0');

        this.minutes = Math.floor(delta / 60) % 60;
        delta -= this.minutes * 60;
        this.minutes = String(this.minutes).padStart(2, '0');

        this.seconds = Math.floor(delta % 60);
        this.seconds = String(this.seconds).padStart(2, '0');
      }
    }, 1000);
  }

  selectProduct(product: MembershipProduct) {
    if (!product) {
      return;
    }
    this.activeProduct = product;
    const index = this.membershipPlans.map((p) => p.variation_id).indexOf(this.activeProduct.variation_id);
    if (index === 0) {
      this.featuresRadious = 'radious-left-none';
    } else if (index === this.membershipPlans.length - 1) {
      this.featuresRadious = 'radious-right-none';
    } else {
      delete this.featuresRadious;
    }
  }

  pay(product: MembershipProduct) {
    if (environment.needD7) {
      this.navDataService.navigate('/roles-fees-management', true);
    } else {
      let requestData = [];
      const price = product.price_discount;
      requestData = [
        {
          type: 'subscription',
          purchased_entity: {
            type: 'subscription',
            sku: product.sku,
            price: this.customService.replaceCommaWithDot(price),
            currency_code: product.price__currency_code,
          },
        },
      ];
      this.modalManagerService.open(ModalTypes.payment, { products: requestData, total: price });
    }
  }

  getFaq() {
    const viewOptions: ViewOptions = {
      args: [],
    };
    this.categoryTerms.forEach((categoryTerm) => {
      if (this.rolesService.userHasRole(categoryTerm.name, true)) {
        viewOptions.args[0] = categoryTerm.tid;
      }
    });
    this.viewService
      .get(`/${this.translate.currentLang.replace('en', '')}/api/v1/faq.json`, viewOptions)
      .subscribe((data) => {
        this.faq = data;
      });
  }
}
