import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CenterList } from '@app/core/models';
import { CentersService } from '@app/core/services/centers/centers.service';
import { ToolsService } from '@app/core/services/tools';
import { debounceTime, distinctUntilChanged, Observable, switchMap, tap } from 'rxjs';
import { OperatorFunction } from 'rxjs/internal/types';

@Component({
  selector: 'center-selector',
  templateUrl: './center-selector.component.html',
  styleUrls: ['./center-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CenterSelectorComponent),
      multi: true,
    },
  ],
})
export class CenterSelectorComponent implements ControlValueAccessor {
  @Input() inputClass: string;
  @Input() needOnlyId = false;
  @Input() iconPrepend: string;
  @Input() highlightOnValue = false;
  searching = false;
  currentCenter: CenterList;
  currentCenterModel = new FormControl(null);
  isDisabled = false;
  onChange;
  onTouch;
  constructor(private centersService: CentersService, private tools: ToolsService) {
    this.currentCenterModel.valueChanges.subscribe((value) => this.onChangeCenter(value));
  }

  formatter = (x: CenterList) =>
    `${x.title} (${x.field_school_address_locality} - ${x.field_school_address_country_code} )`;

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        this.searching = true;
        return this.centersService
          .getCenters({
            filters: { full_search: term, country: term },
            pagination: { items_per_page: 50, page: 0 },
          })
          .pipe(
            tap(() => {
              this.searching = false;
            })
          );
      })
    );

  writeValue(obj: any): void {
    this.currentCenterModel.setValue(obj);
    // TODO set the value of the instructor
    // in case of the Coach object is fine,
    // in casae of the id we need to fetch the coach by id
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onChangeCenter(value: CenterList | string) {
    if (!this.onChange) {
      return;
    }
    if (!value || value === '') {
      this.onChange(null);
    }
    if (typeof value === 'string') {
      return;
    }
    this.onChange(this.needOnlyId ? value.nid : value);
  }
}
