<div
  class="alert fixed-top"
  role="alert"
  [ngClass]="'bg-' + notificationMessage.type"
  @slideTopBottom
  *ngIf="notification() as notificationMessage"
>
  <div class="container-fluid d-flex align-items-center px-0">
    <i class="me-3 icon-24" *ngIf="notificationMessage.icon" [ngClass]="notificationMessage.icon"></i>
    <div [innerHTML]="notificationMessage.message"></div>
    <div class="flex-fill"></div>
    <!-- <button
        class="small border border-light rounded-1"
        (click)="stopTimerObservable()"
        *ngIf="timerObservable"
      >
        Cancel
      </button> -->
    <button
      type="button"
      class="button-for-close"
      aria-label="Close"
      *ngIf="notificationMessage.close"
      (click)="close()"
    >
      <span aria-hidden="true">
        <i class="icon icon-24 text-white icon-times"></i>
      </span>
    </button>
  </div>
</div>
