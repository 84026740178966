import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DistanceFromMapValue } from '../distance-from-map-selector/distance-from-map-selector.component';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { GoogleMapService } from '@app/core/services/tools';

@Component({
  selector: 'responsive-distance-from-map-selector',
  templateUrl: './responsive-distance-from-map-selector.component.html',
  styleUrls: ['./responsive-distance-from-map-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResponsiveDistanceFromMapSelectorComponent),
      multi: true,
    },
  ],
})
export class ResponsiveDistanceFromMapSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<DistanceFromMapValue>
{
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: DistanceFromMapValue;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;
  currentValue: DistanceFromMapValue;
  selectedPlaceHolder: string;
  mapCenter$: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject<google.maps.LatLngLiteral>(null);
  distanceValues = {
    min: 1,
    max: 200,
  };
  distanceConfig = {
    start: this.distanceValues.min,
    behaviour: 'snap',
    connect: 'lower',
    range: this.distanceValues,
    step: 1,
    tooltips: true,
  };
  distance: FormControl = new FormControl(this.distanceValues.min);
  currentZoom = 13;
  confirmed = false;
  currentAutoCompleteText = '';
  mapOptions: google.maps.MapOptions = { ...this.googleMapsService.standardMapOptions, fullscreenControl: false };

  private myPosition: google.maps.LatLngLiteral;

  constructor(public googleMapsService: GoogleMapService) {
    this.googleMapsService.initGoogleMaps().subscribe();
    navigator.geolocation.getCurrentPosition((position) => {
      this.myPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.setPinOnMap();
    });
    this.distance.valueChanges.subscribe(() => this.setPinOnMap());
  }

  get value(): DistanceFromMapValue | null {
    return this.currentValue;
  }

  set value(val: DistanceFromMapValue | null) {
    this.writeValue(val);
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: DistanceFromMapValue | null) => {};

  onTouched = () => {};

  writeValue(value: DistanceFromMapValue | null): void {
    this.currentValue = value;
    this.onChange(value);
    this.generatePlaceHolder();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * handle the click on the map
   *
   * @param {google.maps.MapMouseEvent | google.maps.IconMouseEvent} $event
   */
  mapClick($event: google.maps.MapMouseEvent | google.maps.IconMouseEvent): void {
    this.currentValue = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
    this.setPinOnMap();
  }

  /**
   * handle the address choose from the autocomplete
   *
   * @param address new address
   * @returns
   */
  handleAddressChange(address?: any) {
    if (!(address && address.geometry)) {
      return;
    }
    this.currentValue = {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      formattedAddress: address.formatted_address,
    };
    this.setPinOnMap(true);
  }

  increaseDistance(): void {
    if (this.distance.value < this.distanceValues.max) {
      this.distance.setValue(this.distance.value + 1);
    }
  }

  decreaseDistance(): void {
    if (this.distance.value > this.distanceValues.min) {
      this.distance.setValue(this.distance.value - 1);
    }
  }

  clear($event?: Event) {
    $event?.stopImmediatePropagation();
    $event?.preventDefault();
    this.distance.setValue(this.distanceValues.min);
    this.value = null;
    this.dropDown.close();
  }

  confirm(): void {
    this.onChange(this.currentValue);
    this.dropDown.close();
  }

  /**
   * add the pin to the map from the location selected by user
   * and calculate automatically the zoom base by the distance
   * if the parameter setCenter is true center the pin on the map
   *
   * @param {boolean} setCenter specify if the pin has to be center in the map
   * @returns
   */
  private setPinOnMap(setCenter = false) {
    if (this.isInModal) {
      this.onChange(this.currentValue);
    }
    if (this.currentValue) {
      this.currentValue.distance = this.distance.value;

      /**
       * original formula to calculate meter per pixel from lat and current zoom
       *
       * (156543.03392 * Math.cos((this.currentValue.lat * Math.PI) / 180)) /
       * this.distance.value /
       * Math.pow(2, this.currentZoom)
       */

      this.currentZoom =
        Math.round(
          Math.log((156543.03392 * Math.cos((this.currentValue.lat * Math.PI) / 180)) / this.distance.value / 15) /
            Math.log(2)
        ) + 1;
      if (setCenter) {
        this.mapCenter$.next({ lat: this.currentValue.lat, lng: this.currentValue.lng });
      }
      this.generatePlaceHolder();
      return;
    }
    if (this.myPosition) {
      this.mapCenter$.next({ lat: this.myPosition.lat, lng: this.myPosition.lng });
      return;
    }
  }

  /**
   * generate the placeholder for the field
   *
   */
  private generatePlaceHolder(): void {
    if (!this.currentValue) {
      this.selectedPlaceHolder = null;
      this.currentAutoCompleteText = '';
      return;
    }
    if (this.currentValue.formattedAddress) {
      this.formatPlacholder();
      return;
    }

    this.googleMapsService.reverseGeocoding(this.currentValue.lat, this.currentValue.lng).subscribe((place) => {
      if (this.currentValue) {
        this.currentValue.formattedAddress = place.formatted_address;
        this.currentAutoCompleteText = place.formatted_address;
        this.formatPlacholder();
      }
    });
  }

  /**
   * format the string for the placeholder in case
   * the user select a point and distance
   *
   */
  private formatPlacholder() {
    this.selectedPlaceHolder = `${this.currentValue.formattedAddress} - ${this.currentValue.distance}Km`;
  }
}
