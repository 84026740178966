<div class="d-flex flex-grow-1 flex-column justify-content-center">
  <div class="modal-header pt-1 mb-1">
    <h3 class="mt-0 modal-title text-center mx-auto" id="modal-basic-title">
      <span class="d-inline-block align-middle ms-1" translate>Certificate details</span>
    </h3>
    <button class="btn-close btn-red" (click)="dismiss()"></button>
  </div>
  <div class="modal-body" translate>
    <div class="d-lg-flex justify-content-between">
      <div class="">
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Cert ID:</div>
          <div>{{ navDataService.storage.id }}</div>
        </div>
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Cert Date:</div>
          <div>{{ navDataService.storage.certification_date }}</div>
        </div>
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Cert Type:</div>
          <div>{{ navDataService.storage.type }}</div>
        </div>
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Students:</div>
          <div>{{ studentNamesEmails }}</div>
        </div>
      </div>
      <div class="">
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Teaching hours:</div>
          <div>{{ navDataService.storage.teaching_hours }}</div>
        </div>
        <div class="d-flex flex-fill" *ngIf="navDataService.storage.course?.id">
          <div class="fw-bold me-2">Course ID:</div>
          <div>{{ navDataService.storage.course?.id }}</div>
        </div>
        <div class="d-flex flex-fill" *ngIf="navDataService.storage.course?.title">
          <div class="fw-bold me-2">Course title:</div>
          <div>{{ navDataService.storage.course?.title }}</div>
        </div>
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Training levels:</div>
          <div>{{ trainingLevelNames }}</div>
        </div>
        <div class="d-flex flex-fill">
          <div class="fw-bold me-2">Skills:</div>
          <div>{{ skillTitles }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
