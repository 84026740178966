import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'circular-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circular-spinner.component.html',
  styleUrls: ['./circular-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircularSpinnerComponent {
  @Input() iconClass: string;
}
