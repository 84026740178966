import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@app/core/services';

@Pipe({
  name: 'i18nDate',
  pure: false,
})
export class I18nDatePipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: any, format: string): string {
    if (!value) {
      return '';
    }
    try {
      return this.i18nService.localizeDateAndFormat(value, format);
    } catch (error) {
      console.error(error);
      return '';
    }
  }
}
