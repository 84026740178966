import { Pipe, PipeTransform } from '@angular/core';
import { SpokenLanguage } from '@app/core/models';

@Pipe({
  name: 'filterSpokenLanguages',
})
export class FilterSpokenLanguagesPipe implements PipeTransform {
  transform(languages: SpokenLanguage[], filter: string, originaLanguage = true): SpokenLanguage[] {
    if (!filter || filter === '') {
      return languages;
    }
    filter = filter.toLowerCase();
    const field = originaLanguage ? 'lang_trans' : 'name_en';
    return languages.filter((language) => language[field]?.toLowerCase().startsWith(filter));
  }
}
