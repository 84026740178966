import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToolsService } from '@app/core/services';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { Observable, map } from 'rxjs';
import { AutocompleteForFiltersItem } from '../autocomplete-for-filters/autocomplete-for-filters-item.interface';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';

@Component({
  selector: 'responsive-filter-coach-selector',
  templateUrl: './responsive-filter-coach-selector.component.html',
  styleUrls: ['./responsive-filter-coach-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResponsiveFilterCoachSelectorComponent),
      multi: true,
    },
  ],
})
export class ResponsiveFilterCoachSelectorComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<AutocompleteForFiltersItem>, OnInit
{
  @Input() placeHolder;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() isInModal = false;
  @Input() replaceMobileButton = false;

  currentValue: AutocompleteForFiltersItem;
  formControl: FormControl = new FormControl();
  protected placement: string[] = ['bottom-start'];
  private isInit = false;
  private apiEndpoint = '/api/v1/coaches-search';
  private fieldPicture = 'user_picture';

  constructor(private viewService: ViewService, private tools: ToolsService) {}

  @Input() set initialValue(center: AutocompleteForFiltersItem) {
    this.isInit = true;
    this.formControl.setValue(center);
  }

  @Input() set extraData(extraData: any) {
    if (extraData?.apiEndpoint) {
      this.apiEndpoint = extraData.apiEndpoint;
    }
    if (extraData?.placement) {
      this.placement = extraData.placement;
    }
    if (extraData?.userPictureField) {
      this.fieldPicture = extraData.userPictureField;
    }
  }

  search = (searchedText: string): Observable<AutocompleteForFiltersItem[]> =>
    this.viewService
      .get(this.apiEndpoint, {
        filters: { search: searchedText },
        pagination: { items_per_page: 50, page: 0 },
      })
      .pipe(
        map((trainers) =>
          trainers.map((trainer) => ({
            id: trainer.uid,
            label: `${trainer.field_first_name} ${trainer.field_surname}`,
            objectValues: trainer,
            picture: trainer[this.fieldPicture]?.square_md,
          }))
        )
      );

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.currentValue = value;
      if (this.isInit) {
        this.isInit = false;
        return;
      }
      this.onChange(value);
    });
  }

  /**
   * Handle the on change of input value.
   *
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: AutocompleteForFiltersItem | null) => {};

  /**
   * Handle the touch of the element.
   */
  onTouched = () => {};

  writeValue(center: AutocompleteForFiltersItem): void {
    this.isInit = true;
    this.formControl.setValue(center);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
