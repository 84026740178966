<div class="modal-header pt-1 text-center hide-in-trasparent poisition-relative">
  <h3 class="modal-title mx-auto" id="modal-basic-title">
    <span translate>Choose on the map</span>
  </h3>
  <button
    type="button"
    class="btn-close btn-red position-absolute"
    aria-label="Close"
    (click)="modalRef.dismiss()"
  ></button>
</div>
<div class="modal-body p-2 p-md-3 pb-0 pb-md-0 hide-in-trasparent">
  <distance-from-map-selector
    [formControl]="mapControl"
    [initialDistance]="initialDistance"
  ></distance-from-map-selector>
</div>
<div class="modal-footer">
  <div class="w-100 d-flex justify-content-end button-map-modal">
    <button class="btn rounded-2 me-2 d-flex justify-content-center align-items-center" (click)="modalRef.dismiss()">
      <i class="icon icon-times me-1"></i> <span tanslate>Close</span>
    </button>
    <button class="btn btn-success rounded-2 m-0 d-flex justify-content-center align-items-center" (click)="confirm()">
      <i class="icon icon-check me-1"></i>
      <span translate>Confirm</span>
    </button>
  </div>
</div>
