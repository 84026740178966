import { Pipe, PipeTransform } from '@angular/core';
import { SpokenLanguage } from '@app/core/models';
import { StaticValuesListService } from '@app/core/services/static-values-list/static-values-list.service';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'showSpokenLanguages',
})
export class ShowSpokenLanguagesPipe implements PipeTransform {
  constructor(private staticValuesList: StaticValuesListService) {}

  transform(languages: string[]): Observable<string> {
    return this.staticValuesList.getSpokenLanguages().pipe(
      map((data) => {
        const filteredLanguages: SpokenLanguage[] = [];
        languages.forEach((language) => {
          const spkLang = data.filter((spokeLanguage) => spokeLanguage.lang_code === language);
          if (spkLang.length > 0) {
            filteredLanguages.push(spkLang[0]);
          }
        });
        return filteredLanguages.map((spokenLanguage) => spokenLanguage.name_lang).join(',');
      })
    );
  }
}
