import { Injectable } from '@angular/core';
import { EreaderTableOfContentItem } from '@app/core/models';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { Book } from '@makiwin/epubjs/dist/epub.min';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';

@Injectable({
  providedIn: 'root',
})
export class EreaderControllerService {
  instance: NgxExtendedPdfViewerComponent | Book;
  currentZoom: number;
  precentage: number;
  tableOfContent: EreaderTableOfContentItem[];
  totalPages: number;
  type: string;
  displayToc = false;
  tocTitle: string;
  // current displayed search item index
  searchIndex: number;
  // search results
  results: any[];
  private readonly zoomStep = 0.25;

  constructor(
    private navDataService: NavDataService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.init();
  }

  init() {
    delete this.instance;
    delete this.tableOfContent;
    delete this.totalPages;
    delete this.type;
    delete this.results;
    delete this.tocTitle;
    this.currentZoom = 100;
    this.precentage = 0;
    this.searchIndex = 0;
    this.displayToc = false;
    this.navDataService.resetHeaderLeftButton();
    this.navDataService.resetHeaderRightButton();
  }

  // handle search for string
  search(stringToSearch: string) {
    if (this.results) {
      // remove old highlights
      this.results.forEach((element) => {
        this.instance.rendition.annotations.remove('highlight', element.cfi, {});
      });
      this.results.forEach((element) => {
        this.instance.rendition.annotations.remove(element.cfi, 'highlight');
      });
      delete this.results;
    }
    // if string length less that 1 chars, do nothing
    if (stringToSearch.length < 1) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'e_books_search',
      search: stringToSearch,
    });

    // set current search index to first item
    this.searchIndex = 0;
    // looping over all the sections of the book
    this.instance.spine.each((section) => {
      // force loading section even if not viewed
      section.load(this.instance.load.bind(this.instance)).then((contents) => {
        // if the section have content "not a cover for example"
        if (contents) {
          const results = section.find(stringToSearch);
          if (results.length > 0) {
            // highlight the result of the search
            results.forEach((element) => {
              this.instance.rendition.annotations.add('highlight', element.cfi, {});
            });
            // init
            if (!this.results) {
              this.results = [];
            }
            // join result for each section
            this.results = this.results.concat(results);
          }
        }
      });
    });
  }

  /**
   * Navigate to next highlighted search result
   */
  navigateNextResult() {
    // if there is no result
    if (!this.results || this.results.length === 0) {
      return;
    }
    // return to first item when we are on last result
    if (this.searchIndex === this.results.length) {
      this.searchIndex = 0;
    }
    // display the next result
    this.navigate(this.results[this.searchIndex].cfi);
    // prepare for next item
    this.searchIndex++;
  }

  // handle navigate via toc
  navigate(destination: string | number) {
    if (this.type === 'epub') {
      // display distination cfi or href
      this.instance.rendition.display(destination);
    } else {
      // for quiz question index
      this.instance.navigateQuestion(destination, true);
    }
    // fix to prevent to toggle sidemenu from ipad portrait to bigger screen
    this.navDataService.toggleSideMenu();
  }

  incrementZoom(): void {
    this.zoom(this.currentZoom / 100 + this.zoomStep);
  }

  decrementZoom(): void {
    this.zoom(this.currentZoom / 100 - this.zoomStep);
  }

  resetZoom(): void {
    this.zoom(1);
  }

  zoom(zoomAmount: number) {
    this.currentZoom = zoomAmount * 100;
    if (this.type === 'pdf') {
      return;
    }

    if (this.instance) {
      this.instance.rendition?.themes.fontSize(`${this.currentZoom}%`);
    }
  }
}

// FOR PDF, DISABLED
// /**
//    * TODO
//    * @param stringToSearch text to search for
//    */
//   search(stringToSearch: string) {
//     if (stringToSearch.length < 3) {
//       return;
//     }
//     // trigger the search function of the component
//     this.instance.pdfFindController.executeCommand('find', {
//       caseSensitive: false,
//       findPrevious: undefined,
//       highlightAll: true,
//       phraseSearch: true,
//       query: stringToSearch
//     });
//   }

//   // trigger the navigation function of the component
//   navigate(destination: string) {
//     this.instance.pdfLinkService.navigateTo(destination);
//   }
