import { AutocompleteInputItem } from '@app/shared/forms/autocomplete-input/autocomplete-input-item';
import { AutoCompleteInputProvider } from '@app/shared/forms/autocomplete-input/autocomplete-input-provider';
import { ViewService } from '@makiwin/ngx-drupal8-rest';
import { map, Observable } from 'rxjs';

export class ProviderDynamicAutoCompleteMembers implements AutoCompleteInputProvider {
  private viewService: ViewService;

  constructor(viewService: ViewService) {
    this.viewService = viewService;
  }

  filter(term: string): Observable<AutocompleteInputItem[]> {
    return this.viewService
      .get('/api/v1/members.json', {
        filters: { search_api_fulltext: term },
        pagination: { items_per_page: 50 },
      })
      .pipe(
        map((result) =>
          result.map((member) => ({ value: member.uid, label: member.field_first_name + ' ' + member.field_surname }))
        )
      );
  }
}
