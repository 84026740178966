<div class="filters w-100 overflow-x-scroll scrollbar d-flex align-items-center">
  <div class="chip-wrapper d-flex text-black justify-content-between align-items-center">
    <ng-container *ngFor="let item of internalItems">
      <div
        *ngIf="item.label && item.label !== ''"
        (click)="toggleCheck(item)"
        [ngClass]="{
          'active-pill': item.selected,          
        }"
        class="py-2 px-4 bg-light btn text-dark chip me-2 rounded-2"
      >
        {{ item.label }}
      </div>
    </ng-container>
  </div>
</div>
