import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appElementHover]',
})
export class ElementHoverDirective {
  @Input()
  additionClass = 'menu-item-active';

  constructor(private element: ElementRef) {}

  @HostListener('mouseover')
  onMouseover() {
    this.element.nativeElement.classList.add(this.additionClass);
  }

  @HostListener('mouseout')
  onMouseout() {
    this.element.nativeElement.classList.remove(this.additionClass);
  }
}
