import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  public static equal(val: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (CustomValidators.isPresent(Validators.required(control))) {
        return null;
      }

      const v: any = control.value;

      return val === v ? null : { equal: true };
    };
  }

  public static readonly validCenter: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value?.nid) {
      return { validCenter: true };
    }
    return null;
  };

  public static readonly email: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (CustomValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    )
      ? null
      : { email: true };
  };

  public static url: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
    if (CustomValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      v
    )
      ? null
      : { url: true };
  };

  static isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }
}
