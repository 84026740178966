import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderWithWordsService {
  visible$: Observable<boolean>;
  private pVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private translate: TranslateService) {
    this.visible$ = this.pVisible$.asObservable();
  }

  show(): void {
    this.pVisible$.next(true);
  }

  hide(): void {
    this.pVisible$.next(false);
  }

  getWords(): Observable<string[]> {
    return this.translate.get('LOADER_WORDS').pipe(map((words: string) => words.split('|').map((word) => word.trim())));
  }
}
