import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { GoogleMapService } from '@app/core/services/tools';
import { take } from 'rxjs';

@Directive({
  selector: '[appGooglePlacesAutocomplete]',
})
export class GooglePlacesAutocompleteDirective implements AfterViewInit {
  @Input() options: google.maps.places.AutocompleteOptions;
  @Output() addressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  public place: google.maps.places.PlaceResult;
  private autocomplete: google.maps.places.Autocomplete;
  private isDirty = false;

  constructor(private el: ElementRef, private ngZone: NgZone, private googleMapsService: GoogleMapService) {}

  /**
   * @inheritdoc
   */
  ngAfterViewInit(): void {
    this.initialize();
  }

  /**
   * Initialize google maps autocomplete.
   */
  private initialize(): void {
    this.googleMapsService.initGoogleMapsAutoComplete();
    // if (!this.isGoogleLibExists()) throw new Error('Google maps library can not be found');
    this.googleMapsService.autocompleteLoaded$.pipe(take(1)).subscribe({
      next: () => {
        this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

        if (!this.autocomplete) throw new Error('Autocomplete is not initialized');

        this.el.nativeElement.addEventListener('blur', () => {
          if (!this.isDirty) {
            return;
          }
          setTimeout(() => {
            if (!this.place) {
              this.addressChange.emit(null);
            }
          }, 1000);
        });

        this.el.nativeElement.addEventListener('change', () => {
          this.isDirty = true;
        });

        if (!this.autocomplete.addListener != null) {
          // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
          this.autocomplete.addListener('place_changed', () => {
            this.handleChangeEvent();
          });
        }

        this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
          if (!event.key) {
            return;
          }

          const key = event.key.toLowerCase();

          if (key == 'enter' && event.target === this.el.nativeElement) {
            event.preventDefault();
            event.stopPropagation();
          }
        });

        // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
        if (
          window &&
          window.navigator &&
          window.navigator.userAgent &&
          navigator.userAgent.match(/(iPad|iPhone|iPod)/g)
        ) {
          setTimeout(() => {
            const containers = document.getElementsByClassName('pac-container');

            if (containers) {
              const arr = Array.from(containers);

              if (arr) {
                for (const container of arr) {
                  if (!container) continue;

                  container.addEventListener('touchend', (e) => {
                    e.stopImmediatePropagation();
                  });
                }
              }
            }
          }, 500);
        }
      },
    });
  }

  public reset(): void {
    this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
    this.autocomplete.setTypes(this.options.types);
  }

  private handleChangeEvent(): void {
    this.isDirty = false;
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();
      if (this.place?.name === '') {
        this.place = null;
      }
      this.addressChange.emit(this.place);
    });
  }
}
