import { Component, Input } from '@angular/core';
import { ResponsiveImageResource } from '@app/core/models';
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination]);
@Component({
  selector: 'app-slider-preview',
  templateUrl: './slider-preview.component.html',
  styleUrls: ['./slider-preview.component.scss'],
})
export class SliderPreviewComponent {
  @Input() images: ResponsiveImageResource[] = [];
  // galleryThumbs: any = {
  //   direction: 'horizontal',
  //   el: '.gallery-thumbs',
  //   spaceBetween: 10,
  //   slidesPerView: this.deviceDetectService.isMobile ? 2 : 4,
  //   loop: true,
  //   navigation: false,
  //   freeMode: true,
  //   loopedSlides: 5,
  //   watchSlidesVisibility: true,
  //   watchSlidesProgress: true,
  //   observer: true,
  // };

  // galleryTop: SwiperOptions = {
  //   direction: 'horizontal',
  //   autoplay: { delay: 3000, stopOnLastSlide: false, reverseDirection: false, disableOnInteraction: false },
  //   spaceBetween: 10,
  //   loop: true,
  //   loopedSlides: 5,
  //   navigation: {
  //     nextEl: '.button-next',
  //     prevEl: '.button-prev',
  //     disabledClass: 'disabled',
  //   },
  //   thumbs: {
  //     swiper: this.galleryThumbs,
  //   },
  //   observer: true,
  //   keyboard: true,
  // };

  // constructor(public deviceDetectService: DeviceDetectService) {}
}
