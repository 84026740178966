import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { isPlatformBrowser } from '@angular/common';
import { UserProfile } from '@app/core/models';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { CustomService } from '@app/core/services/drupal8';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { StorageDocumentsService } from '@app/core/services/storage-documents/storage-documents.service';
import { TermsService } from '@app/core/services/terms/terms.service';
import { ToolsService } from '@app/core/services/tools';
import { environment } from '@env/environment';
import { DrupalConstants, ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vcard',
  templateUrl: './vcard.component.html',
  styleUrls: ['./vcard.component.scss'],
})
export class VcardComponent implements OnInit, OnDestroy {
  cssClass: string;
  bgGradient: string;
  expired = false;
  levelOpen = false;
  pictureMissing = true;
  trainingLevels: { code: string; skills: string[] }[] = [];
  shareIcon: boolean;
  copied: boolean;
  inSharing = false;
  private pUnsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    public navDataService: NavDataService,
    public rolesService: RolesService,
    private socialSharing: SocialSharing,
    private elementRef: ElementRef,
    private deviceDetectService: DeviceDetectService,
    private viewService: ViewService,
    @Inject(PLATFORM_ID) private platform: string,
    public customService: CustomService,
    private tools: ToolsService,
    private zone: NgZone,
    private storageDocumentsService: StorageDocumentsService,
    private termsService: TermsService
  ) {}

  ngOnInit() {
    // get current user profile info
    this.getProfileInfo();
    // if (this.deviceDetectService.isCordova) {
    //   this.shareIcon = true;
    // } else {
    //   this.shareIcon = false;
    // }
  }

  ngOnDestroy(): void {
    this.pUnsubscribeAll.next();
    this.pUnsubscribeAll.complete();
  }

  /**
   * get user info
   */
  getProfileInfo() {
    // get the current user profile details
    this.navDataService.currentUserProfile$.pipe(takeUntil(this.pUnsubscribeAll)).subscribe((profile) => {
      if (!profile) {
        return;
      }
      // To be tested
      if (profile.user_picture_multiple_json.toString().indexOf('blank-profile.png') === -1) {
        this.pictureMissing = false;
      }
      this.initColorByRole(profile.field_top_qualification);
      if (profile.field_training_level_skills.length > 0) {
        this.getSkillsForLevels(profile);
      }
    });
  }

  getSkillsForLevels(profile: UserProfile) {
    const levelsIds = profile.field_training_level_skills
      ?.map((s) => s.id)
      .join()
      .replace(' ', '');
    const options: ViewOptions = {
      filters: {
        levels: levelsIds ? levelsIds : 0,
      },
    };
    this.viewService.get('/api/skills', options).subscribe((data: any) => {
      this.trainingLevels = data.levels
        ? data.levels
            .split(',')
            .map((l) => {
              const levelIndex = levelsIds.split(',').indexOf(`${l}`);
              const level = profile.field_training_level_skills[levelIndex];
              const trainingLevel = {
                code: level?.code,
                skills: level?.skills?.map((s) => s.label),
              };
              return trainingLevel;
            })
            .reverse()
        : [];
    });
  }

  /**
   * handle vcard styles for each role
   */
  initColorByRole(name) {
    this.termsService.getQualifications().subscribe((data) => {
      const qualification = data.find((el) => el.name === name);
      if (qualification) {
        return (this.bgGradient = qualification.field_colors);
      }
    });

    // Keep the old logic for production, It will be ignored once D8 have the correct qualifications for the user
    if (this.rolesService.userHasRole(['kiter', 'assistant_instructor_candidate'], true)) {
      this.cssClass = 'vcard-green';
      if (this.rolesService.userHasRole('expired', true)) {
        this.expired = true;
        this.cssClass = 'vcard-black';
      }
    } else if (this.rolesService.userHasRole(['instructor', 'assistant_trainer_candidate'], true)) {
      this.cssClass = 'vcard-blue';
    } else if (
      this.rolesService.userHasRole(['trainer', 'coach'], true) && // not examiner_trainer or coach_trainer
      !this.rolesService.userHasRole('examiner', true) // WEB-2281
    ) {
      this.cssClass = 'vcard-orange';
    } else if (this.rolesService.userHasRole(['coach'], true)) {
      this.cssClass = 'vcard-purple';
    } else {
      // for examiner
      this.cssClass = 'vcard-black';
    }
  }

  toggleLevel() {
    this.levelOpen = !this.levelOpen;
  }

  share(): void {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    let url;

    if (environment.needD7) {
      url = `https://www.ikointl.com/member-public-detail?uid=${this.navDataService.currentUserProfile.uid}`;
    } else {
      url = `https://next.ikointl.com/members/${this.navDataService.currentUserProfile.name}`;
    }

    if (!this.deviceDetectService.isCordova) {
      return this.navDataService.navigate(url, true);
    }
    // if (!this.shareIcon) {
    //   this.tools.copyToClipboard('https://www.ikointl.com');
    //   return;
    // }
    this.inSharing = true;
    // this.navDataService.navigate('/elearning');
    import('html2canvas').then((html2canvas) => {
      // init html2canvas
      // allow remote image by usecors
      this.elementRef.nativeElement.classList.add('hide-scrollbar');
      // setTimeout cause html taking a while to reload and image was not capturing
      setTimeout(() => {
        html2canvas
          .default(this.elementRef.nativeElement, {
            useCORS: true,
            scale: 0.8,
          })
          .then((canvas) => {
            this.elementRef.nativeElement.classList.remove('hide-scrollbar');
            const imageBase64 = canvas.toDataURL('image/png');
            // commented because id deleted the local pic on no connection vCard image goes to blank
            // delete this.localProfilePic;
            // if cordova available
            // if (this.deviceDetectService.isCordova) {
            // share after adding the image and url
            this.socialSharing
              .share(
                `${DrupalConstants.Connection.current_user.name} IKO vCard`,
                `Check my profile at www.ikointl.com`,
                imageBase64,
                // `${DrupalConstants.backEndUrl}/user/${DrupalConstants.Connection.current_user.uid}`
                url
              )
              .finally(() => this.zone.run(() => (this.inSharing = false)));
            // .then(() => this.navDataService.navigateBack());
            // } else {
            //   /// create an "off-screen" anchor tag
            //   const lnk = document.createElement('a');
            //   lnk.download = 'profile_pic.png';
            //   lnk.href = canvas.toDataURL('image/png;base64');
            //   lnk.click();
            //   this.inSharing = false;
            // }
          });
      });
    });
  }

  getProfileImage() {
    return !this.inSharing &&
      this.customService.connected &&
      this.navDataService.currentUserProfile.user_picture_multiple_json
      ? this.navDataService.currentUserProfile.user_picture_multiple_json
      : this.navDataService.localProfilePic;
  }
}
