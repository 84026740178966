import { Component, OnInit } from '@angular/core';
import { DeviceDetectService } from '@app/core/services/device-detect/device-detect.service';
import { CustomService } from '@app/core/services/drupal8';
import { EreaderControllerService } from '@app/core/services/ereader-controller/ereader-controller.service';
import { ModalManagerService } from '@app/core/services/modal-manager';
import { NavDataService } from '@app/core/services/nav-data/nav-data.service';
import { RolesService } from '@app/core/services/roles/roles.service';

import { environment } from '@env/environment';
import { CrispChatService } from '@app/core/services/crisp-chat/crisp-chat.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  env = environment;

  constructor(
    public ereaderControllerService: EreaderControllerService,
    public navDataService: NavDataService,
    public deviceDetectService: DeviceDetectService,
    public rolesService: RolesService,
    public modalManagerService: ModalManagerService,
    public customService: CustomService, // private router: Router, // private localizeRouter: LocalizeRouterService
    private crispChatService: CrispChatService
  ) {}

  ngOnInit() {
    const lastIndex = this.navDataService.navigationHistory.length - 1;
    this.navDataService.currentPage = this.navDataService.navigationHistory[lastIndex];
    this.crispChatService.initChatBot();
    if (this.navDataService.currentPage === '/') {
      this.navDataService.currentPage = '/account';
    }
  }

  /**
   * handle log out
   */
  logout() {
    if (this.customService.logOutInProgress$.value) {
      return;
    }
    this.customService.logout();
    this.navDataService.sideMenuOpen = false;
  }

  navigateToLogin() {
    delete this.navDataService.currentUserProfile;
    // close side menu
    this.navDataService.toggleSideMenu();
    // navigate to login
    this.navDataService.navigate('/user/login');
  }

  navigateToBookings() {
    if (this.rolesService.userHasRole('school', true)) {
      if (this.rolesService.userHasRole(['school_expired', 'school_suspended'], true)) {
        this.modalManagerService.toggle();
      } else {
        if (environment.inAppBrowsing.bookings) {
          this.navDataService.navigate('/account/pro-training', true);
          return;
        }
        this.navDataService.navigate('/account/recreational-training');
      }
    } else if (
      this.rolesService.userHasRole(
        ['kiter', 'instructor', 'assistant_trainer', 'coach', 'examiner', 'administrator', 'suspended'],
        true
      )
    ) {
      // if (environment.inAppBrowsing.bookings) {
      //   this.navDataService.navigate('/account/booking', true);
      //   return;
      // }
      this.navDataService.navigate('/bookings/userBookings');
    } else {
      this.navDataService.navigate('/account');
    }
  }

  navigateToCourses() {
    if (this.rolesService.userHasRole(['assistant_trainer', 'examiner'], true)) {
      if (environment.inAppBrowsing.course) {
        this.navDataService.navigate('https://www.ikointl.com/account/pro-training', true);
        return;
      }
      this.navDataService.navigate('/courses/trainer');
      return;
    }
    // school
    if (environment.inAppBrowsing.bookings) {
      this.navDataService.navigate('/account/pro-training', true);
      return;
    }
    this.navDataService.navigate('/account/training');
  }

  // open crisp chat
  openCrispChat() {
    this.crispChatService.openChat();
  }

  navigateToProfile() {
    if (this.rolesService.userHasRole('school', true) && environment.production) {
      this.navDataService.navigate(
        `https://www.ikointl.com/user/${this.navDataService.currentUserProfile.uid}/edit`,
        true
      );
      return;
    }
    this.navDataService.navigate('/profile');
  }
}
