import { Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ItemListForFilters } from '../models/item-list-for-filters.model';
import { ResponsiveSearchFiltersInterface } from '../responsive-search-filters/responsive-search-filters-component.interface';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'rounded-multiselect-for-filters',
  templateUrl: './rounded-multiselect-for-filters.component.html',
  styleUrls: ['./rounded-multiselect-for-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoundedMultiselectForFiltersComponent),
      multi: true,
    },
  ],
})
export class RoundedMultiselectForFiltersComponent
  implements ControlValueAccessor, ResponsiveSearchFiltersInterface<any[]>, OnDestroy
{
  @Input() placeHolder: string;
  @Input() showCleaner = true;
  @Input() placeHolderClass: string;
  @Input() iconPrepend: string;
  @Input() disabled = false;
  @Input() initialValue: any[];
  @Input() replaceMobileButton = false;
  @Input() isInModal = false;
  @Input() extraData?: any;
  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dropDown: NgbDropdown;

  readonly defaultLimit = 6;
  itemsSelected: ItemListForFilters[] = [];
  selectedPlaceHolder: string;

  needShowMoreItems: boolean;
  currentLimit = this.defaultLimit;
  useChips = false;
  protected internalItems: ItemListForFilters[];
  private valueToSet: any[];
  private unsubscribeAll: Subject<void> = new Subject<void>();

  @Input() set items(items: ItemListForFilters[] | Observable<ItemListForFilters[]>) {
    if (items instanceof Observable) {
      items.pipe(takeUntil(this.unsubscribeAll)).subscribe((itemsAsync) => this.handleItems(itemsAsync));
      return;
    }
    this.handleItems(items);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get value(): any[] {
    const values = this.itemsSelected.map((item) => item.value);
    return values.length > 0 ? values : null;
  }

  set value(val: any[]) {
    this.convertValues(val);
    this.onChange(val);
    this.onTouched();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (selected: any[]) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};
  writeValue(values: any[]): void {
    if (!this.internalItems) {
      this.valueToSet = values;
      return;
    }
    this.convertValues(values);
    this.generatePlaceHolder();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleCheck(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.itemsSelected.push(item);
    } else {
      const index = this.itemsSelected.indexOf(item);
      if (index !== -1) {
        this.itemsSelected.splice(index, 1);
      }
    }
    this.onChange(this.value);
    this.generatePlaceHolder();
  }

  clear($event: Event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.itemsSelected = [];
    this.value = null;
    this.dropDown.close();
    this.internalItems.forEach((item) => (item.selected = false));
    this.generatePlaceHolder();
  }

  switchLimit(): void {
    this.currentLimit = this.currentLimit === this.defaultLimit ? 10000 : this.defaultLimit;
  }

  private convertValues(val: any[]) {
    this.itemsSelected = [];

    this.internalItems?.forEach((item) => (item.selected = false));
    if (val && Array.isArray(val)) {
      val.forEach((value) => {
        const item = this.internalItems.find((internalItem) => internalItem.value === value);
        if (!item) {
          return;
        }
        item.selected = true;
        this.itemsSelected.push(item);
      });
    }
  }

  private generatePlaceHolder() {
    const lengthOfSelected = this.itemsSelected.length;
    switch (true) {
      case !this.internalItems:
      case lengthOfSelected === 0:
        this.selectedPlaceHolder = null;
        break;
      case lengthOfSelected === 1:
        this.selectedPlaceHolder = this.itemsSelected[0].label;
        break;
      default:
        this.selectedPlaceHolder = `${this.placeHolder} · ${lengthOfSelected}`;
    }
  }

  private handleItems(items: ItemListForFilters[]) {
    this.internalItems = items;
    if (this.extraData?.showMoreItems) {
      this.needShowMoreItems = items?.length > this.defaultLimit;
    } else {
      this.currentLimit = 10000;
    }
    if (this.valueToSet) {
      this.convertValues(this.valueToSet);
      delete this.valueToSet;
    }
    this.generatePlaceHolder();
  }
}
