import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@app/core/services/localstorage/localstorage.service';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-cookie-consent-warning',
  templateUrl: './cookie-consent-warning.component.html',
  styleUrls: ['./cookie-consent-warning.component.scss'],
})
export class CookieConsentWarningComponent implements OnInit {
  @Output() cookieStatusChange = new EventEmitter<boolean>();
  isCookieHasConsented$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private ccService: NgcCookieConsentService, private localStorageService: LocalStorageService) {
    const status = this.localStorageService.getItem('cookieAccepted') === 'true';
    this.isCookieHasConsented$.next(status);
  }

  ngOnInit(): void {
    if (this.isCookieHasConsented$.value === false) {
      // this.isCookieHasConsented$.next(this.ccService.hasConsented());

      this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        this.isCookieHasConsented$.next(event.status === 'allow');
        this.cookieStatusChange.emit(event.status === 'allow');
      });
    } else {
      this.cookieStatusChange.emit(true);
    }
  }

  openCookieBar(): void {
    this.ccService.fadeIn();

    const elem: HTMLElement = document.getElementsByClassName('cc-btn cc-allow').item(0) as HTMLElement;
    elem?.click();
  }
}
