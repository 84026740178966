import { Injectable } from '@angular/core';
import { Country, SpokenLanguage as SpokenLanguage } from '@app/core/models';
import { ViewOptions, ViewService } from '@makiwin/ngx-drupal8-rest';
import { Observable, of, tap } from 'rxjs';
import { I18nService } from '../i18n/i18n.service';

export enum StaticValues {
  spokenLanguages = 'spokenLanguages',
  courseTemplates = 'courseTemplates',
  ridingStyle = 'ridingStyle',
  recreationalCourseType = 'recreationalCourseType',
  classSizes = 'classSizes',
  countries = 'countries',
}

const configuration: {
  [key: string]: {
    endpoint: string;
  };
} = {
  [StaticValues.spokenLanguages]: { endpoint: '/api/spoken-languages' },
  [StaticValues.courseTemplates]: { endpoint: '/api/v1.1/course_templates.json' },
  [StaticValues.ridingStyle]: { endpoint: '/api/v1/terms/riding_style.json' },
  [StaticValues.recreationalCourseType]: { endpoint: '/api/v1/terms/recreational_course_type.json' },
  [StaticValues.classSizes]: { endpoint: '/api/class_size' },
  [StaticValues.countries]: { endpoint: '/api/countries' },
};

@Injectable({
  providedIn: 'root',
})
export class StaticValuesListService {
  private cache: { [key: string]: any[] } = {};

  constructor(private viewService: ViewService, private i18nService: I18nService) {}

  getValues<T>(staticValues: StaticValues, viewOptions?: ViewOptions): Observable<T[]> {
    let cache: T[] | null;

    let keyCache = staticValues.toString();
    if (viewOptions) {
      keyCache += JSON.stringify(viewOptions);
    }

    if ((cache = this.getFromCache<T>(keyCache))) {
      return of(cache);
    }
    return this.viewService.get(configuration[staticValues].endpoint, viewOptions).pipe(
      tap((data) => {
        this.setInCache<T>(keyCache, data);
      })
    );
  }

  getCountries(): Observable<Country[]> {
    return this.getValues<Country>(StaticValues.countries, { filters: { langcode: this.i18nService.language } });
  }

  getSpokenLanguages(): Observable<SpokenLanguage[]> {
    return this.getValues<SpokenLanguage>(StaticValues.spokenLanguages);
  }

  private setInCache<T>(keyCache: string, values: T[]) {
    this.cache[keyCache] = values;
  }

  private getFromCache<T>(keyCache: string): T[] | null {
    return this.cache[keyCache];
  }
}
