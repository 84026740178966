<svg
  class="rounded-2 bg-white shwadow shadow-lg d-block m-auto position-absolute"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100px"
  height="40px"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
>
  <g transform="translate(0 50)">
    <circle cx="0" cy="0" r="12" fill="#ff6600">
      <animateTransform
        attributeName="transform"
        type="scale"
        begin="-0.375s"
        calcMode="spline"
        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
        values="0;1;0"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </g>
  <g transform="translate(33 50)">
    <circle cx="0" cy="0" r="12" fill="#ff6600">
      <animateTransform
        attributeName="transform"
        type="scale"
        begin="-0.25s"
        calcMode="spline"
        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
        values="0;1;0"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </g>
  <g transform="translate(60 50)">
    <circle cx="0" cy="0" r="12" fill="#ff6600">
      <animateTransform
        attributeName="transform"
        type="scale"
        begin="-0.125s"
        calcMode="spline"
        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
        values="0;1;0"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </g>
  <g transform="translate(100 50)">
    <circle cx="0" cy="0" r="12" fill="#ff6600">
      <animateTransform
        attributeName="transform"
        type="scale"
        begin="0s"
        calcMode="spline"
        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
        values="0;1;0"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </g>
</svg>
