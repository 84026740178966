import { formatDate, FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/services/i18n/i18n.service';

import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * A service providing default implementation for the datepicker i18n.
 * It can be used as a base implementation if necessary.
 *
 * @since 9.1.0
 */
@Injectable()
export class NgbDatepickerI18nIko extends NgbDatepickerI18n {
  private monthsShort: readonly string[];
  private monthsFull: readonly string[];

  constructor(private i18nService: I18nService) {
    super();
    this.i18nService.changedSelectedLanguage$.subscribe((lang) => {
      this.setLocaleValues(lang);
    });
  }
  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const weekdaysStartingOnSunday = getLocaleDayNames(
      this.i18nService.language,
      FormStyle.Standalone,
      width === undefined ? TranslationWidth.Short : width
    );
    const weekdays = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
    return weekdays[weekday - 1] || '';
  }

  getMonthShortName(month: number): string {
    return this.monthsShort[month - 1] || '';
  }

  getMonthFullName(month: number): string {
    return this.monthsFull[month - 1] || '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', this.i18nService.language);
  }

  private setLocaleValues(lang: string) {
    this.monthsShort = getLocaleMonthNames(lang, FormStyle.Standalone, TranslationWidth.Abbreviated);
    this.monthsFull = getLocaleMonthNames(lang, FormStyle.Standalone, TranslationWidth.Wide);
  }
}
