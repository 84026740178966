import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { growDown } from '@app/core/animations';
import { InternetConnectionService } from '@app/core/services';

@Component({
  selector: 'offline-bar',
  templateUrl: './offline-bar.component.html',
  styleUrls: ['./offline-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [growDown],
})
export class OfflineBarComponent {
  protected internetConnectionService: InternetConnectionService = inject(InternetConnectionService);
  protected isOnLine$ = this.internetConnectionService.isOnline$;
  protected height: string;

  constructor() {
    this.height = getComputedStyle(document.documentElement).getPropertyValue('--bar-fixed-message-height');
  }
}
