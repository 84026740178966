<div class="bg-primary-gradient text-white d-flex flex-grow-1 flex-column">
  <div class="bg-light text-black d-flex flex-column flex-grow-1">
    <div class="modal-header pt-1 text-center">
      <h4 class="modal-title mx-auto" id="modal-basic-title">
        <span translate>Contact Us</span>
        <button
          type="button"
          class="btn-close btn-red position-absolute"
          aria-label="Close"
          (click)="modalRef.dismiss()"
        ></button>
      </h4>
    </div>
    <div class="modal-body p-3">
      <div class="px-3 text-start" translate>
        We are available Monday to Friday from {{ firstShiftStart }} to {{ firstShiftEnd }} and from
        {{ secondShiftStart }} to {{ secondShiftEnd }}
      </div>
      <div class="mt-3 text-center">
        <div (click)="openCall()">
          <i class="icon icon-whatsapp me-1"></i>
          <span>+1(809) 5719530</span>
        </div>
        <div>
          <a href="mailto:office@ikointl.com?subject=IKO App Support">
            <i class="icon icon-envelope me-1"></i>
            <span>office@ikointl.com</span>
          </a>
        </div>
        <div (click)="openLauncher()">
          <i class="icon icon-map-marker-alt me-1"></i>
          <span translate>Plaza Novus Mare, Cabarete,</span>
        </div>
        <div (click)="openLauncher()">
          <span class="iti-flag do d-inline-block me-1"></span>
          <span translate>Dominican Republic</span>
        </div>
        <button class="btn btn-success mb-0" (click)="navigateToMessages()" translate>Message Support</button>
      </div>
    </div>
  </div>
</div>
