import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { FormGroup, NG_VALUE_ACCESSOR, FormBuilder, Validators } from '@angular/forms';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { NavDataService } from '@app/core/services';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CaptchaComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent implements OnInit {
  @Input() public captchaGroup: FormGroup;
  @ViewChild('invisibleCaptchaElem', { static: false }) invisibleCaptchaElem: InvisibleReCaptchaComponent;
  public pAuthFailed: number;
  public isCaptchaVisible = false;
  public siteKey = environment.captchaKey;
  public lang: string;
  public isBrowser: boolean;

  constructor(
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private navDataService: NavDataService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.lang = translate.currentLang;
    this.isBrowser = !this.isUserLoggegIn();
  }

  @Input() public get authFailed(): number {
    return this.pAuthFailed;
  }
  public set authFailed(value: number) {
    this.pAuthFailed = value;
    this.reloadInvisibleCaptcha();
    this.updateCaptchaVisibility();
  }

  ngOnInit() {
    this.captchaGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
  }

  public handleLoad(): void {
    this.updateInvisibleCaptcha();
  }

  public handleExpire(): void {
    this.reload();
  }

  private reloadInvisibleCaptcha() {
    if (this.authFailed < 2 && !this.isCaptchaVisible && !!this.invisibleCaptchaElem) {
      this.invisibleCaptchaElem.reloadCaptcha();
    }
  }

  private updateInvisibleCaptcha() {
    setTimeout(() => {
      try {
        this.invisibleCaptchaElem.execute();
      } catch {
        this.isCaptchaVisible = true;
      }
    });
  }

  private updateCaptchaVisibility() {
    if (this.authFailed > 1 && this.isCaptchaVisible) {
      this.reload();
    } else if (this.authFailed > 1) {
      this.isCaptchaVisible = true;
    }
  }

  private reload(): void {
    this.invisibleCaptchaElem.reloadCaptcha();
  }

  private isUserLoggegIn() {
    this.navDataService.currentUserProfile$.subscribe((data) => {
      if (isPlatformBrowser(this.platformId) && data) {
        return true;
      }
    });
    return false;
  }
}
