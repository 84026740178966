import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, reduce, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DeviceDetectService } from '../device-detect/device-detect.service';
import { NavDataService } from '../nav-data/nav-data.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { I18nService } from '../i18n/i18n.service';

export interface SuggestionPlaceOptions {
  types?: string[];
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  bounds?: google.maps.LatLngBounds;
  fields: string[];
  strictBounds?: boolean;
  oroigin?: google.maps.LatLng;
}

export interface AutoCompletePlaceQueryItem {
  description: string;
  place_id: string;
}

export interface DrupalAddress {
  country_code: string;
  country_name: string;
  address_line1: string;
  locality: string;
  postal_code: string;
  administrative_area: string;
}

export class Place {
  formatted_address = '';
  street_number = '';
  address = '';
  locality = '';
  province = '';
  short_province = '';
  region = '';
  country = '';
  lat: number;
  lon: number;
  cap = '';
}

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  standardMapOptions: google.maps.MapOptions = {
    // scrollwheel: false,
    gestureHandling: 'auto',
    mapTypeControl: false,
    streetViewControl: false,
    draggable: true,
    mapId: environment.googleMapId,
    zoomControlOptions: {
      // it should be google.maps.ControlPosition.RIGHT_CENTER, but i'm using a direct value
      // to avoid a problem with not loaded google maps library
      position: 7,
    },
  };

  mapsLoaded$: Observable<boolean>;
  autocompleteLoaded$: Observable<boolean>;
  private autoCompleteService: google.maps.places.AutocompleteService;
  private placeService: google.maps.places.PlacesService;
  private clusterMarkerEnabled = false;
  private types = [
    { name: 'street_number', toFind: 'street_number', short: false },
    { name: 'address', toFind: 'route', short: false },
    { name: 'locality', toFind: 'locality', short: false },
    { name: 'locality', toFind: 'administrative_area_level_3', short: false },
    { name: 'province', toFind: 'administrative_area_level_2', short: false },
    {
      name: 'short_province',
      toFind: 'administrative_area_level_2',
      short: true,
    },
    { name: 'region', toFind: 'administrative_area_level_1', short: false },
    { name: 'country', toFind: 'country', short: false },
    { name: 'cap', toFind: 'postal_code', short: false },
  ];
  private pMapsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pAutocompleteLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isInitializating = false;

  constructor(
    private httpClient: HttpClient,
    private deviceDetect: DeviceDetectService,
    private navDataService: NavDataService,
    private inAppBroser: InAppBrowser,
    private i18nService: I18nService
  ) {
    this.mapsLoaded$ = this.pMapsLoaded$.asObservable();
    this.autocompleteLoaded$ = this.pAutocompleteLoaded$.asObservable();
    this.i18nService.changedSelectedLanguage$.subscribe({
      next: () => {
        // WEB-4130 it's not possible to reload on the fly google maps libary
        // so wee need to reload the page.
        if (window.google) {
          setTimeout(
            () => {
              window.location.reload();
            },
            this.navDataService.isLogged ? 0 : 300
          );
        }
      },
    });
  }

  /**
   * Convert a google maps place to a Place.
   *
   * @param place
   *  Place to convert.
   * @returns
   *  Place object.
   */
  convert(place: google.maps.places.PlaceResult | any): Place {
    const _place = new Place();
    let element;
    for (const type of this.types) {
      element = place.address_components.find((elem) => elem.types.indexOf(type.toFind) !== -1);

      if (element === undefined || _place[type.name] !== '') {
        continue;
      }

      if (type.short) {
        _place[type.name] = element.short_name;
      } else {
        _place[type.name] = element.long_name;
      }
    }
    _place.formatted_address = place.formatted_address;
    if (place.lat) {
      _place.lat = place.lat;
    } else {
      _place.lat = place.geometry.location.lat;
    }
    if (place.lng) {
      _place.lon = place.lng;
    } else {
      _place.lon = place.geometry.location.lng;
    }

    return _place;
  }

  /**
   * Convert a google maps place to a drupal address.
   *
   * @param address
   *  Place to convert.
   * @returns
   *  Drupal address object.
   */
  convertAddressToDrupalAddress(address: google.maps.places.PlaceResult): DrupalAddress {
    const drupalAddress: DrupalAddress = {
      country_code: '',
      country_name: '',
      address_line1: '',
      locality: '',
      postal_code: '',
      administrative_area: '',
    };

    address.address_components.forEach((item) => {
      switch (true) {
        case item.types.indexOf('country') !== -1:
          drupalAddress.country_code = item.short_name;
          drupalAddress.country_name = item.long_name;
          break;
        case item.types.indexOf('locality') !== -1:
          drupalAddress.locality = item.long_name;
          break;
        case item.types.indexOf('administrative_area_level_3') !== -1 && !drupalAddress.locality:
          drupalAddress.locality = item.long_name;
          break;
        case item.types.indexOf('administrative_area_level_2') !== -1:
          drupalAddress.administrative_area = item.short_name;
          break;
        case item.types.indexOf('postal_code') !== -1:
          drupalAddress.postal_code = item.long_name;
          break;
        case item.types.indexOf('route') !== -1:
          drupalAddress.address_line1 = item.long_name;
          break;
      }
    });
    if (address.address_components[0].types.indexOf('"street_number"')) {
      drupalAddress.address_line1 += ' ' + address.address_components[0].long_name;
    }
    return drupalAddress;
  }

  /**
   * Calculate the radius of the maps viewport.
   * @param viewport
   *  Viewport of the map.
   * @returns
   *  Radius in km.
   */

  calculateRadiusOfVieport(viewport: google.maps.LatLngBounds): number {
    const center = viewport.getCenter();
    const northEast = viewport.getNorthEast();

    // r = radius of the earth in statute kilometers
    const radius = 6378;

    // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
    const lat1 = center.lat() / 57.2958;
    const lon1 = center.lng() / 57.2958;
    const lat2 = northEast.lat() / 57.2958;
    const lon2 = northEast.lng() / 57.2958;

    // distance = circle radius from center to Northeast corner of bounds
    const result =
      radius * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
    return result > 1 ? result : 1;
  }

  /**
   * Get place details from google maps api.
   *
   * @param placeId
   *   Place id to get details.
   * @returns
   *  Observable with place details.
   */
  getPlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    return new Observable((observer) => {
      this.placeService.getDetails({ placeId, language: this.i18nService.language }, (place, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return observer.error('error fetching place');
        }
        observer.next(place);
        observer.complete();
      });
    });
  }

  /**
   * Search with google autocomplete service
   * to suggest places matching with searchText.
   *
   *
   * @param searchText
   *   Text to search.
   * @returns
   *   Observable of list of suggestion with place_id reference.
   */
  suggestionPlaces(searchText: string, options?: SuggestionPlaceOptions): Observable<AutoCompletePlaceQueryItem[]> {
    if (!this.autoCompleteService) {
      return of([]);
    }
    const request = {
      ...{
        input: searchText,
        language: this.i18nService.language,
        types: ['locality', 'country', 'continent', 'sublocality', 'administrative_area_level_1'],
      },
      ...options,
    };
    return from(this.autoCompleteService.getPlacePredictions(request)).pipe(
      map((res: any) => {
        if (!res.predictions) {
          throwError(() => new Error('wrong response from google maps api'));
        }
        return res.predictions
          .filter((item) => item.place_id)
          .map((item) => ({
            description: item.description,
            place_id: item.place_id,
          }));
      })
    );
  }

  /**
   * Do a reverse geocoding from coordinates.
   *
   * @param lat
   *   Latitude of the place.
   * @param lng
   *   Longitude of the place.
   * @returns
   *  Observable with place details.
   */
  reverseGeocoding(lat: number, lng: number): Observable<Place> {
    return this.httpClient
      .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=` + environment.googleMapsKey)
      .pipe(
        map((res: any) => {
          if (!res.results) {
            throwError(() => new Error('wrong response from google maps api'));
          }
          return this.convert(res.results[0]);
        })
      );
  }

  /**
   * Load google maps library.
   *
   * @param enableClusterMarker
   *   If yes load also the cluster marker library.
   * @returns
   *  An observable with the result of the loading.
   */
  initGoogleMaps(enableClusterMarker = false): Observable<boolean> {
    if (window.google) {
      if (enableClusterMarker && !this.clusterMarkerEnabled) {
        this.pMapsLoaded$.next(false);
        return this.loadClusterMarkers().pipe(tap((result) => this.pMapsLoaded$.next(result)));
      }
      return of(true);
    }
    if (this.isInitializating) {
      return of(true);
    }
    this.isInitializating = true;
    const processes: Observable<boolean>[] = [];
    if (enableClusterMarker) {
      processes.push(this.loadClusterMarkers());
    }

    processes.push(
      this.httpClient
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places,visualization&v=beta&language=${this.i18nService.language}`,
          'callback'
        )
        .pipe(
          map(() => true),
          catchError((err) => {
            console.log(err);
            return of(false);
          })
        )
    );
    return from([true]).pipe(
      switchMap(() => {
        if (this.pMapsLoaded$.value) {
          return of(true);
        }
        return forkJoin(processes).pipe(
          reduce((result, currentValue) => currentValue && result, true),
          switchMap((result) => {
            this.autoCompleteService = new google.maps.places.AutocompleteService();
            this.placeService = new google.maps.places.PlacesService(
              new google.maps.Map(document.createElement('div'))
            );
            return from(google.maps.importLibrary('marker')).pipe(
              map((marker: google.maps.MarkerLibrary) => {
                google.maps.marker = marker;
                return result;
              })
            );
          }),
          tap((result: boolean) => {
            this.pMapsLoaded$.next(result);
            this.isInitializating = false;
          })
        );
      })
    );
  }

  /**
   * Load google maps autocomplete library.
   *
   * @returns
   *  An observable with the result of the loading.
   */
  initGoogleMapsAutoComplete(): Observable<boolean> {
    if (window.google?.maps?.places) {
      return of(true);
    }
    return this.initGoogleMaps().pipe(
      map(() => {
        this.pAutocompleteLoaded$.next(true);
        return true;
      }),
      catchError((err) => {
        console.log(err);
        this.pAutocompleteLoaded$.next(false);
        return of(false);
      })
    );
  }

  /**
   * Open google maps to show the place, in a different tab in the browser
   * or in inappbrowser if the app is running in cordova.
   *
   * @param query
   *   Query of the place to show.
   * @param zoom
   *   Zoom of the map.
   * @param lat
   *  Latitude of the place.
   * @param lng
   *  Longitude of the place.
   * @param label
   *  Label of the place.
   */
  openExternalMap(query: string, zoom = 10, lat?: number, lng?: number, label?: string): void {
    query = query.replace(' ', '+');
    if (this.deviceDetect.isCordova) {
      if (this.deviceDetect.device.platform === 'iOS') {
        // window.open('maps://?q=' + geoCooords, 'blank');
        if (lat && lng) {
          query += `&ll=${lat},${lng}`;
        }
        this.inAppBroser.create(`maps://?z=${zoom}&q=${query}`);
      } else {
        let url = `geo:0,0?z=${zoom}&q=${query}`;
        if (label) {
          url += '(' + label + ')';
        }
        window.open(url, '_system');
      }
      return;
    }
    this.navDataService.navigate(`http://maps.google.com/maps?z=${zoom}&t=m&q=${query}`, true);
  }

  /**
   * Load the cluster marker library.
   *
   * @returns
   *  An observable with the result of the loading.
   */
  private loadClusterMarkers(): Observable<boolean> {
    if (this.clusterMarkerEnabled) {
      return of(true);
    }
    return new Observable((observer) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js`;
      script.onload = () => {
        observer.next(true);
        observer.complete();
        this.clusterMarkerEnabled = true;
      };
      script.onerror = () => {
        observer.next(false);
        observer.complete();
      };
      document.body.appendChild(script);
    });
  }
}
