import { Injectable } from '@angular/core';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';
import { MembershipSubscriptionsComponent } from './membership-subscriptions.component';

@Injectable({
  providedIn: 'root',
})
export class MembershipSubscriptionsService extends ModalServiceModel {
  protected component = MembershipSubscriptionsComponent;
}
