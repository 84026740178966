import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { UploadDownloadProgress, uploadDownloadProgress } from './upload-download-progress';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  uploadFileLoading = false;
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  downloadFile(url: string): Observable<UploadDownloadProgress> {
    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
        withCredentials: true,
      })
      .pipe(uploadDownloadProgress());
  }

  changeUploadFileLoading(val) {
    this.uploadFileLoading = val;
  }

  // delete null values from object
  removeEmpty(obj) {
    return (
      Object.entries(obj)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, v]) => v !== null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    );
  }

  convertDateToCurrentTimeZone(date) {
    const userTimeZone = moment.tz.guess(); // Automatically detect user's time zone

    // Create a moment object with the original time in UTC
    const utcMoment = moment.utc(date);

    // Convert the time to the user's time zone
    const userMoment = utcMoment.clone().tz(userTimeZone);

    // Format the times for display
    if (userTimeZone !== 'Asia/Calcutta') {
      return userMoment.format('hh:mm A');
    }

    return moment(date).format('hh:mm A');
  }

  // get qualification level based on next user level
  determineKiteboarderLevel(currentLevel: string): number {
    if (!currentLevel) {
      return 0;
    }
    currentLevel = currentLevel.toLowerCase();

    const BeginnerLevels: string[] = [
      'Kiteboarder Level 1A',
      'Kiteboarder Level 1B',
      'Kiteboarder Level 1C',
      'Kiteboarder Level 1D',
      'Kiteboarder Level 1E',
      'Kiteboarder Level 2F',
      'Kiteboarder Level 2G',
      'Kiteboarder Level 2H',
      'Kiteboarder Level 2I',
      'Kiteboarder Level 3J',
      'Kiteboarder Level 3K',
      'Kiteboarder Level 3L',
      'Kiteboarder Level 3M',
      'Kiteboarder Level 3N',
    ];

    const AdvancedLevels: string[] = [
      'Kiteboarder Level 4O',
      'Kiteboarder Level 4P',
      'Kiteboarder Level 4Q',
      'Kiteboarder Level 4R',
      'Kiteboarder Level 4S',
      'Kiteboarder Level 4T',
      'Kiteboarder Level 4U',
      'Kiteboarder Level 4V',
      'Kiteboarder Level 4W',
      'Kiteboarder Level 4X',
      'Kiteboarder Level 4Y',
    ];

    const EvolutionLevels: string[] = [
      'Kiteboarder Level 5 Freeride',
      'Kiteboarder Level 5 Freestyle',
      'Kiteboarder Level 5 Wave',
      'Kiteboarder Level 5 Hydrofoil',
      'Wing Foil Level 5',
    ];

    const AssistantLevels: string[] = ['Assistant'];
    const InstructorLevels: string[] = ['Instructor Level 1'];

    if (BeginnerLevels.some((item) => item.toLowerCase() === currentLevel)) {
      return 3654;
    } else if (AdvancedLevels.some((item) => item.toLowerCase() === currentLevel)) {
      return 3661;
    } else if (EvolutionLevels.some((item) => item.toLowerCase() === currentLevel)) {
      return 3659;
    } else if (AssistantLevels.some((item) => item.toLowerCase() === currentLevel)) {
      return 3658;
    } else if (InstructorLevels.some((item) => item.toLowerCase() === currentLevel)) {
      return 3657;
    } else {
      return null;
    }
  }
}
