import { Injectable } from '@angular/core';
import { StandardComponent } from './standard.component';
import { ModalServiceModel } from '@app/core/services/modal-manager/modal-service.model';

@Injectable({
  providedIn: 'root',
})
export class StandardModalService extends ModalServiceModel {
  protected component = StandardComponent;
  protected options = { size: 'md', windowClass: 'd-flex modal-dialog-centered' };
}
